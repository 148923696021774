import React from 'react'
import { siteKey } from '../constants'
import makeInspectable from 'mobx-devtools-mst'
import ProductionStation from '../models/production-stations'
import { createContext } from 'react'

const storageKey = `${siteKey}:production-stations`

const initialState = !!localStorage.getItem(storageKey)
  ? JSON.parse(localStorage.getItem(storageKey))
  : {
      list: []
    }

const store = ProductionStation.create(initialState)

makeInspectable(store)

export const ProductionStoreContext = createContext(store)

interface Props {
  children: React.ReactNode
}

export default function ProductionStore({ children }: Props) {
  return (
    <ProductionStoreContext.Provider value={store}>
      {children}
    </ProductionStoreContext.Provider>
  )
}
