import React, { useCallback, useMemo } from 'react'
import Flex from '../../../../../components/auto-flow-flex'
import Card from '../../../../../components/card'
import CardTitle from '../../../../../components/card-title'
import Checkbox from '../../../../../components/checkbox'
import { RadioSelect } from '../../../../../components/radio-button'
import { Gridder } from '../../../../../components/grids'
import HorizontalTab from '../../../../../components/horizontal-tab'
import { BiquadStage } from '../../../../../types/dbmc2evb2'
import { resizeArray } from '../../../../../utils/array'
import initialData from '../../constants/initial-data'
import FilterViewer from '../filter-viewer'
import LoadingSpinner from '../../../../../components/loading-spinner'
import FilterEditor from '../filter-editor'
import { PlaybackParameters } from '../../../../../types/dbmc2evb2'
import { createInputComponents } from '../input-fields'
import playbackFields from '../../constants/fields/playback-fields'
import { updateRegister } from '../../utils/binary'

const rates = [8000, 16000, 44100, 48000, 96000]
const channelToString = (c: number) => (c === 1 ? 'Mono' : 'Stereo')
const editorComponentHeight = '220px'

const channelOptions = [1, 2].map(channel => ({
  label: channelToString(channel),
  value: channel
}))

interface PlaybackData {
  parameters: PlaybackParameters
  onChange: (p: PlaybackParameters) => void
}

const getRateName = (r: number) => {
  const dps = 1
  const n = (r / 1000).toFixed(dps)
  const c = n.slice(-dps) === '0'.repeat(dps) ? n.slice(0, -(dps + 1)) : n
  return `${c} kHz`
}

const domains = [
  { label: 'Comp1', value: 'comp1' },
  { label: 'Equ', value: 'equ' }
]

interface PlaybackFilterViewerProps {
  parameters: typeof initialData.parameters.playback
}

function PlaybackFilterViewer(props: PlaybackFilterViewerProps) {
  const { parameters } = props

  const getChannelName = useCallback(
    (index: number) => {
      if (parameters.header.number_of_channels < 2) return ''
      return index === 0 ? 'Left' : 'Right'
    },
    [parameters]
  )

  const stages = useMemo(
    () =>
      parameters.data.rates.reduce((rates, rate) => {
        rates.push(
          rate.channels.reduce((channels, channel) => {
            channels.push(
              ['comp1', 'equ'].map(domain => channel[domain].stages)
            )
            return channels
          }, [])
        )
        return rates
      }, []),
    [parameters]
  )

  return (
    <FilterViewer
      rates={parameters.header.rates.map(r => ({
        label: getRateName(r),
        value: r
      }))}
      channels={[1, 2]
        .slice(0, parameters.header.number_of_channels)
        .map((channel, index) => ({
          label: getChannelName(index),
          value: channel
        }))}
      domains={domains}
      stages={stages}
      persistenceKey="playback"
    />
  )
}

interface Props {
  parameters: PlaybackParameters
  onChange: (s: PlaybackParameters) => void
}

export default function PlaybackDesigner(props: Props) {
  console.log('*** PlaybackDesigner')

  const { parameters, onChange } = props
  const isLoading = parameters === undefined

  const updateChannels = useCallback(
    (numOfChannels: number) => {
      parameters.header.number_of_channels = numOfChannels
      parameters.data.rates.forEach(
        rate =>
          (rate.channels = resizeArray(
            rate.channels,
            parameters.header.number_of_channels
          ))
      )
      onChange(parameters)
    },
    [parameters, onChange]
  )

  const updateRates = useCallback(
    (rate, newValue) => {
      const i = parameters.header.rates.indexOf(rate)
      if (newValue === false) {
        if (parameters.header.number_of_rates > 1) {
          if (i >= 0) {
            parameters.header.rates.splice(i, 1)
            parameters.header.number_of_rates = parameters.header.rates.length
            parameters.data.rates.splice(i, 1)
          }
        }
      } else {
        if (i === -1) {
          parameters.header.rates.push(rate)
          parameters.header.number_of_rates = parameters.header.rates.length
          parameters.data.rates = resizeArray(
            parameters.data.rates,
            parameters.header.number_of_rates
          )
        }
      }
      onChange(parameters)
    },
    [parameters, onChange]
  )

  const getDomainName = (domain: string) =>
    domain === 'comp1' ? 'Comp1' : 'Equ'

  const getChannelName = useCallback(
    (index: number) => {
      if (parameters.header.number_of_channels < 2) return ''
      return index === 0 ? 'Left' : 'Right'
    },
    [parameters]
  )

  const getBiquadCoefficientName = (stage: number, coefficient: string) =>
    `BQ${stage}_${coefficient.toUpperCase()}`

  const handleBiquadStageChange = useCallback(
    (
      biquadStage: BiquadStage,
      rate: number,
      channel: number,
      domain: string,
      stage: number
    ) => {
      const rateIndex = parameters.header.rates.indexOf(rate)
      parameters.data.rates[rateIndex].channels[channel][domain].stages[
        stage - 1
      ] = biquadStage

      console.log('onChange', parameters)
      onChange(parameters)
    },
    [onChange, parameters]
  )

  const createFilterEditor = useCallback(
    (rate: number, channel: number, domain: string, stage: number) => {
      const rateIndex = parameters?.header.rates.indexOf(rate)
      const rates = parameters?.data.rates[rateIndex]
      const biquadStage: BiquadStage =
        rates?.channels[channel][domain].stages[stage - 1]

      return function render() {
        return (
          <div>
            <FilterEditor
              onChange={biquadStage =>
                handleBiquadStageChange(
                  biquadStage,
                  rate,
                  channel,
                  domain,
                  stage
                )
              }
              rate={rate}
              biquadStage={biquadStage}></FilterEditor>
          </div>
        )
      }
    },
    [handleBiquadStageChange, parameters]
  )

  const createStagesComponent = useCallback(
    (rate: number, channel: number, domain: string) => {
      const getStages = (domain: string) =>
        domain === 'comp1' ? [1, 2, 3, 4] : [1, 2, 3, 4, 5]

      return function render() {
        return (
          <HorizontalTab
            nested
            persistenceKey={`playback-filter`}
            padOptions={true}
            navigationProps={{
              width: '120px'
            }}
            height={editorComponentHeight}
            options={getStages(domain).map(stage => {
              return {
                title: `Stage ${stage}`,
                component: createFilterEditor(rate, channel, domain, stage)
              }
            })}
          />
        )
      }
    },
    [createFilterEditor]
  )

  const createParamsComponent = useCallback(
    (rate: number, channel: number, domain: string) => {
      const getStages = (domain: string) =>
        domain === 'comp1' ? [1, 2, 3, 4] : [1, 2, 3, 4, 5]

      const rateIndex = parameters?.header.rates.indexOf(rate)
      const rates = parameters?.data.rates[rateIndex]

      return function render() {
        return (
          <HorizontalTab
            navigationProps={{
              width: '120px'
            }}
            nested
            height={editorComponentHeight}
            persistenceKey={`playback-params`}
            options={[
              {
                title: 'BQC_CFG1',
                component: createInputComponents(
                  rates.channels[channel][domain].bqc_cfg1,
                  playbackFields.rate.channel[domain].BQC_CFG1.fields,
                  playbackFields.rate.channel[domain].BQC_CFG1.mask,
                  (field, { value, bitmin, bitmax }) => {
                    rates.channels[channel][domain].bqc_cfg1 = updateRegister(
                      rates.channels[channel][domain].bqc_cfg1,
                      bitmin,
                      bitmax,
                      value
                    )
                    onChange(parameters)
                  }
                )
              },
              ...getStages(domain).map((stage, stageIndex) =>
                Object.keys(playbackFields.rate.channel[domain].stage)
                  .map(
                    coefficient =>
                      playbackFields.rate.channel[domain].stage[coefficient]
                  )
                  .map(coefficient => ({
                    title: getBiquadCoefficientName(
                      stageIndex,
                      coefficient.prop
                    ),
                    component: createInputComponents(
                      rates.channels[channel][domain].stages[stageIndex][coefficient.prop],
                      coefficient.fields,
                      coefficient.mask,
                      (field, { value, bitmin, bitmax }) => {
                        rates.channels[channel][domain].stages[stageIndex][coefficient.prop] = updateRegister(
                          rates.channels[channel][domain].stages[stageIndex][coefficient.prop],
                          bitmin,
                          bitmax,
                          value
                        )
                        onChange(parameters)
                      }
                    )
                  }))
              )
            ].flat()}
          />
        )
      }
    },
    [onChange, parameters]
  )

  const createChannelComponent = useCallback(
    (channel: number, rate: number) => {
      return function render() {
        return (
          <HorizontalTab
            nested
            persistenceKey={`playback-domain`}
            padOptions={false}
            navigationProps={{
              width: '120px'
            }}
            height={editorComponentHeight}
            options={['comp1', 'equ'].map(domain => ({
              title: getDomainName(domain),
              component: function domainTab() {
                return (
                  <HorizontalTab
                    nested
                    persistenceKey={`playback-stage`}
                    padOptions={false}
                    navigationProps={{
                      width: '120px'
                    }}
                    height={editorComponentHeight}
                  
                    options={[
                      {
                        title: 'Stages',
                        component: createStagesComponent(rate, channel, domain)
                      },
                      {
                        title: 'Params',
                        component: createParamsComponent(rate, channel, domain)
                      }
                    ]}
                  />
                )
              }
            }))}
          />
        )
      }
    },
    [createParamsComponent, createStagesComponent]
  )

  const createRateComponent = useCallback(
    (rate: number) => {
      if (parameters?.header.number_of_channels < 2) {
        return createChannelComponent(0, rate)
      } else {
        return function render() {
          return (
            <HorizontalTab
              nested
              persistenceKey={`playback-channel`}
              padOptions={false}
              navigationProps={{
                width: '120px'
              }}
              height={editorComponentHeight}
              options={[0, 1].map(channel => ({
                title: getChannelName(channel),
                component: createChannelComponent(channel, rate)
              }))}
            />
          )
        }
      }
    },
    [createChannelComponent, getChannelName, parameters]
  )

  const sortedRates = parameters?.header?.rates?.sort(
    (a: number, b: number) => a - b
  )
  
  

  return (
    <div style={{ borderWidth: 2, borderColor: 'red' }}>
      {isLoading ? (
        <div style={{ textAlign: 'center' }}>
          <LoadingSpinner variant={'dark'} />
        </div>
      ) : (
        <div>
          <Gridder align={'start'} template={['200px', '1fr']} gap={'24px'}>
            <Card>
              <CardTitle>Channels</CardTitle>
              <RadioSelect
                options={channelOptions}
                onChange={o => updateChannels(o.value as number)}
                value={parameters.header.number_of_channels}
              />
            </Card>
            <Card>
              <CardTitle>Rates</CardTitle>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                {rates.map(rate => (
                  <Flex key={rate} gap={'12px 8px'} style={{ marginRight: 20 }}>
                    <Checkbox
                      onChange={newValue => updateRates(rate, newValue)}
                      initialValue={parameters.header.rates.includes(rate)}
                    />
                    <p>{getRateName(rate)}</p>
                  </Flex>
                ))}
              </div>
            </Card>
          </Gridder>

          <Card padding={'8px 0px'}>
            <HorizontalTab
              nested
              padOptions={false}
              persistenceKey={'playback-rate'}
              navigationProps={{
                width: '120px'
              }}
              height={editorComponentHeight}
              options={sortedRates.map(rate => ({
                title: getRateName(rate),
                component: createRateComponent(rate)
              }))}
            />
          </Card>

          <div>
            <PlaybackFilterViewer
              parameters={parameters}></PlaybackFilterViewer>
          </div>
        </div>
      )}
    </div>
  )
}
