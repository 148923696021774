import styled from '@emotion/styled/macro'
import React, { useMemo } from 'react'
import { useParams } from 'react-router'
import { ReactComponent as AddIcon } from '../../../assets/icons/plus-icon.svg'
import Button from '../../../components/button'
import Error from '../../../components/error'
import Pagination from '../../../components/pagination'
import SectionTitle from '../../../components/section-title'
import Table from '../../../components/table'
import TopRightIcon from '../../../components/top-right-icon'
import Content from '../../../containers/content'
import useAsync from '../../../hooks/use-async'
import useFilter from '../../../hooks/use-filter'
import usePagedAsync from '../../../hooks/use-paged-async'
import useToggle from '../../../hooks/use-toggle'
import {
  fetchProduct,
  fetchProductDetails,
  fetchProductImage,
  fetchProductLogs,
  fetchProductResults,
  fetchProductStations,
  fetchProductUsers,
  removeUserFromProduct
} from '../../../services/entities/product'
import { areInitialized } from '../../../utils/common'
import AddVisibleUserForm from './add-visible-user-form'
import ProductLogs from './logs'
import ProductDetails from './product-details'
import ProductResult, { createResultsMeta } from './result'
import ProductStation from './station'
import ProductUsers from './users'
import { ModalWrapper } from '../../../components/modal'

export interface SectionProp<T> {
  data: T[]
  count: number
  loading: boolean
  error: React.ComponentProps<typeof Error>['error']
  setPage: React.ComponentProps<typeof Pagination>['onPageChange']
  tableTitle?: React.ComponentProps<typeof Table>['title']
  query?: ReturnType<typeof useFilter>
  initialized?: boolean
  pageSize: number
}

export default function Product() {
  const { id } = useParams()
  const idParams = useMemo(() => [id], [id])
  const { loading, data: productInfo, error } = useAsync({
    fn: fetchProduct,
    args: idParams
  })
  const idArgs = useMemo(() => [id], [id])
  const logs = usePagedAsync({
    fn: fetchProductLogs,
    args: idArgs,
    entity: 'logs'
  })
  const stations = usePagedAsync({
    fn: fetchProductStations,
    args: idArgs,
    entity: 'stations'
  })
  const resultsPayload = useMemo(
    () => ({
      filterKey: `product-results/${id}`,
      filter: createResultsMeta().filters
    }),
    [id]
  )
  const results = usePagedAsync({
    fn: fetchProductResults,
    args: idArgs,
    opts: resultsPayload,
    entity: 'results'
  })
  const users = usePagedAsync({
    fn: fetchProductUsers,
    args: idArgs,
    entity: 'users'
  })
  const details = useAsync({ fn: fetchProductDetails, args: idArgs })
  const image = useAsync({ fn: fetchProductImage, args: idArgs })
  const [
    addVisibleUserShown,
    showAddVisibleUser,
    hideAddVisibleUser
  ] = useToggle(false)
  const contentReady =
    !loading && areInitialized(logs, stations, results, users, details)

  const closeAddVisibleUserModal = () => {
    users.refreshData()
    hideAddVisibleUser()
  }
  const onDeleteVisibleUser = async (email: string) => {
    await removeUserFromProduct(id, email)
    users.refreshData()
  }

  return (
    <Content
      subtitle={['Products', '/missioncontrol/products']}
      title={'Missioncontrol'}
      itemName={productInfo?.name ?? ''}
      itemNameLoading={loading}
      loading={!contentReady}
      error={error}
      section={false}>
      <Section>
        <ProductDetails productId={id} {...details} image={image} />
      </Section>
      <Section>
        <ProductResult
          entity={`product/${id}`}
          tableTitle={'Test Results'}
          productId={id}
          {...results}
          pageSize={Number(results.pageSize)}
        />
      </Section>
      <Section>
        <ProductStation tableTitle={'Stations'} {...stations} />
      </Section>
      <VisibleUsersContainer>
        <TopRightIcon>
          <Button icon={true} onClick={showAddVisibleUser}>
            <AddIcon height={18} />
          </Button>
        </TopRightIcon>
        <SectionTitle title={'Visible Users'} />
        <ProductUsers onDelete={onDeleteVisibleUser} {...users} />
      </VisibleUsersContainer>
      <OneThird>
        <SectionTitle title={'Activity Logs'} />
        <ProductLogs {...logs} />
      </OneThird>
      <ModalWrapper
        onClose={hideAddVisibleUser}
        title={'Add a Visible User'}
        shown={addVisibleUserShown}
        hide={hideAddVisibleUser}>
        <AddVisibleUserFormContent>
          <AddVisibleUserForm
            productId={id}
            closeModal={closeAddVisibleUserModal}
          />
        </AddVisibleUserFormContent>
      </ModalWrapper>
    </Content>
  )
}

const OneThird = styled.div`
  max-width: 300px;
`

const VisibleUsersContainer = styled.div`
  position: relative;
  width: fit-content;
  padding-right: 10px;
  padding-top: 16px;
  min-height: 180px;
`

const AddVisibleUserFormContent = styled.div`
  width: 300px;
`

const Section = styled.div`
  transition: 400ms all;
  display: grid;
  width: 100%;
  margin-bottom: 28px;
`
