import styled from '@emotion/styled/macro'
import React from 'react'
import { useFormik } from 'formik'
import { ReactComponent as ArrowRight } from '../../../assets/icons/arrow-right.svg'
import Button from '../../../components/button'
import Card from '../../../components/card'
import CenteredMargin from '../../../components/centered-margin'
import Status from '../../../components/status'
import Subtitle from '../../../components/subtitle'
import TopRightIcon from '../../../components/top-right-icon'
import { postStationSupport } from '../../../services/entities/station'
import { SupportConnection } from '../../../types/station'
import { interpretError } from '../../../utils/common'
import Input from '../../../components/input'

interface Props {
  stationName: string
}

export default function ChannelForm({
  stationName
}: Props & Partial<SupportConnection>) {
  const {
    handleSubmit,
    status,
    setStatus,
    isSubmitting,
    resetForm,
    values: { port },
    isValid,
    dirty,
    handleChange
  } = useFormik({
    initialValues: {
      port: 0
    },
    onSubmit: async ({ port }) => {
      try {
        await postStationSupport(stationName, port)
        resetForm()
        setStatus(`Successfully queued support channel at: ${port}`)
      } catch (err) {
        setStatus(interpretError(err))
      }
    },
    validate: ({ port }) => ({
      ...(port < 0 && port > 65336 ? { port: 'Enter a valid port' } : {})
    })
  })

  return (
    <Container>
      <Card>
        <Subtitle>Open Support</Subtitle>
        {status && <Status>{status}</Status>}
        <form onSubmit={handleSubmit}>
          <CenteredMargin>
            <Input
              type="number"
              placeholder="Port"
              min={1}
              max={65336}
              value={port ?? ''}
              required
              name="port"
              onChange={handleChange}
            />
          </CenteredMargin>
          <TopRightIcon>
            <Button
              icon={true}
              type="submit"
              disabled={!isValid || !dirty}
              loading={isSubmitting}>
              <ArrowRight width={24} />
            </Button>
          </TopRightIcon>
        </form>
      </Card>
    </Container>
  )
}

const Container = styled.div`
  position: relative;
  width: 100%;
`
