import { Form, FormikProps, withFormik } from 'formik'
import { useObserver } from 'mobx-react-lite'
import queryString from 'query-string'
import React, { useContext, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import * as Yup from 'yup'
import {
  LinkUnderForm,
  LoginButton,
  LoginField,
  Subtitle
} from '../../components/auth'
import AuthLayout from '../../containers/auth-layout'
import { RootStoreContext } from '../../context/root-store'
import Authentication from '../../models/authentication'
import { createRequiredTextField } from '../../utils/form'
import { capitalize } from '../../utils/text'

interface Props {
  authentication: ReturnType<typeof Authentication.create>
}

const initialValues = {
  email: '',
  password: ''
}

const loginSchema = Yup.object().shape({
  email: createRequiredTextField('email').email('Enter a valid email.'),
  password: createRequiredTextField('password')
})

const ObserverLogin = (props: Props) => {
  const { authentication } = useContext(RootStoreContext)

  return useObserver(() => (
    <LoginWithFormik {...props} authentication={authentication} />
  ))
}

const LoginWithFormik = withFormik<Props, typeof initialValues>({
  mapPropsToValues: () => ({
    ...initialValues
  }),
  handleSubmit: async (
    { email, password },
    {
      props: {
        authentication: { login }
      }
    }
  ) => {
    await login(email, password)
  },
  validationSchema: loginSchema
})(Login)

export default ObserverLogin

function Login({
  isValid,
  isSubmitting,
  status,
  dirty,
  setStatus,
  authentication: { error, clearError }
}: FormikProps<typeof initialValues> & Props) {
  const location = useLocation()
  const { message } = queryString.parse(location.search)

  useEffect(() => {
    if (message) {
      setStatus(message)
    }
  }, [setStatus, message])

  useEffect(() => {
    clearError()
    !message && setStatus('')

    return () => {
      clearError()
      !message && setStatus('')
    }
  }, [message, clearError, setStatus])

  return (
    <AuthLayout>
      <Form>
        <Subtitle>{capitalize(error ? error : status ? status : '')}</Subtitle>
        <LoginField
          type="email"
          autoComplete="email"
          required
          placeholder="Email"
          disabled={isSubmitting}
          id="email"
          name="email"
          autoFocus
        />
        <LoginField
          type="password"
          name="password"
          placeholder="Password"
          required
          minLength={6}
          disabled={isSubmitting}
          id="password"
          autoComplete="password"
        />
        <LoginButton
          type="submit"
          disabled={!isValid || !dirty}
          loading={isSubmitting}
          variant={'light'}>
          Login
        </LoginButton>
        <LinkUnderForm>
          <Link to={'/forgot-password'}>Forgot your password?</Link>
        </LinkUnderForm>
      </Form>
    </AuthLayout>
  )
}
