import React from 'react'
import Clickable from '../../../components/clickable'
import Table, { TableData } from '../../../components/table'
import { Station } from '../../../types/station'
import { timestampToLocalDate } from '../../../utils/date-time'
import { SectionProp } from './product.page'

const stationsMeta: TableData<Station>['meta'] = {
  entity: 'stations',
  fields: {
    name: {
      label: 'Station',
      size: 2,
      content: function StationLink(name) {
        return (
          <Clickable to={`/missioncontrol/station/${name}`}>{name}</Clickable>
        )
      }
    },
    locationName: { label: 'Location', size: 1 },
    testVersion: { label: 'Version', size: 1 },
    testAuthor: { label: 'Author', size: 2 },
    licensee: { label: 'Licensee', size: 1 },
    licenceFrom: {
      label: 'Licence From',
      size: 2,
      content: timestampToLocalDate
    },
    licenceTo: {
      label: 'Licence To',
      size: 2,
      content: timestampToLocalDate
    },
    timestamp: {
      label: 'Last Status Report',
      size: 2,
      content: timestampToLocalDate
    }
  },
  arrangement: [
    'name',
    'locationName',
    'testVersion',
    'testAuthor',
    'licensee',
    'licenceFrom',
    'licenceTo',
    'timestamp'
  ]
}

export default function ProductStation({
  data,
  count,
  loading,
  error,
  setPage,
  tableTitle
}: SectionProp<Station>) {
  return (
    <Table
      meta={stationsMeta}
      data={data}
      keyExtractor={station => `${station.name}`}
      error={error}
      count={count}
      onPageChange={({ selected }) => setPage(selected)}
      loading={loading}
      title={tableTitle}
    />
  )
}
