import React, { useCallback } from 'react'
import styled from '@emotion/styled/macro'
import { range } from '../utils/stat'

interface Props {
  progress: { title: string; percent: number; millisRemaining?: number }
  foreground?: string
  background?: string
}

export default function ProgressBar({
  progress,
  background,
  foreground,
  ...props
}: React.ComponentProps<typeof Container> & Props) {
  if (!range(progress.percent, 0, 100))
    throw new Error(
      'Progress bar should not receive a number beyond 100 or less than 0.'
    )

  const formatTimeRemaining = useCallback(() => {
    const minutes = Math.floor(progress.millisRemaining / (1000 * 60))
    const seconds = Math.floor(progress.millisRemaining / 1000)

    if (minutes > 0)
      return minutes + ' minute' + (minutes === 1 ? '' : 's') + ' remaining'
    else return seconds + ' second' + (seconds === 1 ? '' : 's') + ' remaining'
  }, [progress.millisRemaining])

  return (
    <>
      <PercentLabel>{Math.ceil(progress.percent)}%</PercentLabel>
      <Container background={background} {...props}>
        <ProgressShade foreground={foreground} progress={progress.percent} />
      </Container>
      <TimeRemainingLabel>{formatTimeRemaining()}</TimeRemainingLabel>
    </>
  )
}

const PercentLabel = styled.div`
  text-align: center;
  margin-bottom: 10px;
  font-weight: bold;
`

const TimeRemainingLabel = styled.div`
  text-align: center;
  margin-bottom: 10px;
`

const ProgressShade = styled.div<{ progress: number; foreground?: string }>`
  height: 8px;
  border-radius: 99px;
  width: 100%;
  background-color: ${({ foreground }) => foreground || 'red'};
  width: ${({ progress }) => progress}%;
  transform-origin: left;
  border-radius: 99px;
  transition: 1s all;
`

const Container = styled.div<{ background?: string }>`
  background-color: ${({ background }) => background || '#d8d8d8'};
  padding: 0;
  margin: 0;
  border-radius: 99px;
  width: 100%;
  position: relative;
  transition: 1s all;
`
