import React from 'react'
import Table, { TableData } from '../../../components/table'
import { PagedAsync } from '../../../hooks/use-paged-async'
import { StationMessage } from '../../../types/station-message'
import { timestampToLocalDate } from '../../../utils/date-time'
import { capitalize } from '../../../utils/text'

const updatesMeta: TableData<StationMessage>['meta'] = {
  entity: 'updates',
  fields: {
    createdTimestamp: {
      label: 'Created',
      size: 2,
      content: timestampToLocalDate
    },
    description: {
      label: 'Description',
      size: 1,
      content: capitalize
    },
    initiator: {
      label: 'Initiator',
      size: 2
    },
    updatedTimestamp: {
      label: 'Updated',
      size: 2,
      content: timestampToLocalDate
    },
    response: {
      label: 'Response',
      size: 6,
      content: (data, datum) =>
        datum.status === 'Complete' && datum?.request?.data?.test?.Id
          ? `Installed test ID: ${datum.request.data.test.Id}`
          : data.status
    },
    status: {
      label: 'Status',
      size: 1,
      content: capitalize
    }
  },
  arrangement: [
    'createdTimestamp',
    'description',
    'initiator',
    'updatedTimestamp',
    'response',
    'status'
  ]
}

export default function StationUpdates({
  data,
  count,
  loading,
  error,
  setPage
}: PagedAsync<StationMessage>) {
  return (
    <Table
      meta={updatesMeta}
      data={data}
      keyExtractor={datum => datum.id}
      error={error}
      count={count}
      onPageChange={({ selected }) => setPage(selected)}
      loading={loading}
    />
  )
}
