import styled from '@emotion/styled/macro'
import React from 'react'
import { ReactComponent as CloseIcon } from '../assets/icons/close.svg'
import defaultUser from '../assets/images/default-user.png'
import useToggle from '../hooks/use-toggle'
import LoadingSpinner from './loading-spinner'
import TopRightIcon from './top-right-icon'
import { noop } from '../utils/common'

interface Props {
  company: string
  email: string
  name: string
  details?: Record<string, Record<string, string> | string>
  onDelete: (email: string) => void
}

export default function UserInfo({
  company,
  email,
  name,
  onDelete = noop
}: Props) {
  const [isCloseLoading, startCloseLoading, finishCloseLoading] = useToggle(
    false
  )
  const onCloseClicked = async () => {
    startCloseLoading()
    await onDelete(email)
    finishCloseLoading()
  }
  return (
    <Container>
      <TopRightIcon>
        {isCloseLoading ? (
          <LoadingSpinner variant={'dark'} width={18} />
        ) : (
          <StyledCloseIcon fill="#666" width={10} onClick={onCloseClicked} />
        )}
      </TopRightIcon>
      <Avatar src={defaultUser} alt={'Default user avatar'} />
      <Details>
        <Email>{email}</Email>
        <Name>{name}</Name>
        <Company>{company}</Company>
      </Details>
    </Container>
  )
}

const StyledCloseIcon = styled(CloseIcon)`
  opacity: 0;
  cursor: pointer;
`

const Company = styled.p`
  font-size: 12px;
  color: #666;
`

const Name = styled.p`
  color: #666666;
`

const Email = styled.p`
  color: #333333;
`

const Details = styled.div`
  font-weight: 300;
  line-height: 24px;
  font-size: 15px;
`

const Avatar = styled.img`
  height: 50px;
  width: 50px;
  background-color: #d4d4d4;
  border-radius: 50px;
`

const Container = styled.div`
  display: grid;
  grid-template-columns: 50px 1fr;
  grid-column-gap: 25px;
  align-items: center;
  margin-bottom: 8px;
  position: relative;
  padding-right: 40px;
  padding-top: 8px;

  &:hover svg {
    opacity: 1;
  }
`
