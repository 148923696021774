import styled from '@emotion/styled/macro'

const Subtitle = styled.p`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 12px;
  margin-top: 12px;
  line-height: 1.4;
`

export default Subtitle
