import styled from '@emotion/styled/macro'
import makeInspectable from 'mobx-devtools-mst'
import { observer } from 'mobx-react-lite'
import { types } from 'mobx-state-tree'
import React from 'react'
import { Flipper } from 'react-flip-toolkit'
import { ReactComponent as VisibilityIcon } from '../../../assets/icons/visibility.svg'
import Button from '../../../components/button'
import Error from '../../../components/error'
import TopRightOptions from '../../../components/top-right-options'
import { siteKey } from '../../../constants'
import Content from '../../../containers/content'
import usePagedAsync from '../../../hooks/use-paged-async'
import { fetchProducts } from '../../../services/entities/product'
import { ProductInfo } from '../../../types/product'
import ProductCard from './product-card'

const storageKey = `${siteKey}:products-page`

const initialState = !!localStorage.getItem(storageKey)
  ? JSON.parse(localStorage.getItem(storageKey))
  : {
      filteredIds: []
    }

const ProductsPageStore = types
  .model('ProductsPageState', {
    filteredIds: types.array(types.string)
  })
  .actions(self => ({
    addToFilter: (id: string) => {
      self.filteredIds.push(id)
    },
    reset: () => {
      self.filteredIds.replace([])
    }
  }))
  .postProcessSnapshot(snapshot =>
    localStorage.setItem(storageKey, JSON.stringify(snapshot))
  )

const store = ProductsPageStore.create(initialState)

makeInspectable(store)

const opts = {
  type: 'grid',
  stagger: 'forwards',
  spring: 'noWobble'
}

const pageParams = {
  pageNumber: 0,
  pageSize: 200
}

function Products() {
  const { type, stagger, spring } = opts
  const { data, error, initialized } = usePagedAsync({
    fn: fetchProducts,
    opts: pageParams,
    entity: 'products'
  })
  const { filteredIds } = store
  const filteredData = data.filter(({ id }) => !filteredIds.includes(id))
  const addIdToFilter = (id: ProductInfo['id']) => store.addToFilter(id)
  const showAll = () => store.reset()

  return (
    <Content
      loading={!initialized}
      subtitle={'Products'}
      title={'Missioncontrol'}
      error={error}>
      <div>
        <TopRightOptions>
          {!!data.length && !!filteredIds.length && (
            <Button
              variant={'light'}
              disabled={!filteredIds.length}
              onClick={showAll}
              icon={true}>
              <VisibilityIcon height={24} width={24} />
            </Button>
          )}
        </TopRightOptions>
        {!!data.length && (
          <Flipper flipKey={JSON.stringify(filteredIds)} spring={spring}>
            <ProductsGrid>
              {[...filteredData].map(product => (
                <ProductCard
                  {...product}
                  stagger={['forward', 'reverse'].includes(stagger)}
                  type={type}
                  key={product.id}
                  id={product.id}
                  addToFilteredIds={addIdToFilter}
                />
              ))}
            </ProductsGrid>
          </Flipper>
        )}
        {(!data.length || !filteredData.length) && (
          <Error
            fullPage={true}
            error={false}
            message={
              !data.length
                ? 'There are no products at the moment.'
                : 'All products are hidden.'
            }
          />
        )}
      </div>
    </Content>
  )
}

export default observer(Products)

const ProductsGrid = styled.ul`
  list-style-type: none;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
  grid-gap: 1rem;
  padding: 0;
  margin: 0;
  will-change: transform;
`
