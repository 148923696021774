import { useHistory } from 'react-router-dom'

export type NavigationType = 'widget' | 'parameter'
export type NavigationTypeValue = DesignerType | WidgetType
export type WidgetType = 'evb' | 'control'
export type DesignerType =
  | 'device-setup'
  | 'playback'
  | 'voicepickup'
  | 'feedback'
  | 'feed-forward'
  | 'talk-through'
  | 'calibration'

interface LinkToProps {
  linkType: NavigationType
  value: NavigationTypeValue
  passableValues?: string[]
  nullable?: boolean
  history: ReturnType<typeof useHistory>
}

export function linkTo({
  linkType,
  value,
  nullable = true,
  history
}: LinkToProps) {
  return () => {
    const { pathname } = history.location

    let newPathname = pathname

    if (pathname?.match(`/${linkType}/${value}`) && nullable) {
      newPathname = pathname.replace(`/${linkType}/${value}`, '')
    } else {
      if (pathname?.includes(`/${linkType}`)) {
        newPathname = pathname.replace(
          new RegExp(`/${linkType}/[\\w]+`),
          `/${linkType}/${value}`
        )
      } else {
        const shouldAddSlash = pathname.charAt(pathname.length - 1) !== '/'
        newPathname = `${pathname}${
          shouldAddSlash ? '/' : ''
        }${linkType}/${value}`
      }
    }
    history.replace(newPathname)
  }
}

export function isSelected(
  linkType: NavigationType,
  value: NavigationTypeValue,
  history: ReturnType<typeof useHistory>
) {
  return history?.location?.pathname?.includes(`/${linkType}/${value}`)
}
