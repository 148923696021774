import styled from '@emotion/styled/macro'
import React from 'react'
import { capitalize } from '../utils/text'
import { joinFractions } from '../utils/common'

interface Props {
  // https://github.com/microsoft/TypeScript/issues/15300
  // @ts-ignore
  data: Record<string, any>
  keys?: string[]
  content?: (data: unknown) => unknown
  shouldCapitalize?: boolean
  gridSizes?: number[]
}

export default function PropertiesInfo({
  data,
  keys,
  content,
  shouldCapitalize,
  gridSizes,
  ...props
}: Props) {
  const objKeys = keys ?? Object.keys(data)
  return objKeys.length ? (
    <>
      {' '}
      {objKeys.map(key =>
        typeof data[key] !== 'object' &&
        !Array.isArray(data[key]) &&
        data[key] !== '' ? (
          <Properties
            key={key}
            {...props}
            {...(gridSizes ? { gridSizes } : {})}>
            <div>{shouldCapitalize === false ? key : capitalize(key)}</div>
            <div>{content ? content(data[key]) : data[key]}</div>
          </Properties>
        ) : null
      )}
    </>
  ) : (
    <div />
  )
}

export const Properties = styled.div<{ gridSizes?: number[] }>`
  display: grid;
  grid-template-columns: ${({ gridSizes }) =>
    gridSizes ? joinFractions(gridSizes) : `140px 1fr`};
  grid-row-gap: 20px;
  font-size: 15px;
  line-height: 25px;
  margin: 8px 0px;
`
