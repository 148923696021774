import styled from '@emotion/styled/macro'
import React, { useState } from 'react'
import useToggle from '../hooks/use-toggle'
import { Nullable } from '../types/misc'

interface Props {
  errorMessage?: string
  name: string
}

export default function Input(
  props: React.ComponentProps<typeof StyledInput> & Props
) {
  const [isTouched, setHasTouched] = useToggle(false)
  const [error, setError] = useState<Nullable<string>>(null)
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.onChange && props.onChange(event)
    setError(
      event?.currentTarget?.validity?.valid === false
        ? props.errorMessage ?? event.currentTarget.validationMessage
        : null
    )
  }

  return (
    <div>
      <StyledInput
        {...props}
        touched={isTouched}
        onFocus={setHasTouched}
        onChange={onChange}
      />
      {isTouched && error && <Error>{error}</Error>}
    </div>
  )
}

const Error = styled.div`
  font-size: 12px;
  color: #d93900;
  font-weight: bold;
  margin-top: 4px;
  line-height: 1.5;
`

const StyledInput = styled.input<{ touched?: boolean }>`
  font-weight: 300;
  display: block;
  width: 100%;
  padding: 14px;
  border-radius: 4px;
  font-size: 14px;
  background-color: transparent;
  border: 1px solid #dcdcdc;
  transition: 400ms ease;

  ${({ touched }) =>
    touched &&
    `
        &:invalid {
            border: 1px solid #ec1b23;
        }
    `}

  &:focus {
    border: 1px solid #a8a8a8;
  }

  &:disabled {
    opacity: 0.7;
  }
`
