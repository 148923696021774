import styled from '@emotion/styled/macro'
import React from 'react'
import { Link } from 'react-router-dom'

interface Props {
  locked: boolean
  link?: string
  label: string
  onClick?: Function
}

function LinkLabel({
  locked,
  onClick,
  label,
  ...props
}: React.ComponentProps<typeof Label> & Props) {
  return (
    <Label locked={!locked} onClick={onClick} {...props}>
      {label}
    </Label>
  )
}

function LockableLink({ onClick, link, locked, ...props }: Props) {
  const handleClick = () => {
    !locked && onClick && onClick()
  }

  return !locked && link ? (
    <Link to={link}>
      <LinkLabel {...props} locked={locked} onClick={handleClick} link={link} />
    </Link>
  ) : (
    <LinkLabel {...props} locked={locked} onClick={handleClick} link={link} />
  )
}

const Label = styled.div<{ locked: boolean }>`
  color: ${({ locked }) => (locked ? `rgba(255, 255, 255, 0.5)` : '#3a3a3a')};
  text-decoration: none;
  cursor: ${props => (props.locked ? 'pointer' : 'not-allowed')};
  transition: 400ms all;

  &:hover {
    ${({ locked }) => locked && 'color: #ed1c24;'}
  }
`

export default LockableLink
