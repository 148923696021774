import styled from '@emotion/styled/macro'

const HiddenInput = styled.input`
  overflow: hidden;
  height: 0;
  padding: 0;
  margin: 0;
  visibility: hidden;
`

export default HiddenInput
