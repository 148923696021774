import styled from '@emotion/styled/macro'
import { Field, Form, FormikContext, useFormik } from 'formik'
import moment from 'moment'
import React, { useCallback, useMemo } from 'react'
import Select from 'react-select'
import AsyncSelect from 'react-select/async'
import { mobileSize, pageSizeOptions } from '../constants'
import useFilter, { createInitialValues } from '../hooks/use-filter'
import { capitalize } from '../utils/text'
import Button from './button'
import DateTime from './date-time'
import FilterInputGroup from './filter-input-group'
import Table from './table'
import { createDefaultOption } from '../utils/input-option-utils'

const selectPageSizeOptions = pageSizeOptions.map(size => ({
  label: String(size),
  value: String(size)
}))

type BaseFilterMeta = {
  label?: string
  size?: number
  initialValue?: string | number
}

export type FilterMeta = BaseFilterMeta &
  (
    | {
        type:
          | 'text'
          | 'number'
          | 'email'
          | 'boolean'
          | 'timestamp'
          | 'date'
          | 'enum'
      }
    | {
        type: 'enum'
        multi: boolean
        options:
          | React.ComponentProps<typeof Select>['options']
          | React.ComponentProps<typeof Select>['loadOptions']
          | (() => Promise<React.ComponentProps<typeof Select>['options']>)
        isClearable?: boolean
      }
  )

type DataMeta = {
  [key in FilterMeta['type']]: {
    empty: null | undefined | string | Array<unknown>
    serializer?: Function
  }
}

const momentToIso = (value: moment.Moment | string) => {
  if (typeof value === 'string') return moment(value).toISOString()
  else return value.toISOString()
}

export const dataMeta: DataMeta = {
  text: { empty: undefined },
  number: { empty: undefined },
  email: { empty: undefined },
  boolean: { empty: undefined },
  timestamp: {
    empty: '',
    serializer: momentToIso
  },
  date: {
    empty: '',
    serializer: momentToIso
  },
  enum: { empty: undefined }
}

export const pageSizeFilterMeta: FilterMeta = {
  type: 'enum',
  multi: false,
  options: selectPageSizeOptions,
  label: 'Page Size'
}

interface Props {
  filters: React.ComponentProps<typeof Table>['meta']['filters']
  query: ReturnType<typeof useFilter>
  entity: string
}

const selectProps: React.ComponentProps<typeof Select> = {
  isClearable: true,
  components: { IndicatorSeparator: () => null },
  styles: {
    control: provided => ({
      ...provided,
      borderWidth: 1,
      boxShadow: 'none',
      minHeight: 38,
      backgroundColor: 'transparent'
    }),
    input: provided => ({
      ...provided
    })
  }
}

export default function Filter({ filters: originalFilters, query }: Props) {
  const filters = useMemo(
    () => ({
      ...originalFilters,
      pageSize: pageSizeFilterMeta
    }),
    [originalFilters]
  )
  const sizes = Object.keys(filters).map(key => filters[key].size)

  const initialValues = useMemo(
    () =>
      Object.keys(filters).reduce(
        (values, currentValue) => ({
          ...values,
          [currentValue]: query.queryFields[currentValue]
        }),
        {} as { [key in keyof typeof filters]: string }
      ),
    [filters, query.queryFields]
  )

  const emptyForm = useMemo(() => createInitialValues(filters), [filters])

  const formik = useFormik({
    initialValues,
    onSubmit: values => {
      const serializedValues = Object.keys(values)
        .filter(value => !!values[value])
        .reduce(
          (newValues, key) => ({
            ...newValues,
            ...(!!values[key]
              ? {
                  [key]: dataMeta[filters[key].type]?.serializer
                    ? dataMeta[filters[key].type]?.serializer(values[key])
                    : values[key]
                }
              : {})
          }),
          {}
        )
      query.setQueryFields(serializedValues)
    },
    enableReinitialize: true
  })

  const { values, setFieldValue } = formik

  const clearForm = useCallback(() => {
    query.reset()
    Object.keys(values).forEach(key => setFieldValue(key, emptyForm[key]))
  }, [query, setFieldValue, emptyForm, values])

  const createSelectHandler = useCallback(
    (filter: string) => values =>
      setFieldValue(
        filter,
        filters[filter]?.multi
          ? (values ?? []).map(({ value }) => value)
          : values?.value ?? undefined
      ),
    [setFieldValue, filters]
  )

  // In a Select/AsyncSelect component, there
  // can be three kinds of cached values from localStorage/URL params
  // `singleValue` as a single select => `singleValue`
  // `singleValue` as a multi select => [`singleValue`]
  // or `firstValue,anotherValue` => [`firstValue', `anotherValue`]
  const getSelectValues = useCallback(
    (filter: string) => {
      if (filters[filter].multi) {
        let filterValue = []

        // If cached value is an array
        if (Array.isArray(values[filter])) {
          filterValue = values[filter]
        } else if (
          typeof values[filter] !== 'undefined' &&
          values[filter] !== ''
        ) {
          // Cached value is multi but input is a single item
          filterValue.push(values[filter])
        }

        // Map stringified values to actual object
        return filterValue.map(value =>
          filters[filter].options?.find(
            ({ value: optionValue }) => optionValue === value
          )
        )
      } else {
        // Find the single value from the filter's options to map
        // the single string value to the object array
        return filters[filter].options.find(
          option =>
            option.value ===
            (Array.isArray(values[filter]) ? values[filter][0] : values[filter])
        )
      }
    },
    [values, filters]
  )

  return (
    <FormikContext.Provider value={formik}>
      <Form onReset={clearForm}>
        <Container gridSizes={sizes}>
          {Object.keys(filters).map(filter => (
            <FieldContainer key={filter} size={filters[filter].size ?? 1}>
              <FilterInputGroup>
                <p>{filters[filter].label ?? capitalize(filter)}</p>
                {filters[filter].type === 'text' ? (
                  <FilterField
                    type={filters[filter].type}
                    name={filter}
                    value={values[filter] ?? ''}
                  />
                ) : filters[filter].type === 'date' ? (
                  <DateTime
                    value={values[filter]}
                    onChange={value => setFieldValue(filter, value)}
                    closeOnSelect={true}
                    timeFormat={false}
                    // https://github.com/YouCanBookMe/react-datetime/pull/677
                    // @ts-ignore
                    closeOnTab={true}
                  />
                ) : filters[filter].type === 'enum' &&
                  typeof filters[filter].options === 'function' ? (
                  <AsyncSelect
                    key={filter}
                    {...selectProps}
                    loadOptions={filters[filter].options}
                    defaultOptions
                    isMulti={filters[filter].multi}
                    onChange={createSelectHandler(filter)}
                    isClearable={!(filters[filter]?.isClearable === 'false')}
                    value={
                      typeof values[filter] !== 'undefined' &&
                      values[filter] !== '' &&
                      values[filter] !== null
                        ? Array.isArray(values[filter])
                          ? values[filter].map(createDefaultOption)
                          : [createDefaultOption(values[filter])]
                        : null
                    }
                  />
                ) : filters[filter].type === 'enum' ? (
                  <Select
                    options={filters[filter].options}
                    isMulti={filters[filter]?.multi}
                    {...selectProps}
                    onChange={createSelectHandler(filter)}
                    value={getSelectValues(filter) ?? null}
                    isClearable={!(filters[filter]?.isClearable === 'false')}
                  />
                ) : filters[filter].type === 'timestamp' ? (
                  <DateTime
                    value={
                      typeof values[filter] === 'string'
                        ? moment(values[filter])
                        : values[filter]
                    }
                    onChange={value => setFieldValue(filter, value)}
                    closeOnSelect={true}
                    // https://github.com/YouCanBookMe/react-datetime/pull/677
                    // @ts-ignore
                    closeOnTab={true}
                  />
                ) : (
                  <p>This filter type is unsupported.</p>
                )}
              </FilterInputGroup>
            </FieldContainer>
          ))}
          <Options>
            <FilterButton type="submit">Search</FilterButton>
            <FilterButton onClick={clearForm}>Reset</FilterButton>
          </Options>
        </Container>
      </Form>
    </FormikContext.Provider>
  )
}

const FilterButton = styled(Button)`
  background-color: #ebebeb;
  height: 38px;
  padding: 0px 16px;
  align-self: end;
  border: 1px solid #ebebeb;
`

const FieldContainer = styled.div<{ size: number }>`
  grid-column: ${({ size }) => `auto / span ${size}`};
`

const Options = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 8px;
`

const FilterField = styled(Field)`
  width: 100%;
  min-height: 38px;

  @media screen and (max-width: ${mobileSize}px) {
    max-width: 100%;
  }
`

const Container = styled.div<{ gridSizes: number[] }>`
  grid-gap: 20px;
  display: grid;
  width: 100%;
  padding-bottom: 12px;
  grid-template-columns: repeat(auto-fit, minmax(160px, auto));

  @media screen and (max-width: ${mobileSize}px) {
    grid-template-columns: 1fr;
    width: 100%;
  }
`
