import styled from '@emotion/styled/macro'
import React from 'react'
import showdown from 'showdown'
import Card from '../../components/card'
import { WhiteTitle } from '../../components/title'
import { mobileSize } from '../../constants'
import Content from '../../containers/content'
import useAsync from '../../hooks/use-async'
import { getAbouts } from '../../services/entities/about'
import { Base64Media } from '../../types/misc'
import { capitalize } from '../../utils/text'

const converter = new showdown.Converter({
  openLinksInNewWindow: true
})

export default function About() {
  const {
    loading,
    error,
    data: { about, version }
  } = useAsync({ fn: getAbouts })

  return (
    <Container>
      <Content
        title={'Support'}
        subtitle={'About'}
        loading={loading}
        error={error}>
        <OneHalf>
          <HalfTitle>Soundstation</HalfTitle>
          <VersionCard>
            <VersionDetails>
              {version &&
                Object.keys(version).map(v => (
                  <Detail key={v}>
                    <div>{capitalize(v)}</div>
                    <div>{version[v]}</div>
                  </Detail>
                ))}
            </VersionDetails>
          </VersionCard>
        </OneHalf>
      </Content>
      {about &&
        about.map(item => (
          <AboutDetails
            bgUrl={item?.image?.base64}
            key={JSON.stringify(item)}
            fullWidth={true}>
            <Overlay />
            <AboutTitle>{item.title}</AboutTitle>
            <AboutDetail
              dangerouslySetInnerHTML={{
                __html: converter.makeHtml(item.text)
              }}></AboutDetail>
          </AboutDetails>
        ))}
    </Container>
  )
}

const VersionCard = styled(Card)`
  border-radius: 0;
`

const Detail = styled.div`
  display: grid;
  grid-template-columns: 150px 1fr;
  margin: 8px 0px;
`

const VersionDetails = styled.div`
  line-height: 28px;
  font-weight: 300;
  font-size: 15px;
`

const HalfTitle = styled.p`
  font-size: 32px;
  justify-self: start;
  font-weight: 300;
`

const OneHalf = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
  padding: 25px 0 25px 0px;
`

const AboutTitle = styled(WhiteTitle)`
  font-size: 36px;
  margin-bottom: 32px;
`

const AboutDetail = styled.div`
  max-width: 682px;
  line-height: 1.7;
  color: #fff;
  font-weight: 300;
  margin-top: 20px;
  margin-bottom: 20px;

  h1 {
    margin-top: 50px;
    margin-bottom: 12px;
    color: #fff;
    font-size: 20px;
    font-weight: bold;
  }

  p {
    height: fit-content;
    margin-bottom: 12px;
  }
`

type AboutDetailsProps = {
  bgUrl?: Base64Media['base64']
  fullWidth?: boolean
}

const AboutDetails = styled.div<AboutDetailsProps>`
  display: grid;
  align-items: center;
  margin-left: 120px;
  margin-right: 120px;
  justify-items: center;
  background-position: center center;
  background-size: cover;
  background-attachment: fixed;
  padding: 100px;
  position: relative;
  z-index: 1;
  min-height: 400px;

  code,
  br {
    display: none;
  }

  &${AboutDetail}::nth-of-type(even) {
    direction: rtl;
  }

  @media screen and (max-width: ${mobileSize}px) {
    margin-left: 0;
    margin-right: 0;
    padding: 50px;
  }

  ${({ bgUrl }) => bgUrl && `background-image: url(${bgUrl})`};
  ${({ fullWidth }) =>
    fullWidth &&
    `
    margin-left: 0px; 
    margin-right: 0px;
  `}
`

const Overlay = styled.div`
  background-color: rgba(0, 0, 0, 0.65);
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`

const Container = styled.div``
