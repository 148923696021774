import styled from '@emotion/styled/macro'
import React, { useRef, useState } from 'react'
import Flex from '../../../../../components/auto-flow-flex'
import Button from '../../../../../components/button'
import OriginalCard from '../../../../../components/card'
import OriginalCardTitle from '../../../../../components/card-title'
import { Gridder } from '../../../../../components/grids'
import { ModalWrapper } from '../../../../../components/modal'
import { RadioSelect } from '../../../../../components/radio-button'
import * as EVBTypes from '../../../../../types/dbmc2evb2'
import {
  AncMicrophone,
  VoiceMicrophone,
  Micbias
} from '../../../../../types/dbmc2evb2'
import { SelectOption, ToggleOption } from '../../../../../types/input-option'
import { toggleOptions } from '../../../../../utils/input-option-utils'
import useToggle from '../../../../../hooks/use-toggle'
import {
  calibrationParametersEmitter,
  feedbackParametersEmitter,
  feedforwardParametersEmitter,
  playbackParametersEmitter,
  deviceSetupParametersEmitter,
  talkthroughParametersEmitter,
  voicePickupParametersEmitter,
  calibrationParametersParseHex,
  feedbackParametersParseHex,
  feedforwardParametersParseHex,
  playbackParametersParseHex,
  deviceSetupParametersParseHex,
  talkthroughParametersParseHex,
  voicePickupParametersParseHex
} from '../../utils/serialization'
import useClippy from 'use-clippy'
import {
  setDeviceSetupParameters,
  setPlaybackParameters,
  setVoicePickupParameters,
  setFeedbackParameters,
  setFeedforwardParameters,
  setTalkthroughParameters,
  setCalibrationParameters
} from '../../utils/board'

const ancMicrophoneOptions: SelectOption<AncMicrophone>[] = [
  { value: 'mems-se', label: 'MEMS Single Ended' },
  { value: 'ecm-se', label: 'ECM Single Ended' },
  { value: 'mems-diff', label: 'MEMS Differential' }
]

const voiceMicrophoneOptions: SelectOption<VoiceMicrophone>[] = [
  { value: 'mems-se', label: 'MEMS Single Ended' },
  { value: 'ecm-diff', label: 'ECM Differential' }
]

const micbiasOptions: SelectOption<Micbias>[] = [
  { value: 'function-ganged', label: 'Function Ganged' },
  { value: 'channel-ganged', label: 'Channel Ganged' }
]

const Card = ({
  children,
  ...props
}: React.ComponentProps<typeof OriginalCard>) => (
  <OriginalCard padding={'24px 32px 32px 24px'} {...props}>
    {children}
  </OriginalCard>
)
interface ParameterCopyProps {
  parameters: unknown
  name: string
  visible: boolean
  load: Function
}

function ParameterCopy(props: ParameterCopyProps) {
  const { parameters, name, visible, load } = props

  // we can't use `clipboard`; it's not well supported across browsers
  // and I have read that Apple and Mozilla are pushing to remove it
  // from the Clipboard API
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [clipboard, setClipboard] = useClippy()

  const hexTransformer = hex => hex
  const cTransformer = hex => {
    const bytes = hex
      .trim()
      .match(/.{1,2}/g)
      .map(b => `0x${b}`)
      .join(', ')
    return `static const uint8_t ${name}_parameters[] = { ${bytes} };`
  }
  const matlabTransformer = hex => {
    const bytes = hex
      .trim()
      .match(/.{1,2}/g)
      .map(b => parseInt(b, 16))
      .join(' ')
    return `${name}_parameters = [ ${bytes} ];`
  }

  const handleCopyToClipboard = (ref, transformer, e) => {
    setClipboard(transformer(ref.current.value))
  }

  return (
    <div style={{ opacity: visible ? 1 : 0 }}>
      <CopyList>
        {document.queryCommandSupported('copy') && (
          <>
            <CopyItem
              onClick={e =>
                handleCopyToClipboard(parameters, hexTransformer, e)
              }>
              Hex
            </CopyItem>
            <CopyItem
              onClick={e => handleCopyToClipboard(parameters, cTransformer, e)}>
              C
            </CopyItem>
            <CopyItem
              onClick={e =>
                handleCopyToClipboard(parameters, matlabTransformer, e)
              }>
              Matlab
            </CopyItem>
          </>
        )}
        <CopyItem onClick={() => load()}>Load</CopyItem>
      </CopyList>
    </div>
  )
}

interface LoadParametersDialogProps {
  hide: Function
  handle: Function
}

function LoadParametersDialog(props: LoadParametersDialogProps) {
  const { hide, handle } = props
  const ref = useRef(null)

  return (
    <div>
      <textarea ref={ref}></textarea>
      <Button
        onClick={() => {
          if (handle(ref.current.value)) {
            hide()
          }
        }}
        fit>
        Load
      </Button>
    </div>
  )
}

interface EVBSetupProps {
  data: EVBTypes.Dbmc2Evb2
  setData: Function
  writeData: Function
  socket:any
  path:string
}

async function setParameterFileData(socket, path, data){
  if (data.DeviceSetup) {
    console.log('%csetParameterFileData - DeviceSetup', 'background-color:magenta; color:black;')
    await setDeviceSetupParameters(socket, path, data.DeviceSetup)
  }
  if (data.Playback) {
    console.log('%csetParameterFileData - Playback', 'background-color:magenta; color:black;')
    await setPlaybackParameters(socket, path, data.Playback)
  }
  if (data.VoicePickup) {
    console.log('%csetParameterFileData - VoicePickup', 'background-color:magenta; color:black;')
    await setVoicePickupParameters(socket, path, data.VoicePickup)
  }
  if (data.Feedback) {
    console.log('%csetParameterFileData - Feedback', 'background-color:magenta; color:black;')
    await setFeedbackParameters(socket, path, data.Feedback)
  }
  if (data.Feedforward) {
    console.log('%csetParameterFileData - Feedforward', 'background-color:magenta; color:black;')
    await setFeedforwardParameters(socket, path, data.Feedforward)
  }
  if (data.Talkthrough) {
    console.log('%csetParameterFileData - Talkthrough', 'background-color:magenta; color:black;')
    await setTalkthroughParameters(socket, path, data.Talkthrough)
  }
  if (data.Calibration) {
    console.log('%csetParameterFileData - Calibration', 'background-color:magenta; color:black;')
    await setCalibrationParameters(socket, path, data.Calibration)
  }
}

//no idea why but this is imported as SetupEVB in src/pages/engineer/design/designer.tsx 
export default function EVBSetup(props: EVBSetupProps) {
  const { data, setData, writeData } = props

  const [isLoadingJson, setIsLoadingJson] = useState(false)

  const [isVisibleSetupCopy, showSetupCopy, hideSetupCopy] = useToggle(false)
  const [isVisiblePlaybackCopy, showPlaybackCopy, hidePlaybackCopy] = useToggle(false)
  const [isVisibleFeedbackCopy, showFeedbackCopy, hideFeedbackCopy] = useToggle(false)
  const [
    isVisibleFeedforwardCopy,
    showFeedforwardCopy,
    hideFeedforwardCopy
  ] = useToggle(false)
  const [
    isVisibleTalkthroughCopy,
    showTalkthroughCopy,
    hideTalkthroughCopy
  ] = useToggle(false)
  const [
    isVisibleVoicePickupCopy,
    showVoicePickupCopy,
    hideVoicePickupCopy
  ] = useToggle(false)
  const [
    isVisibleCalibrationCopy,
    showCalibrationCopy,
    hideCalibrationCopy
  ] = useToggle(false)

  const setupRef = useRef(null)
  const voicepickupRef = useRef(null)
  const playbackRef = useRef(null)
  const feedforwardRef = useRef(null)
  const feedbackRef = useRef(null)
  const talkthroughRef = useRef(null)
  const calibrationRef = useRef(null)

  const invokeLoadParameterFile=()=>{
    const fileInput = document.createElement('input')
    fileInput.type='file'
    fileInput.onchange=e=>loadParameterFile(e, data, props.socket, props.path)
    document.body.appendChild(fileInput)
    fileInput.click()
    setTimeout(()=>{
      document.body.removeChild(fileInput)
    }, 0)
  }

  const loadParameterFile = (e, componentData, socket, path)=>{
    e.preventDefault()
    setIsLoadingJson(true)
    
    const reader = new FileReader()
    reader.onload = async e => { 
      const text = e.target.result
      const string=text.toString()
      const data=JSON.parse(string)
      
      let importedParams={
        DeviceSetup:null,
        Playback:null,
        VoicePickup:null,
        Feedback:null,
        Feedforward:null,
        Talkthrough:null,
        Calibration:null
      }

      if (data.DeviceSetup) {
        try {
          importedParams.DeviceSetup = deviceSetupParametersParseHex(`01${data.DeviceSetup}`)
        } catch(e) {
          console.warn("🛑 loadParameterFile couldn't load DeviceSetup",e)
        }
      }

      if (data.Playback) {
        try {
          importedParams.Playback = playbackParametersParseHex(`01${data.Playback}`)
        } catch(e) {
          console.warn("🛑 loadParameterFile couldn't load Playback",e)
        }
      }

      if (data.VoicePickup) {
        try {
          importedParams.VoicePickup = voicePickupParametersParseHex(`02${data.VoicePickup}`)
        } catch(e) {
          console.warn("🛑 loadParameterFile couldn't load VoicePickup",e)
        }
      }

      if (data.Feedback) {
        try {
          importedParams.Feedback = feedbackParametersParseHex(`02${data.Feedback}`)
        } catch(e) {
          console.warn("🛑 loadParameterFile couldn't load Feedback",e)
        }
      }

      if (data.Feedforward) {
        try {
          importedParams.Feedforward = feedforwardParametersParseHex(`02${data.Feedforward}`)
        } catch(e) {
          console.warn("🛑 loadParameterFile couldn't load Feedforward",e)
        }
      }

      if (data.Talkthrough) {
        try {
          importedParams.Talkthrough = talkthroughParametersParseHex(`02${data.Talkthrough}`)
        } catch(e) {
          console.warn("🛑 loadParameterFile couldn't load Talkthrough",e)
        }
      }
      
      if (data.Calibration) {
        try {
          importedParams.Calibration = calibrationParametersParseHex(`02${data.Calibration}`)
        } catch(e) {
          console.warn("🛑 loadParameterFile couldn't load Calibration",e)
        }
      }
      
      await setParameterFileData(socket, path, importedParams)

    }
    reader.readAsText(e.target.files[0])
    setIsLoadingJson(false)
  }

  const controlHandler = (handler: (u: boolean) => void) => (
    newValue: ToggleOption
  ) => handler(newValue.value === 'enabled')
  const controlInitialValue = (options: ToggleOption[], o: boolean) =>
    options[o ? 0 : 1].value

  const ancMicrophoneHandler = (handler: (u: AncMicrophone) => void) => (
    newValue: SelectOption<AncMicrophone>
  ) => handler(newValue.value)
  const ancMicrophonesInitialValue = (
    options: SelectOption<AncMicrophone>[],
    o: AncMicrophone
  ) => options.find(v => o === v.value).value

  const voiceMicrophonesInitialValue = (
    options: SelectOption<VoiceMicrophone>[],
    o: VoiceMicrophone
  ) => {
    if (!o) {
      return ''
    }
    const val = options.find(v => o === v.value).value
    return val
  }
  const voiceMicrophoneHandler = (handler: (u: VoiceMicrophone) => void) => (
    newValue: SelectOption<VoiceMicrophone>
  ) => handler(newValue.value)

  const micbiasInitialValue = (options: SelectOption<Micbias>[], o: Micbias) =>
    options.find(v => o === v.value).value
  const micbiasHandler = (handler: (u: Micbias) => void) => (
    newValue: SelectOption<Micbias>
  ) => handler(newValue.value)

  const handleAncMicrophoneChanged = (u: EVBTypes.AncMicrophone) => {
    setData({ ...data, microphones: { ...data.microphones, anc: u } })
  }
  const handleVoicePickupMicrophoneChanged = (u: EVBTypes.VoiceMicrophone) => {
    setData({ ...data, microphones: { ...data.microphones, voice: u } })
  }
  const handleMicbiasChanged = (u: EVBTypes.Micbias) => {
    setData({ ...data, microphones: { ...data.microphones, micbias: u } })
  }

  const handleResetControlChanged = (enabled: boolean) => {
    setData({ ...data, boardControl: { ...data.boardControl, reset: enabled } })
  }
  const handleLedControlChanged = (enabled: boolean) => {
    setData({ ...data, boardControl: { ...data.boardControl, led: enabled } })
  }
  const handleFilterControlChanged = (enabled: boolean) => {
    setData({
      ...data,
      boardControl: { ...data.boardControl, filter: enabled }
    })
  }
  const handleDaughterControlChanged = (enabled: boolean) => {
    setData({
      ...data,
      boardControl: { ...data.boardControl, daughter: enabled }
    })
  }
  const handleUpdateDeviceSetupParameters = (
    parameters: EVBTypes.DeviceSetupParameters
  ) => {
    setData({
      ...data,
      parameters: { ...data.parameters, setup: parameters }
    })
  }
  const handleUpdatePlaybackParameters = (
    parameters: EVBTypes.PlaybackParameters
  ) => {
    setData({
      ...data,
      parameters: { ...data.parameters, playback: parameters }
    })
  }
  const handleUpdateVoicePickupParameters = (
    parameters: EVBTypes.VoicePickupParameters
  ) => {
    setData({
      ...data,
      parameters: { ...data.parameters, voicepickup: parameters }
    })
  }
  const handleUpdateFeedbackParameters = (
    parameters: EVBTypes.FeedbackParameters
  ) => {
    setData({
      ...data,
      parameters: { ...data.parameters, feedback: parameters }
    })
  }
  const handleUpdateFeedforwardParameters = (
    parameters: EVBTypes.FeedforwardParameters
  ) => {
    setData({
      ...data,
      parameters: { ...data.parameters, feedforward: parameters }
    })
  }
  const handleUpdateTalkthroughParameters = (
    parameters: EVBTypes.TalkthroughParameters
  ) => {
    setData({
      ...data,
      parameters: { ...data.parameters, talkthrough: parameters }
    })
  }
  const handleUpdateCalibrationParameters = (
    parameters: EVBTypes.CalibrationParameters
  ) => {
    setData({
      ...data,
      parameters: { ...data.parameters, calibration: parameters }
    })
  }

  const handleLoadParameters = (parameterString: string) => {
    // TODO: Good grief. Refactor the parser to return Result<Parameters, Error> or Option<Parameters>
    try {
      const parameters = deviceSetupParametersParseHex(`00${parameterString}`)
      handleUpdateDeviceSetupParameters(parameters)
    } catch (error) {
      try {
        const parameters = playbackParametersParseHex(`01${parameterString}`)
        handleUpdatePlaybackParameters(parameters)
      } catch (error) {
        try {
          const parameters = voicePickupParametersParseHex(`02${parameterString}`)
          handleUpdateVoicePickupParameters(parameters)
        } catch (error) {
          try {
            const parameters = feedbackParametersParseHex(`03${parameterString}`)
            handleUpdateFeedbackParameters(parameters)
          } catch (error) {
            try {
              const parameters = feedforwardParametersParseHex(`04${parameterString}`)
              handleUpdateFeedforwardParameters(parameters)
            } catch (error) {
              try {
                const parameters = talkthroughParametersParseHex(`05${parameterString}`)
                handleUpdateTalkthroughParameters(parameters)
              } catch (error) {
                try {
                  const parameters = calibrationParametersParseHex(`09${parameterString}`)
                  handleUpdateCalibrationParameters(parameters)
                } catch (error) {
                  alert(
                    'Error\n\nCould not parse parameters.\n\nAccepted files are text files of hex bytes. Ensure that the checksum is correct.'
                  )
                  return false
                }
              }
            }
          }
        }
      }
    }

    return true
  }

  const controlProps = [
    {
      title: 'DBMC2 Reset',
      options: toggleOptions,
      onChange: controlHandler(handleResetControlChanged),
      value: controlInitialValue(
        toggleOptions,
        data?.boardControl?.reset
      )
    },
    {
      title: 'LED',
      options: toggleOptions,
      onChange: controlHandler(handleLedControlChanged),
      value: controlInitialValue(toggleOptions, data?.boardControl?.led)
    },
    {
      title: 'Expansion Filter',
      options: toggleOptions,
      onChange: controlHandler(handleFilterControlChanged),
      value: controlInitialValue(
        toggleOptions,
        data?.boardControl?.filter
      )
    },
    {
      title: 'Daughterboard',
      options: toggleOptions,
      onChange: controlHandler(handleDaughterControlChanged),
      value: controlInitialValue(
        toggleOptions,
        data?.boardControl?.daughter
      )
    }
  ]

  const microphoneData = [
    {
      title: 'ANC',
      options: ancMicrophoneOptions,
      onChange: ancMicrophoneHandler(handleAncMicrophoneChanged),
      value: ancMicrophonesInitialValue(
        ancMicrophoneOptions,
        data?.microphones?.anc
      )
    },
    {
      title: 'Voice Pickup',
      options: voiceMicrophoneOptions,
      onChange: voiceMicrophoneHandler(handleVoicePickupMicrophoneChanged),
      value: voiceMicrophonesInitialValue(
        voiceMicrophoneOptions,
        data?.microphones?.voice
      )
    },
    {
      title: 'Bias',
      options: micbiasOptions,
      onChange: micbiasHandler(handleMicbiasChanged),
      value: micbiasInitialValue(
        micbiasOptions,
        data?.microphones?.micbias
      )
    }
  ]

  const [
    loadParametersShown,
    showLoadParameters,
    hideLoadParameters
  ] = useToggle(false)
  const closeModal = () => {
    hideLoadParameters()
  }

  return (
    <Container {...props}>
      <Gridder
        template={['600px', '1fr']}
        gap={'20px'}
        align={'start'}
        style={{ paddingRight: 12 }}>
        <div>
          <Card>
            <CardTitle>Control</CardTitle>
            <Flex gap={'32px'} align={'start'}>
              {!data.isFlashWriting &&
                controlProps.map(({ title, ...props }, i) => (
                  <div key={JSON.stringify({ props, title, i })}>
                    <OptionsTitle>{title}</OptionsTitle>
                    <RadioSelect {...props} key={i} />
                  </div>
                ))}
            </Flex>
          </Card>
          <Card>
            <CardTitle>Firmware</CardTitle>
            <Flex gap={'12px'}>
              <div>Version: {data.firmware.version}</div>
              <div>Commit: {data.firmware.commit}</div>
            </Flex>
          </Card>
        </div>
        <div>
          <Card>
            <CardTitle>Microphones</CardTitle>
            <Flex gap={'32px'} align={'start'}>
              {!data.isFlashWriting &&
                microphoneData.map(({ title, ...props }, i) => (
                  <div key={JSON.stringify(props)}>
                    <OptionsTitle>{title}</OptionsTitle>
                    <RadioSelect key={i} {...props} />
                  </div>
                ))}
            </Flex>
          </Card>
        </div>
      </Gridder>
      <Card>
        <CardTitle>Parameters</CardTitle>
        <ModalWrapper
          onClose={closeModal}
          title={'Load Parameters'}
          shown={loadParametersShown}
          hide={closeModal}>
          <LoadParametersDialog
            hide={closeModal}
            handle={handleLoadParameters}
          />
        </ModalWrapper>
        <Gridder
          template={['1fr', '1fr', '1fr', '1fr']}
          gap={'20px'}
          align={'start'}>
          <div onMouseEnter={showSetupCopy} onMouseLeave={hideSetupCopy}>
            <OptionsTitle>Device-Setup</OptionsTitle>
            <Parameters
              ref={setupRef}
              readOnly={true}
              value={
                data.parameters.setup &&
                deviceSetupParametersEmitter(data.parameters.setup)
              }
            />
            <ParameterCopy
              name={'setup'}
              parameters={setupRef}
              visible={isVisibleSetupCopy}
              load={showLoadParameters}
            />
          </div>
          <div onMouseEnter={showPlaybackCopy} onMouseLeave={hidePlaybackCopy}>
            <OptionsTitle>Playback</OptionsTitle>
            <Parameters
              ref={playbackRef}
              readOnly={true}
              value={
                data.parameters.playback &&
                playbackParametersEmitter(data.parameters.playback)
              }
            />
            <ParameterCopy
              name={'playback'}
              parameters={playbackRef}
              visible={isVisiblePlaybackCopy}
              load={showLoadParameters}
            />
          </div>
          <div
            onMouseEnter={showVoicePickupCopy}
            onMouseLeave={hideVoicePickupCopy}>
            <OptionsTitle>Voice Pickup</OptionsTitle>
            <Parameters
              ref={voicepickupRef}
              readOnly={true}
              value={
                data.parameters.voicepickup &&
                voicePickupParametersEmitter(data.parameters.voicepickup)
              }
            />
            <ParameterCopy
              name={'voicepickup'}
              parameters={voicepickupRef}
              visible={isVisibleVoicePickupCopy}
              load={showLoadParameters}
            />
          </div>
          <div onMouseEnter={showFeedbackCopy} onMouseLeave={hideFeedbackCopy}>
            <OptionsTitle>Feedback</OptionsTitle>
            <Parameters
              ref={feedbackRef}
              readOnly={true}
              value={
                data.parameters.feedback &&
                feedbackParametersEmitter(data.parameters.feedback)
              }
            />
            <ParameterCopy
              name={'feedback'}
              parameters={feedbackRef}
              visible={isVisibleFeedbackCopy}
              load={showLoadParameters}
            />
          </div>
          <div
            onMouseEnter={showFeedforwardCopy}
            onMouseLeave={hideFeedforwardCopy}>
            <OptionsTitle>Feed-Forward</OptionsTitle>
            <Parameters
              ref={feedforwardRef}
              readOnly={true}
              value={
                data.parameters.feedforward &&
                feedforwardParametersEmitter(data.parameters.feedforward)
              }
            />
            <ParameterCopy
              name={'feedforward'}
              parameters={feedforwardRef}
              visible={isVisibleFeedforwardCopy}
              load={showLoadParameters}
            />
          </div>
          <div
            onMouseEnter={showTalkthroughCopy}
            onMouseLeave={hideTalkthroughCopy}>
            <OptionsTitle>Talkthrough</OptionsTitle>
            <Parameters
              ref={talkthroughRef}
              readOnly={true}
              value={
                data.parameters.talkthrough &&
                talkthroughParametersEmitter(data.parameters.talkthrough)
              }
            />
            <ParameterCopy
              name={'talkthrough'}
              parameters={talkthroughRef}
              visible={isVisibleTalkthroughCopy}
              load={showLoadParameters}
            />
          </div>
          <div
            onMouseEnter={showCalibrationCopy}
            onMouseLeave={hideCalibrationCopy}>
            <OptionsTitle>Calibration</OptionsTitle>
            <Parameters
              ref={calibrationRef}
              readOnly={true}
              value={
                data.parameters.calibration &&
                calibrationParametersEmitter(data.parameters.calibration)
              }
            />
            <ParameterCopy
              name={'calibration'}
              parameters={calibrationRef}
              visible={isVisibleCalibrationCopy}
              load={showLoadParameters}
            />
          </div>
          <div>
            <OptionsTitle>&nbsp;</OptionsTitle>
            <Button onClick={()=>invokeLoadParameterFile()} disabled={isLoadingJson}>
              Load{isLoadingJson ? 'ing' : ''} JSON{isLoadingJson ? '...' : ''}
            </Button>
            <br />
            <Button onClick={()=>writeData()} disabled={data.isFlashWriting}>
              Write to Flash
            </Button>
            <FlashMessage>{data.flashMessage}</FlashMessage>
          </div>
        </Gridder>
      </Card>
    </Container>
  )
}

const FlashMessage = styled.p`
  padding-top: 10px;
  :first-letter {
    text-transform: capitalize;
  }
`

const CopyList = styled.ul`
  list-style: none;
  display: inline;
`

const CopyItem = styled.li`
  font-style: oblique;
  cursor: pointer;
  list-style: none;
  display: inline;
  :after {
    content: ' · ';
  }
  :last-child:after {
    content: none;
  }
`

const OptionsTitle = styled.p`
  font-weight: bold;
  margin-bottom: 16px;
  margin-left: 2px;
  color: #868686;
`

const Parameters = styled.textarea`
  width: 100%;
  border: 1px solid #dddfe3;
  word-break: break-all;
  padding: 10px;
  font-family: monospace;
  border-radius: 8px;
  overflow: scroll;
  height: 240px;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 3px;
  }

  ::selection {
    background-color: grey;
  }
`

const CardTitle = styled(OriginalCardTitle)`
  margin-bottom: 24px;
  font-size: 17px;
`

const Container = styled.div`
  font-size: 14px;
`
