interface AuxiliaryWindow {
  Beacon?: (...args: string[]) => any
}

type ExtendedWindow = Window & typeof globalThis & AuxiliaryWindow

const extendedWindow: ExtendedWindow = window

if (!extendedWindow.Beacon) extendedWindow.Beacon = () => {}

export default extendedWindow
