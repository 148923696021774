import styled from '@emotion/styled/macro'
import { observer } from 'mobx-react-lite'
import React from 'react'
import BodyStyle from '../components/body-style'
import { maxWidth } from '../constants'
import Header from './header'

interface Props {
  children: React.ReactNode
  hasFooter?: boolean
}

export default observer(Layout)

function Layout({ children, hasFooter = true, ...props }: Props) {
  return (
    <>
      <LayoutWrapper {...props}>
        <BodyStyle
          style={`
          background-color: #f2f2f2;
        `}
        />
        <Header />
        {children}
      </LayoutWrapper>
      {hasFooter && (
        <Footer>
          <FooterContent>
            <span>
              &copy; 2010—{new Date().getFullYear()} SoundChip SA. All right
              reserved. Use of this website and web application subject to terms
              and conditions available on request.
            </span>
          </FooterContent>
        </Footer>
      )}
    </>
  )
}

const FooterContent = styled.div`
  margin: 0 auto;
  max-width: ${maxWidth}px;
`

const Footer = styled.div`
  background: #23201f;
  border: 1px solid #000;
  font-size: 12px;
  font-weight: 300;
  line-height: 1.92;
  letter-spacing: 0.2px;
  color: #666;
  padding: 20px 40px;
  width: 100%;
`

const LayoutWrapper = styled.div`
  margin: 0 auto;
  min-height: 100vh;
  max-width: ${maxWidth}px;
  width: 100%;
`
