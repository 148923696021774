import React, { useEffect, useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import LoadingErrorEmptyWrapper from '../../components/loading-error-empty'
import Content from '../../containers/content'
import useAsync from '../../hooks/use-async'
import usePagedAsync from '../../hooks/use-paged-async'
import ProductResult from './product/result'
import {
  fetchProduct,
  fetchProductUuidResult,
  fetchProductUuidResults
} from '../../services/entities/product'
import { isEmpty } from '../../utils/common'
import TestResults from '../production/test-results/test-results.page'

const testResultOpts = { unnamed: true }

export default function ProductTestResults() {
  const { productId, resultUuid, testId } = useParams()
  const history = useHistory()
  const [initialSelect] = useState(testId)
  const productArgs = useMemo(() => [productId], [productId])
  const product = useAsync({ fn: fetchProduct, args: productArgs })
  const filterKey = useMemo(
    () => `product-results/${productId}/test/${testId}/result/${resultUuid}`,
    [productId, testId, resultUuid]
  )
  const initialPayloadParams = useMemo(
    () => ({
      selected: initialSelect,
      initialFilters: {
        uuid: resultUuid
      },
      filterKey
    }),
    [initialSelect, resultUuid, filterKey]
  )

  const resultsArgs = useMemo(() => [productId, resultUuid], [
    productId,
    resultUuid
  ])
  const resultsParams = useMemo(
    () => ({
      fn: fetchProductUuidResults,
      args: resultsArgs,
      opts: initialPayloadParams,
      entity: filterKey
    }),
    [resultsArgs, initialPayloadParams, filterKey]
  )
  const results = usePagedAsync(resultsParams)
  const initialized = product.initialized && results.initialized

  useEffect(() => {
    if (!(productId && resultUuid)) history.push('/')
  }, [productId, resultUuid, history])

  return (
    <Content
      title={'Missioncontrol'}
      subtitle={['Products', `/missioncontrol/products`]}
      loading={!initialized}
      itemName={[
        product?.data?.name ?? '',
        `/missioncontrol/product/${productId ?? ''}`
      ]}
      error={results.error}>
      {results.initialized && (
        <ProductResult
          {...results}
          tableTitle={`UUID: ${resultUuid}`}
          includeUuid={false}
          entity={filterKey}
          initialPage={results.page}
          selector={result => String(result.id) === testId}
          selectedTestId={testId}
          query={results.query}
          rowProps={{
            onClick: result =>
              history.push(
                `/missioncontrol/product/${productId}/result/${result.uuid}/${result.id}`
              ),
            isSelected: result => testId === String(result.id)
          }}
        />
      )}
      {productId && resultUuid && testId && (
        <ProductTestResult
          opts={testResultOpts}
          productId={productId}
          resultUuid={resultUuid}
          testId={testId}
        />
      )}
    </Content>
  )
}

interface ProductTestResultProps {
  productId: string
  resultUuid: string
  testId: string
  opts?: React.ComponentProps<typeof TestResults>['opts']
}

function ProductTestResult({
  productId,
  resultUuid,
  testId
}: React.ComponentProps<'div'> & ProductTestResultProps) {
  const resultParams = useMemo(() => [productId, resultUuid, testId], [
    productId,
    resultUuid,
    testId
  ])
  const { data, error, loading } = useAsync({
    fn: fetchProductUuidResult,
    args: resultParams
  })

  return (
    <LoadingErrorEmptyWrapper
      loadingProps={{ height: 45, width: 45 }}
      error={error}
      initialized={!loading}>
      {!isEmpty(data) && (
        <TestResults
          opts={testResultOpts}
          data={data}
          download={URL.createObjectURL(
            new Blob([JSON.stringify(data)], {
              type: 'application/json'
            })
          )}
        />
      )}
    </LoadingErrorEmptyWrapper>
  )
}
