import React, { useState, useCallback, useEffect } from 'react'
import styled from '@emotion/styled/macro'
import Select from '../../../../components/select'
import { capitalize } from '../../../../utils/text'
import { BiquadStage } from '../../../../types/dbmc2evb2'

import {
  toFloat,
  toFixed,
  toUserParameters,
  toCoefficents
} from '../../../../utils/biquad'

import {
  BiquadType,
  BiquadParams,
  BiquadCoeffs
} from '../../../../types/biquad'

const StyledInput = styled.input`
  padding: 10px;
  z-index: 1;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.06);
`

const InputGroup = styled.div`
  margin-bottom: 12px;
  width: 160px;
  padding: 8px;
`

const InputComponentsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const InputTitle = styled.div`
  text-transform: uppercase;
  font-size: 14px;
  margin-bottom: 4px;
`

const playbackSelectProps = {
  control: baseStyles => ({
    ...baseStyles,
    border: '1px solid #e8e8e8',
    // padding: 0,
    // minHeight: 24
    zIndex: 1,
    boxShadow: '0 0 4px 0 rgba(0, 0, 0, 0.06)'

  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  container: baseStyles => ({
    ...baseStyles
  })
}

export const filterTypes = [
  'gain',
  'lowpass',
  'highpass',
  'bandpass',
  'bandcut',
  'peaking',
  'lowshelf',
  'highshelf',
  'coefficient'
]

type FilterOptionType = { label: string; value: BiquadType }

const filterTypeOptions: FilterOptionType[] = filterTypes.map(
  (option: BiquadType) => {
    return { value: option, label: capitalize(option) }
  }
)

const getFilterTypeOption = (value: BiquadType): FilterOptionType => {
  if (!value) return filterTypeOptions[0]
  return filterTypeOptions.find(o => {
    return o.value === value
  })
}

const numberOrNull = (value: unknown) => {
  if (value === '') return null

  const number = Number(value)
  if (isNaN(number)) return null

  return number
}

const filterTypeHasGain = (type: BiquadType): boolean =>
  ['gain', 'peaking', 'lowshelf', 'highshelf'].includes(type)
const filterTypeHasQ = (type: BiquadType): boolean =>
  [
    'peaking',
    'lowshelf',
    'highshelf',
    'lowpass',
    'highpass',
    'bandpass',
    'bandcut'
  ].includes(type)
const filterTypeHasCenterFreq = (type: BiquadType): boolean =>
  [
    'peaking',
    'lowshelf',
    'highshelf',
    'lowpass',
    'highpass',
    'bandpass',
    'bandcut'
  ].includes(type)
const filterTypeHasCoeffs = (type: BiquadType): boolean => type === 'coefficient'

interface Props {
  rate: number
  biquadStage: BiquadStage
  onChange: (s: BiquadStage) => void
}

export default function FilterEditor(props: Props) {
  const [biquadParameters, setBiquadParameters] = useState<BiquadParams>()
  const [biquadCoefficientsFloat, setBiquadCoefficientsFloat] = useState<BiquadCoeffs>()

  useEffect(() => {
    const { biquadStage } = props
    const coefficientsFloat = toFloat(biquadStage).unwrap()
    const parameters = toUserParameters(props.rate, coefficientsFloat)
    
    setBiquadCoefficientsFloat(coefficientsFloat)
    setBiquadParameters(parameters)
  }, [props])

  const commitParameters = useCallback(
    biquadParameters => {
      setBiquadParameters(biquadParameters)

      if (!biquadParameters){
        return
      }

      const biquadCoefficientsFloat: BiquadCoeffs = toCoefficents(biquadParameters)
      const biquadCoefficientsFixedResult = toFixed(biquadCoefficientsFloat)

      if (biquadCoefficientsFixedResult.ok) {
        const biquadStage = biquadCoefficientsFixedResult.unwrap()
        props.onChange(biquadStage)
      }
    },
    [props]
  )

  const commitCoefficients = useCallback(
    biquadCoefficientsFloat => {
      setBiquadCoefficientsFloat(biquadCoefficientsFloat)

      if (!biquadCoefficientsFloat){
        return
      }

      const biquadCoefficientsFixedResult = toFixed(biquadCoefficientsFloat)

      if (biquadCoefficientsFixedResult.ok) {
        const biquadStage = biquadCoefficientsFixedResult.unwrap()
        props.onChange(biquadStage)
      }
    },
    [props]
  )

  return (
    <InputComponentsContainer>
      <InputGroup>
        <InputTitle>Type</InputTitle>
        <Select
          styles={playbackSelectProps}
          options={filterTypeOptions}
          value={getFilterTypeOption(biquadParameters?.type)}
          placeholder={'Type'}
          onChange={(selected: FilterOptionType) => {
            commitParameters({
              ...biquadParameters,
              type: selected.value,
              Q: 0.5,
              centerFreq: 1000,
              gainDB: 3
            })
          }}
        />
      </InputGroup>

      {filterTypeHasGain(biquadParameters?.type) && (
        <InputGroup>
          <InputTitle>Gain</InputTitle>
          <StyledInput
            type="number"
            placeholder={'Gain'}
            value={biquadParameters.gainDB}
            onChange={({ currentTarget: { value } }) => {
              setBiquadParameters({
                ...biquadParameters,
                gainDB: numberOrNull(value)
              })
            }}
            onBlur={() => {
              commitParameters(biquadParameters)
            }}
            onKeyPress={event => {
              if (event.key === 'Enter') {
                commitParameters(biquadParameters)
              }
            }}
          />
        </InputGroup>
      )}

      {filterTypeHasCenterFreq(biquadParameters?.type) && (
        <InputGroup>
          <InputTitle>Frequency</InputTitle>
          <StyledInput
            type="number"
            placeholder={'Frequency'}
            value={biquadParameters.centerFreq}
            onChange={({ currentTarget: { value } }) => {
              setBiquadParameters({
                ...biquadParameters,
                centerFreq: numberOrNull(value)
              })
            }}
            onBlur={() => {
              commitParameters(biquadParameters)
            }}
            onKeyPress={event => {
              if (event.key === 'Enter') {
                commitParameters(biquadParameters)
              }
            }}
          />
        </InputGroup>
      )}

      {filterTypeHasQ(biquadParameters?.type) && (
        <InputGroup>
          <InputTitle>Q</InputTitle>
          <StyledInput
            type="number"
            placeholder={'Q'}
            value={biquadParameters.Q}
            onChange={({ currentTarget: { value } }) => {
              setBiquadParameters({
                ...biquadParameters,
                Q: numberOrNull(value)
              })
            }}
            onBlur={() => {
              commitParameters(biquadParameters)
            }}
            onKeyPress={event => {
              if (event.key === 'Enter') {
                commitParameters(biquadParameters)
              }
            }}
          />
        </InputGroup>
      )}

      {filterTypeHasCoeffs(biquadParameters?.type) && (
        <>
          <InputGroup>
            <InputTitle>b0</InputTitle>
            <StyledInput
              type="number"
              placeholder={'b0'}
              value={biquadCoefficientsFloat.b0}
              onChange={({ currentTarget: { value } }) => {
                setBiquadCoefficientsFloat({
                  ...biquadCoefficientsFloat,
                  b0: numberOrNull(value)
                })
              }}
              onBlur={() => {
                commitCoefficients(biquadCoefficientsFloat)
              }}
              onKeyPress={event => {
                if (event.key === 'Enter') {
                  commitCoefficients(biquadCoefficientsFloat)
                }
              }}
            />
          </InputGroup>
          <InputGroup>
            <InputTitle>b1</InputTitle>
            <StyledInput
              type="number"
              placeholder={'b1'}
              value={biquadCoefficientsFloat.b1}
              onChange={({ currentTarget: { value } }) => {
                setBiquadCoefficientsFloat({
                  ...biquadCoefficientsFloat,
                  b1: numberOrNull(value)
                })
              }}
              onBlur={() => {
                commitCoefficients(biquadCoefficientsFloat)
              }}
              onKeyPress={event => {
                if (event.key === 'Enter') {
                  commitCoefficients(biquadCoefficientsFloat)
                }
              }}
            />
          </InputGroup>
          <InputGroup>
            <InputTitle>b2</InputTitle>
            <StyledInput
              type="number"
              placeholder={'b2'}
              value={biquadCoefficientsFloat.b2}
              onChange={({ currentTarget: { value } }) => {
                setBiquadCoefficientsFloat({
                  ...biquadCoefficientsFloat,
                  b2: numberOrNull(value)
                })
              }}
              onBlur={() => {
                commitCoefficients(biquadCoefficientsFloat)
              }}
              onKeyPress={event => {
                if (event.key === 'Enter') {
                  commitCoefficients(biquadCoefficientsFloat)
                }
              }}
            />
          </InputGroup>
          <InputGroup>
            <InputTitle>a1</InputTitle>
            <StyledInput
              type="number"
              placeholder={'a1'}
              value={biquadCoefficientsFloat.a1}
              onChange={({ currentTarget: { value } }) => {
                setBiquadCoefficientsFloat({
                  ...biquadCoefficientsFloat,
                  a1: numberOrNull(value)
                })
              }}
              onBlur={() => {
                commitCoefficients(biquadCoefficientsFloat)
              }}
              onKeyPress={event => {
                if (event.key === 'Enter') {
                  commitCoefficients(biquadCoefficientsFloat)
                }
              }}
            />
          </InputGroup>

          <InputGroup>
            <InputTitle>a2</InputTitle>
            <StyledInput
              type="number"
              placeholder={'a2'}
              value={biquadCoefficientsFloat.a2}
              onChange={({ currentTarget: { value } }) => {
                setBiquadCoefficientsFloat({
                  ...biquadCoefficientsFloat,
                  a2: numberOrNull(value)
                })
              }}
              onBlur={() => {
                commitCoefficients(biquadCoefficientsFloat)
              }}
              onKeyPress={event => {
                if (event.key === 'Enter') {
                  commitCoefficients(biquadCoefficientsFloat)
                }
              }}
            />
          </InputGroup>
        </>
      )}
    
    </InputComponentsContainer>
  )
}

