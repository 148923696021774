const initialData = {
  path: undefined,
  deviceControl: {
    anc: undefined,
    awareness: undefined
  },
  isFlashWriting: false,
  flashMessage: '',
  microphones: {
    anc: undefined,
    voice: undefined,
    micbias: undefined
  },
  boardControl: {
    reset: undefined,
    led: undefined,
    filter: undefined,
    daughter: undefined
  },
  firmware: {
    version: undefined,
    commit: undefined
  },
  parameters: {
    setup: undefined,
    playback: undefined,
    voicepickup: undefined,
    feedback: undefined,
    feedforward: undefined,
    talkthrough: undefined,
    calibration: undefined
  }
}

export default initialData
