import styled from '@emotion/styled/macro'
import { Field } from 'formik'
import Button from './button'

export const Title = styled.div`
  font-weight: bold;
  font-size: 28px;
  color: #fff;
  margin-bottom: 16px;
`

export const Subtitle = styled.div`
  color: #909295;
  font-size: 17px;
  font-weight: 300;
  margin-bottom: 16px;
  line-height: 1.5;
  min-height: 52px;
`

export const LoginButton = styled(Button)`
  width: 100%;
  font-size: 14px;
  border: 0;
  background-color: #222222;
  color: #fff;
  cursor: pointer;
  margin-top: 18px;
  margin-bottom: 32px;
  line-height: 13px;
  position: relative;
  height: 48px;
  transition: 500ms all;
  border-radius: 8px;
  font-weight: 300;

  &:disabled {
    opacity: 0.8;
    cursor: not-allowed;
  }

  &:hover svg {
    right: 12px;
  }
`

export const LoginField = styled(Field)`
  outline-width: 0;
  border: 0;
  font-size: 15px;
  font-weight: 300;
  background-color: transparent;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  width: 100%;
  padding: 12px 10px;
  color: #fff;
  padding-left: 10px;
  padding-right: 0;
  margin-bottom: 28px;
  transition: 500ms all;
  border-radius: 0;

  &:focus {
    border-bottom: 1px solid rgba(255, 255, 255, 1);
  }
`

export const LoginForm = styled.div`
  width: 380px;
  padding: 40px;
`

export const Content = styled.div`
  display: grid;
  height: inherit;
  align-content: center;
  justify-content: center;
`

export const LinkUnderForm = styled.div`
  color: #909295;
  font-size: 13px;
  font-weight: 300;

  a:hover {
    color: #ec1b23;
  }
`
