import styled from '@emotion/styled/macro'
import React, { useState } from 'react'
import Select from '../../../../components/select'
import { toHex, getHexDigits, getRegisterField } from '../utils/binary'
import { SelectOption } from '../../../../types/input-option'
import ReactTooltip from 'react-tooltip'

function HexInput({ data, name, handler, bitmin, bitmax }) {
  const format = v => toHex(v, getHexDigits(bitmin, bitmax))

  const shiftedData = getRegisterField(data, bitmin, bitmax)
  const [label, setLabel] = useState(format(shiftedData))
  const [value, setValue] = useState(shiftedData)

  return (
    <StyledInput
      type="text"
      value={label}
      style={{ backgroundColor: '#fff' }}
      onChange={event => {
        const isHexPrefix = /^0x$/i.test(event.currentTarget.value)
        const isHex = /^0x[0-9a-f]+$/i.test(event.currentTarget.value)
        if (isHexPrefix) {
          const inputValue = 0
          setValue(inputValue)
          setLabel(format(inputValue))
        } else if (isHex) {
          const inputValue = parseInt(event.currentTarget.value)
          const maxValue = Math.pow(2, bitmax - bitmin + 1)
          if (inputValue <= maxValue) {
            setValue(inputValue)
            setLabel(format(inputValue))
          }
        }
      }}
      onBlur={event => {
        setLabel(format(value))
        handler(name, { value, bitmin, bitmax })
      }}
    />
  )
}

interface InputComponentProps {
  gap: number
}

export function createInputComponents(
  data,
  fields,
  mask,
  handler: (componentInputName: string, value: any) => void = () => false,
  opts: InputComponentProps = {
    gap: 24
  },
  overrideName?:string
) {

  const bitsPassMask=(bitmin,bitmax,mask=0xFFFFFFFF)=>{
    if (mask===0xFFFFFFFF){
      return true
    }

    for (let bit=bitmin;bit<=bitmax;bit++){
      if ((Math.pow(2,bit)&mask)>0){
        return true
      }
    }

    return false
  }

  return function InputComponent() {
    return (
      <>
        <ReactTooltip
          type={'dark'}
          place={'right'}
          multiline={true}
          html={true}
          effect={'solid'}
        />
        <InputComponentsContainer style={{ gridGap: opts?.gap }}>
          {fields.map(({ name, options, bitmin, bitmax, description }) => {
            if (!bitsPassMask(bitmin,bitmax,mask)){
              return null
            }
            
            //if an option is just a string, use that as label and index as value
            //otherwise if it's an obj with label & value props, use those
            const selectOptions = options
              ? options.map((option, index) => (
                {
                  label:typeof(option.label)!=='undefined'?option.label:option, 
                  value:typeof(option.value)!=='undefined'?option.value:index
                }
              ))
              : []

            return (
              <InputField
                key={name}
                data-tip={`<p>${description}</p><p>bits: [${bitmin},${bitmax}]</p>`}>
                <InputFieldTitle>{overrideName?overrideName:name}</InputFieldTitle>
                {options?.length ? (
                  <StyledSelect
                    options={selectOptions}
                    value={selectOptions.find(
                      e => e.value === getRegisterField(data, bitmin, bitmax)
                    )}
                    onChange={({ value }: SelectOption<unknown>) => {
                      console.log('selectchanged '+name+':'+value)
                      handler(name, { value, bitmin, bitmax })
                    }}
                  />
                ) : (
                  <HexInput
                    data={data}
                    name={name}
                    bitmin={bitmin}
                    bitmax={bitmax}
                    handler={handler}
                  />
                )}
              </InputField>
            )
          })}
        </InputComponentsContainer>
      </>
    )
  }
}

const StyledSelect = (props: React.ComponentProps<typeof Select>) => (
  <Select
    {...props}
    styles={{
      control: baseStyles => ({
        ...baseStyles,
        padding: 0,
        borderWidth: 0,
        boxShadow: '0 0 4px 0 rgba(0,0,0,0.06)'
      }),
      valueContainer: baseStyles => ({
        ...baseStyles,
        fontSize: 14
      }),
      menu: baseStyles => ({
        ...baseStyles,
        fontSize: 14
      })
    }}
  />
)

const StyledInput = styled.input`
  padding: 10px;
  z-index: 1;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.06);
`

const InputField = styled.div`
  font-size: 15px;
  min-width: 120px;
`

const InputFieldTitle = styled.p`
  line-height: 2;
  font-size: 14px;
`

const InputComponentsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`
