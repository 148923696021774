import api from '../../services/common/api'
import { ResultList, ResultParams } from '../../types/result-list'
import { Station, StationInfo } from '../../types/station'
import { StationMessage } from '../../types/station-message'

export function fetchStations(opts?: ResultParams) {
  return api.get<ResultList<Station>>(`/stations`, {
    params: opts
  })
}

export function fetchStation(stationName: string, opts?: ResultParams) {
  return api.get<StationInfo>(`/stations/${stationName}`, {
    params: opts
  })
}

export function fetchStationMessages(stationName: string, opts?: ResultParams) {
  return api.get<ResultList<StationMessage>>(
    `/stations/${stationName}/messages`,
    {
      params: opts
    }
  )
}

export function fetchFullStationDetails(stationName: string) {
  return api.get<StationInfo>(`/stations/${stationName}`)
}

export function postStationTest(
  stationName: string,
  test: string | ArrayBuffer
) {
  return api.post(`/stations/${stationName}/test`, test, {
    headers: {
      'Content-Type': 'text/plain'
    }
  })
}

export interface PostStationLicenseParams {
  licensee: string
  host: string
  validFrom: string
  validTo: string
  message: string
}

export function postStationLicense(
  stationName: string,
  license: PostStationLicenseParams
) {
  return api.post<never>(`/stations/${stationName}/license`, license)
}

export interface PostStationFirmwareParams {
  name: string
  version: string
}

export function postStationFirmware(
  stationName: string,
  firmware: PostStationFirmwareParams
) {
  return api.post(`/stations/${stationName}/install`, firmware)
}

export function postStationSupport(stationName: string, port: number) {
  return api.post<void>(`/stations/${stationName}/support`, { port })
}

export function postStationReset(stationName: string) {
  return api.post<void>(`/stations/${stationName}/reset`)
}

export function postStationReport(stationName: string) {
  return api.post<void>(`/stations/${stationName}/report`)
}
