import styled from '@emotion/styled/macro'
import React from 'react'
import LoadingSpinner, { LoaderVariant } from './loading-spinner'

interface Props extends ContainerProps {
  loading?: boolean
  variant?: LoaderVariant
  fit?: boolean
}

export default function Button({
  children,
  icon = false,
  type = 'button',
  loading = false,
  variant = 'dark',
  disabled = false,
  fit = false,
  ...props
}: React.ComponentProps<'button'> & Props) {
  return (
    <Container
      icon={icon}
      disabled={loading || disabled}
      type={type}
      fit={fit}
      {...props}>
      <Content>
        {loading ? (
          <LoadingSpinner variant={variant} fill={'#333'} />
        ) : (
          children
        )}
      </Content>
    </Container>
  )
}

const Content = styled.div`
  width: 100%;
  display: grid;
  align-items: center;
  justify-items: center;
  font-weight: inherit;
`

interface ContainerProps {
  icon?: boolean
  disabled?: boolean
  toggle?: boolean
  fit?: boolean
}

const Container = styled.button<ContainerProps>`
  display: grid;
  align-items: center;
  justify-items: center;
  box-sizing: border-box;
  line-height: 2;
  font-size: 12px;
  font-weight: 800;
  text-transform: uppercase;
  transition: 400ms all;
  overflow: hidden;

  :hover {
    // transform: translate(-2px, -2px);
    background-color: '#888';
  }

  ${({ icon, disabled, toggle, fit }) => `
    height: ${icon ? '40px' : '100%'};
    width: ${icon ? '40px' : fit ? 'fit-content' : '100%'};
    padding: ${icon ? '2px' : '10px 16px'};
    border-radius: ${icon || typeof toggle === 'boolean' ? '9999px' : '.5em'};
    border: ${typeof toggle === 'boolean' || icon ? '2px solid #ec1b23' : '0'};
    background-color: ${
      toggle === true
        ? '#ec1b23'
        : toggle === false || icon
        ? 'transparent'
        : ' #dadada'
    };
    color: ${toggle === true ? '#fff' : toggle === false ? '#ec1b23' : '#666'};
    cursor: ${disabled ? 'not-allowed' : 'pointer'};
    opacity: ${disabled ? 0.5 : 1};

    :focus {
      border: ${
        typeof toggle === 'boolean' || icon ? '2px solid #ec1b23' : '0'
      };
    }

    ${icon &&
      `
        svg, svg * {
          fill: ${toggle ? '#ffffff' : '#ec1b23'};
        }

        :focus {
          border: 2px solid #ec1b23;
        }
    `}
  `}
`
