import styled from '@emotion/styled/macro'
import React, { useContext } from 'react'
import { Redirect } from 'react-router'
import { mobileSize } from '../../../constants'
import Content from '../../../containers/content'
import { ProductionStoreContext } from '../../../context/production-store'
import ResultPreview from './result-preview'

type Props = {
  fqdn?: string
}

export default function StationTest({
  fqdn,
  ...props
}: React.ComponentProps<typeof Container> & Props) {
  const { list } = useContext(ProductionStoreContext)

  return (
    <Content title={'Production'} subtitle={'Test'}>
      <Container {...props}>
        {fqdn ? (
          <ResultPreview fqdn={fqdn} />
        ) : list?.length ? (
          list?.map(fqdn => <ResultPreview key={fqdn} fqdn={fqdn} />)
        ) : (
          <Redirect to={'/production/setup'} />
        )}
      </Container>
    </Content>
  )
}

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(
    auto-fit,
    minmax(calc((100% - 92px * 2) / 3), 1fr)
  );
  grid-gap: 92px;
  margin-left: 80px;
  margin-right: 80px;
  align-content: center;
  grid-auto-flow: row;
  min-height: -webkit-fill-available;
  padding: 82px 0px;

  @media screen and (max-width: ${mobileSize}px) {
    grid-template-columns: 1fr;
    grid-gap: 24px;
    margin-left: 12px;
    margin-right: 12px;
  }
`
