import { useEffect } from 'react'

export default function useKeypress(keyCode: number[] | number, cb: Function) {
  useEffect(() => {
    const handleEscape = (e: KeyboardEvent) => {
      const keyCodes = typeof keyCode === 'number' ? [keyCode] : keyCode

      for (let i = 0; i < keyCodes.length; i++) {
        if (e.keyCode === keyCodes[i]) {
          cb()
          e.preventDefault()
        }
      }
    }

    window.addEventListener('keydown', handleEscape)

    return () => {
      window.removeEventListener('keydown', handleEscape)
    }
  }, [cb, keyCode])
}
