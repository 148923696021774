import styled from '@emotion/styled/macro'
import { Field, Form, FormikProps, withFormik, ErrorMessage } from 'formik'
import * as baseMoment from 'moment'
import { extendMoment } from 'moment-range'
import React from 'react'
import DateRangePicker, { OnSelectCallbackParam } from 'react-daterange-picker'
import * as Yup from 'yup'
import { ReactComponent as ArrowRight } from '../../../assets/icons/arrow-right.svg'
import Button from '../../../components/button'
import Card from '../../../components/card'
import CenteredMargin from '../../../components/centered-margin'
import DatePickerContainer from '../../../components/date-picker-container'
import Status from '../../../components/status'
import Subtitle from '../../../components/subtitle'
import TopRightIcon from '../../../components/top-right-icon'
import { mobileSize, dateFormat } from '../../../constants'
import useKeypress from '../../../hooks/use-keypress'
import useToggle from '../../../hooks/use-toggle'
import { Host, LicenceStatus } from '../../../types/station'
import { interpretError } from '../../../utils/common'
import { createRequiredTextField } from '../../../utils/form'
import { keyEscape } from '../../../utils/key-codes'
import Input from '../../../components/input'
import { InputError } from '../../../components/form'
import {
  postStationLicense,
  PostStationLicenseParams
} from '../../../services/entities/station'

const moment = extendMoment(baseMoment)

interface Props {
  stationName: string
  host: Host['MachineId']
}

interface LicenseeForm {
  from: baseMoment.Moment
  to: baseMoment.Moment
  authorization: LicenceStatus['Message']
  licensee: LicenceStatus['Licensee']
  host: LicenceStatus['Message']
}

export default withFormik<Partial<LicenceStatus> & Props, LicenseeForm>({
  mapPropsToValues: props => ({
    from: props.From ? moment(props.From, 'YYYY-MM-DD') : moment(Date.now()),
    to: props.To ? moment(props.To, 'YYYY-MM-DD') : moment(Date.now()),
    authorization: props?.Message ?? '',
    licensee: props?.Licensee ?? '',
    host: props.host ?? ''
  }),
  handleSubmit: async (
    { to, from, authorization: message, licensee, host },
    { resetForm, setStatus, props: { stationName } }
  ) => {
    try {
      await postStationLicense(stationName, {
        validFrom: from.format(dateFormat),
        validTo: to.format(dateFormat),
        message,
        licensee,
        host
      } as PostStationLicenseParams)
      resetForm()
      setStatus('Successfully queued license installation.')
    } catch (err) {
      setStatus(interpretError(err))
    }
  },
  validationSchema: Yup.object().shape({
    licensee: createRequiredTextField('licensee'),
    authorization: createRequiredTextField('authorization')
  }),
  validate: ({ from, to }) => ({
    ...(from > to
      ? {
          from: 'Starting date must be earlier than expiry date.',
          to: 'Expiry date must be later than from date.'
        }
      : {})
  })
})(LicenseeForm)

function LicenseeForm({
  isValid,
  setFieldValue,
  status,
  values,
  isSubmitting,
  handleChange,
  dirty,
  values: { from, to }
}: FormikProps<LicenseeForm>) {
  const [pickerShown, showPicker, hidePicker] = useToggle(false)

  const onDateRangePick = ({ start, end }: OnSelectCallbackParam) => {
    setFieldValue('from', start)
    setFieldValue('to', end)
    hidePicker()
  }

  useKeypress(keyEscape, hidePicker)

  return (
    <Container>
      <Card>
        <Subtitle>Update Licence</Subtitle>
        {status && <Status>{status}</Status>}
        <FormContainer>
          <Form>
            <CenteredMargin>
              <Field
                type="text"
                name="licensee"
                placeholder="Licensee"
                onFocus={hidePicker}
                onChange={handleChange}
                required
              />
              <InputError>
                <ErrorMessage name="licensee" />
              </InputError>
            </CenteredMargin>
            <CenteredMargin>
              <Field
                type="text"
                name="authorization"
                placeholder="Authorization"
                onFocus={hidePicker}
                onChange={handleChange}
                required
              />
              <InputError>
                <ErrorMessage name="authorization" />
              </InputError>
            </CenteredMargin>
            <CenteredMargin>
              <Field
                type="text"
                disabled
                name="host"
                placeholder="Host"
                onChange={handleChange}
              />
            </CenteredMargin>
            <Duration>
              <Input
                type="text"
                name="from"
                value={from.format('MM-DD-YYYY')}
                onFocus={showPicker}
                onClick={showPicker}
              />
              <ArrowRight width={24} height={24} />
              <Input
                type="text"
                value={to.format('MM-DD-YYYY')}
                onFocus={showPicker}
                onClick={showPicker}
                name="to"
              />
            </Duration>
            <DatePickerContainer shown={pickerShown}>
              <StyledDateRangePicker
                onSelect={onDateRangePick}
                value={moment.range(values.from, values.to)}
              />
            </DatePickerContainer>
            <TopRightIcon>
              <Button
                loading={isSubmitting}
                onSubmit={hidePicker}
                disabled={!(dirty && isValid)}
                type="submit"
                icon={true}>
                <ArrowRight width={24} />
              </Button>
            </TopRightIcon>
          </Form>
        </FormContainer>
      </Card>
    </Container>
  )
}

const StyledDateRangePicker = styled(DateRangePicker)`
  z-index: 1;
`

const Duration = styled.div`
  display: grid;
  grid-template-columns: 127px 46px 127px;
  align-items: center;
  justify-items: center;

  input {
    margin-bottom: 0;
  }

  @media screen and (max-width: ${mobileSize}px) {
    grid-template-columns: 1fr 46px 1fr;
  }
`

const FormContainer = styled.div`
  width: 300px;

  @media screen and (max-width: ${mobileSize}px) {
    width: 100%;
  }
`

const Container = styled.div`
  position: relative;
`
