import DateTimePicker from 'react-datetime'
import 'react-datetime/css/react-datetime.css'
import React from 'react'
import styled from '@emotion/styled/macro'

export default function DateTime(
  props: React.ComponentProps<typeof DateTimePicker>
) {
  return (
    <Container>
      <DateTimePicker {...props} />
    </Container>
  )
}

const Container = styled.div`
  .rdt {
    input {
      height: 38px;
    }

    .rdtPicker {
      padding: 12px;
      border-radius: 8px;
      width: 268px;
      left: -28px;
      margin-top: 6px;

      * {
        transition: 200ms all;
      }

      td.rdtActive,
      td.rdtActive:hover {
        background-color: #ec1b23;
      }

      td.rdtToday:before {
        border-bottom: 7px solid #ec1b23;
      }

      th,
      td {
        text-align: center;
        height: fit-content;
        padding: 8px 0px;
        border-radius: 4px;
      }

      .rdtSwitch {
        padding-top: 11px;
      }

      .rdtTimeToggle {
        height: fit-content;
        padding: 8px 0px;
      }
    }
  }
`
