import axios from 'axios'
import { tokenKey } from '../../constants'
import { requestSuccessful } from '../../utils/common'

const apiBaseUrl =
  process.env.REACT_APP_API_URL || 'https://development-api.soundstation.audio/'

const api = {
  ...axios.create({
    baseURL: apiBaseUrl,
    timeout: 20000
  }),
  setToken: (token: string) => localStorage.setItem(tokenKey, token),
  clearToken: () => localStorage.removeItem(tokenKey)
}

api.interceptors.request.use(config => {
  config.headers.authorization = localStorage.getItem(tokenKey)
  return config
})

api.interceptors.response.use(
  config => {
    if (config && !requestSuccessful(config.status)) {
      throw new Error(
        `The request you sent was unsuccessful. It returned a ${config.status}: ${config.statusText} error. Payload: ${config.data}`
      )
    }
    return config
  },
  function(error) {
    const { data: { message = null } = {}, status } = error?.response ?? {}

    switch (status) {
      case 401:
        if (message?.toLowerCase() === 'the incoming token has expired')
          window.location.replace(`/logout`)
        break
      case 500:
        if (message?.toLowerCase() === 'refresh token has expired')
          window.location.replace(`/logout`)
        break
    }

    throw new Error(error?.response?.data?.message ?? error.toString())
  }
)

export default api
