import React from 'react'
import ReactDOM from 'react-dom'
import App from './containers/app'
import * as serviceWorker from './serviceWorker'

const root = document.getElementById('root')

ReactDOM.render(<App />, root)

// if (process.env.NODE_ENV === 'production') {
//   console.log = () => false
// }

if (module.hot) {
  module.hot.accept('./containers/app', () => {
    const NextApp = require('./containers/app').default
    ReactDOM.render(<NextApp />, root)
  })
}

serviceWorker.register()
