import { types } from 'mobx-state-tree'

const Token = types.model('Token', {
  accessToken: types.string,
  expiresIn: types.number,
  idToken: types.string,
  refreshToken: types.string
})

export default Token
