import styled from '@emotion/styled/macro'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Select from '../../../components/select'
import WebSocketAsPromised from 'websocket-as-promised'
import Button from '../../../components/button'
import { Tab } from '../../../components/tab'
import Content from '../../../containers/content'
import useAsync from '../../../hooks/use-async'
import useFields from '../../../hooks/use-fields'
import useToggle from '../../../hooks/use-toggle'
import api from '../../../services/common/api'
import { getPlatformConnectors } from '../../../services/entities/connector'
import { getFirmwares } from '../../../services/entities/firmware'
import UpdateFirmware from './update-firmware.page'
import { Devices } from '../../../types/engineer-device'
import detectOS from '../../../utils/detect-os'
import ConnectEVB from './connect-evb.page'
import Designer from './designer'
import DownloadPluginPage from './plugin'
import { configureBoard } from './utils/board'
import getDevicePlatform from './utils/common'
import { isSelected, linkTo, WidgetType } from './utils/filter-designer-router'
import { getSocketConnection } from './utils/socket'
import { getFirmwareVersion } from './utils/board'
import { decodeErrorCode } from './utils/dbmc2'

type DeviceList = (Devices['List'][0] & {
  id: string
})[]

const deviceTypeValue = {
  'Uninitialized EVB': 'Uninitialized-evb',
  'DBMC2 EVB': 'dbmc2-evb'
}

const mapKindToLabel = k => {
  switch (k) {
    case 'DBMC2 EVB':
      return 'DBMC2 EDK'
    default:
      return k
  }
}

export default function Design() {

  const [devices, setDevices] = useState([] as DeviceList)
  const os = useMemo(detectOS, [])

  const {
    data: { data: connectorsData },
    loading: platformConnectorsLoading
  } = useAsync({ fn: getPlatformConnectors })

  const [directLinks, setDirectLinkValue] = useFields({
    macOs: null as string,
    windows: null as string,
    linux: null as string
  })
  const {
    data: { data: firmwares },
    loading: firmwaresLoading
  } = useAsync({
    fn: getFirmwares
  })
  const [socketLoading, , socketFinishLoading] = useToggle(true)
  const [downloadLinksLoading, , downloadLinksFinishLoading] = useToggle(true)
  const [firmwareUpdate, setFirmwareUpdate] = useState(null)

  const [, setError] = useState<string>(null)
  const loading = useMemo(
    () => platformConnectorsLoading || downloadLinksLoading || socketLoading,
    [platformConnectorsLoading, downloadLinksLoading, socketLoading]
  )
  const history = useHistory()
  const [selectedDevice, setSelectedDevice] = useState(
    !!devices.length ? devices[0] : null
  )
  const [isConnectorOutdated, setOutdated, setUpdated] = useToggle(false)
  const [openSocket, setSocket] = useState(null as WebSocketAsPromised)
  const memoizedLinkTo = useCallback(
    (
      arg: Omit<
        typeof linkTo extends (params: infer U) => void ? U : never,
        'history'
      >
    ) => linkTo({ ...arg, history }),
    [history]
  )
  const [
    currentConnectedFirmwareVersion,
    setCurrentConnectedFirmwareVersion
  ] = useState(null)

  useEffect(() => {
    if (!!selectedDevice && !!firmwares?.length) {
      const currentPlatform = getDevicePlatform(selectedDevice)
      if (currentConnectedFirmwareVersion !== null) {
        console.log(`FLASH firmware on EDK: ${currentConnectedFirmwareVersion}`)

        const firmware = firmwares?.find(firmware => 
          firmware.platform === currentPlatform &&
          (currentConnectedFirmwareVersion === 'none' ||
          firmware.version !== currentConnectedFirmwareVersion)
        )
        
        // Force no firmware update during development.
        // In a live environment the EVB FW would be updated to the first (only?) version returned by the API server.
        if (false && firmware) {
          console.log(`FLASH firmware update to: ${firmware.version}`)
          setFirmwareUpdate(firmware)
        } else {
          console.warn('FLASH no firmware available for', currentPlatform)
        }
      }
    }
  }, [
    selectedDevice,
    setFirmwareUpdate,
    firmwares,
    currentConnectedFirmwareVersion
  ])

  useEffect(() => {
    let isValid = true

    const requests = connectorsData?.map(({ url, platform }) =>
      api
        .get<{ downloadUrl: string }>(url)
        .then(({ data: { downloadUrl } }) => {
          if (isValid) {
            if (platform === 'MacOS') {
              setDirectLinkValue('macOs', downloadUrl)
            } else if (platform === 'Linux') {
              setDirectLinkValue('linux', downloadUrl)
            } else {
              setDirectLinkValue('windows', downloadUrl)
            }
            downloadLinksFinishLoading()
          }
        })
        .catch(() => {
          if (isValid) {
            downloadLinksFinishLoading()
            setError('Could not load download links')
          }
        })
    )

    !!requests && Promise.all(requests)

    return () => (isValid = false)
  }, [connectorsData, setError, downloadLinksFinishLoading, setDirectLinkValue])

  const [firmware, setFirmware] = useState({
    inprogress: false,
    action: '',
    complete: 0
  })

  const handleSocketMessage = useCallback(
    (openedSocket: WebSocketAsPromised, cb?: () => void) => {
      if (cb) cb()
      openedSocket.onMessage.addListener(message => {
        const data = JSON.parse(message)
        if (data?.Version) {
          const { Platform: platform, Number: versionNumber } = data.Version
          const latestDriver = connectorsData?.filter(
            connector => connector.platform === platform
          )

          if (latestDriver && latestDriver[0].version === versionNumber) {
            setUpdated()
          } else {
            setOutdated()
          }
        } else if (data?.Devices?.List) {
          console.log('attached devices list updated', data.Devices.List)
          setDevices(
            data?.Devices?.List.map((device, index) => ({
              ...device,
              id: index + 1
            })) as DeviceList
          )
        } else if (data?.Error?.Message) {
          console.error(
            `Error\n\n${data.Error.Message}\n\nYou can try reconnecting the EDK and restarting the application.`
          )
          // alert(
          //   `Error\n\n${data.Error.Message}\n\nYou can try reconnecting the EDK and restarting the application.`
          // )
        } else if (data?.Dbmc2 && data.Dbmc2.Response[4] !== 0) {
          alert(
            `Error\n\nUnexpected response from DBMC2: ${JSON.stringify(
              data.Dbmc2
            )}\n\nYou can try reconnecting the EDK and restarting the application.`
          )
          console.error(decodeErrorCode(data.Dbmc2.Response[4]))
        } else if (data?.EepromUpdate) {
          setFirmware(firmware => ({
            ...firmware,
            inprogress: data.EepromUpdate.InProgress,
            action: '',
            complete: 0
          }))
        } else if (data?.EepromProgress) {
          setFirmware(firmware => ({
            ...firmware,
            action: data.EepromProgress.Action,
            complete: data.EepromProgress.Complete
          }))
        }
      })
    },
    [connectorsData, setOutdated, setUpdated, setDevices, setFirmware]
  )

  useEffect(() => {
    let timer = null
    let isValid = true

    if (!openSocket || openSocket?.isClosed) {
      timer = setInterval(() => {
        const retrievedSocket = getSocketConnection()
        retrievedSocket.then(openedRetrievedSocket => {
          if (isValid) {
            setSocket(openedRetrievedSocket)
            handleSocketMessage(openedRetrievedSocket, socketFinishLoading)
            clearInterval(timer)
          }
        })
      }, 3000)
    }

    return () => {
      isValid = false
      timer && clearInterval(timer)
    }
  }, [openSocket, handleSocketMessage, socketFinishLoading])

  useEffect(() => {
    if (openSocket?.isOpened && !!selectedDevice) {
      ;(async () => {
        const fw = await getFirmwareVersion(openSocket, selectedDevice.Path)
        setCurrentConnectedFirmwareVersion(fw.version)
      })()
    }
  }, [openSocket, selectedDevice, setCurrentConnectedFirmwareVersion])

  const createSocketConnection = useCallback(
    ({ isValid }) => {
      const socketPromise = getSocketConnection()

      socketPromise
        .then(openedSocket => {
          if (isValid) {
            setSocket(openedSocket)
            handleSocketMessage(openedSocket, socketFinishLoading)
          }
        })
        .catch(socketFinishLoading)
    },
    [socketFinishLoading, handleSocketMessage]
  )

  useEffect(() => {
    let relevantInstance = true

    if (
      !openSocket?.isOpened &&
      !openSocket?.isOpening &&
      connectorsData?.length
    ) {
      createSocketConnection({
        isValid: relevantInstance
      })
    }

    return () => {
      relevantInstance = false
    }
  }, [createSocketConnection, openSocket, connectorsData])

  const devicesSelectOptions = useMemo(
    () =>
      devices.map(({ Kind, id }) => ({
        label:
          devices.length > 1
            ? `${id}. ${mapKindToLabel(Kind)}`
            : mapKindToLabel(Kind),
        value: deviceTypeValue[Kind] ?? 'dbmc2-evb'
      })),
    [devices]
  )

  const initializeOptions = useMemo(
    () => [
      {
        label: mapKindToLabel('DBMC2 EVB'),
        value: 'dbmc2-evb'
      }
    ],
    []
  )

  const widgetIsSelected = useCallback(
    (widget: WidgetType) => isSelected('widget', widget, history),
    [history]
  )

  const widgetLinkTo = useCallback(
    (widget: WidgetType) =>
      memoizedLinkTo({
        linkType: 'widget',
        value: widget
      }),
    [memoizedLinkTo]
  )

  useEffect(() => {
    if (devices.length === 0) {
      setSelectedDevice(null)
    } else if (selectedDevice === null && !!devices.length) {
      setSelectedDevice(devices[0])
    }
  }, [devices, selectedDevice])

  useEffect(() => {
    return () => {
      if (openSocket) {
        openSocket.close()
      }
    }
  }, [openSocket])

  let mode

  if (loading || firmwaresLoading) {
    mode = 'loading'
  } else if (openSocket?.isOpened && !isConnectorOutdated) {
    if (!!devices.length) {
      if (!!selectedDevice && !!firmwareUpdate) {
        mode = 'updateFirmware'
      } else {
        mode = 'designer'
      }
    } else {
      mode = 'connectEVB'
    }
  } else {
    mode = 'downloadPlugin'
  }
  
  return (
    <Content
      title={'Engineer'}
      subtitle={'Design'}
      loading={loading || firmwaresLoading}>
      {mode === 'updateFirmware' && (
        <UpdateFirmware
          selectedDevice={selectedDevice}
          firmwares={firmwares}
          socket={openSocket}
          path={selectedDevice?.Path}
          firmware={firmware}
          doneUpdating={() => {
            setFirmwareUpdate(null)
          }}></UpdateFirmware>
      )}

      {mode === 'downloadPlugin' && (
        <DownloadPluginPage
          os={os}
          downloadLinksLoading={downloadLinksLoading}
          macOsDirectLink={directLinks.macOs}
          windowsOsDirectLink={directLinks.windows}
          linuxOsDirectLink={directLinks.linux}
        />
      )}

      {mode === 'connectEVB' && <ConnectEVB />}

      {mode === 'designer' && (
        <>
          <NavigateParametersContainer>
            <Select
              styles={{
                container: () => ({
                  position: 'relative',
                  width: 300
                })
              }}
              options={devicesSelectOptions}
              // @ts-ignore
              onChange={({ value }: { value: string }) => {
                if (value && deviceTypeValue[value] !== selectedDevice.Kind) {
                  !!devices.length &&
                    setSelectedDevice(
                      devices.find(
                        device => value === deviceTypeValue[device.Kind]
                      )
                    )
                }
              }}
              value={
                selectedDevice
                  ? devicesSelectOptions?.find(
                      deviceOption =>
                        deviceTypeValue[selectedDevice.Kind] ===
                        deviceOption.value
                    )
                  : null
              }
            />
            {selectedDevice?.Kind !== 'Uninitialized EVB' && (
              <WidgetNavigation>
                <Tab
                  onClick={widgetLinkTo('control')}
                  selected={widgetIsSelected('control')}>
                  Control
                </Tab>
                <Tab
                  onClick={widgetLinkTo('evb')}
                  selected={widgetIsSelected('evb')}>
                  Evaluation Board
                </Tab>
              </WidgetNavigation>
            )}
          </NavigateParametersContainer>
          {selectedDevice?.Kind !== 'Uninitialized EVB' &&
          selectedDevice !== null ? (
            <Designer
              socket={openSocket}
              path={selectedDevice?.Path}
              firmware={firmware}
            />
          ) : selectedDevice !== null ? (
            <div
              style={{
                padding: '20px 0px',
                width: 300,
                fontSize: 14
              }}>
              <p style={{ marginBottom: 20 }}>Initialize as:</p>
              <div
                style={{
                  display: 'grid',
                  gridAutoFlow: 'column',
                  gridGap: '20px',
                  gridTemplateColumns: '1fr'
                }}>
                <Select options={initializeOptions} />
                <Button
                  style={{ width: 'fit-content', padding: '6px 16px' }}
                  onClick={() => {
                    configureBoard(openSocket, String(selectedDevice.Path), {
                      name: 'DBMC2 EVB',
                      major: 1,
                      minor: 1
                    })
                    // Not sure if this is necessary but I'm hoping that it will force the newly
                    // created device to be selected. This can be tested later since we're running
                    // out of boards to initialize
                    setSelectedDevice(null)
                  }}>
                  Initialize
                </Button>
              </div>
            </div>
          ) : (
            <div />
          )}
        </>
      )}
    </Content>
  )
}

export const WidgetNavigation = styled.div`
  width: 100%;
  display: grid;
  justify-content: end;
  grid-auto-flow: column;
  background-color: #e8e8e8;
  width: fit-content;
  justify-self: end;
  border-radius: 8px;
`

const NavigateParametersContainer = styled.div`
  width: 100%;
  font-size: 14px;
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  height: 49px;
`
