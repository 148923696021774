import { types } from 'mobx-state-tree'

const Loading = types
  .model('Loading', {
    loading: types.boolean
  })
  .actions(self => ({
    startLoading: () => {
      self.loading = true
    },
    finishLoading: () => {
      self.loading = false
    }
  }))

export default Loading
