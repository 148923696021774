import convert from './convert'
import { Result, Err, Ok } from 'ts-results'
import {
  BiquadCoeffs,
  BiquadCoeffsReal,
  BiquadParams,
  BiquadType
} from '../types/biquad'

const INTEGERBITS = 2
const FRACTIONBITS = 23

export const coefficientMaximum = convert.maximum(INTEGERBITS, FRACTIONBITS)
export const coefficientMinimum = convert.minimum(INTEGERBITS, FRACTIONBITS)

export const toCoefficents = (parameters: BiquadParams): BiquadCoeffsReal => {
  
  const gain = Math.pow(10, parameters.gainDB / 40)
  const omega = (2 * Math.PI * parameters.centerFreq) / parameters.sampleRate
  const sn = Math.sin(omega)
  const cs = Math.cos(omega)
  const alpha = sn / (2 * parameters.Q)
  const B = 2 * Math.sqrt(gain) * alpha

  let a0 = 0
  let coefficients = { a1: 0, a2: 0, b0: 0, b1: 0, b2: 0 }

  switch (parameters.type) {
    case 'gain':
      coefficients.a1 = 0
      coefficients.a2 = 0
      coefficients.b0 = gain
      coefficients.b1 = 0
      coefficients.b2 = 0
      break
    case 'bandpass':
      a0 = 1 + alpha
      coefficients.a1 = (-2 * cs) / a0
      coefficients.a2 = (1 - alpha) / a0
      coefficients.b0 = alpha / a0
      coefficients.b1 = 0
      coefficients.b2 = -alpha / a0
      break
    case 'lowpass':
      a0 = 1 + alpha
      coefficients.a1 = (-2 * cs) / a0
      coefficients.a2 = (1 - alpha) / a0
      coefficients.b0 = (1 - cs) / 2 / a0
      coefficients.b1 = (1 - cs) / a0
      coefficients.b2 = (1 - cs) / 2 / a0
      break
    case 'highpass':
      a0 = 1 + alpha
      coefficients.a1 = (-2 * cs) / a0
      coefficients.a2 = (1 - alpha) / a0
      coefficients.b0 = (1 + cs) / 2 / a0
      coefficients.b1 = -(1 + cs) / a0
      coefficients.b2 = (1 + cs) / 2 / a0
      break
    case 'bandcut':
      a0 = 1 + alpha
      coefficients.a1 = (-2 * cs) / a0
      coefficients.a2 = (1 - alpha) / a0
      coefficients.b0 = 1 / a0
      coefficients.b1 = (-2 * cs) / a0
      coefficients.b2 = 1 / a0
      break
    case 'peaking':
      a0 = 1 + alpha / gain
      coefficients.a1 = (-2 * cs) / a0
      coefficients.a2 = (1 - alpha / gain) / a0
      coefficients.b0 = (1 + alpha * gain) / a0
      coefficients.b1 = (-2 * cs) / a0
      coefficients.b2 = (1 - alpha * gain) / a0
      break
    case 'lowshelf':
      a0 = gain + 1 + (gain - 1) * cs + B
      coefficients.b0 = (gain * (gain + 1 - (gain - 1) * cs + B)) / a0
      coefficients.b1 = (2 * gain * (gain - 1 - (gain + 1) * cs)) / a0
      coefficients.b2 = (gain * (gain + 1 - (gain - 1) * cs - B)) / a0
      coefficients.a1 = (-2 * (gain - 1 + (gain + 1) * cs)) / a0
      coefficients.a2 = (gain + 1 + (gain - 1) * cs - B) / a0
      break
    case 'highshelf':
      a0 = gain + 1 - (gain - 1) * cs + B
      coefficients.b0 = (gain * (gain + 1 + (gain - 1) * cs + B)) / a0
      coefficients.b1 = (-2 * gain * (gain - 1 + (gain + 1) * cs)) / a0
      coefficients.b2 = (gain * (gain + 1 + (gain - 1) * cs - B)) / a0
      coefficients.a1 = (2 * (gain - 1 - (gain + 1) * cs)) / a0
      coefficients.a2 = (gain + 1 - (gain - 1) * cs - B) / a0
      break
  }

  return coefficients
}

const sortOfEqualNumbers = (a, b, precision = 5): boolean => {
  if (a === Infinity && b === Infinity) return true
  if (a === -Infinity && b === -Infinity) return true
  const expectedDiff = Math.pow(10, -precision) / 2
  const receivedDiff = Math.abs(a - b)
  return receivedDiff < expectedDiff
}

const sortOfEqualCoefficients = (a, b, precision = 5): boolean =>
  sortOfEqualNumbers(a.a1, b.a1, precision) &&
  sortOfEqualNumbers(a.a2, b.a2, precision) &&
  sortOfEqualNumbers(a.b0, b.b0, precision) &&
  sortOfEqualNumbers(a.b1, b.b1, precision) &&
  sortOfEqualNumbers(a.b2, b.b2, precision)

const defaultParameters = (fs): BiquadParams => ({
  id: '',
  type: 'coefficient',
  gainDB: 0,
  centerFreq: 0,
  sampleRate: fs,
  Q: 0
})

const isValidNumber = (x: number): boolean =>
  x !== Infinity && x !== -Infinity && !isNaN(x)

const isValidCandidate = (p: BiquadParams): boolean =>
  [p.centerFreq, p.sampleRate, p.Q, p.gainDB].reduce(
    (v, p) => v && isValidNumber(p),
    true
  )

const tryGain = (
  fs: number,
  coefficients: BiquadCoeffs
): Result<BiquadParams, void> => {
  const candidate = {
    ...defaultParameters(fs),
    type: 'gain' as BiquadType,
    gainDB: 40 * Math.log10(coefficients.b0)
  }

  if (!isValidCandidate(candidate)) return Err.EMPTY
  if (!sortOfEqualCoefficients(coefficients, toCoefficents(candidate)))
    return Err.EMPTY

  return Ok(candidate)
}

const tryBandpass = (
  fs: number,
  coefficients: BiquadCoeffs
): Result<BiquadParams, void> => {
  const a0 = 1 / (coefficients.b0 + coefficients.a2)
  const alpha = a0 - 1
  const cs = (-a0 * coefficients.a1) / 2

  const omega = Math.acos(cs)
  const sn = Math.sin(omega)
  const Q = sn / (2 * alpha)
  const centerFreq = (fs * omega) / (2 * Math.PI)

  const candidate = {
    ...defaultParameters(fs),
    type: 'bandpass' as BiquadType,
    Q,
    centerFreq
  }

  if (!isValidCandidate(candidate)) return Err.EMPTY
  if (!sortOfEqualCoefficients(coefficients, toCoefficents(candidate)))
    return Err.EMPTY

  return Ok(candidate)
}

const tryLowpass = (
  fs: number,
  coefficients: BiquadCoeffs
): Result<BiquadParams, void> => {
  const a0 = 2 / (2 * coefficients.b1 - coefficients.a1)
  const alpha = a0 - 1
  const cs = (-a0 * coefficients.a1) / 2

  const omega = Math.acos(cs)
  const sn = Math.sin(omega)
  const Q = sn / (2 * alpha)
  const centerFreq = (fs * omega) / (2 * Math.PI)

  const candidate = {
    ...defaultParameters(fs),
    type: 'lowpass' as BiquadType,
    Q,
    centerFreq
  }

  if (!isValidCandidate(candidate)) return Err.EMPTY
  if (!sortOfEqualCoefficients(coefficients, toCoefficents(candidate)))
    return Err.EMPTY

  return Ok(candidate)
}

const tryHighpass = (
  fs: number,
  coefficients: BiquadCoeffs
): Result<BiquadParams, void> => {
  const a0 = 2 / (coefficients.a1 - 2 * coefficients.b1)
  const alpha = a0 - 1
  const cs = (-a0 * coefficients.a1) / 2

  const omega = Math.acos(cs)
  const sn = Math.sin(omega)
  const Q = sn / (2 * alpha)
  const centerFreq = (fs * omega) / (2 * Math.PI)

  const candidate = {
    ...defaultParameters(fs),
    type: 'highpass' as BiquadType,
    Q,
    centerFreq
  }

  if (!isValidCandidate(candidate)) return Err.EMPTY
  if (!sortOfEqualCoefficients(coefficients, toCoefficents(candidate)))
    return Err.EMPTY

  return Ok(candidate)
}

const tryNotch = (
  fs: number,
  coefficients: BiquadCoeffs
): Result<BiquadParams, void> => {
  const a0 = 1 / coefficients.b0
  const alpha = a0 - 1
  const cs = (-a0 * coefficients.a1) / 2

  const omega = Math.acos(cs)
  const sn = Math.sin(omega)
  const Q = sn / (2 * alpha)
  const centerFreq = (fs * omega) / (2 * Math.PI)

  const candidate = {
    ...defaultParameters(fs),
    type: 'bandcut' as BiquadType,
    Q,
    centerFreq
  }

  if (!isValidCandidate(candidate)) return Err.EMPTY
  if (!sortOfEqualCoefficients(coefficients, toCoefficents(candidate)))
    return Err.EMPTY

  return Ok(candidate)
}

const tryPeaking = (
  fs: number,
  coefficients: BiquadCoeffs
): Result<BiquadParams, void> => {
  const gain = Math.sqrt(
    (coefficients.a2 - 2 * coefficients.b2 + 1) / (1 - coefficients.a2)
  )
  const cs = -coefficients.a1 / (coefficients.a2 + 1)
  const alpha = (gain * (1 - coefficients.a2)) / (1 + coefficients.a2)

  const omega = Math.acos(cs)
  const sn = Math.sin(omega)
  const Q = sn / (2 * alpha)
  const centerFreq = (fs * omega) / (2 * Math.PI)

  const candidate = {
    ...defaultParameters(fs),
    type: 'peaking' as BiquadType,
    Q,
    centerFreq,
    gainDB: 40 * Math.log10(gain)
  }

  if (!isValidCandidate(candidate)) return Err.EMPTY
  if (!sortOfEqualCoefficients(coefficients, toCoefficents(candidate)))
    return Err.EMPTY

  return Ok(candidate)
}

const tryLowshelf = (
  fs: number,
  coefficients: BiquadCoeffs
): Result<BiquadParams, void> => {
  // const b0 = coefficients.b0
  // const b1 = coefficients.b1
  const b2 = coefficients.b2
  const a1 = coefficients.a1
  const a2 = coefficients.a2

  /*
        var('a0 a1 a2 b0 b1 b2 cs B gain alpha')
        eq4 = (b0 == (gain*((gain+1)-(gain-1)*cs+B)) / a0)
        eq5 = (b1 == (2*gain*((gain-1)-(gain+1)*cs)) / a0)
        eq6 = (b2 == (gain*((gain+1) - (gain-1)*cs-B)) / a0)
        eq1 = (a0 == (gain+1)+(gain-1)*cs+B)
        eq2 = (a1 == (-2 * ((gain-1) + (gain+1)*cs)) / a0)
        eq3 = (a2 == ((gain+1)+(gain-1)*cs-B)/ a0)
        eq7 = (B  == 2 * sqrt(gain) * alpha)

        eq2 = eq2.subs(eq1)
        eq3 = eq3.subs(eq1)
        eq4 = eq4.subs(eq1)
        eq5 = eq5.subs(eq1)
        eq6 = eq6.subs(eq1)

        solns = solve([eq2,eq3,eq6],gain,cs,B)
        gain1 = solns[0][0]
        cs1   = solns[0][1]
        B1    = solns[0][2]
        gain2 = solns[1][0]
        cs2   = solns[1][1]
        B2    = solns[1][2]

        eq8 = eq7.subs(gain1).subs(B1)
        eq9 = eq7.subs(gain2).subs(B2)

        alpha1 = solve(eq8,alpha)[0]
        alpha2 = solve(eq9,alpha)[0]

        cs1
        alpha1
        gain1

        cs2
        alpha2
        gain2
   */

  const cs =
    ((-1 / 2) *
      (Math.pow(a1, 2) -
        a1 * a2 +
        2 * (a1 + a2 + 1) * b2 -
        Math.sqrt(Math.pow(a1, 2) + 4 * (a1 + a2 + 1) * b2 - 4 * a2) *
          (a1 - a2 - 1) -
        a1)) /
    ((a1 + a2 + 1) * b2 + a1 - a2 - 1)
  const alpha =
    ((1 / 2) *
      ((a1 -
        Math.sqrt(
          Math.pow(a1, 2) + 4 * a1 * b2 + 4 * a2 * b2 - 4 * a2 + 4 * b2
        ) +
        2) *
        Math.pow(a2, 2) +
        Math.pow(a1, 2) -
        (Math.pow(a1, 2) -
          a1 *
            (Math.sqrt(
              Math.pow(a1, 2) + 4 * a1 * b2 + 4 * a2 * b2 - 4 * a2 + 4 * b2
            ) -
              2)) *
              a2 -
        4 * (a1 * a2 + Math.pow(a2, 2) - a1 - 1) * b2 -
        a1 *
          (Math.sqrt(
            Math.pow(a1, 2) + 4 * a1 * b2 + 4 * a2 * b2 - 4 * a2 + 4 * b2
          ) -
            1) +
        Math.sqrt(
          Math.pow(a1, 2) + 4 * a1 * b2 + 4 * a2 * b2 - 4 * a2 + 4 * b2
        ) -
        2)) /
    (Math.pow(a1, 2) *
      Math.sqrt(
        -a2 / (a1 + a2 + 1) -
          Math.sqrt(
            Math.pow(a1, 2) + 4 * a1 * b2 + 4 * a2 * b2 - 4 * a2 + 4 * b2
          ) /
            (a1 + a2 + 1) +
          1 / (a1 + a2 + 1)
      ) -
      Math.pow(a2, 2) *
        Math.sqrt(
          -a2 / (a1 + a2 + 1) -
            Math.sqrt(
              Math.pow(a1, 2) + 4 * a1 * b2 + 4 * a2 * b2 - 4 * a2 + 4 * b2
            ) /
              (a1 + a2 + 1) +
            1 / (a1 + a2 + 1)
        ) +
      (Math.pow(a1, 2) *
        Math.sqrt(
          -a2 / (a1 + a2 + 1) -
            Math.sqrt(
              Math.pow(a1, 2) + 4 * a1 * b2 + 4 * a2 * b2 - 4 * a2 + 4 * b2
            ) /
              (a1 + a2 + 1) +
            1 / (a1 + a2 + 1)
        ) +
        Math.pow(a2, 2) *
          Math.sqrt(
            -a2 / (a1 + a2 + 1) -
              Math.sqrt(
                Math.pow(a1, 2) +
                  4 * a1 * b2 +
                  4 * a2 * b2 -
                  4 * a2 +
                  4 * b2
              ) /
                (a1 + a2 + 1) +
              1 / (a1 + a2 + 1)
          ) +
        2 *
          (a1 *
            Math.sqrt(
              -a2 / (a1 + a2 + 1) -
                Math.sqrt(
                  Math.pow(a1, 2) +
                    4 * a1 * b2 +
                    4 * a2 * b2 -
                    4 * a2 +
                    4 * b2
                ) /
                  (a1 + a2 + 1) +
                1 / (a1 + a2 + 1)
            ) +
            Math.sqrt(
              -a2 / (a1 + a2 + 1) -
                Math.sqrt(
                  Math.pow(a1, 2) +
                    4 * a1 * b2 +
                    4 * a2 * b2 -
                    4 * a2 +
                    4 * b2
                ) /
                  (a1 + a2 + 1) +
                1 / (a1 + a2 + 1)
            )) *
            a2 +
        2 *
          a1 *
          Math.sqrt(
            -a2 / (a1 + a2 + 1) -
              Math.sqrt(
                Math.pow(a1, 2) +
                  4 * a1 * b2 +
                  4 * a2 * b2 -
                  4 * a2 +
                  4 * b2
              ) /
                (a1 + a2 + 1) +
              1 / (a1 + a2 + 1)
          ) +
        Math.sqrt(
          -a2 / (a1 + a2 + 1) -
            Math.sqrt(
              Math.pow(a1, 2) + 4 * a1 * b2 + 4 * a2 * b2 - 4 * a2 + 4 * b2
            ) /
              (a1 + a2 + 1) +
            1 / (a1 + a2 + 1)
        )) *
        b2 -
      2 *
      a2 *
        Math.sqrt(
          -a2 / (a1 + a2 + 1) -
            Math.sqrt(
              Math.pow(a1, 2) + 4 * a1 * b2 + 4 * a2 * b2 - 4 * a2 + 4 * b2
            ) /
              (a1 + a2 + 1) +
            1 / (a1 + a2 + 1)
        ) -
      Math.sqrt(
        -a2 / (a1 + a2 + 1) -
          Math.sqrt(
            Math.pow(a1, 2) + 4 * a1 * b2 + 4 * a2 * b2 - 4 * a2 + 4 * b2
          ) /
            (a1 + a2 + 1) +
          1 / (a1 + a2 + 1)
      ))
  const gain =
    -(
      a2 +
      Math.sqrt(Math.pow(a1, 2) + 4 * (a1 + a2 + 1) * b2 - 4 * a2) -
      1
    ) /
    (a1 + a2 + 1)

  /* The other solution
  const cs = -1/2*(Math.sqrt(a1,2) - a1*a2 + 2*(a1 + a2 + 1)*b2 + Math.sqrt(Math.sqrt(a1,2) + 4*(a1 + a2 + 1)*b2 - 4*a2)*(a1 - a2 - 1) - a1)/((a1 + a2 + 1)*b2 + a1 - a2 - 1);
  const alpha = 1/2*((a1 + Math.sqrt(Math.sqrt(a1,2) + 4*a1*b2 + 4*a2*b2 - 4*a2 + 4*b2) + 2)*a2^2 + Math.sqrt(a1,2) - (Math.sqrt(a1,2) + a1*(Math.sqrt(Math.sqrt(a1,2) + 4*a1*b2 + 4*a2*b2 - 4*a2 + 4*b2) + 2))*a2 - 4*(a1*a2 + a2^2 - a1 - 1)*b2 + a1*(Math.sqrt(Math.sqrt(a1,2) + 4*a1*b2 + 4*a2*b2 - 4*a2 + 4*b2) + 1) - Math.sqrt(Math.sqrt(a1,2) + 4*a1*b2 + 4*a2*b2 - 4*a2 + 4*b2) - 2)/(Math.sqrt(a1,2)*Math.sqrt(-a2/(a1 + a2 + 1) + Math.sqrt(Math.sqrt(a1,2) + 4*a1*b2 + 4*a2*b2 - 4*a2 + 4*b2)/(a1 + a2 + 1) + 1/(a1 + a2 + 1)) - a2^2*Math.sqrt(-a2/(a1 + a2 + 1) + Math.sqrt(Math.sqrt(a1,2) + 4*a1*b2 + 4*a2*b2 - 4*a2 + 4*b2)/(a1 + a2 + 1) + 1/(a1 + a2 + 1)) + (Math.sqrt(a1,2)*Math.sqrt(-a2/(a1 + a2 + 1) + Math.sqrt(Math.sqrt(a1,2) + 4*a1*b2 + 4*a2*b2 - 4*a2 + 4*b2)/(a1 + a2 + 1) + 1/(a1 + a2 + 1)) + a2^2*Math.sqrt(-a2/(a1 + a2 + 1) + Math.sqrt(Math.sqrt(a1,2) + 4*a1*b2 + 4*a2*b2 - 4*a2 + 4*b2)/(a1 + a2 + 1) + 1/(a1 + a2 + 1)) + 2*(a1*Math.sqrt(-a2/(a1 + a2 + 1) + Math.sqrt(Math.sqrt(a1,2) + 4*a1*b2 + 4*a2*b2 - 4*a2 + 4*b2)/(a1 + a2 + 1) + 1/(a1 + a2 + 1)) + Math.sqrt(-a2/(a1 + a2 + 1) + Math.sqrt(Math.sqrt(a1,2) + 4*a1*b2 + 4*a2*b2 - 4*a2 + 4*b2)/(a1 + a2 + 1) + 1/(a1 + a2 + 1)))*a2 + 2*a1*Math.sqrt(-a2/(a1 + a2 + 1) + Math.sqrt(Math.sqrt(a1,2) + 4*a1*b2 + 4*a2*b2 - 4*a2 + 4*b2)/(a1 + a2 + 1) + 1/(a1 + a2 + 1)) + Math.sqrt(-a2/(a1 + a2 + 1) + Math.sqrt(Math.sqrt(a1,2) + 4*a1*b2 + 4*a2*b2 - 4*a2 + 4*b2)/(a1 + a2 + 1) + 1/(a1 + a2 + 1)))*b2 - 2*a2*Math.sqrt(-a2/(a1 + a2 + 1) + Math.sqrt(Math.sqrt(a1,2) + 4*a1*b2 + 4*a2*b2 - 4*a2 + 4*b2)/(a1 + a2 + 1) + 1/(a1 + a2 + 1)) - Math.sqrt(-a2/(a1 + a2 + 1) + Math.sqrt(Math.sqrt(a1,2) + 4*a1*b2 + 4*a2*b2 - 4*a2 + 4*b2)/(a1 + a2 + 1) + 1/(a1 + a2 + 1)));
  const gain = -(a2 - Math.sqrt(Math.sqrt(a1,2) + 4*(a1 + a2 + 1)*b2 - 4*a2) - 1)/(a1 + a2 + 1);
  */

  const omega = Math.acos(cs)
  const centerFreq = (fs * omega) / (2 * Math.PI)
  const sn = Math.sin(omega)
  const Q = sn / (2 * alpha)

  const candidate = {
    ...defaultParameters(fs),
    type: 'lowshelf' as BiquadType,
    Q,
    centerFreq,
    gainDB: 40 * Math.log10(gain)
  }

  if (!isValidCandidate(candidate)) return Err.EMPTY
  if (!sortOfEqualCoefficients(coefficients, toCoefficents(candidate)))
    return Err.EMPTY

  return Ok(candidate)
}

const tryHighshelf = (
  fs: number,
  coefficients: BiquadCoeffs
): Result<BiquadParams, void> => {
  // const b0 = coefficients.b0
  // const b1 = coefficients.b1
  const b2 = coefficients.b2
  const a1 = coefficients.a1
  const a2 = coefficients.a2

  /*
        var('a0 a1 a2 b0 b1 b2 cs B gain alpha')
        eq4 = (b0 == (gain*((gain+1)+(gain-1)*cs+B)) / a0)
        eq5 = (b1 == (-2*gain*((gain-1)+(gain+1)*cs)) / a0)
        eq6 = (b2 == (gain*((gain+1) + (gain-1)*cs-B)) / a0)
        eq1 = (a0 == (gain+1)-(gain-1)*cs+B)
        eq2 = (a1 == (2 * ((gain-1) - (gain+1)*cs)) / a0)
        eq3 = (a2 == ((gain+1)-(gain-1)*cs-B)/ a0)
        eq7 = (B  == 2 * sqrt(gain) * alpha)

        eq2 = eq2.subs(eq1)
        eq3 = eq3.subs(eq1)
        eq4 = eq4.subs(eq1)
        eq5 = eq5.subs(eq1)
        eq6 = eq6.subs(eq1)

        solns = solve([eq2,eq3,eq6],gain,cs,B)
        gain1 = solns[0][0]
        cs1   = solns[0][1]
        B1    = solns[0][2]
        gain2 = solns[1][0]
        cs2   = solns[1][1]
        B2    = solns[1][2]

        eq8 = eq7.subs(gain1).subs(B1)
        eq9 = eq7.subs(gain2).subs(B2)

        alpha1 = solve(eq8,alpha)[0]
        alpha2 = solve(eq9,alpha)[0]

        cs1
        alpha1
        gain1

        cs2
        alpha2
        gain2
   */

  const cs =
    ((-1 / 2) *
      (Math.pow(a1, 2) +
        a1 * a2 -
        2 * (a1 - a2 - 1) * b2 -
        Math.sqrt(Math.pow(a1, 2) - 4 * (a1 - a2 - 1) * b2 - 4 * a2) *
          (a1 + a2 + 1) +
        a1)) /
    ((a1 - a2 - 1) * b2 + a1 + a2 + 1)
  const alpha =
    ((-1 / 2) *
      ((a1 -
        Math.sqrt(
          Math.pow(a1, 2) - 4 * a1 * b2 + 4 * a2 * b2 - 4 * a2 + 4 * b2
        ) -
        2) *
        Math.pow(a2, 2) -
        Math.pow(a1, 2) +
        (Math.pow(a1, 2) -
          a1 *
            (Math.sqrt(
              Math.pow(a1, 2) - 4 * a1 * b2 + 4 * a2 * b2 - 4 * a2 + 4 * b2
            ) +
              2)) *
              a2 -
        4 * (a1 * a2 - Math.pow(a2, 2) - a1 + 1) * b2 +
        a1 *
          (Math.sqrt(
            Math.pow(a1, 2) - 4 * a1 * b2 + 4 * a2 * b2 - 4 * a2 + 4 * b2
          ) +
            1) +
        Math.sqrt(
          Math.pow(a1, 2) - 4 * a1 * b2 + 4 * a2 * b2 - 4 * a2 + 4 * b2
        ) +
        2)) /
    (Math.pow(a1, 2) *
      Math.sqrt(
        a2 / (a1 - a2 - 1) -
          Math.sqrt(
            Math.pow(a1, 2) - 4 * a1 * b2 + 4 * a2 * b2 - 4 * a2 + 4 * b2
          ) /
            (a1 - a2 - 1) -
          1 / (a1 - a2 - 1)
      ) -
      Math.pow(a2, 2) *
        Math.sqrt(
          a2 / (a1 - a2 - 1) -
            Math.sqrt(
              Math.pow(a1, 2) - 4 * a1 * b2 + 4 * a2 * b2 - 4 * a2 + 4 * b2
            ) /
              (a1 - a2 - 1) -
            1 / (a1 - a2 - 1)
        ) +
      (Math.pow(a1, 2) *
        Math.sqrt(
          a2 / (a1 - a2 - 1) -
            Math.sqrt(
              Math.pow(a1, 2) - 4 * a1 * b2 + 4 * a2 * b2 - 4 * a2 + 4 * b2
            ) /
              (a1 - a2 - 1) -
            1 / (a1 - a2 - 1)
        ) +
        Math.pow(a2, 2) *
          Math.sqrt(
            a2 / (a1 - a2 - 1) -
              Math.sqrt(
                Math.pow(a1, 2) -
                  4 * a1 * b2 +
                  4 * a2 * b2 -
                  4 * a2 +
                  4 * b2
              ) /
                (a1 - a2 - 1) -
              1 / (a1 - a2 - 1)
          ) -
        2 *
          (a1 *
            Math.sqrt(
              a2 / (a1 - a2 - 1) -
                Math.sqrt(
                  Math.pow(a1, 2) -
                    4 * a1 * b2 +
                    4 * a2 * b2 -
                    4 * a2 +
                    4 * b2
                ) /
                  (a1 - a2 - 1) -
                1 / (a1 - a2 - 1)
            ) -
            Math.sqrt(
              a2 / (a1 - a2 - 1) -
                Math.sqrt(
                  Math.pow(a1, 2) -
                    4 * a1 * b2 +
                    4 * a2 * b2 -
                    4 * a2 +
                    4 * b2
                ) /
                  (a1 - a2 - 1) -
                1 / (a1 - a2 - 1)
            )) *
            a2 -
        2 *
        a1 *
          Math.sqrt(
            a2 / (a1 - a2 - 1) -
              Math.sqrt(
                Math.pow(a1, 2) -
                  4 * a1 * b2 +
                  4 * a2 * b2 -
                  4 * a2 +
                  4 * b2
              ) /
                (a1 - a2 - 1) -
              1 / (a1 - a2 - 1)
          ) +
        Math.sqrt(
          a2 / (a1 - a2 - 1) -
            Math.sqrt(
              Math.pow(a1, 2) - 4 * a1 * b2 + 4 * a2 * b2 - 4 * a2 + 4 * b2
            ) /
              (a1 - a2 - 1) -
            1 / (a1 - a2 - 1)
        )) *
        b2 -
      2 *
      a2 *
        Math.sqrt(
          a2 / (a1 - a2 - 1) -
            Math.sqrt(
              Math.pow(a1, 2) - 4 * a1 * b2 + 4 * a2 * b2 - 4 * a2 + 4 * b2
            ) /
              (a1 - a2 - 1) -
            1 / (a1 - a2 - 1)
        ) -
      Math.sqrt(
        a2 / (a1 - a2 - 1) -
          Math.sqrt(
            Math.pow(a1, 2) - 4 * a1 * b2 + 4 * a2 * b2 - 4 * a2 + 4 * b2
          ) /
            (a1 - a2 - 1) -
          1 / (a1 - a2 - 1)
      ))
  const gain =
    (a2 -
      Math.sqrt(Math.pow(a1, 2) - 4 * (a1 - a2 - 1) * b2 - 4 * a2) -
      1) /
    (a1 - a2 - 1)

  /* The other solution
  const cs = -1/2*(Math.pow(a1,2) + a1*a2 - 2*(a1 - a2 - 1)*b2 + Math.sqrt(Math.pow(a1,2) - 4*(a1 - a2 - 1)*b2 - 4*a2)*(a1 + a2 + 1) + a1)/((a1 - a2 - 1)*b2 + a1 + a2 + 1);
  const alpha = -1/2*((a1 + Math.sqrt(Math.pow(a1,2) - 4*a1*b2 + 4*a2*b2 - 4*a2 + 4*b2) - 2)*Math.pow(a2,2) - Math.pow(a1,2) + (Math.pow(a1,2) + a1*(Math.sqrt(Math.pow(a1,2) - 4*a1*b2 + 4*a2*b2 - 4*a2 + 4*b2) - 2))*a2 - 4*(a1*a2 - Math.pow(a2,2) - a1 + 1)*b2 - a1*(Math.sqrt(Math.pow(a1,2) - 4*a1*b2 + 4*a2*b2 - 4*a2 + 4*b2) - 1) - Math.sqrt(Math.pow(a1,2) - 4*a1*b2 + 4*a2*b2 - 4*a2 + 4*b2) + 2)/(Math.pow(a1,2)*Math.sqrt(a2/(a1 - a2 - 1) + Math.sqrt(Math.pow(a1,2) - 4*a1*b2 + 4*a2*b2 - 4*a2 + 4*b2)/(a1 - a2 - 1) - 1/(a1 - a2 - 1)) - Math.pow(a2,2)*Math.sqrt(a2/(a1 - a2 - 1) + Math.sqrt(Math.pow(a1,2) - 4*a1*b2 + 4*a2*b2 - 4*a2 + 4*b2)/(a1 - a2 - 1) - 1/(a1 - a2 - 1)) + (Math.pow(a1,2)*Math.sqrt(a2/(a1 - a2 - 1) + Math.sqrt(Math.pow(a1,2) - 4*a1*b2 + 4*a2*b2 - 4*a2 + 4*b2)/(a1 - a2 - 1) - 1/(a1 - a2 - 1)) + Math.pow(a2,2)*Math.sqrt(a2/(a1 - a2 - 1) + Math.sqrt(Math.pow(a1,2) - 4*a1*b2 + 4*a2*b2 - 4*a2 + 4*b2)/(a1 - a2 - 1) - 1/(a1 - a2 - 1)) - 2*(a1*Math.sqrt(a2/(a1 - a2 - 1) + Math.sqrt(Math.pow(a1,2) - 4*a1*b2 + 4*a2*b2 - 4*a2 + 4*b2)/(a1 - a2 - 1) - 1/(a1 - a2 - 1)) - Math.sqrt(a2/(a1 - a2 - 1) + Math.sqrt(Math.pow(a1,2) - 4*a1*b2 + 4*a2*b2 - 4*a2 + 4*b2)/(a1 - a2 - 1) - 1/(a1 - a2 - 1)))*a2 - 2*a1*Math.sqrt(a2/(a1 - a2 - 1) + Math.sqrt(Math.pow(a1,2) - 4*a1*b2 + 4*a2*b2 - 4*a2 + 4*b2)/(a1 - a2 - 1) - 1/(a1 - a2 - 1)) + Math.sqrt(a2/(a1 - a2 - 1) + Math.sqrt(Math.pow(a1,2) - 4*a1*b2 + 4*a2*b2 - 4*a2 + 4*b2)/(a1 - a2 - 1) - 1/(a1 - a2 - 1)))*b2 - 2*a2*Math.sqrt(a2/(a1 - a2 - 1) + Math.sqrt(Math.pow(a1,2) - 4*a1*b2 + 4*a2*b2 - 4*a2 + 4*b2)/(a1 - a2 - 1) - 1/(a1 - a2 - 1)) - Math.sqrt(a2/(a1 - a2 - 1) + Math.sqrt(Math.pow(a1,2) - 4*a1*b2 + 4*a2*b2 - 4*a2 + 4*b2)/(a1 - a2 - 1) - 1/(a1 - a2 - 1)));
  const gain = (a2 + Math.sqrt(Math.pow(a1,2) - 4*(a1 - a2 - 1)*b2 - 4*a2) - 1)/(a1 - a2 - 1);
  */

  const omega = Math.acos(cs)
  const centerFreq = (fs * omega) / (2 * Math.PI)
  const sn = Math.sin(omega)
  const Q = sn / (2 * alpha)

  const candidate = {
    ...defaultParameters(fs),
    type: 'highshelf' as BiquadType,
    Q,
    centerFreq,
    gainDB: 40 * Math.log10(gain)
  }

  if (!isValidCandidate(candidate)) return Err.EMPTY
  if (!sortOfEqualCoefficients(coefficients, toCoefficents(candidate)))
    return Err.EMPTY

  return Ok(candidate)
}

export const toUserParameters = (
  fs: number,
  coefficients: BiquadCoeffs
): BiquadParams =>
  Result.any(
    tryGain(fs, coefficients),
    tryBandpass(fs, coefficients),
    tryLowpass(fs, coefficients),
    tryHighpass(fs, coefficients),
    tryNotch(fs, coefficients),
    tryLowshelf(fs, coefficients),
    tryHighshelf(fs, coefficients),
    tryPeaking(fs, coefficients),
    Ok(defaultParameters(fs))
  ).unwrap()

export const toFloat = (
  coefficients: BiquadCoeffs
): Result<BiquadCoeffsReal, string> => {
  const a1 = convert.toFloat(INTEGERBITS, FRACTIONBITS, coefficients.a1, true)
  const a2 = convert.toFloat(INTEGERBITS, FRACTIONBITS, coefficients.a2, true)
  const b0 = convert.toFloat(INTEGERBITS, FRACTIONBITS, coefficients.b0)
  const b1 = convert.toFloat(INTEGERBITS, FRACTIONBITS, coefficients.b1)
  const b2 = convert.toFloat(INTEGERBITS, FRACTIONBITS, coefficients.b2)
  
  return Result.all(a1, a2, b0, b1, b2).map(([a1, a2, b0, b1, b2]) => ({
    a1,
    a2,
    b0,
    b1,
    b2
  }))
}

export const toFixed = (
  coefficients: BiquadCoeffsReal
): Result<BiquadCoeffs, string> => {
  let a1 = convert.toFixed(INTEGERBITS, FRACTIONBITS, coefficients.a1, true)
  let a2 = convert.toFixed(INTEGERBITS, FRACTIONBITS, coefficients.a2, true)
  let b0 = convert.toFixed(INTEGERBITS, FRACTIONBITS, coefficients.b0)
  let b1 = convert.toFixed(INTEGERBITS, FRACTIONBITS, coefficients.b1)
  let b2 = convert.toFixed(INTEGERBITS, FRACTIONBITS, coefficients.b2)

  return Result.all(a1, a2, b0, b1, b2).map(([a1, a2, b0, b1, b2]) => ({
    a1,
    a2,
    b0,
    b1,
    b2
  }))
}

export const logFrequency = (
  lower: number,
  upper: number,
  size: number
): number[] => {
  const l = Math.log10(lower)
  const u = Math.log10(upper)
  const s = (u - l) / (size - 1)

  return Array.from({ length: size }, (_, i) => Math.pow(10, l + s * i))
}

export const calculate = (
  fs: number,
  c: BiquadCoeffsReal,
  frequency: number[]
): { frequency: number[]; gain: number[]; phase: number[] } => {
  const calculateOmega = (f: number) =>
    Math.pow(Math.sin((2.0 * Math.PI * f) / (2.0 * fs)), 2.0)

  const calculateGain = (w: number) => {
    let r =
      (Math.pow(c.b0 + c.b1 + c.b2, 2.0) -
        4.0 * (c.b0 * c.b1 + 4.0 * c.b0 * c.b2 + c.b1 * c.b2) * w +
        16.0 * c.b0 * c.b2 * w * w) /
      (Math.pow(1.0 + c.a1 + c.a2, 2.0) -
        4.0 * (c.a1 + 4.0 * c.a2 + c.a1 * c.a2) * w +
        16.0 * c.a2 * w * w)
    if (r < 0) r = 0

    try {
      r = 40 * Math.log10(Math.sqrt(r))
    } catch (e) {
      r = -1000
    }

    return r
  }

  const calculatePhase = (w: number) => {
    const ca = 1 + c.a1 * Math.cos(w) + c.a2 * Math.cos(2 * w)
    const sa = c.a1 * Math.sin(w) + c.a2 * Math.sin(2 * w)
    const sb = c.b1 * Math.sin(w) + c.b2 * Math.sin(2 * w)
    const cb = c.b0 + c.b1 * Math.cos(w) + c.b2 * Math.cos(2 * w)
    const P = Math.atan2(sb, cb) - Math.atan2(sa, ca)
    return P
  }

  // Is pre-allocation in js faster? Let's assume that it is and write this ugly code
  let gain = new Array(frequency.length)
  let phase = new Array(frequency.length)

  for (var i = 0; i < frequency.length; ++i) {
    const omega = calculateOmega(frequency[i])
    gain[i] = calculateGain(omega)
    phase[i] = calculatePhase(omega)
  }

  return { frequency, gain, phase }
}
