const dbmc2GmGain = gain=>{
  gain -= 6
  if (gain < 0) {
    gain = Math.pow(2, 5) + gain
  }
  return gain
}

const dbmc2GmGainReverse = data=>{
  if (data > Math.pow(2, 4)) {
    data = data - Math.pow(2, 5)
  }
  data += 6
  return data
}

const feedbackFields = {
  common: {
    FB_CFG0: {
      prop: 'fb_cfg0',
      mask:0x0000830C,
      fields: [
        /*
        {
          name: 'fb_gm_cm_trim',
          bitmin: 20,
          bitmax: 23,
          description: '',
          options: []
        },
        {
          name: 'fb_vcom_trim',
          bitmin: 16,
          bitmax: 19,
          description: '',
          options: []
        },
        */
        {
          name: 'Feedback Filter Polarity',//fb_polarity',
          bitmin: 15,
          bitmax: 15,
          description: '',
          options: ['negative', 'positive']
        },
        /*
        {
          name: 'fb_pm_curr_ctrl',
          bitmin: 10,
          bitmax: 11,
          description: '',
          options: []
        },
        {
          name: 'fb_ext_pga',
          bitmin: 8,
          bitmax: 9,
          description: '',
          options: []
        },
        {
          name: 'fb_fbmics2adcs',
          bitmin: 7,
          bitmax: 7,
          description: '',
          options: ['disabled', 'enabled']
        },
        {
          name: 'fb_mic_pga',
          bitmin: 4,
          bitmax: 6,
          description: '',
          options: []
        },
        {
          name: 'fb_ext2notch2_sel',
          bitmin: 3,
          bitmax: 3,
          description: '',
          options: ['disabled', 'enabled']
        },
        {
          name: 'fb_ext2peak_sel',
          bitmin: 2,
          bitmax: 2,
          description: '',
          options: ['disabled', 'enabled']
        },
        {
          name: 'fb_gm_2stg_curr',
          bitmin: 1,
          bitmax: 1,
          description: '',
          options: ['disabled', 'enabled']
        },
        {
          name: 'fb_gm_disconnect',
          bitmin: 0,
          bitmax: 0,
          description: '',
          options: ['disabled', 'enabled']
        }
        */
      ]
    },
    FB_CFG3: {
      prop: 'fb_cfg3',
      mask:0x000001F0,
      fields: [
        {
          name: 'fb_cfg3_31_24',
          bitmin: 24,
          bitmax: 31,
          description: '',
          options: []
        },
        {
          name: 'fb_input_clipdtct_high_trim',
          bitmin: 22,
          bitmax: 23,
          description: '',
          options: []
        },
        {
          name: 'fb_input_clipdtct_low_trim',
          bitmin: 20,
          bitmax: 21,
          description: '',
          options: []
        },
        {
          name: 'fb_oa_clipdtct_high_trim',
          bitmin: 18,
          bitmax: 19,
          description: '',
          options: []
        },
        {
          name: 'fb_oa_clipdtct_low_trim',
          bitmin: 16,
          bitmax: 17,
          description: '',
          options: []
        },
        {
          name: 'fb_cfg3_15_14',
          bitmin: 14,
          bitmax: 15,
          description: '',
          options: []
        },
        {
          name: 'fb_clipdet_en_r',
          bitmin: 13,
          bitmax: 13,
          description: '',
          options: ['disabled', 'enabled']
        },
        {
          name: 'fb_clipdet_en_l',
          bitmin: 12,
          bitmax: 12,
          description: '',
          options: ['disabled', 'enabled']
        },
        {
          name: 'fb_cfg3_11_9',
          bitmin: 9,
          bitmax: 11,
          description: '',
          options: []
        },
        {
          name: 'Feedback GM Stage Gain',//fb_gm_gain_sel',
          bitmin: 4,
          bitmax: 8,
          description: '',
          options: [
            {label:'-10 dB', value:dbmc2GmGain(-10)}, 
            {label:'-9 dB', value:dbmc2GmGain(-9)}, 
            {label:'-8 dB', value:dbmc2GmGain(-8)}, 
            {label:'-7 dB', value:dbmc2GmGain(-7)}, 
            {label:'-6 dB', value:dbmc2GmGain(-6)}, 
            {label:'-5 dB', value:dbmc2GmGain(-5)}, 
            {label:'-4 dB', value:dbmc2GmGain(-4)}, 
            {label:'-3 dB', value:dbmc2GmGain(-3)}, 
            {label:'-2 dB', value:dbmc2GmGain(-2)}, 
            {label:'-1 dB', value:dbmc2GmGain(-1)}, 
            {label:'0 dB', value:dbmc2GmGain(0)}, 
            {label:'1 dB', value:dbmc2GmGain(1)}, 
            {label:'2 dB', value:dbmc2GmGain(2)}, 
            {label:'3 dB', value:dbmc2GmGain(3)}, 
            {label:'4 dB', value:dbmc2GmGain(4)}, 
            {label:'5 dB', value:dbmc2GmGain(5)}, 
            {label:'6 dB', value:dbmc2GmGain(6)}, 
            {label:'7 dB', value:dbmc2GmGain(7)}, 
            {label:'8 dB', value:dbmc2GmGain(8)}, 
            {label:'9 dB', value:dbmc2GmGain(9)}, 
            {label:'10 dB', value:dbmc2GmGain(10)}, 
            {label:'11 dB', value:dbmc2GmGain(11)}, 
            {label:'12 dB', value:dbmc2GmGain(12)}
          ]
        },
        {
          name: 'fb_cfg3_3',
          bitmin: 3,
          bitmax: 3,
          description: '',
          options: ['disabled', 'enabled']
        },
        {
          name: 'fb_res_calib',
          bitmin: 0,
          bitmax: 2,
          description: '',
          options: []
        }
      ]
    },
    channel: {
      TX_CTRL: {
        prop: 'tx_ctrl',
        mask:0x0200,
        fields: [
          {
            name: 'fb_gm_en_R',
            bitmin: 28,
            bitmax: 28,
            description: '',
            options: ['disabled', 'enabled']
          },
          {
            name: 'fp_peakamp_en_R',
            bitmin: 27,
            bitmax: 27,
            description: '',
            options: ['disabled', 'enabled']
          },
          {
            name: 'fb_filters_en_R',
            bitmin: 26,
            bitmax: 26,
            description: '',
            options: ['disabled', 'enabled']
          },
          {
            name: 'fb_extfilt_en_R',
            bitmin: 25,
            bitmax: 25,
            description: '',
            options: ['disabled', 'enabled']
          },
          {
            name: 'fb_diffmic_en_R',
            bitmin: 24,
            bitmax: 24,
            description: '',
            options: ['disabled', 'enabled']
          },
          {
            name: 'fb_micamp_en_R',
            bitmin: 23,
            bitmax: 23,
            description: '',
            options: ['disabled', 'enabled']
          },
          {
            name: 'fb_opamp_bias_en_R',
            bitmin: 22,
            bitmax: 22,
            description: '',
            options: ['disabled', 'enabled']
          },
          {
            name: 'fb_v_swbias_en_R',
            bitmin: 21,
            bitmax: 21,
            description: '',
            options: ['disabled', 'enabled']
          },
          {
            name: 'fb_i_swbias_en_R',
            bitmin: 20,
            bitmax: 20,
            description: '',
            options: ['disabled', 'enabled']
          },
          {
            name: 'fb_vcom_gen_en_R',
            bitmin: 18,
            bitmax: 18,
            description: '',
            options: ['disabled', 'enabled']
          },
          {
            name: 'outamp_en_R',
            bitmin: 17,
            bitmax: 17,
            description: '',
            options: ['disabled', 'enabled']
          },
          {
            name: 'dac_en_R',
            bitmin: 16,
            bitmax: 16,
            description: '',
            options: ['disabled', 'enabled']
          },
          {
            name: 'fb_gm_en_L',
            bitmin: 12,
            bitmax: 12,
            description: '',
            options: ['disabled', 'enabled']
          },
          {
            name: 'fb_peakamp_en_L',
            bitmin: 11,
            bitmax: 11,
            description: '',
            options: ['disabled', 'enabled']
          },
          {
            name: 'fb_filters_en_L',
            bitmin: 10,
            bitmax: 10,
            description: '',
            options: ['disabled', 'enabled']
          },
          {
            name: 'fb_extfilt_en_L',
            bitmin: 9,
            bitmax: 9,
            description: '',
            options: ['disabled', 'enabled']
          },
          {
            name: 'fb_diffmic_en_L',
            bitmin: 8,
            bitmax: 8,
            description: '',
            options: ['disabled', 'enabled']
          },
          {
            name: 'fb_micamp_en_L',
            bitmin: 7,
            bitmax: 7,
            description: '',
            options: ['disabled', 'enabled']
          },
          {
            name: 'fb_opamp_bias_en_L',
            bitmin: 6,
            bitmax: 6,
            description: '',
            options: ['disabled', 'enabled']
          },
          {
            name: 'fb_v_swbias_en_L',
            bitmin: 5,
            bitmax: 5,
            description: '',
            options: ['disabled', 'enabled']
          },
          {
            name: 'fb_i_swbias_en_L',
            bitmin: 4,
            bitmax: 4,
            description: '',
            options: ['disabled', 'enabled']
          },
          {
            name: 'fb_vcom_gen_en_L',
            bitmin: 2,
            bitmax: 2,
            description: '',
            options: ['disabled', 'enabled']
          },
          {
            name: 'outamp_en_L',
            bitmin: 1,
            bitmax: 1,
            description: '',
            options: ['disabled', 'enabled']
          },
          {
            name: 'dac_en_L',
            bitmin: 0,
            bitmax: 0,
            description: '',
            options: ['disabled', 'enabled']
          }
        ]
      },
      FB_CFG12: {
        prop: 'fb_cfg12',
        mask:0xFFFFFFFF,
        fields: [
          {
            name: 'Highpass Resistor',
            bitmin: 0,
            bitmax: 3,
            description: '',
            options: [
              '100 Ω', 
              '2663 Ω', 
              '3883 Ω', 
              '5647 Ω', 
              '8178 Ω', 
              '11776 Ω', 
              '16817 Ω', 
              '23744 Ω', 
              '33010 Ω', 
              '44969 Ω', 
              '59711 Ω', 
              '76890 Ω', 
              '95642 Ω', 
              '11470 Ω', 
              '13273 Ω', 
              '200000 Ω', 
            ]
          },
          {
            name: 'Notch 1 Bridge',
            bitmin: 4,
            bitmax: 7,
            description: '',
            options: [
              '100 Ω', 
              '1218 Ω', 
              '1708 Ω', 
              '2431 Ω', 
              '3496 Ω', 
              '5066 Ω', 
              '7379 Ω', 
              '10787 Ω', 
              '15808 Ω', 
              '23208 Ω', 
              '34112 Ω', 
              '50178 Ω', 
              'Open 0xC', 
              'Open 0xD', 
              'Open 0xE', 
              'Open 0xF', 
            ]
          },
          {
            name: 'Notch 1 Shunt',
            bitmin: 8,
            bitmax: 11,
            description: '',
            options: [
              '20 Ω',  
              '714 Ω', 
              '831 Ω', 
              '971 Ω', 
              '1137 Ω', 
              '1335 Ω', 
              '1572 Ω', 
              '1854 Ω', 
              '2191 Ω', 
              '2593 Ω', 
              '3071 Ω', 
              '3643 Ω', 
              '4324 Ω', 
              'Open 0xD', 
              'Open 0xE', 
              'Open 0xF'
            ]
          },
          {
            name: 'Peak Bridge',
            bitmin: 12,
            bitmax: 15,
            description: '',
            options: [
              '100 Ω', 
              '129476 Ω',  
              '160556 Ω',  
              '199122 Ω',  
              '246977 Ω',  
              '306357 Ω',  
              '380038 Ω',  
              '471465 Ω',  
              '584912 Ω',  
              '725681 Ω',  
              '900354 Ω',  
              '1117097 Ω', 
              'Open 0xC',  
              'Open 0xD',  
              'Open 0xE',  
              'Open 0xF'
            ]
          },
          {
            name: 'Peak Shunt',
            bitmin: 16,
            bitmax: 19,
            description: '',
            options: [
              '350 Ω', 
              '8806 Ω',
              '10565 Ω',  
              '12699 Ω',  
              '15289 Ω',  
              '18430 Ω',  
              '22241 Ω',  
              '26865 Ω',  
              '32475 Ω',  
              '39282 Ω',  
              '47539 Ω',  
              '57557 Ω',  
              '69712 Ω',  
              'Open 0xD', 
              'Open 0xE', 
              'Open 0xF'
            ]
          },
          {
            name: 'Notch 2 Bridge',
            bitmin: 20,
            bitmax: 23,
            description: '',
            options: [
              '100 Ω', 
              '2220 Ω', 
              '3123 Ω', 
              '4426 Ω', 
              '6306 Ω', 
              '9017 Ω', 
              '12927 Ω', 
              '18567 Ω', 
              '26702 Ω', 
              '38435 Ω', 
              '55358 Ω', 
              '79768 Ω', 
              '114974 Ω', 
              '165754 Ω', 
              'Open 0xE', 
              'Open 0xF'
            ]
          },
          {
            name: 'Notch 2 Shunt',
            bitmin: 24,
            bitmax: 27,
            description: '',
            options: [
              '20 Ω', 
              '299 Ω',  
              '335 Ω',  
              '379 Ω',  
              '432 Ω',  
              '498 Ω',  
              '578 Ω',  
              '675 Ω',  
              '795 Ω',  
              '941 Ω',  
              '1120 Ω',  
              '1338 Ω',  
              '1605 Ω',  
              '1932 Ω',  
              '2331 Ω',  
              '2819 Ω'
            ]
          },
          {
            name: 'Notch 2 Slug',
            bitmin: 28,
            bitmax: 31,
            description: '',
            options: [
              '100 Ω',  
              '800 Ω',  
              '994 Ω',  
              '1272 Ω',  
              '1672 Ω',  
              '2245 Ω',  
              '3066 Ω',  
              '4245 Ω',  
              '5934 Ω',  
              '8357 Ω',  
              '11831 Ω',  
              '16813 Ω',  
              'Open 0xC',  
              'Open 0xD',  
              'Open 0xE',  
              'Open 0xF'
            ]
          },
        ]
      }
    }
  },
  rate: {
    channel: {
      BQC_CFG1: {
        prop: 'bqc_cfg1',
        mask:0x000007C0,
        fields: [
          {
            name: 'IR',
            bitmin: 24,
            bitmax: 30,
            description: 'Interpolation Ratio',
            options: []
          },
          {
            name: 'DR',
            bitmin: 16,
            bitmax: 22,
            description: 'Decimation Ratio',
            options: []
          },
          {
            name: 'SBQ',
            bitmin: 6,
            bitmax: 10,
            description: 'Number of Biquad stages',
            options: []
          },
          {
            name: 'OUT_DLY',
            bitmin: 1,
            bitmax: 5,
            description:
              'The number of operational clock cycles by which valid BQC output is delayed',
            options: []
          },
          {
            name: 'BQC_EN',
            bitmin: 0,
            bitmax: 0,
            description: 'Enable Biquad filter',
            options: ['disabled', 'enabled']
          }
        ]
      },
      stage: {
        BQ_B0: {
          prop: 'b0',
          mask:0xFFFFFFFF,
          fields: [
            {
              name: 'BQ_B0',
              bitmin: 0,
              bitmax: 31,
              description: 'b0 Biquad coefficient',
              options: []
            }
          ]
        },
        BQ_B1: {
          prop: 'b1',
          mask:0xFFFFFFFF,
          fields: [
            {
              name: 'BQ_B1',
              bitmin: 0,
              bitmax: 31,
              description: 'b1 Biquad coefficient',
              options: []
            }
          ]
        },
        BQ_B2: {
          prop: 'b2',
          mask:0xFFFFFFFF,
          fields: [
            {
              name: 'BQ_B2',
              bitmin: 0,
              bitmax: 31,
              description: 'b2 Biquad coefficient',
              options: []
            }
          ]
        },
        BQ_A1: {
          prop: 'a1',
          mask:0xFFFFFFFF,
          fields: [
            {
              name: 'BQ_A1',
              bitmin: 0,
              bitmax: 31,
              description: 'a1 Biquad coefficient',
              options: []
            }
          ]
        },
        BQ_A2: {
          prop: 'a2',
          mask:0xFFFFFFFF,
          fields: [
            {
              name: 'BQ_A2',
              bitmin: 0,
              bitmax: 31,
              description: 'a2 Biquad coefficient',
              options: []
            }
          ]
        }
      }
    }
  }
}

export default feedbackFields
