import styled from '@emotion/styled/macro'
import React, { useMemo, useRef, useState, useCallback } from 'react'
import {
  Link,
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
  useHistory
} from 'react-router-dom'

import WebSocketAsPromised from 'websocket-as-promised'
import { Tab } from '../../../components/tab'
import { capitalize } from '../../../utils/text'
import PlaybackDesigner from './components/parameter-designers/playback'
import DeviceSetupParametersDesigner from './components/parameter-designers/devicesetup'
import VoicePickupDesigner from './components/parameter-designers/voice-pickup'
import ControlEVB from './components/widgets/control'
import SetupEVB from './components/widgets/evb'
import Draggable from 'react-draggable'

import {
  DesignerType,
  isSelected,
  WidgetType
} from './utils/filter-designer-router'
import FeedbackDesigner from './components/parameter-designers/feedback'
import FeedforwardDesigner from './components/parameter-designers/feedforward'
import TalkthroughDesigner from './components/parameter-designers/talkthrough'
import CalibrationDesigner from './components/parameter-designers/calibration'
import { useBoard } from './utils/use-board'
import ReactJson from 'react-json-view'
import LoadingSpinner from '../../../components/loading-spinner'

import {
  writeParameters,
} from './utils/board'

const designerTypes: DesignerType[] = [
  'device-setup',
  'playback',
  /*Disabled per [CAE-13]*/ //'voicepickup',
  'feedback',
  'feed-forward',
  'talk-through',
  'calibration'
]

const baseRoute = `/engineer/design`

const createPath = (paramType: DesignerType, widgetType?: WidgetType) =>
  widgetType === undefined
    ? `${baseRoute}/parameter/${paramType}`
    : `${baseRoute}/parameter/${paramType}/widget/${widgetType}`

export default function Designer({
  socket,
  path,
  firmware
}: {
  socket: WebSocketAsPromised
  path: string
  firmware: { inprogress: boolean, action: string, complete: number }
}) {
  const [data, setData, isReady] = useBoard(socket, path, firmware)
  const history = useHistory()
  const [isDataViewerCollapsed, setIsDataViewCollapsed] = useState(true)
  const containerRef = useRef<HTMLDivElement>(null)
  const pathname = useMemo(() => history.location.pathname, [
    history.location.pathname
  ])
  const widget = useMemo(() => {
    const m = pathname.match(/\/widget\/([\w]+)/)
    return (m && m[1]) || undefined
  }, [pathname])
  const hasWidget = useMemo(() => widget !== undefined, [widget])

  const toggleDataViewer = useCallback(() => {
    setIsDataViewCollapsed(!isDataViewerCollapsed)
  }, [isDataViewerCollapsed])

  const isLocalHost = window.location.host.startsWith('localhost')

  const writeData = () => {
    ;(async () => {
      await writeParameters(socket, path, data.parameters)
    })()
  }

  return (
    <div>
      {isLocalHost ? (
        <Draggable handle="#handle" bounds="body">
          <DragContent>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <DragHandle id="handle">Data Viewer</DragHandle>
              <div
                onClick={toggleDataViewer}
                style={{
                  color: 'white',
                  marginLeft: 10,
                  marginTop: 6,
                  marginRight: 10,
                  cursor: 'pointer'
                }}>
                {isDataViewerCollapsed ? '▼' : '▲'}
              </div>
            </div>
            {isDataViewerCollapsed ? null : (
              <div style={{ overflow: 'scroll' }}>
                <ReactJson
                  style={{ marginTop: 8, width: 600, height: 600 }}
                  theme={'paraiso'}
                  collapsed={true}
                  src={data}></ReactJson>
              </div>
            )}
          </DragContent>
        </Draggable>
      ) : null}

      {!isReady ? (
        <CenterContainer>
          <LoadingSpinner
            variant={'dark'}
            width={40}
            height={40}
            style={{ margin: 20 }}></LoadingSpinner>
          <div>Loading data from board</div>
        </CenterContainer>
      ) : (
        <>
          <Container
            ref={containerRef}
            style={{ maxHeight: hasWidget ? 1000 : 0 }}>
            <Route exact path={'/engineer/design'}>
              <Redirect to={'/engineer/design/parameter/device-setup'} />
            </Route>
            <Route
              path={'/engineer/design/parameter/:parametersType/widget/:widget'}
              render={function SwitchWidget({
                match: {
                  params: { widget }
                }
              }: RouteComponentProps<{
                widget: WidgetType
              }>) {
                switch (widget) {
                  case 'evb':
                    return <SetupEVB data={data} setData={setData} writeData={writeData} socket={socket} path={path} />
                  case 'control':
                    return <ControlEVB data={data} setData={setData} socket={socket} path={path} />
                }
              }}
            />
          </Container>
          <TabNavigationContainer>
            <FilterNavigation>
              {designerTypes.map(designerType => (
                <Link key={designerType} to={createPath(designerType, widget as WidgetType)}>
                  <Tab
                    selected={isSelected('parameter', designerType, history)}>
                    {capitalize(designerType)}
                  </Tab>
                </Link>
              ))}
            </FilterNavigation>
          </TabNavigationContainer>
          <Switch>
            <Route
              path={createPath('device-setup')}
              component={() => {
                if (!data.parameters.setup) return null

                return (
                  <DeviceSetupParametersDesigner
                    parameters={{ ...data.parameters.setup }}
                    onChange={setup =>
                      setData({
                        ...data,
                        parameters: { ...data.parameters, setup }
                      })
                    }
                  />
                )
              }}
            />
            <Route
              path={createPath('playback')}
              component={() => {
                if (!data.parameters.playback) return null

                return (
                  <PlaybackDesigner
                    parameters={{ ...data.parameters.playback }}
                    onChange={playback =>
                      setData({
                        ...data,
                        parameters: { ...data.parameters, playback }
                      })
                    }
                  />
                )
              }}
            />
            <Route
              path={createPath('voicepickup')}
              component={() => {
                if (!data.parameters.voicepickup) return null

                return (
                  <VoicePickupDesigner
                    parameters={{ ...data.parameters.voicepickup }}
                    onChange={voicepickup =>
                      setData({
                        ...data,
                        parameters: { ...data.parameters, voicepickup }
                      })
                    }
                  />
                )
              }}
            />
            <Route
              path={createPath('feedback')}
              component={() => {
                if (!data.parameters.feedback) return null

                return (
                  <FeedbackDesigner
                    parameters={{ ...data.parameters.feedback }}
                    onChange={feedback =>
                      setData({
                        ...data,
                        parameters: { ...data.parameters, feedback }
                      })
                    }
                  />
                )
              }}
            />
            <Route
              path={createPath('feed-forward')}
              component={() => {
                if (!data.parameters.feedforward) return null

                return (
                  <FeedforwardDesigner
                    parameters={{ ...data.parameters.feedforward }}
                    onChange={feedforward =>
                      setData({
                        ...data,
                        parameters: { ...data.parameters, feedforward }
                      })
                    }
                  />
                )
              }}
            />
            <Route
              path={createPath('talk-through')}
              component={() => {
                if (!data.parameters.talkthrough) return null

                return (
                  <TalkthroughDesigner
                    parameters={{ ...data.parameters.talkthrough }}
                    onChange={talkthrough =>
                      setData({
                        ...data,
                        parameters: { ...data.parameters, talkthrough }
                      })
                    }
                  />
                )
              }}
            />
            <Route
              path={createPath('calibration')}
              component={() => {
                if (!data.parameters.calibration) return null

                return (
                  <CalibrationDesigner
                    parameters={{ ...data.parameters.calibration }}
                    onChange={calibration =>
                      setData({
                        ...data,
                        parameters: { ...data.parameters, calibration }
                      })
                    }
                  />
                )
              }}
            />
          </Switch>
        </>
      )}
    </div>
  )
}

const FilterNavigation = styled.div`
  display: grid;
  grid-gap: 4px;
  grid-auto-flow: column;
  width: fit-content;
`

const TabNavigationContainer = styled.div`
  margin-top: 8px;
  margin-bottom: 24px;
`

const Container = styled.div`
  position: relative;
  overflow: auto;
  transition: 400ms all;
  margin-bottom: 12px;
`

const DragHandle = styled.div`
  cursor: pointer;
  padding: 4px;
  color: white;
  font-weight: bold;
  flex: 1;
`
const CenterContainer = styled.div`
  display:flex;
  justify-content:center;
  align-items:center;
  flex:1;
  flex-direction: column;
  height:300px;
`

const DragContent = styled.div`
  position: fixed;
  background-color: #333;
  border-radius: 8px;
  padding: 8px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
`
