import React from 'react'
import Helmet from 'react-helmet'

export default function BodyStyle({ style }: { style: string }) {
  return (
    <Helmet>
      <style>
        {`body {
            ${style}
        }`}
      </style>
    </Helmet>
  )
}
