import React from 'react'
import styled from '@emotion/styled/macro'
import ShimmerComponent from './shimmer'

export default function ImageHoverCard({
  children,
  loading = false,
  clickable = true,
  ...containerProps
}: {
  children: React.ReactNode
  loading?: boolean
  clickable?: boolean
} & React.ComponentProps<typeof Container>) {
  return (
    <Container {...containerProps} clickable={clickable}>
      {loading ? <ShimmerComponent /> : children}
    </Container>
  )
}

const Container = styled.div<{
  height?: string
  backgroundColor?: string
  borderRadius?: number
  clickable?: boolean
  width?: string
}>`
  width: ${({ width }) => width ?? '100%'};
  height: ${({ height }) => height ?? '320px'};
  border-radius: ${({ borderRadius }) => borderRadius ?? '12px'};
  overflow: hidden;
  position: relative;
  background-color: ${({ backgroundColor }) => backgroundColor ?? '#e6e6e6'};
  cursor: ${({ clickable }) => (clickable ? 'pointer' : 'default')};

  img {
    width: ${({ width }) => width ?? '100%'};
    height: ${({ height }) => height ?? '100%'};
    object-fit: cover;
    cursor: pointer;
    transition: 500ms all;
    border-radius: ${({ borderRadius }) => borderRadius ?? '12px'};

    ${({ clickable }) =>
      clickable &&
      `&:hover {
      transform: scale(1.05);
    }`}
  }
`
