import styled from '@emotion/styled/macro'
import dateRangePickerStyle from '../assets/styles/date-range-picker'

const DatePickerContainer = styled.div<{ shown?: boolean }>`
  overflow: hidden;
  height: ${({ shown }) => (shown ? 'initial' : 0)};
  ${({ shown }) => !shown && `position: relative;`}

  * {
    overflow: hidden;
    height: ${({ shown }) => (shown ? 'initial' : 0)};
    ${({ shown }) => !shown && `position: relative;`}
  }

  ${dateRangePickerStyle}
`

export default DatePickerContainer
