import * as Yup from 'yup'

export const createRequiredTextField = (
  name: string,
  customRequiredMessage?: string
) =>
  Yup.string()
    .required(customRequiredMessage ?? `Enter ${name}`)
    .min(2, 'Too short')
    .max(200, 'Too long')
