import styled from '@emotion/styled/macro'
import React from 'react'
import { ReactPaginateProps } from 'react-paginate'
import { tableSize as defaultTableSize } from '../constants'
import { Optional } from '../types/misc'
import LoadingErrorEmptyWrapper from './loading-error-empty'
import LoadingSpinner from './loading-spinner'
import Pagination from './pagination'

interface Props {
  children: React.ReactNode
  error?: unknown
  onPageChange?: ReactPaginateProps['onPageChange']
  count?: number
  shouldPaginate?: Optional<boolean>
  loading?: boolean
  emptyMessage?: string
  name?: string
  initialPage?: number
  pageSize?: number
}

export default function ResultsWrapper({
  children,
  error,
  shouldPaginate = undefined,
  pageSize = defaultTableSize,
  count,
  onPageChange,
  loading,
  name,
  emptyMessage,
  initialPage = 0
}: Props) {
  return (
    <Container>
      {loading && (
        <LoadingSpinner
          width={26}
          height={26}
          variant={'dark'}
          section={true}
          style={{
            backgroundColor: 'rgba(242, 242, 242, .8)',
            position: 'absolute',
            zIndex: 5
          }}
        />
      )}
      <LoadingErrorEmptyWrapper
        error={error}
        empty={!count}
        emptyMessage={emptyMessage ?? `There are no ${name ?? 'entries'}`}
        initialized={true}>
        <SectionContent>{children}</SectionContent>
        {(shouldPaginate ?? count > pageSize) && (
          <Pagination
            tableSize={pageSize}
            count={count}
            initialPage={initialPage}
            onPageChange={onPageChange}
          />
        )}
      </LoadingErrorEmptyWrapper>
    </Container>
  )
}

const SectionContent = styled.div`
  margin-bottom: 12px;
`

const Container = styled.div`
  position: relative;
`
