import { observer } from 'mobx-react-lite'
import React, { useContext, useMemo } from 'react'
import { Helmet } from 'react-helmet'
import { RootStoreContext } from '../context/root-store'

export default observer(SupportChat)

function SupportChat() {
  const {
    authentication: {
      user: { givenName, familyName, email, company, phoneNumber }
    }
  } = useContext(RootStoreContext)
  const name = useMemo(() => `${givenName} ${familyName}`, [
    givenName,
    familyName
  ])
  return (
    <Helmet>
      <script defer type="text/javascript">
        {`/* not used !function(e,t,n){function a(){var e=t.getElementsByTagName("script")[0],n=t.createElement("script");n.type="text/javascript",n.async=!0,n.src="https://beacon-v2.helpscout.net",e.parentNode.insertBefore(n,e)}if(e.Beacon=n=function(t,n,a){e.Beacon.readyQueue.push({method:t,options:n,data:a})},n.readyQueue=[],"complete"===t.readyState)return a();e.attachEvent?e.attachEvent("onload",a):e.addEventListener("load",a,!1)}(window,document,window.Beacon||function(){}); */`}
      </script>
      <script defer type="text/javascript">
        {` window.Beacon("init", "2cff8e7a-4fd2-49e2-81fc-1d03b7fa5f8e"); `}
      </script>
      <script defer type="text/javascript">
        {`window.Beacon("identify", { name: "${name}", email: "${email}", company: "${company}", phoneNumber: "${phoneNumber}" });`}
      </script>
    </Helmet>
  )
}
