const dateRangePickerStyle = `
  .DateRangePicker {
    display: inline-block;
    margin-bottom: 10px;
    padding: 0;
    position: absolute;
    user-select: none;
    background-color: #fff;
    border-radius: 12px;
    padding: 20px 0px;
    margin-top: 6px;
    transform: translate(-102px, 0px);
  }
  .DateRangePicker__Legend {
    color: #52575e;
    font-size: 14px;
    line-height: 16px;
    list-style-type: none;
    margin: 20px 0;
    padding: 0;
  }
  .DateRangePicker__LegendItem {
    display: inline-block;
    margin: 0 20px;
  }
  .DateRangePicker__LegendItemColor {
    display: inline-block;
    height: 14px;
    margin-right: 6px;
    vertical-align: text-bottom;
    width: 14px;
    border: 1px solid rgba(0, 0, 0, 0.25);
  }
  .DateRangePicker__LegendItemColor--selection {
    background-color: #ec1b23;
  }
  .DateRangePicker__PaginationArrow {
    border: 0;
    cursor: pointer;
    display: block;
    height: 35px;
    outline: none;
    overflow: hidden;
    padding: 0;
    position: absolute;
    text-align: center;
    top: 0;
    white-space: nowrap;
    width: 35px;
    z-index: 1;
  }
  .DateRangePicker__PaginationArrow--previous {
    left: 60px;
    top: 19px;
  }
  .DateRangePicker__PaginationArrow--next {
    top: 19px;
    right: 60px;
  }
  .DateRangePicker__PaginationArrowIcon {
    border-bottom: 8px solid transparent;
    border-top: 8px solid transparent;
    height: 0;
    position: absolute;
    top: 10px;
    width: 0;
  }
  .DateRangePicker__PaginationArrowIcon--is-disabled {
    opacity: 0.25;
  }
  .DateRangePicker__PaginationArrowIcon--previous {
    border-left: 8px solid transparent;
    border-right: 8px solid #dadada;
    right: 11px;
  }
  .DateRangePicker__PaginationArrowIcon--next {
    border-left: 8px solid #dadada;
    border-right: 8px solid transparent;
    left: 11px;
  }
  .DateRangePicker__Month {
    color: #333;
    display: inline-block;
    margin: 0 20px;
    position: relative;
    user-select: none;
  }
  .DateRangePicker__MonthHeader {
    color: #000;
    font-size: 14px;
    font-weight: bold;
    height: 35px;
    line-height: 35px;
    position: relative;
    text-align: center;
  }
  .DateRangePicker__MonthHeaderLabel {
    display: inline-block;
    position: relative;
  }
  .DateRangePicker__MonthHeaderSelect {
    background: #e4e4e4;
    border: 0;
    cursor: pointer;
    display: inline-block;
    height: 100%;
    left: 0;
    margin: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 5;
  }
  .DateRangePicker__MonthDates {
    margin: 0;
    width: 100%;
  }
  .DateRangePicker__WeekdayHeading,
  .DateRangePicker__Date {
    width: 20px;
    height: 30px;
    line-height: 32px;
    text-align: center;
  }
  .DateRangePicker__WeekdayHeading {
    color: #000;
    font-weight: bold;
  }
  .DateRangePicker__WeekdayHeading abbr[title] {
    border-bottom-width: 0;
    color: #000;
    cursor: pointer;
    font-size: inherit;
    text-decoration: none;
  }
  .DateRangePicker__Date {
    overflow: hidden;
    position: relative;
  }
  .DateRangePicker__Date:first-of-type {
    border-left-width: 1px;
  }
  .DateRangePicker__Date--otherMonth {
    opacity: 0.25;
  }
  .DateRangePicker__Date--is-disabled {
    color: #cdcdd1;
    cursor: default;
  }
  .DateRangePicker__Date--is-selected {
    color: #fff;
  }
  .DateRangePicker__Date--is-highlighted {
    color: #333;
  }
  .DateRangePicker__CalendarDatePeriod {
    bottom: 0;
    position: absolute;
    top: 0;
  }
  .DateRangePicker__CalendarDatePeriod--am {
    left: 0;
    right: 50%;
  }
  .DateRangePicker__CalendarDatePeriod--pm {
    left: 50%;
    right: 0;
  }
  .DateRangePicker__CalendarSelection {
    background-color: #ec1b23;
    border: 1px solid #db121a;
    bottom: 2px;
    left: 0;
    position: absolute;
    right: 0;
    top: 2px;
  }
  .DateRangePicker__CalendarSelection--inOtherMonth {
    opacity: 0.5;
  }
  .DateRangePicker__CalendarSelection--start {
    border-bottom-left-radius: 2px;
    border-right-width: 0;
    border-top-left-radius: 2px;
    left: 2px;
  }
  .DateRangePicker__CalendarSelection--end {
    border-bottom-right-radius: 2px;
    border-left-width: 0;
    border-top-right-radius: 2px;
    right: 2px;
  }
  .DateRangePicker__CalendarSelection--segment {
    border-left-width: 0;
    border-right-width: 0;
  }
  .DateRangePicker__CalendarSelection--single {
    border-radius: 2px;
    left: 2px;
    right: 2px;
  }
  .DateRangePicker__CalendarSelection--is-pending {
    background-color: rgba(236, 27, 35, 0.75);
    border-width: 0;
  }
  .DateRangePicker__CalendarHighlight {
    background-color: rgba(255, 255, 255, 0.25);
    border: 1px solid rgba(0, 0, 0, 0.25);
    bottom: 2px;
    left: 0;
    position: absolute;
    right: 0;
    top: 2px;
  }
  .DateRangePicker__CalendarHighlight--inOtherMonth {
    opacity: 0.5;
  }
  .DateRangePicker__CalendarHighlight--start {
    border-bottom-left-radius: 2px;
    border-right-width: 0;
    border-top-left-radius: 2px;
    left: 2px;
  }
  .DateRangePicker__CalendarHighlight--end {
    border-bottom-right-radius: 2px;
    border-left-width: 0;
    border-top-right-radius: 2px;
    right: 2px;
  }
  .DateRangePicker__CalendarHighlight--segment {
    border-left-width: 0;
    border-right-width: 0;
  }
  .DateRangePicker__CalendarHighlight--single {
    background-color: #fff;
    border: 1px solid #db121a;
    border-radius: 2px;
    left: 2px;
    right: 2px;
  }
  .DateRangePicker__HalfDateStates {
    bottom: -50px;
    left: -50px;
    position: absolute;
    right: -50px;
    top: -50px;
    transform: rotate(30deg);
  }
  .DateRangePicker__FullDateStates {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  .DateRangePicker__DateLabel {
    text-align: center;
    width: 100%;
    z-index: 2;
    position: relative;
    display: grid;
    width: 36px;
    height: 36px;
    align-items: center;
  }`

export default dateRangePickerStyle
