import axios from 'axios'
import { StationInfo } from '../../types/station'
import { ProductionStationStatus } from '../../types/production-station'
import { TestResult } from '../../types/test-result'
import { TestStatus } from '../../types/test-status'

export function fetchProdStationSystem(fqdn: string) {
  return axios.get<StationInfo>(`https://${fqdn}/system`)
}

export function fetchProdStationStatus(fqdn: string) {
  return axios.get<ProductionStationStatus>(`https://${fqdn}/status`)
}

export function fetchTestResult(fqdn: string) {
  return axios.get<TestResult>(`https://${fqdn}/test`)
}

export function startProductionTest(fqdn: string) {
  return axios.post<TestStatus>(`https://${fqdn}/test`)
}
