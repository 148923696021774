import styled from '@emotion/styled/macro'
import { ErrorMessage, Field, Form, FormikProps, withFormik } from 'formik'
import React, { useCallback } from 'react'
import * as Yup from 'yup'
import Button from '../../../components/button'
import CenteredMargin from '../../../components/centered-margin'
import Status from '../../../components/status'
import Switch from '../../../components/switch'
import { addUser } from '../../../services/entities/user'
import { interpretError } from '../../../utils/common'
import { createRequiredTextField } from '../../../utils/form'
import {
  FormContainer,
  FormInline,
  InputGroup,
  InputError
} from '../../../components/form'

interface Props {
  closeModal?: () => void
}

const errorTranslators = (err: string) => {
  if (err.includes('invalid email')) return { email: 'Email is already taken.' }
  return {}
}

const addUserFormSchema = Yup.object().shape({
  email: createRequiredTextField('email').email('Enter a valid email'),
  company: createRequiredTextField('company'),
  familyName: createRequiredTextField('last name'),
  givenName: createRequiredTextField('given name'),
  phoneNumber: createRequiredTextField('phone number').matches(
    /\+[0-9)]{4,}$/,
    'Enter a valid phone number'
  ),
  bulkAdd: Yup.bool()
})

const initialValues = {
  email: '',
  company: '',
  givenName: '',
  familyName: '',
  phoneNumber: '',
  bulkAdd: false
}

export default withFormik<Props, typeof initialValues>({
  mapPropsToValues: () => initialValues,
  handleSubmit: async (
    { bulkAdd, ...user },
    { props, setStatus, resetForm, setErrors }
  ) => {
    try {
      await addUser({ ...user, enabled: true })
      resetForm()
      if (bulkAdd) {
        setStatus(`Successfully added ${user.givenName}. Add another one.`)
      } else {
        props?.closeModal()
      }
      if (document.activeElement instanceof HTMLInputElement)
        document.activeElement?.blur()
    } catch (err) {
      setErrors(errorTranslators(interpretError(err)))
    }
  },
  validationSchema: addUserFormSchema,
  validateOnBlur: true
})(AddUserForm)

function AddUserForm({
  isSubmitting,
  status,
  errors,
  isValid,
  dirty,
  setFieldValue
}: FormikProps<typeof initialValues>) {
  const onBulkAddSwitch = useCallback(isOn => setFieldValue('bulkAdd', isOn), [
    setFieldValue
  ])
  return (
    <Form>
      <FormContainer>
        {status && <Status>{status}</Status>}
        <CenteredMargin>
          <FormInline>
            <label htmlFor="email">Email*</label>
            <InputGroup>
              <Field
                type="email"
                placeholder="Email"
                name="email"
                required
                error={errors.email}
                autoFocus
              />
              <InputError>
                <ErrorMessage name="email" />
              </InputError>
            </InputGroup>
          </FormInline>
        </CenteredMargin>
        <CenteredMargin>
          <FormInline>
            <label htmlFor="company">Company*</label>
            <InputGroup>
              <Field
                type="text"
                placeholder="Company"
                name="company"
                error={errors.company}
              />
              <InputError>
                <ErrorMessage name="company" />
              </InputError>
            </InputGroup>
          </FormInline>
        </CenteredMargin>
        <CenteredMargin>
          <FormInline>
            <label htmlFor="lname">Surname*</label>
            <InputGroup>
              <Field
                type="text"
                placeholder="Surname"
                name="familyName"
                error={errors.familyName}
              />
              <InputError>
                <ErrorMessage name="familyName" />
              </InputError>
            </InputGroup>
          </FormInline>
        </CenteredMargin>
        <CenteredMargin>
          <FormInline>
            <label htmlFor="fname">Given name*</label>
            <InputGroup>
              <Field
                type="text"
                placeholder="Given Name"
                name="givenName"
                error={errors.givenName}
              />
              <InputError>
                <ErrorMessage name="givenName" />
              </InputError>
            </InputGroup>
          </FormInline>
        </CenteredMargin>
        <CenteredMargin>
          <FormInline>
            <label htmlFor="phoneNumber">Phone</label>
            <InputGroup>
              <Field
                type="text"
                name="phoneNumber"
                placeholder="Phone Number"
                error={errors.phoneNumber}
              />
              <InputError>
                <ErrorMessage name="phoneNumber" />
              </InputError>
            </InputGroup>
          </FormInline>
        </CenteredMargin>
        <OneHalf>
          <AuxiliaryOption>
            <Switch
              height={20}
              width={40}
              onSwitch={onBulkAddSwitch}
              initialValue={initialValues.bulkAdd}
            />
            <div>Bulk Add</div>
          </AuxiliaryOption>
          <AddUserButton
            type="submit"
            disabled={!(dirty && isValid)}
            loading={isSubmitting}>
            {'Add User'}
          </AddUserButton>
        </OneHalf>
      </FormContainer>
    </Form>
  )
}

const AuxiliaryOption = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 12px;
  align-items: center;
  justify-self: start;
  font-size: 15px;
`

const AddUserButton = styled(Button)`
  justify-self: end;
  height: 48px;
  width: 92px;
`

const SubmitCenteredMargin = styled.div`
  display: grid;
  justify-items: end;
  margin-top: 24px;
`

const OneHalf = styled(SubmitCenteredMargin)`
  display: grid;
  grid-template-columns: 1fr 1fr;
`
