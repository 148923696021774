import styled from '@emotion/styled/macro'

const Flex = styled.div<{ gap?: string; column?: boolean; align?: string }>`
  display: flex;
  align-items: ${({ align }) => align ?? 'center'};
  grid-gap: ${({ gap }) => gap ?? '4px'};
  flex-wrap: wrap;
  ${({ column }) => column && `flex-direction: column;`}
`

export default Flex
