import { css } from '@emotion/core'
import styled from '@emotion/styled/macro'
import React, {
  Fragment,
  useCallback,
  useState,
  useRef,
  useEffect,
  useMemo
} from 'react'
import { ReactComponent as FilterIcon } from '../assets/icons/filter.svg'
import { mobileSize } from '../constants'
import useFilter from '../hooks/use-filter'
import useToggle from '../hooks/use-toggle'
import useWindowDimensions from '../hooks/use-window-dimensions'
import { joinFractions } from '../utils/common'
import Button from './button'
import Filter, { FilterMeta } from './filter'
import { JustifyRight, OneHalf } from './grids'
import ResultsWrapper from './results-wrapper'
import SectionTitle from './section-title'
import useThrottle from '../hooks/use-throttle'

type FieldType<T> = {
  label: string
  size?: number
  onClick?: (data: T) => void
  // TODO: Fix `any` typing here
  // eslint-disable-next-line
  content?: (colData: any, datum: T) => React.ReactNode

  // TODO: Fix paired conditional type here
  changeable?: boolean
  onInput?: (event: React.FormEvent<HTMLSpanElement>, colData: T) => void
}

export interface TableData<T> {
  meta: {
    rowHoverContent?: (datum: T) => React.ReactNode
    rowProps?: {
      onClick?: (datum: T) => void
      isSelected?: (datum: T) => boolean
    }
    fields: {
      [key in keyof T]?: FieldType<T>
    }
    arrangement: (string & keyof T)[]
    filters?: {
      [key in keyof T]?: FilterMeta
    }
    entity: string
    selector?: (datum: T) => boolean
  }
  data: T[]
  keyExtractor: (datum: T) => string | number
}

export default function Table<T>({
  meta,
  data,
  keyExtractor,
  rowClassName,
  initialFilterShown = false,
  title,
  query,
  rowStyle,
  excludedFilterFields = [],
  ...resultProps
}: TableData<T> &
  Omit<React.ComponentProps<typeof ResultsWrapper>, 'children'> & {
    rowClassName?: string
    initialFilterShown?: boolean
    title?: string
    query?: ReturnType<typeof useFilter>
    rowStyle?: string
    excludedFilterFields?: (keyof T)[]
  }) {
  const arrangement = meta.arrangement || Object.keys(meta.fields)
  const previousQuery = useRef<typeof query>(undefined)
  const includedFields = useMemo(
    () =>
      Object.keys(query?.queryFields ?? {}).filter(
        key => !excludedFilterFields.find(excludedKey => key === excludedKey)
      ),
    [query, excludedFilterFields]
  )
  const [isFilterShown, showFilter, , toggleFilter] = useToggle(
    !!includedFields.length || initialFilterShown
  )
  const fields = meta.fields
  const sizes = arrangement.map(key => meta?.fields[key]?.size ?? 1)
  const [width] = useWindowDimensions()
  const isMobile = width <= mobileSize
  const [activeHoveredItem, setActiveHoveredItem] = useState(null)
  const onHoverableItemClick = useCallback(
    (datum: T) => {
      const itemKey = keyExtractor(datum)
      setActiveHoveredItem(hoveredItem =>
        itemKey === hoveredItem ? null : keyExtractor(datum)
      )
    },
    [keyExtractor]
  )

  // Show filters pane when there's an initial filter
  useEffect(() => {
    if (
      !!query?.queryFields &&
      typeof previousQuery?.current === 'undefined' &&
      !!includedFields.length
    ) {
      showFilter()
      previousQuery.current = query
    }
  }, [query, showFilter, includedFields])

  const throttleFilterToggle = useThrottle(toggleFilter, 400)

  return (
    <Container>
      <Options>
        {title ? <SectionTitle title={title} noMargin={true} /> : <div />}
        <OptionsContainer>
          {!!meta.filters && (
            <Button
              toggle={isFilterShown}
              onClick={throttleFilterToggle}
              icon={true}>
              <FilterIcon width={18} fill={isFilterShown ? '#fff' : '#333'} />
            </Button>
          )}
        </OptionsContainer>
      </Options>
      {!!meta.filters && !!query && (
        <FilterContainer shown={isFilterShown}>
          <Filter entity={meta.entity} filters={meta.filters} query={query} />
        </FilterContainer>
      )}
      <ResultsWrapper {...resultProps} name={meta.entity ?? resultProps.name}>
        <TableHeader>
          <TableRow gridSizes={sizes} className={rowClassName}>
            {arrangement.map(key => (
              <Col key={key}>{fields[key].label}</Col>
            ))}
          </TableRow>
        </TableHeader>
        <TableBody>
          {data?.map((datum, index) => (
            <Fragment
              key={keyExtractor ? keyExtractor(datum) : JSON.stringify(datum)}>
              <TableRow
                {...(rowStyle
                  ? {
                      css: css`
                        ${rowStyle}
                      `
                    }
                  : {})}
                isSelected={
                  (meta?.selector && meta.selector(datum)) ||
                  activeHoveredItem === keyExtractor(datum)
                }
                gridSizes={sizes}
                className={rowClassName}
                {...(meta.rowHoverContent
                  ? {
                      onClick: () => onHoverableItemClick(datum)
                    }
                  : {})}
                {...(meta?.rowProps?.onClick
                  ? {
                      onClick: event => {
                        event.stopPropagation()
                        meta?.rowProps?.onClick(datum)
                      }
                    }
                  : {})}>
                {arrangement.map(key => (
                  <Col key={key}>
                    <span>{isMobile && `${fields[key].label}:`} </span>
                    <span
                      {...(fields[key]
                        ? {
                            onInput: e => fields[key].onInput(e, datum[index])
                          }
                        : {})}
                      suppressContentEditableWarning={!!fields[key].changeable}
                      contentEditable={!!fields[key].changeable}>
                      {fields[key].content
                        ? fields[key].content(datum[key], datum)
                        : datum[key]}
                    </span>
                  </Col>
                ))}
              </TableRow>
              {meta.rowHoverContent && (
                <HoverableContent
                  shown={activeHoveredItem === keyExtractor(datum)}>
                  {meta.rowHoverContent(datum)}
                </HoverableContent>
              )}
            </Fragment>
          ))}
        </TableBody>
      </ResultsWrapper>
    </Container>
  )
}

const HoverableContent = styled.div<{ shown?: boolean }>`
  margin-top: ${({ shown }) => (shown ? 20 : 0)}px;
  margin-bottom: ${({ shown }) => (shown ? 60 : 0)}px;
  overflow: hidden;
  max-height: ${({ shown }) => (shown ? 20000 : 0)}px;
  opacity: ${({ shown }) => (shown ? 1 : 0)};
  transition: 400ms all;
`

const OptionsContainer = styled(JustifyRight)`
  margin-bottom: 16px;
  justify-self: end;
  align-items: center;
`

const Options = styled(OneHalf)`
  margin-bottom: 12px;
  align-items: center;
`

export const Col = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  @media screen and (max-width: ${mobileSize}px) {
    grid-template-columns: 1fr 2fr;

    > span:first-of-type {
      font-weight: bold;
    }
  }
`

export const TableRow = styled.div<{
  gridSizes: number[]
  onClick?: (...args: unknown[]) => void
  clickable?: boolean
  isSelected?: boolean
}>`
  width: 100%;
  display: grid;
  color: #666;
  grid-template-columns: ${({ gridSizes }) => joinFractions(gridSizes)};
  align-items: center;
  font-size: 14px;
  line-height: 1.5;
  padding-top: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #e8ecef;
  font-weight: 300;
  grid-column-gap: 20px;
  word-break: break-word;
  cursor: ${({ onClick, clickable }) =>
    onClick && (typeof clickable === 'undefined' || clickable)
      ? 'pointer'
      : 'default'};
  background-color: ${({ isSelected }) => (isSelected ? '#eaeaea' : 'inherit')};

  &:hover {
    ${({ onClick, clickable, isSelected }) =>
      (onClick || clickable) && !isSelected && `background-color: #eaeaea`}
  }

  @media screen and (max-width: ${mobileSize}px) {
    grid-template-columns: 1fr;
    grid-gap: 12px;
    border-bottom: 2px solid #e8ecef;
  }

  a:hover {
    color: #ec1b23;
  }
`

const TableBody = styled.div``

export const TableHeader = styled.div`
  line-height: 1.5;
  border-top: 2px solid #e8ecef;
  border-bottom: 2px solid #e8ecef;
  font-size: 14px;
  line-height: 1.5;

  > div:hover {
    background-color: transparent;
  }

  > div {
    padding-top: 16px;
    padding-bottom: 16px;
    color: #a4a4a4;
  }

  @media screen and (max-width: ${mobileSize}px) {
    display: none;
  }
`

const FilterContainer = styled.div<{ shown: boolean }>`
  max-height: ${({ shown }) => (shown ? '1000px' : '0px')};
  transition: 400ms all;
  transition-delay: opacity 200ms;
  overflow: ${({ shown }) => (shown ? 'initial' : 'hidden')};
  opacity: ${({ shown }) => (shown ? 1 : 0)};
  margin-bottom: ${({ shown }) => (shown ? 24 : 0)}px;

  @media screen and (max-width: ${mobileSize}px) {
    max-height: ${({ shown }) => (shown ? '1000px' : '0px')};
    animation-delay: 200ms;
  }
`

const Container = styled.div``
