import { useEffect } from 'react'

export default function useRunEvery(cb: Function, ms: number) {
  useEffect(() => {
    const interval = setInterval(cb, ms)

    return () => {
      clearInterval(interval)
    }
  }, [cb, ms])
}
