import styled from '@emotion/styled/macro'
import React from 'react'
import Paginate from 'react-paginate'
import { tableSize as defaultTableSize } from '../constants'

interface Props {
  count: number
  onPageChange: (any) => void
  tableSize?: number
  initialPage?: number
}

export default function Pagination({
  count,
  tableSize = defaultTableSize,
  onPageChange,
  initialPage
}: Props) {
  return (
    <PaginateContainer>
      <Paginate
        pageCount={count / tableSize}
        pageRangeDisplayed={8}
        onPageChange={onPageChange}
        marginPagesDisplayed={1}
        {...(initialPage !== 0 ? { initialPage } : {})}
        nextLabel={'>'}
        previousLabel={'<'}
        containerClassName={'paginate-list'}
        activeClassName={'current-page'}
      />
    </PaginateContainer>
  )
}

const PaginateContainer = styled.div`
  .paginate-list {
    width: fit-content;
    max-width: 280px;
    display: grid;
    margin-top: 18px;
    margin-bottom: 18px;
    grid-auto-flow: column;
    grid-gap: 8px;

    a {
      color: #666666;
      font-size: 14px;
      cursor: pointer;
      outline: none;

      &:focus {
        outline: none;
      }
    }

    .current-page {
      a {
        color: #ed1c24;
      }
    }
  }
`
