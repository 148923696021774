import styled from '@emotion/styled/macro'
import React, { useEffect, useRef } from 'react'
import useToggle from '../hooks/use-toggle'

interface Props {
  height?: number
  width?: number
  onSwitch?: (isOn: boolean) => void
  initialValue?: boolean | string
}

export default function Switch(props: React.ComponentProps<'div'> & Props) {
  const { height = 24, width, initialValue, onSwitch, ...otherProps } = props
  const [isOn, , , toggle] = useToggle(
    typeof initialValue === 'string' ? initialValue === 'true' : initialValue
  )
  const refOnSwitch = useRef(onSwitch)
  const switchWidth = width ?? height * 2.5

  useEffect(() => {
    if (refOnSwitch.current) {
      refOnSwitch.current(isOn)
    }
  }, [isOn])

  return (
    <Container
      {...otherProps}
      height={height}
      width={switchWidth}
      {...(isOn ? { on: 'on' } : {})}
      onClick={toggle}
      role="switch"
      aria-checked={isOn ? 'true' : 'false'}>
      <Ball
        height={height}
        width={switchWidth}
        {...(isOn ? { on: 'on' } : {})}
      />
    </Container>
  )
}

interface SwitchComponentProps {
  height: number
  width: number
  on?: string
}

const Ball = styled.span<SwitchComponentProps>`
  background-color: #fff;
  height: ${({ height }) => height}px;
  width: ${({ height }) => height}px;
  position: absolute;
  border-radius: 999px;
  transition: 200ms all;

  ${({ height, width, on }) => {
    const size = height * 0.7
    const sideOffset = width * 0.1
    const slideLength = width - sideOffset - size
    const translateX = on ? slideLength : sideOffset
    const translateY = height * 0.15

    return `
        height: ${size}px;
        width: ${size}px;
        transform: translate(${translateX}px, ${translateY}px);
      `
  }}
`

const Container = styled.div<SwitchComponentProps>`
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  border-radius: 999px;
  position: relative;
  background-color: ${({ on }) => (on ? '#414141' : '#D8D8D8')};
  transition: 200ms all;
  cursor: pointer;
`
