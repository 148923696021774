import { useState, useCallback } from 'react'

export default function useCounter(initialValue = 0) {
  const [count, setCount] = useState(initialValue)
  const incrementCount = useCallback(() => setCount(count => count + 1), [])
  const consumeCount = useCallback(() => {
    incrementCount()
    return count
  }, [count, incrementCount])

  return [count, incrementCount, consumeCount] as const
}
