export function scale(opts: {
  domain: [number, number]
  range: [number, number]
}) {
  const istart = opts.domain[0],
    istop = opts.domain[1],
    ostart = opts.range[0],
    ostop = opts.range[1]

  return function scale(value) {
    return ostart + (ostop - ostart) * ((value - istart) / (istop - istart))
  }
}

export function minMax<T>(
  dataset: T[],
  targetPath: (string | number)[]
): [number, number] {
  let min: number = null,
    max: number = null

  dataset.forEach(data => {
    let ref = null
    targetPath.forEach(path => (ref = data[path]))
    if (min === null) min = ref
    if (max === null) max = ref
    if (ref < min) min = ref
    if (ref > max) max = ref
  })
  return [min ?? 0, max ?? 0]
}

export function range(number: number, min: number, max: number) {
  return number <= max && number >= min
}
