import { flow, types } from 'mobx-state-tree'
import { tokenKey, tokenPoolInterval } from '../constants'
import initialData from '../initial-data'
import {
  postAuthenticate,
  postRefreshToken
} from '../services/entities/authentication'
import { Authentication as AuthenticationType } from '../types/authentication'
import { interpretError } from '../utils/common'
import AuthUser from './auth-user'
import Error from './error'
import Loading from './loading'
import Token from './token'

const Authentication = types
  .compose(
    types.model({
      user: AuthUser,
      tokens: Token
    }),
    Loading,
    Error
  )
  .actions(self => ({
    clearError: () => {
      self.error = ''
    },
    login: flow(function*(username: string, password: string) {
      self.startLoading()
      try {
        const { data }: { data: AuthenticationType } = yield postAuthenticate(
          username,
          password
        )

        self.user = data.user
        self.tokens = data.tokens
        localStorage.setItem(tokenKey, data.tokens.idToken)
        self.error = ''
      } catch (err) {
        self.error = interpretError(err)
      }

      self.finishLoading()
    }),
    refreshToken: flow(function*() {
      if (self.user.email && self.tokens.refreshToken) {
        self.startLoading()
        try {
          const {
            data: {
              user,
              tokens: { accessToken, expiresIn, idToken }
            }
          }: { data: AuthenticationType } = yield postRefreshToken(
            self.user.email,
            self.tokens.refreshToken
          )

          self.user = user
          self.tokens.accessToken = accessToken
          self.tokens.expiresIn = expiresIn
          self.tokens.idToken = idToken
          localStorage.setItem(tokenKey, idToken)
        } catch (err) {
          self.error = interpretError(err)
        }
        self.finishLoading()
      }
    }),
    logout: () => {
      const { user, tokens } = initialData.root.authentication
      self.user = user
      self.tokens = tokens
    }
  }))
  .actions(self => {
    let poolInterval: NodeJS.Timeout
    const clearExistingPools = () => poolInterval && clearInterval(poolInterval)

    return {
      startPool: function() {
        clearExistingPools()
        self.refreshToken()
        poolInterval = setInterval(() => {
          self.refreshToken()
        }, tokenPoolInterval)
      },
      beforeDestroy: function() {
        clearExistingPools()
      }
    }
  })
  .views(self => ({
    get isLoggedIn() {
      const {
        tokens: { idToken, refreshToken },
        user: { email }
      } = self
      return idToken && refreshToken && email
    }
  }))
  .named('Authentication')

export default Authentication
