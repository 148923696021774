import styled from '@emotion/styled/macro'
import React, { useCallback, useEffect, useState } from 'react'
import ProgressBar from '../../../components/progress-bar'
import useAsync from '../../../hooks/use-async'
import { getFirmwareDownloadLink } from '../../../services/entities/firmware'
import { Devices } from '../../../types/engineer-device'
import { Firmware } from '../../../types/firmware'
import { devAddCors } from '../../../utils/request'
import getDevicePlatform from './utils/common'
import { writeFirmware } from './utils/board'
import WebSocketAsPromised from 'websocket-as-promised'

interface Props {
  selectedDevice: Devices['List'][0]
  firmwares: Firmware[]
  doneUpdating: () => void
  socket: WebSocketAsPromised
  path: string
  firmware: { inprogress: boolean; action: string; complete: number }
}

const stages = [
  { title: 'Downloading firmware', percent: 0.1 },
  { title: 'Unpacking', percent: 0.1 },
  { title: 'Erasing', percent: 1.5 },
  { title: 'Writing', percent: 98.3 }
]

const STAGE_DOWNLOADING_FIRMWARE = 0
const STAGE_CONVERTING_BLOB = 1
const STAGE_ERASING_EEPROM = 2
const STAGE_WRITING_EEPROM = 3

let startTime = -1

export default function UpdateFirmware({
  selectedDevice,
  firmwares,
  doneUpdating,
  path,
  socket,
  firmware
}: Props) {
  const firmwarePlatform = selectedDevice && getDevicePlatform(selectedDevice)
  const [fileError, setFileError] = useState<string>('')
  const [progress, setProgress] = useState({
    percent: 0,
    title: '',
    millisRemaining: 0
  })

  const updateProgress = useCallback((stageIndex: number, percent = 100) => {
    const p = stages.reduce((total, stage, index) => {
      if (index < stageIndex) {
        return total + stage.percent
      }
      if (index > stageIndex) {
        return total
      }
      return total + (stage.percent * percent) / 100
    }, 0)

    const timeElapsed = new Date().getTime() - startTime

    let millisRemaining
    if (p === 0) {
      millisRemaining = 0
    } else {
      millisRemaining = (timeElapsed / p) * (100 - p)
    }

    setProgress({
      millisRemaining,
      percent: p,
      title: stages[stageIndex].title
    })
  }, [])

  const firmwareToDownload = firmwares?.find(
    firmware => firmwarePlatform === firmware.platform
  )

  const getFirmwareLink = useCallback(
    () => getFirmwareDownloadLink(firmwareToDownload.url),
    [firmwareToDownload]
  )

  const {
    data: { downloadUrl }
  } = useAsync({ fn: getFirmwareLink })

  useEffect(() => {
    if (firmware.inprogress) {
      if (firmware.action === 'erase') {
        updateProgress(STAGE_ERASING_EEPROM, firmware.complete)
      } else if (firmware.action === 'write') {
        updateProgress(STAGE_WRITING_EEPROM, firmware.complete)
      } else {
        setFileError(`Unknown action ${firmware.action}`)
      }
    } else if (startTime > 0) {
      doneUpdating()
      startTime = -1
    }
  }, [doneUpdating, firmware, updateProgress])

  useEffect(() => {
    if (!downloadUrl) {
      setFileError('There is a problem on retrieving the file.')
      return
    }

    if (startTime > 0) {
      return
    }

    startTime = new Date().getTime()

    const fn = async () => {
      const url = devAddCors(downloadUrl)

      updateProgress(STAGE_DOWNLOADING_FIRMWARE)
      const response = await fetch(url, {
        headers: {
          'Content-Type': 'application/octet-stream'
        }
      })

      updateProgress(STAGE_CONVERTING_BLOB)
      const blob: Blob = await response.blob()
      const arrayBuffer: ArrayBuffer = await blob.arrayBuffer()

      //debug save file
      if (false) {
        var a = document.createElement("a")
        var saveurl = URL.createObjectURL(blob);
        a.href = saveurl;
        a.download = 'dbmc2-firmware.bin';
        document.body.appendChild(a);
        a.click();
        setTimeout(function() {
          document.body.removeChild(a);
          window.URL.revokeObjectURL(saveurl);  
        }, 0);
      }

      await writeFirmware(socket, path, arrayBuffer)
    }

    fn()
  }, [downloadUrl, path, setFileError, updateProgress, socket])

  return (
    <Container>
      <UpdateContainer>
        {!!fileError ? (
          <>
            <Title>Updating DBMC Firmware</Title>
            <UpdateText>{progress.title.toLowerCase()}... &nbsp;</UpdateText>
            <ProgressBar background={'#F2F2F2'} progress={progress} />
          </>
        ) : (
          <Title>
            It seems there has been an error when updating the firmware, please
            contact the administrator.
          </Title>
        )}
      </UpdateContainer>
    </Container>
  )
}

const UpdateText = styled.p`
  margin-bottom: 34px;
`

const Title = styled.h1``

const UpdateContainer = styled.div`
  background-color: #fff;
  padding: 30px 34px;
  max-width: 500px;
  border-radius: 12px;
  line-height: 1.5;
  position: absolute;
  width: 500px;
  top: 45%;
  transform: translate(-50%, -50%);
  left: 50%;

  ${Title} {
    font-size: 16px;
    font-weight: bold;
    margin: 8px 0px;
  }
`

const Container = styled.div`
  min-height: 200px;
  font-size: 15px;
`
