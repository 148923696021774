import { Form, FormikProps, withFormik } from 'formik'
import queryString from 'query-string'
import React, { useEffect } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import * as Yup from 'yup'
import {
  LinkUnderForm,
  LoginButton,
  LoginField,
  Subtitle,
  Title
} from '../../components/auth'
import AuthLayout from '../../containers/auth-layout'
import { postChangePassword } from '../../services/entities/authentication'
import { interpretError } from '../../utils/common'
import { createRequiredTextField } from '../../utils/form'
import { capitalize } from '../../utils/text'

const initialValues = {
  email: '',
  code: '',
  newPassword: '',
  verifyPassword: ''
}

const forgotPassSchema = Yup.object().shape({
  email: createRequiredTextField('email').email('Enter a valid email'),
  code: createRequiredTextField('code'),
  newPassword: createRequiredTextField(
    'newPassword',
    'Enter your new password'
  ),
  verifyPassword: createRequiredTextField(
    'verifyPassword',
    'Enter your new password again'
  )
})

export default withFormik<{ history: History }, typeof initialValues>({
  mapPropsToValues: () => ({
    ...initialValues
  }),
  handleSubmit: async (
    { email, code, newPassword, verifyPassword },
    { setStatus }
  ) => {
    if (newPassword === verifyPassword) {
      try {
        await postChangePassword(email, newPassword, code)
        window.location.replace(
          `/login/?message=${encodeURI(
            'Your password has been changed. You can now login.'
          )}`
        )
      } catch (err) {
        setStatus(interpretError(err))
      }
    } else {
      setStatus('Passwords do not match.')
    }
  },
  validationSchema: forgotPassSchema
})(ResetPassword)

function ResetPassword({
  isSubmitting,
  status,
  dirty,
  setFieldValue
}: FormikProps<typeof initialValues>) {
  const history = useHistory()
  const location = useLocation()
  const { e: email, c: code } = queryString.parse(location.search)

  useEffect(() => {
    if (!(email && code)) history.push('/')
    else {
      setFieldValue('email', email)
      setFieldValue('code', code)
    }
  }, [email, code, setFieldValue, history])

  return (
    <AuthLayout>
      <Form>
        <Title>Reset Password</Title>
        <Subtitle>
          {capitalize(status ? status : 'Enter new password')}
        </Subtitle>
        <LoginField
          type="password"
          required
          placeholder="New password"
          disabled={isSubmitting}
          id="newPassword"
          name="newPassword"
        />
        <LoginField
          type="password"
          required
          placeholder="Verify password"
          disabled={isSubmitting}
          id="verifyPassword"
          name="verifyPassword"
        />
        <LoginButton
          type="submit"
          disabled={!dirty}
          loading={isSubmitting}
          variant={'light'}
          autoFocus>
          Reset Password
        </LoginButton>
        <LinkUnderForm>
          <Link to={'/login'}>Return to Login</Link>
        </LinkUnderForm>
      </Form>
    </AuthLayout>
  )
}
