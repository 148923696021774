import { Firmware } from '../../types/firmware'
import api from '../common/api'

export function getFirmwares() {
  return api.get<{ data: Firmware[] }>(`/firmware`)
}

export function getFirmwareDownloadLink(firmwareDownloadUrl: string) {
  return api.get<{ downloadUrl: string }>(firmwareDownloadUrl)
}
