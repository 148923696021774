import { defaultPagePayload, filterDelimeter } from '../constants'
import { ResultParams } from '../types/result-list'
import { capitalize } from './text'

type Primitive = string | number | boolean

export function convertFilterToQuery(
  filters: {
    [key: string]: Primitive | Primitive[]
  },
  entity: string
) {
  return Object.keys(filters).reduce(
    (prev, curr) => ({
      ...prev,
      [`filter${capitalize(entity)}${capitalize(curr)}`]: Array.isArray(
        filters[curr]
      )
        ? (filters[curr] as Primitive[]).join(filterDelimeter)
        : filters[curr]
    }),
    {}
  )
}

export function createFacetsFromParams(obj: ResultParams = {}) {
  const {
    pageNumber: paramPageNumber,
    pageSize: paramPageSize,
    selected,
    filter,
    pageChanged = true
  } = obj

  const pageNumber = paramPageNumber ?? defaultPagePayload.pageNumber
  const pageSize = paramPageSize ?? defaultPagePayload.pageSize

  const pageAndSelectedPayload = {
    ...(selected && !pageChanged
      ? { pageSize, selected }
      : { pageNumber, pageSize })
  }

  return {
    ...pageAndSelectedPayload,
    ...convertFilterToQuery(filter, '')
  }
}
