import { observer } from 'mobx-react-lite'
import React, { useCallback, useContext, useState } from 'react'
import { ReactComponent as AddIcon } from '../../../assets/icons/plus-icon.svg'
import { ReactComponent as TrashIcon } from '../../../assets/icons/trash.svg'
import Button from '../../../components/button'
import { ModalWrapper } from '../../../components/modal'
import SectionTitle from '../../../components/section-title'
import { Col, TableHeader, TableRow } from '../../../components/table'
import TopRightOptions from '../../../components/top-right-options'
import Content from '../../../containers/content'
import { ProductionStoreContext } from '../../../context/production-store'
import useToggle from '../../../hooks/use-toggle'
import { Nullable } from '../../../types/misc'
import AddStationForm from './add-station-form'
import StationRow from './station-row'

export default observer(SetupStations)

const sizes = [3, 1, 1, 1, 1, 1.5, 1, 1, 1]

function SetupStations() {
  const { list, addToList, removeFromList } = useContext(ProductionStoreContext)
  const [addStationShown, showAddStation, hideAddStation] = useToggle(false)
  const addHandler = ({ fqdn }: { fqdn: string }) => {
    addToList(fqdn)
  }
  const [selectedStation, setSelectedStation] = useState<Nullable<string>>(null)
  const onDeleteProduct = useCallback(() => {
    if (selectedStation) {
      removeFromList(selectedStation)
      setSelectedStation(null)
    }
  }, [removeFromList, selectedStation])
  const handleRowToggle = useCallback(
    (fqdn: string) =>
      setSelectedStation(current => (current === fqdn ? null : fqdn)),
    []
  )

  return (
    <Content title={'Production'} subtitle={'Setup'}>
      <TopRightOptions>
        {selectedStation && (
          <Button icon={true} onClick={onDeleteProduct}>
            <TrashIcon height={20} width={20} fill={'#333'} />
          </Button>
        )}
        {list?.length < 6 && (
          <Button icon={true} onClick={showAddStation}>
            <AddIcon height={20} width={20} fill={'#333'} />
          </Button>
        )}
      </TopRightOptions>
      <ModalWrapper
        title={'Add a Production Station'}
        shown={addStationShown}
        hide={hideAddStation}>
        <AddStationForm add={addHandler} closeModal={hideAddStation} />
      </ModalWrapper>
      <SectionTitle title={'Stations'} />
      {!list?.length ? (
        <p>There are no stations.</p>
      ) : (
        <div>
          <TableHeader>
            <TableRow gridSizes={sizes}>
              <Col>Address</Col>
              <Col>Status</Col>
              <Col>Test</Col>
              <Col>Version</Col>
              <Col>Licensee</Col>
              <Col>License</Col>
              <Col>HW Address</Col>
              <Col>IP Address</Col>
              <Col>Internet</Col>
            </TableRow>
          </TableHeader>
          <div>
            {list?.map(fqdn => (
              <StationRow
                key={fqdn}
                toggle={handleRowToggle}
                fqdn={fqdn}
                gridSizes={sizes}
                isSelected={selectedStation === fqdn}
              />
            ))}
          </div>
        </div>
      )}
    </Content>
  )
}
