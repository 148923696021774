import { About, SiteVersion } from '../../types/about'
import api from '../common/api'

export interface AboutResponseData {
  about: About[]
  version: SiteVersion
}

export function getAbouts() {
  return api.get<AboutResponseData>(`/about/about`)
}
