import { useState } from 'react'

export default function useThrottle<T>(
  cb: T extends Function ? T : never,
  delay: number
) {
  const [latestTick, setLatestTick] = useState(0)

  return function throttledFunction(...args) {
    if (Date.now() - latestTick >= delay) {
      setLatestTick(Date.now())
      return cb(...args)
    }
  }
}
