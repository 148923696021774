const calibrationFields = {
  RESISTOR_CALIBRATION: {
    prop: 'resistor_calibration',
    mask:0xFF,
    fields: [
      {
        name: 'RESISTOR_CALIBRATION',
        bitmin: 0,
        bitmax: 7,
        description: '',
        options: []
      }
    ]
  },
  channel: {
    DC_OFFSET_WITH_FB: {
      prop: 'dc_offset_with_fb',
      mask:0x00FFFFFF,
      fields: [
        {
          name: 'DC_OFFSET_WITH_FB',
          bitmin: 0,
          bitmax: 31,
          description: '',
          options: []
        }
      ]
    },
    DC_OFFSET: {
      prop: 'dc_offset',
      mask:0x00FFFFFF,
      fields: [
        {
          name: 'DC_OFFSET',
          bitmin: 0,
          bitmax: 31,
          description: '',
          options: []
        }
      ]
    },
    C1_MAN: {
      prop: 'c1_man',
      mask:0xFFFFFFFF,
      fields: [
        {
          name: 'C1_MANTISSA',
          bitmin: 0,
          bitmax: 31,
          description: '',
          options: []
        }
      ]
    },
    C1_EXP: {
      prop: 'c1_exp',
      mask:0xFFFFFFFF,
      fields: [
        {
          name: 'C1_EXPONENT',
          bitmin: 0,
          bitmax: 31,
          description: '',
          options: []
        }
      ]
    },
    C2_MAN: {
      prop: 'c2_man',
      mask:0xFFFFFFFF,
      fields: [
        {
          name: 'C2_MANTISSA',
          bitmin: 0,
          bitmax: 31,
          description: '',
          options: []
        }
      ]
    },
    C2_EXP: {
      prop: 'c2_exp',
      mask:0xFFFFFFFF,
      fields: [
        {
          name: 'C2_EXPONENT',
          bitmin: 0,
          bitmax: 31,
          description: '',
          options: []
        }
      ]
    },
    FF_CFG3: {
      prop: 'ff_cfg3',
      mask:0xFFFFFFFF,
      fields: [
        {
          name: 'FF_CFG3',
          bitmin: 0,
          bitmax: 31,
          description: '',
          options: []
        }
      ]
    },
    LEV_AGAIN: {
      prop: 'lev_again',
      mask:0xFFFF,
      fields: [
        {
          name: 'LEV_AGAIN',
          bitmin: 0,
          bitmax: 31,
          description: '',
          options: []
        }
      ]
    }
  }
}

export default calibrationFields
