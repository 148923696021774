import React from 'react'
import { withFormik, Form, Field, FormikProps, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { createRequiredTextField } from '../../../utils/form'
import styled from '@emotion/styled/macro'
import Status from '../../../components/status'
import {
  FormContainer,
  SubmitContainer,
  FormInline,
  InputGroup,
  InputError
} from '../../../components/form'
import Button from '../../../components/button'
import { addUserToProduct } from '../../../services/entities/product'
import { interpretError } from '../../../utils/common'

interface Props {
  closeModal?: () => void
  productId: string
}

const addVisibleUserFormSchema = Yup.object().shape({
  email: createRequiredTextField('email').email('Enter a valid email')
})

const initialValues = {
  email: ''
}

const errorTranslators = (error: string) => {
  if (error.includes('invalid email')) {
    return { email: 'Email is incorrect.' }
  }
}

export default withFormik<Props, typeof initialValues>({
  mapPropsToValues: () => ({
    ...initialValues
  }),
  handleSubmit: async (
    { email },
    { setErrors, resetForm, props: { productId, closeModal } }
  ) => {
    try {
      await addUserToProduct(productId, email)
      resetForm()
      closeModal()
    } catch (err) {
      setErrors(errorTranslators(interpretError(err)))
    }
  },
  validationSchema: addVisibleUserFormSchema
})(AddVisibleUserForm)

function AddVisibleUserForm({
  status,
  dirty,
  isSubmitting,
  isValid
}: FormikProps<typeof initialValues>) {
  return (
    <Form>
      <FormContainer>
        {status && <Status>{status}</Status>}
        <AddVisibleUserFormInline>
          <label htmlFor="email">Email</label>
          <InputGroup>
            <Field
              type="email"
              required
              name="email"
              placeholder="User email"
            />
            <InputError>
              <ErrorMessage name="email" />
            </InputError>
          </InputGroup>
        </AddVisibleUserFormInline>
        <SubmitContainer>
          <AddUserButton
            type="submit"
            disabled={!dirty || !isValid}
            loading={isSubmitting}>
            Add User
          </AddUserButton>
        </SubmitContainer>
      </FormContainer>
    </Form>
  )
}

const AddUserButton = styled(Button)`
  width: 92px;
  height: 48px;
`

const AddVisibleUserFormInline = styled(FormInline)`
  grid-template-columns: 60px 1fr;
`
