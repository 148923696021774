import { keyframes } from '@emotion/core'
import styled from '@emotion/styled/macro'
import React from 'react'
import { ReactComponent as Spin } from '../assets/icons/spinner.svg'

export type LoaderVariant = 'dark' | 'light'

interface Props {
  width?: number
  height?: number
  fullPage?: boolean
  variant?: LoaderVariant
  section?: boolean
  fill?: string
}

export default function LoadingSpinner({
  width = 24,
  height = 24,
  fullPage = false,
  variant = 'light',
  section = false,
  fill = '#000',
  ...props
}: Props & React.ComponentProps<typeof Spinner>) {
  return fullPage || section ? (
    <FullPageContainer section={section}>
      <Spinner
        {...props}
        {...(fill ? { stroke: fill } : {})}
        variant={variant}
        width={width}
        height={height}
      />
    </FullPageContainer>
  ) : (
    <Spinner {...props} variant={variant} width={width} height={height} />
  )
}

const FullPageContainer = styled.div<{ section: boolean }>`
  position: ${({ section }) => (section ? 'absolute' : 'fixed')};
  height: ${({ section }) => !section && '100vh'};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  align-items: center;
  justify-items: center;
  display: grid;
  z-index: 3;
  background-color: ${({ section }) =>
    `rgba(242, 242, 242, ${section ? 0.8 : 1})`};
`

const rotate = keyframes`
  100% {
    transform: rotate(360deg);
  }
`

const dash = keyframes`
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
`

interface SpinnerProps extends React.ComponentProps<'svg'> {
  variant?: LoaderVariant
}

export const Spinner = styled(Spin as React.FunctionComponent<SpinnerProps>)`
  animation: ${rotate} 2s linear infinite;
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  align-self: center;
  justify-self: center;

  circle {
    animation: ${dash} 1.5s ease-in-out infinite;
    stroke: ${({ variant, stroke }) =>
      stroke ?? variant === 'dark'
        ? 'rgba(0, 0, 0, .3)'
        : '#f2f2f2'} !important;
  }
`
