import styled from '@emotion/styled/macro'

const CenteredMargin = styled.div`
  margin: 12px 0px;
  display: grid;
  align-items: center;
  min-height: 40px;

  &:first-of-type {
    margin-top: 0;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
`

export default CenteredMargin
