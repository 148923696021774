import { keyframes } from '@emotion/core'
import styled from '@emotion/styled/macro'
import React, { useContext, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { ReactComponent as AdminIcon } from '../assets/icons/icon-admin.svg'
import { ReactComponent as OmniIcon } from '../assets/icons/icon-omniscient.svg'
import { ReactComponent as SoundchipTextLogo } from '../assets/icons/logo-text.svg'
import { ReactComponent as SoundchipLogo } from '../assets/icons/soundchip-logo.svg'
import LockableLink from '../components/lockable-link'
import { mobileSize, maxWidth } from '../constants'
import SupportChat from '../containers/chat'
import { RootStoreContext } from '../context/root-store'
import useKeypress from '../hooks/use-keypress'
import useToggle from '../hooks/use-toggle'
import Beacon from '../services/common/beacon'
import { keyEscape } from '../utils/key-codes'

export default function Header() {
  const {
    authentication: {
      isLoggedIn,
      logout,
      user: { email, omniscient, administrator }
    }
  } = useContext(RootStoreContext)
  const [shown, , hide, toggle] = useToggle(false)
  const location = useLocation()

  useEffect(() => {
    hide()
    Beacon.close()
  }, [location, hide])

  // const hideAfter = (fn: Function) => () => {
  //   fn()
  //   hide()
  // }

  useKeypress(keyEscape, hide)

  useEffect(() => {
    if (shown) {
      document.addEventListener('click', hide)
      return () => document.removeEventListener('click', hide)
    }
  }, [shown, hide])

  // const onKnowledgeBase = hideAfter(() => Beacon.navigate('/answers/'))
  // const onAsk = hideAfter(() => Beacon.navigate('/ask/message'))

  return (
    <>
      <Logo onClick={toggle} />
      <Wrapper shown={shown}>
        <Overlay onClick={hide} />
        <Content>
          <SupportChat />
          <DividedColumn>
            <NavLink to={'/'}>
              <TextLogo />
            </NavLink>
            <UserActions>
              {isLoggedIn ? (
                <>
                  <EmailWithIcons>
                    {email}
                    {administrator && <AdminIcon height={14} />}
                    {omniscient && <OmniIcon height={14} />}
                  </EmailWithIcons>
                  <NavLink to={'/login'} onClick={logout}>
                    Logout
                  </NavLink>
                </>
              ) : (
                <NavLink to="/login">Login</NavLink>
              )}
            </UserActions>
          </DividedColumn>
          <Column>
            <NavLabel>Engineer</NavLabel>
            <LockableLink
              link={'/engineer/design'}
              label={'Design'}
              locked={!isLoggedIn}
            />
          </Column>
          <Column>
            <NavLabel>Production</NavLabel>
            <NavLink to={'/production/test'}>Test</NavLink>
            <NavLink to={'/production/setup'}>Setup</NavLink>
          </Column>
          <Column>
            <NavLabel>Missioncontrol</NavLabel>
            <LockableLink
              link={'/missioncontrol/products'}
              label={'Products'}
              locked={!isLoggedIn}
            />
            <LockableLink
              link={'/missioncontrol/stations'}
              label={'Stations'}
              locked={!isLoggedIn}
            />
          </Column>
          <Column>
            <NavLabel>Support</NavLabel>
            {administrator && isLoggedIn && (
              <LockableLink
                link={'/support/users'}
                label={'Users'}
                locked={!isLoggedIn}
              />
            )}
            <NavLink to={'/support/about'}>About</NavLink>
          </Column>
        </Content>
      </Wrapper>
    </>
  )
}

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  background-color: rgba(102, 102, 102, 0.3);
`

const Wrapper = styled.div<{ shown?: boolean }>`
  background-color: rgb(4, 4, 4);
  position: fixed;
  width: -webkit-fill-available;
  left: 0;
  width: fill-available;
  opacity: ${({ shown }) => (shown ? 1 : 0)};
  z-index: 4;
  transition: 400ms all;
  overflow: hidden;
  animation-name: ${({ shown }) => (shown ? 'none' : hide)};
  animation-delay: 400ms;
  animation-fill-mode: forwards;
`

const Logo = styled(
  SoundchipLogo as React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & { onClick?: Function }
  >
)`
  z-index: 5;
  height: 36px;
  width: 36px;
  fill: #ec1b23;
  position: fixed;
  top: 30px;
  transform: translateX(24px);
  cursor: pointer;
`

const EmailWithIcons = styled.div`
  display: grid;
  align-items: center;
  grid-auto-flow: column;
  width: fit-content;
  grid-column-gap: 8px;
`

const NavLabel = styled.p`
  color: #5f5f5f;
  cursor: default;
`

const NavLink = styled(Link)`
  display: block;
  color: #a4a4a4;

  &:hover {
    color: #ed1c24;
  }
`

const UserActions = styled.div`
  color: rgba(255, 255, 255, 0.6);
  font-size: 13px;
  line-height: 30px;
  display: grid;
  align-items: self-end;
  grid-template-rows: 28px 28px;
  align-self: self-end;
`

const Column = styled.div`
  padding: 30px 40px 24px 40px;
  line-height: 32px;
  font-size: 14px;
  color: #fff;
  font-weight: lighter;
`

const DividedColumn = styled(Column)`
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-row-gap: 24px;
  padding-left: 28px;

  @media screen and (max-width: ${mobileSize}px) {
    grid-template-rows: 1fr;
  }
`

const TextLogo = styled(SoundchipTextLogo)`
  fill: #fff;
  height: 36px;
  margin-left: 42px;
  position: fixed;
`

const hide = keyframes`
  100% {
    height: 0;
    transform: translateY(10000%);
  }
`

const Content = styled.div<{ shown?: boolean }>`
  margin: 0 auto;
  width: 100%;
  transition: 400ms all;
  display: grid;
  grid-template-columns: 3fr repeat(4, 2fr);
  opacity: 1;
  overflow: hidden;
  max-width: ${maxWidth}px;

  @media screen and (max-width: ${mobileSize}px) {
    grid-template-columns: 1fr;
    min-height: 100vh;
  }
`
