import React from 'react'
import LogsInfo from '../../../components/logs-info'
import ResultsWrapper from '../../../components/results-wrapper'
import { Log } from '../../../types/log'
import { SectionProp } from './product.page'

export default function ProductUsers({
  data,
  count,
  loading,
  error,
  setPage
}: SectionProp<Log>) {
  return (
    <ResultsWrapper
      error={error}
      count={count}
      onPageChange={({ selected }) => setPage(selected)}
      loading={loading}>
      {data.map(({ key, details, initiator, message, timestamp }) => {
        return (
          <LogsInfo
            details={details}
            initiator={initiator}
            message={message}
            timestamp={timestamp}
            key={`${key}`}
          />
        )
      })}
    </ResultsWrapper>
  )
}
