import { defaultPagePayload } from '../../constants'
import { Log } from '../../types/log'
import { Base64Media } from '../../types/misc'
import { Product, ProductInfo } from '../../types/product'
import { Result } from '../../types/result'
import {
  ListableParams,
  ResultList,
  ResultParams
} from '../../types/result-list'
import { Station } from '../../types/station'
import { TestResult } from '../../types/test-result'
import { User } from '../../types/user'
import { omit } from '../../utils/common'
import api from '../common/api'

export function fetchProduct(id: string) {
  return api.get<Product['info']>(`/products/${id}`)
}

export function fetchProducts(opts?: ResultParams) {
  return api.get<ResultList<Product['info']>>('/products', {
    params: opts
  })
}

export function fetchProductResults(id: string, opts?: ResultParams) {
  return api.get<ResultList<Result>>(`/products/${id}/results`, {
    params: opts
  })
}

export function fetchProductLogs(id: string, opts?: ResultParams) {
  return api.get<ResultList<Log>>(`/products/${id}/logs`, {
    params: opts
  })
}

export function fetchProductUsers(id: string, opts?: ResultParams) {
  return api.get<ResultList<User>>(`/products/${id}/users`, {
    params: opts
  })
}

export function fetchProductStations(id: string, opts?: ResultParams) {
  return api.get<ResultList<Station>>(`/products/${id}/stations`, {
    params: opts
  })
}

export function fetchProductImage(id: string) {
  return api.get<Base64Media>(`products/${id}/image`)
}

export function fetchProductUuidResults(
  id: string,
  uuid: string,
  opts?: ListableParams & { selected?: string }
) {
  const pageParams = {
    ...(opts.selected
      ? omit(defaultPagePayload, 'pageNumber')
      : defaultPagePayload)
  }
  return api.get<ResultList<Result>>(`/products/${id}/results/${uuid}`, {
    params: { ...pageParams, ...opts }
  })
}

export function fetchProductUuidResult(
  id: string,
  uuid: string,
  testId: string
) {
  return api.get<TestResult>(`/products/${id}/results/${uuid}/${testId}`)
}

export function addUserToProduct(id: string, email: string) {
  return api.post<void>(`/products/${id}/users`, {
    user: email
  })
}

export function removeUserFromProduct(id: string, email: string) {
  return api.delete<void>(`/products/${id}/users`, {
    data: {
      user: email
    }
  })
}

export function fetchProductDetails(id: string) {
  return api.get<ProductInfo>(`/products/${id}`)
}

export function postProductImage(id: string, photo: string | ArrayBuffer) {
  return api.post(`/products/${id}/image`, { base64: photo })
}

export function deleteProductImage(id: string) {
  return api.delete(`/products/${id}/image`)
}

export function postProductDetails(id: string, product: Partial<ProductInfo>) {
  return api.post(`/products/${id}`, product)
}

export function getProductMessages(id: string) {
  return api.get<ResultList<string>>(`/products/${id}/results/messages`, {
    params: { pageSize: 500 }
  })
}
