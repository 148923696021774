import styled from '@emotion/styled/macro'
import React from 'react'
import { Link } from 'react-router-dom'

interface Props {
  children: React.ReactNode
  to?: string
}

export default function Clickable({ children, to }: Props) {
  const stopPropagation = (e: React.MouseEvent) => e.stopPropagation()
  return to ? (
    <Link to={to} onClick={stopPropagation}>
      <Container>{children} </Container>
    </Link>
  ) : (
    <Container>{children}</Container>
  )
}

const Container = styled.span`
  transition: 400ms;

  &:hover {
    color: #ec1b23;
  }
`
