import { Authentication } from './types/authentication'
import { AsyncResult } from './types/misc'

export interface StateShape {
  root: {
    authentication: Authentication & AsyncResult
  }
}

const loading = false
const error = ''

const initialData: StateShape = {
  root: {
    authentication: {
      user: {
        active: false,
        administrator: false,
        authenticatorStatus: '',
        company: '',
        email: '',
        familyName: '',
        givenName: '',
        key: '',
        omniscient: false,
        phoneNumber: ''
      },
      tokens: {
        accessToken: '',
        expiresIn: 0,
        idToken: '',
        refreshToken: ''
      },
      loading,
      error
    }
  }
}

export default initialData
