import pxGroteskFont from './px-grotesk-font'
import reset from './reset'
import defaultStyling from './default-styling'

export default `
    ${pxGroteskFont}
    ${reset}
    ${defaultStyling}

    div::selection, button::selection, p::selection, span::selection { 
        background-color: transparent; 
    } 

    body, input, button {
        font-family: 'Px Grotesk';
        color: #666;
    }

    * {
        box-sizing: border-box;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        -webkit-transition: "color 99999s ease-out, background-color 99999s ease-out";
        -webkit-transition-delay: 99999s;
    }

    a {
        color: inherit;
        transition: 400ms;
        outline: none;
    }

    a:hover {
        color: ec1b23 !important;
        pointer: cursor;
    }

    p, span, div {
        font-weight: 300;
    }
`
