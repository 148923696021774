import styled from '@emotion/styled/macro'

const SubSubtitle = styled.p`
  font-size: 18px;
  font-weight: normal;
  font-style: oblique;
  margin-bottom: 12px;
  margin-top: 0;
  line-height: 1.4;
`

export default SubSubtitle
