/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import styled from '@emotion/styled/macro'
import React, { useCallback } from 'react'
import { SelectOption } from '../types/input-option'

interface RadioSelectProps<T> {
  options: SelectOption<T>[]
  value: any
  onChange?: (newValue: SelectOption<T>) => void
  containerStyle?: React.CSSProperties
  optionContainerStyle?: React.CSSProperties
  labelStyle?: React.CSSProperties
  alignment?: 'row' | 'column'
  gap?: string
}

export function RadioSelect<T>({
  options,
  value,
  onChange,
  containerStyle = {},
  optionContainerStyle = {},
  labelStyle = {},
  alignment = 'row',
  gap = '12px'
}: RadioSelectProps<T>) {
  
  const handleSelect = useCallback(
    newValue => {
      if (newValue !== value) {
        onChange(options.find(opt=>opt.value===newValue))
      }
    }, [value, options, onChange]
  )
  
  return (
    
      <RadioSelectContainer
        style={{ ...containerStyle }}
        gap={gap}
        alignment={alignment}>
        {options.map(option => 
          <OptionContainer role={"option-container"} key={option.label} style={{ ...optionContainerStyle }}>
            <RadioButton
              role={"radio-button"}
              selected={option.value === value}
              onClick={() => handleSelect(option.value)}
            />
            <label style={{ ...labelStyle }}>{option.label}</label>
          </OptionContainer>
        )}
      </RadioSelectContainer>
  )
}

const OptionContainer = styled.div<
  Pick<RadioSelectProps<unknown>, 'alignment' | 'gap'>
>`
  display: flex;
  align-items: center;
  gap: 8px;
`

const RadioSelectContainer = styled.div<
  Pick<RadioSelectProps<unknown>, 'alignment' | 'gap'>
>`
  display: flex;
  flex-direction: ${({ alignment }) => alignment};
  gap: ${({ gap }) => gap};
`

interface RadioButtonProps {
  onChange?: (newValue: boolean) => void
  initialValue?: boolean
  selected?: boolean
}

export function RadioButton({
  selected = false,
  ...props
}: RadioButtonProps & React.ComponentProps<typeof Container>) {
  return (
    <Container {...props} css={css}>
      <Circle checked={selected} />
    </Container>
  )
}

const Circle = styled.div<{ checked?: boolean }>`
  margin: 2px;
  width: 60%;
  height: 60%;
  border-radius: 99px;
  transition: 200ms all;

  ${({ checked }) =>
    checked &&
    `
    background-color: #878787;
  `}
`

const Container = styled.div`
  border: 2px solid #878787;
  height: 1.5rem;
  width: 1.5rem;
  cursor: pointer;
  border-radius: 99px;
  display: grid;
  align-items: center;
  justify-items: center;
`
