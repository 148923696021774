import styled from '@emotion/styled/macro'
import React from 'react'
import { interpretError } from '../utils/common'
import LoadingSpinner from './loading-spinner'
import SectionTitle from './section-title'

interface Props {
  error: unknown
  initialized?: boolean
  children: React.ReactNode
  empty?: boolean
  emptyMessage?: string
  loadingProps?: React.ComponentProps<typeof LoadingSpinner>
}

export default function LoadingErrorEmptyWrapper({
  error,
  initialized = false,
  children,
  empty = false,
  emptyMessage,
  loadingProps = {}
}: Props) {
  return (
    <Container>
      {!initialized ? (
        <LoadingSpinner variant={'dark'} section={true} {...loadingProps} />
      ) : error ? (
        <SectionTitle title={interpretError(error)} />
      ) : empty ? (
        <Empty>
          {emptyMessage ?? 'There are no entries to show at the moment.'}
        </Empty>
      ) : (
        children ?? <div />
      )}
    </Container>
  )
}

const Empty = styled.div`
  font-size: 16px;
  font-weight: 300;
  color: #666;
`

const Container = styled.div`
  margin-bottom: 50px;
  position: relative;
  z-index: 0;
`
