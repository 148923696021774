import { Parser } from 'binary-parser'
import { Struct, U16BE, U16LE, U32LE, U8 } from 'construct-js'
import {
  BiquadStage,
  CalibrationParameters,
  CommonParametersHeader,
  FeedbackParameters,
  FeedforwardParameters,
  PlaybackParameters,
  DeviceSetupParameters,
  DeviceSetupParametersChannel,
  DeviceSetupParametersCommon,
  DeviceSetupParametersData,
  DeviceSetupParametersHeader,
  DeviceSetupParametersMode,
  TalkthroughParameters,
  VoicePickupParameters
} from '../../../../types/dbmc2evb2'

const crc = (buffer: Buffer): number => {
  const CRC_TABLE = [
    0x0000,
    0x1189,
    0x2312,
    0x329b,
    0x4624,
    0x57ad,
    0x6536,
    0x74bf,
    0x8c48,
    0x9dc1,
    0xaf5a,
    0xbed3,
    0xca6c,
    0xdbe5,
    0xe97e,
    0xf8f7,
    0x1081,
    0x0108,
    0x3393,
    0x221a,
    0x56a5,
    0x472c,
    0x75b7,
    0x643e,
    0x9cc9,
    0x8d40,
    0xbfdb,
    0xae52,
    0xdaed,
    0xcb64,
    0xf9ff,
    0xe876,
    0x2102,
    0x308b,
    0x0210,
    0x1399,
    0x6726,
    0x76af,
    0x4434,
    0x55bd,
    0xad4a,
    0xbcc3,
    0x8e58,
    0x9fd1,
    0xeb6e,
    0xfae7,
    0xc87c,
    0xd9f5,
    0x3183,
    0x200a,
    0x1291,
    0x0318,
    0x77a7,
    0x662e,
    0x54b5,
    0x453c,
    0xbdcb,
    0xac42,
    0x9ed9,
    0x8f50,
    0xfbef,
    0xea66,
    0xd8fd,
    0xc974,
    0x4204,
    0x538d,
    0x6116,
    0x709f,
    0x0420,
    0x15a9,
    0x2732,
    0x36bb,
    0xce4c,
    0xdfc5,
    0xed5e,
    0xfcd7,
    0x8868,
    0x99e1,
    0xab7a,
    0xbaf3,
    0x5285,
    0x430c,
    0x7197,
    0x601e,
    0x14a1,
    0x0528,
    0x37b3,
    0x263a,
    0xdecd,
    0xcf44,
    0xfddf,
    0xec56,
    0x98e9,
    0x8960,
    0xbbfb,
    0xaa72,
    0x6306,
    0x728f,
    0x4014,
    0x519d,
    0x2522,
    0x34ab,
    0x0630,
    0x17b9,
    0xef4e,
    0xfec7,
    0xcc5c,
    0xddd5,
    0xa96a,
    0xb8e3,
    0x8a78,
    0x9bf1,
    0x7387,
    0x620e,
    0x5095,
    0x411c,
    0x35a3,
    0x242a,
    0x16b1,
    0x0738,
    0xffcf,
    0xee46,
    0xdcdd,
    0xcd54,
    0xb9eb,
    0xa862,
    0x9af9,
    0x8b70,
    0x8408,
    0x9581,
    0xa71a,
    0xb693,
    0xc22c,
    0xd3a5,
    0xe13e,
    0xf0b7,
    0x0840,
    0x19c9,
    0x2b52,
    0x3adb,
    0x4e64,
    0x5fed,
    0x6d76,
    0x7cff,
    0x9489,
    0x8500,
    0xb79b,
    0xa612,
    0xd2ad,
    0xc324,
    0xf1bf,
    0xe036,
    0x18c1,
    0x0948,
    0x3bd3,
    0x2a5a,
    0x5ee5,
    0x4f6c,
    0x7df7,
    0x6c7e,
    0xa50a,
    0xb483,
    0x8618,
    0x9791,
    0xe32e,
    0xf2a7,
    0xc03c,
    0xd1b5,
    0x2942,
    0x38cb,
    0x0a50,
    0x1bd9,
    0x6f66,
    0x7eef,
    0x4c74,
    0x5dfd,
    0xb58b,
    0xa402,
    0x9699,
    0x8710,
    0xf3af,
    0xe226,
    0xd0bd,
    0xc134,
    0x39c3,
    0x284a,
    0x1ad1,
    0x0b58,
    0x7fe7,
    0x6e6e,
    0x5cf5,
    0x4d7c,
    0xc60c,
    0xd785,
    0xe51e,
    0xf497,
    0x8028,
    0x91a1,
    0xa33a,
    0xb2b3,
    0x4a44,
    0x5bcd,
    0x6956,
    0x78df,
    0x0c60,
    0x1de9,
    0x2f72,
    0x3efb,
    0xd68d,
    0xc704,
    0xf59f,
    0xe416,
    0x90a9,
    0x8120,
    0xb3bb,
    0xa232,
    0x5ac5,
    0x4b4c,
    0x79d7,
    0x685e,
    0x1ce1,
    0x0d68,
    0x3ff3,
    0x2e7a,
    0xe70e,
    0xf687,
    0xc41c,
    0xd595,
    0xa12a,
    0xb0a3,
    0x8238,
    0x93b1,
    0x6b46,
    0x7acf,
    0x4854,
    0x59dd,
    0x2d62,
    0x3ceb,
    0x0e70,
    0x1ff9,
    0xf78f,
    0xe606,
    0xd49d,
    0xc514,
    0xb1ab,
    0xa022,
    0x92b9,
    0x8330,
    0x7bc7,
    0x6a4e,
    0x58d5,
    0x495c,
    0x3de3,
    0x2c6a,
    0x1ef1,
    0x0f78
  ]

  let r = 0xffff

  // @ts-ignore
  for (let i = 0; i < buffer.length; i++) {
    // let c = buffer.readUInt8(i)
    r = CRC_TABLE[(r ^ buffer.readUInt8(i)) & 0xff] ^ (r >> 8)
  }

  return (r & 0xffff) ^ 0xffff
}

// const packetHeaderParser = new Parser()
//     .endianess('little')
//     .uint16('length')
//     .uint8('service_id')
//     .uint8('op_code')

const deviceSetupHeaderEmitter = (data: DeviceSetupParametersHeader): Struct =>
  Struct('header')
    .field('version', U16LE(data.version))
    .field('number_of_channels', U8(data.number_of_channels))
    .field('number_of_modes', U8(data.number_of_modes))
    .field(
      'modes',
      data.modes.reduce(
        (a, v, i) => a.field('mode' + i, U32LE(v)),
        Struct('modes')
      )
    )

const deviceSetupModeEmitter = (data: DeviceSetupParametersMode): Struct =>
  Struct('mode')
    .field('pb_pbg_dgu_dc_c1_man', U32LE(data.pb_pbg_dgu_dc_c1_man))
    .field('pb_pbg_dgu_dc_c1_exp', U32LE(data.pb_pbg_dgu_dc_c1_exp))
    .field('pb_zoh_gain', U32LE(data.pb_zoh_gain))

const deviceSetupChannelEmitter = (data: DeviceSetupParametersChannel): Struct =>
  Struct('channel')
    .field('vp_hq_ddf_dc_offset', U32LE(data.vp_hq_ddf_dc_offset))
    .field('vp1_max_gain', U32LE(data.vp1_max_gain))
    .field('vp2_max_gain', U32LE(data.vp2_max_gain))
    .field('afe_inamp01_cfg', U16LE(data.afe_inamp01_cfg))
    .field('afe_tx_ctrl', U16LE(data.afe_tx_ctrl))
    .field('pb_max_gain', U32LE(data.pb_max_gain))
    .field('pb_cmpg_dgu_dc_c1_exp', U32LE(data.pb_cmpg_dgu_dc_c1_exp))
    .field('pb_cmpg_dgu_dc_c1_man', U32LE(data.pb_cmpg_dgu_dc_c1_man))
    .field('pb_cmpg_dgu_dc_c2_exp', U32LE(data.pb_cmpg_dgu_dc_c2_exp))
    .field('pb_cmpg_dgu_dc_c2_man', U32LE(data.pb_cmpg_dgu_dc_c2_man))
    .field('pb_pgain1_dgu_dc_c1_exp', U32LE(data.pb_pgain1_dgu_dc_c1_exp))
    .field('pb_pgain1_dgu_dc_c1_man', U32LE(data.pb_pgain1_dgu_dc_c1_man))
    .field('pb_pgain2_dgu_dc_c1_exp', U32LE(data.pb_pgain2_dgu_dc_c1_exp))
    .field('pb_pgain2_dgu_dc_c1_man', U32LE(data.pb_pgain2_dgu_dc_c1_man))
    .field('SU_FB_L_GAIN', U8(data.SU_FB_L_GAIN))
    .field('SU_FB_R_GAIN', U8(data.SU_FB_R_GAIN))
    .field('SU_FF_L_GAIN', U8(data.SU_FF_L_GAIN))
    .field('SU_FF_R_GAIN', U8(data.SU_FF_R_GAIN))
    .field(
      'modes',
      data.modes.reduce(
        (a, v, i) => a.field('mode' + i, deviceSetupModeEmitter(v)),
        Struct('modes')
      )
    )

const deviceSetupCommonEmitter = (data: DeviceSetupParametersCommon): Struct =>
  Struct('common')
    //-----------------------------
    .field('STEREO', U8(data.STEREO))		
    .field('AGPIO0', U8(data.AGPIO0)) 
    .field('AGPIO1', U8(data.AGPIO1)) 
    .field('AGPIO2', U8(data.AGPIO2)) 
    .field('AGPIO3', U8(data.AGPIO3)) 
    .field('AGPIO4', U8(data.AGPIO4)) 
    .field('AGPIO5', U8(data.AGPIO5)) 
    .field('AGPIO6', U8(data.AGPIO6)) 
    .field('AGPIO7', U8(data.AGPIO7)) 
    .field('AGPIO8', U8(data.AGPIO8)) 
    .field('AGPIO9', U8(data.AGPIO9)) 
    .field('AGPIO10', U8(data.AGPIO10))
    .field('AGPIO11', U8(data.AGPIO11))
    .field('AGPIO12', U8(data.AGPIO12))
    .field('AGPIO13', U8(data.AGPIO13))
    .field('AGPIO14', U8(data.AGPIO14))
    .field('AGPIO15', U8(data.AGPIO15))
    .field('AGPIO16', U8(data.AGPIO16))
    .field('AGPIO17', U8(data.AGPIO17))
    .field('afe_inamp23_cfg', U32LE(data.afe_inamp23_cfg))
    .field('afe_fb_cfg0', U32LE(data.afe_fb_cfg0))
    .field('afe_dac_cfg', U32LE(data.afe_dac_cfg))
    .field('lev_lc_st_cfg1', U32LE(data.lev_lc_st_cfg1))
    .field('DCDC_VCC_1_9', U8(data.DCDC_VCC_1_9)) 

const deviceSetupDataEmitter = (data: DeviceSetupParametersData): Struct =>
  Struct('data')
    .field('common', deviceSetupCommonEmitter(data.common))
    .field(
      'channels',
      data.channels.reduce(
        (a, v, i) => a.field('channel' + i, deviceSetupChannelEmitter(v)),
        Struct('channels')
      )
    )

const deviceSetupParametersEmitterNoCrc = (data: DeviceSetupParameters): Struct =>
  Struct('setup')
    .field('header', deviceSetupHeaderEmitter(data.header))
    .field('data', deviceSetupDataEmitter(data.data))

const deviceSetupParametersEmitterStruct = (data: DeviceSetupParameters): Struct => {
  const p = deviceSetupParametersEmitterNoCrc(data)
  const c = crc(p.toBuffer())

  return Struct('parameters')
    .field('type', U8(0x00))
    .field('parameters', p)
    .field('crc', U16BE(c))
}

export const deviceSetupParametersEmitterBytes = (data: DeviceSetupParameters): number[] => 
  deviceSetupParametersEmitterStruct(data).toBytes()

export const deviceSetupParametersEmitter = (data: DeviceSetupParameters): string => 
  deviceSetupParametersEmitterStruct(data)
    .toBuffer()
    .toString('hex')

const crcParser = new Parser().endianess('big').uint16('crc')

const deviceSetupModesParser = new Parser()
  .endianess('little')
  .uint32('pb_pbg_dgu_dc_c1_man')
  .uint32('pb_pbg_dgu_dc_c1_exp')
  .uint32('pb_zoh_gain')

const deviceSetupChannelParser = new Parser()
  .endianess('little')
  .uint32('vp_hq_ddf_dc_offset')
  .uint32('vp1_max_gain')
  .uint32('vp2_max_gain')
  .uint16('afe_inamp01_cfg')
  .uint16('afe_tx_ctrl')
  .uint32('pb_max_gain')
  .uint32('pb_cmpg_dgu_dc_c1_exp')
  .uint32('pb_cmpg_dgu_dc_c1_man')
  .uint32('pb_cmpg_dgu_dc_c2_exp')
  .uint32('pb_cmpg_dgu_dc_c2_man')
  .uint32('pb_pgain1_dgu_dc_c1_exp')
  .uint32('pb_pgain1_dgu_dc_c1_man')
  .uint32('pb_pgain2_dgu_dc_c1_exp')
  .uint32('pb_pgain2_dgu_dc_c1_man')
  .uint8('SU_FB_L_GAIN')
  .uint8('SU_FB_R_GAIN')
  .uint8('SU_FF_L_GAIN')
  .uint8('SU_FF_R_GAIN')
  .array('modes', {
    type: deviceSetupModesParser,
    // @ts-ignore
    length: x => x.header.number_of_modes
  })

const deviceSetupHeaderParser = new Parser()
  .endianess('little')
  .uint8('type')
  .uint16('version')
  .uint8('number_of_channels')
  .uint8('number_of_modes')
  .array('modes', {
    type: 'uint32le',
    length: 'number_of_modes'
  })

const deviceSetupCommonParser = new Parser()
  .endianess('little')
  .uint8('STEREO') 
  .uint8('AGPIO0')
  .uint8('AGPIO1')
  .uint8('AGPIO2') 
  .uint8('AGPIO3') 
  .uint8('AGPIO4') 
  .uint8('AGPIO5') 
  .uint8('AGPIO6') 
  .uint8('AGPIO7') 
  .uint8('AGPIO8') 
  .uint8('AGPIO9') 
  .uint8('AGPIO10') 
  .uint8('AGPIO11') 
  .uint8('AGPIO12') 
  .uint8('AGPIO13') 
  .uint8('AGPIO14') 
  .uint8('AGPIO15') 
  .uint8('AGPIO16') 
  .uint8('AGPIO17') 
  .uint32('afe_inamp23_cfg')
  .uint32('afe_fb_cfg0')
  .uint32('afe_dac_cfg')
  .uint32('lev_lc_st_cfg1')
  .uint8('DCDC_VCC_1_9')

const deviceSetupDataParser = new Parser()
  .endianess('little')
  .nest('common', { type: deviceSetupCommonParser })
  .array('channels', {
    type: deviceSetupChannelParser,
    // @ts-ignore
    length: x => x.header.number_of_channels
  })

const deviceSetupParametersParser = new Parser()
  .endianess('little')
  .nest('header', { type: deviceSetupHeaderParser })

  // TODO: resolve this probable bug in the DBMC2 firmware
  // there seems to be a bug in the DBMC2 firmware that means that the parameter type is not populated correctly so we just ignore this for now
  //  .choice('data', {
  // @ts-ignore
  //    tag: x => x.header.type,
  //    choices: {
  //      0: deviceSetupDataParser
  //    }
  //  })

  .nest('data', { type: deviceSetupDataParser })
  .nest(null, { type: crcParser })

export const deviceSetupParametersParse = (data: Buffer): DeviceSetupParameters =>
  // @ts-ignore
  deviceSetupParametersParser.parse(data)

export const deviceSetupParametersParseBytes = (data: number[]): DeviceSetupParameters => 
  deviceSetupParametersParse(Buffer.from(data))

export const deviceSetupParametersParseHex = (data: string): DeviceSetupParameters =>
  deviceSetupParametersParse(Buffer.from(data, 'hex'))

const commonHeaderParser = new Parser()
  .endianess('little')
  .uint8('type')
  .uint16('version')
  .uint8('number_of_channels')
  .uint8('number_of_rates')
  .array('rates', {
    type: 'uint32le',
    length: 'number_of_rates'
  })
  
const biquadStageParser = new Parser()
  .endianess('little')
  .uint32('b0')
  .uint32('b1')
  .uint32('b2')
  .uint32('a1')
  .uint32('a2')

const biquadStagesParser = stages => 
  new Parser().endianess('little').array('stages', {
    type: biquadStageParser,
    length: stages
  })
  
const biquadParser = stages => 
  new Parser()
    .endianess('little')
    .uint32('bqc_cfg1')
    .nest(null, { type: biquadStagesParser(stages) })

const biquadStageEmitter = (data: BiquadStage) => 
  Struct('stage')
    .field('b0', U32LE(data.b0))
    .field('b1', U32LE(data.b1))
    .field('b2', U32LE(data.b2))
    .field('a1', U32LE(data.a1))
    .field('a2', U32LE(data.a2))

const biquadStagesEmitter = (data: BiquadStage[]) =>
  Struct('stages').field(
    'stages',
    data.reduce(
      (a, v, i) => a.field('stage' + i, biquadStageEmitter(v)),
      Struct('stages')
    )
  )

const biquadEmitter = data =>
  Struct('biquad')
    .field('bqc_cfg1', U32LE(data.bqc_cfg1))
    .field('stages', biquadStagesEmitter(data.stages))

const commonHeaderEmitter = (data: CommonParametersHeader) =>
  Struct('header')
    .field('version', U16LE(data.version))
    .field('number_of_channels', U8(data.number_of_channels))
    .field('number_of_rates', U8(data.number_of_rates))
    .field(
      'rates',
      data.rates.reduce(
        (a, v, i) => a.field('rate' + i, U32LE(v)),
        Struct('rates')
      )
    )

const playbackChannelParser = new Parser()
  .endianess('little')
  .nest('comp1', { type: biquadParser(4) })
  .nest('equ', { type: biquadParser(5) })

const playbackRateParser = new Parser().endianess('little').array('channels', {
  type: playbackChannelParser,
  // @ts-ignore
  length: x => x.header.number_of_channels
})

const playbackDataParser = new Parser().endianess('little').array('rates', {
  type: playbackRateParser,
  // @ts-ignore
  length: x => x.header.number_of_rates
})

const playbackParametersParser = new Parser()
  .endianess('little')
  .nest('header', { type: commonHeaderParser })

  // TODO: resolve this probable bug in the DBMC2 firmware
  // there seems to be a bug in the DBMC2 firmware that means that the parameter type is not populated correctly so we just ignore this for now
  //  .choice('data', {
  // @ts-ignore
  //    tag: x => x.header.type,
  //    choices: {
  //      1: playbackDataParser
  //    }
  //  })
  .nest('data', { type: playbackDataParser })
  .nest(null, { type: crcParser })

export const playbackParametersParse = (data: Buffer): PlaybackParameters => 
  //@ts-ignore
  playbackParametersParser.parse(data)
  
export const playbackParametersParseBytes = (data: number[]): PlaybackParameters => 
  playbackParametersParse(Buffer.from(data))

export const playbackParametersParseHex = (data: string): PlaybackParameters => 
  playbackParametersParse(Buffer.from(data, 'hex'))
  
const playbackChannelEmitter = data =>
  Struct('channel')
    .field('comp1', biquadEmitter(data.comp1))
    .field('equ', biquadEmitter(data.equ))

const playbackRateEmitter = data =>
  Struct('data').field(
    'channels',
    data.channels.reduce(
      (a, v, i) => a.field('channel' + i, playbackChannelEmitter(v)),
      Struct('channels')
    )
  )

const playbackDataEmitter = data =>
  Struct('data').field(
    'rates',
    data.rates.reduce(
      (a, v, i) => a.field('rate' + i, playbackRateEmitter(v)),
      Struct('rates')
    )
  )

const playbackParametersEmitterNoCrc = data =>
  Struct('playback')
    .field('header', commonHeaderEmitter(data.header))
    .field('data', playbackDataEmitter(data.data))

const playbackParametersEmitterStruct = (data: PlaybackParameters): Struct => {
  const p = playbackParametersEmitterNoCrc(data)
  const c = crc(p.toBuffer())

  return Struct('parameters')
    .field('type', U8(0x01))
    .field('parameters', p)
    .field('crc', U16BE(c))
}

export const playbackParametersEmitterBytes = (data: PlaybackParameters): number[] => 
  playbackParametersEmitterStruct(data).toBytes()

export const playbackParametersEmitter = (data: PlaybackParameters): string => 
  playbackParametersEmitterStruct(data)
    .toBuffer()
    .toString('hex')

const voicePickupChannelParser = new Parser()
  .endianess('little')
  .uint32('cfg1')
  .uint32('cfg2')
  .uint32('cfg3')
  .nest(null, { type: biquadStagesParser(10) })

const voicePickupRateParser = new Parser()
  .endianess('little')
  .array('channels', {
    type: voicePickupChannelParser,
    // @ts-ignore
    length: x => x.header.number_of_channels
  })

const voicePickupDataParser = new Parser().endianess('little').array('rates', {
  type: voicePickupRateParser,
  // @ts-ignore
  length: x => x.header.number_of_rates
})

const voicePickupParametersParser = new Parser()
  .endianess('little')
  .nest('header', { type: commonHeaderParser })

  // TODO: resolve this probable bug in the DBMC2 firmware
  // there seems to be a bug in the DBMC2 firmware that means that the parameter type is not populated correctly so we just ignore this for now
  //  .choice('data', {
  // @ts-ignore
  //    tag: x => x.header.type,
  //    choices: {
  //      2: voicePickupDataParser
  //    }
  //  })

  .nest('data', { type: voicePickupDataParser })
  .nest(null, { type: crcParser })

export const voicePickupParametersParse = (data: Buffer): VoicePickupParameters =>
  // @ts-ignore
  voicePickupParametersParser.parse(data)

export const voicePickupParametersParseBytes = (data: number[]): VoicePickupParameters =>
  voicePickupParametersParse(Buffer.from(data))

export const voicePickupParametersParseHex = (data: string): VoicePickupParameters =>
  voicePickupParametersParse(Buffer.from(data, 'hex'))

const voicePickupChannelEmitter = data =>
  Struct('channel')
    .field('cfg1', U32LE(data.cfg1))
    .field('cfg2', U32LE(data.cfg2))
    .field('cfg3', U32LE(data.cfg3))
    .field(
      'stages',
      data.stages.reduce(
        (a, v, i) => a.field('stage' + i, biquadStageEmitter(v)),
        Struct('stages')
      )
    )

const voicePickupRateEmitter = data =>
  Struct('rate').field(
    'channels',
    data.channels.reduce(
      (a, v, i) => a.field('channel' + i, voicePickupChannelEmitter(v)),
      Struct('channels')
    )
  )

const voicePickupDataEmitter = data =>
  Struct('data').field(
    'rates',
    data.rates.reduce(
      (a, v, i) => a.field('rate' + i, voicePickupRateEmitter(v)),
      Struct('rates')
    )
  )

const voicePickupParametersEmitterNoCrc = data =>
  Struct('voicepickup')
    .field('header', commonHeaderEmitter(data.header))
    .field('data', voicePickupDataEmitter(data.data))

const voicePickupParametersEmitterStruct = (data: VoicePickupParameters): Struct => {
  const p = voicePickupParametersEmitterNoCrc(data)
  const c = crc(p.toBuffer())

  return Struct('parameters')
    .field('type', U8(0x02))
    .field('parameters', p)
    .field('crc', U16BE(c))
}

export const voicePickupParametersEmitter = (data: VoicePickupParameters): string => 
  voicePickupParametersEmitterStruct(data)
    .toBuffer()
    .toString('hex')

export const voicePickupParametersEmitterBytes = (data: VoicePickupParameters): number[] => 
  voicePickupParametersEmitterStruct(data).toBytes()

const feedbackCommonChannelsParser = new Parser()
  .endianess('little')
  .uint16('tx_ctrl')
  .uint32('fb_cfg12')

const feedbackCommonParser = new Parser()
  .endianess('little')
  .uint32('fb_cfg0')
  .uint32('fb_cfg3')
  .array('channels', {
    type: feedbackCommonChannelsParser,
    // @ts-ignore
    length: x => x.header.number_of_channels
  })

const feedbackRateParser = new Parser().array('channels', {
  type: biquadParser(4),
  // @ts-ignore
  length: x => x.header.number_of_channels
})

const feedbackDataParser = new Parser()
  .endianess('little')
  .nest('common', { type: feedbackCommonParser })
  .array('rates', {
    type: feedbackRateParser,
    // @ts-ignore
    length: x => x.header.number_of_rates
  })

const feedbackParametersParser = new Parser()
  .endianess('little')
  .nest('header', { type: commonHeaderParser })

  // TODO: resolve this probable bug in the DBMC2 firmware
  // there seems to be a bug in the DBMC2 firmware that means that the parameter type is not populated correctly so we just ignore this for now
  //  .choice('data', {
  // @ts-ignore
  //    tag: x => x.header.type,
  //    choices: {
  //      3: feedbackDataParser
  //    }
  //  })

  .nest('data', { type: feedbackDataParser })
  .nest(null, { type: crcParser })

export const feedbackParametersParse = (data: Buffer): FeedbackParameters =>
  // @ts-ignore
  feedbackParametersParser.parse(data)

export const feedbackParametersParseBytes = (data: number[]): FeedbackParameters =>
  feedbackParametersParse(Buffer.from(data))

export const feedbackParametersParseHex = (data: string): FeedbackParameters =>
  feedbackParametersParse(Buffer.from(data, 'hex'))

const feedbackChannelEmitter = data =>
  Struct('channel')
    .field('tx_ctrl', U16LE(data.tx_ctrl))
    .field('fb_cfg12', U32LE(data.fb_cfg12))

const feedbackRateEmitter = data =>
  Struct('data').field(
    'channels',
    data.channels.reduce(
      (a, v, i) => a.field('channel' + i, biquadEmitter(v)),
      Struct('channels')
    )
  )

const feedbackCommonEmitter = data =>
  Struct('common')
    .field('fb_cfg0', U32LE(data.fb_cfg0))
    .field('fb_cfg3', U32LE(data.fb_cfg3))
    .field(
      'channels',
      data.channels.reduce(
        (a, v, i) => a.field('channel' + i, feedbackChannelEmitter(v)),
        Struct('channels')
      )
    )

const feedbackDataEmitter = data =>
  Struct('data')
    .field('common', feedbackCommonEmitter(data.common))
    .field(
      'rates',
      data.rates.reduce(
        (a, v, i) => a.field('rate' + i, feedbackRateEmitter(v)),
        Struct('rates')
      )
    )

const feedbackParametersEmitterNoCrc = data =>
  Struct('feedback')
    .field('header', commonHeaderEmitter(data.header))
    .field('data', feedbackDataEmitter(data.data))

const feedbackParametersEmitterStruct = (data: FeedbackParameters): Struct => {
  const p = feedbackParametersEmitterNoCrc(data)
  const c = crc(p.toBuffer())

  return Struct('parameters')
    .field('type', U8(0x03))
    .field('parameters', p)
    .field('crc', U16BE(c))
}

export const feedbackParametersEmitter = (data: FeedbackParameters): string => 
  feedbackParametersEmitterStruct(data)
    .toBuffer()
    .toString('hex')

export const feedbackParametersEmitterBytes = (data: FeedbackParameters): number[] => 
  feedbackParametersEmitterStruct(data).toBytes()

const feedforwardBiquad = new Parser()
  .endianess('little')
  .uint32('cfg1')
  .nest(null, { type: biquadParser(7) })

const feedforwardRateParser = new Parser()
  .endianess('little')
  .array('channels', {
    type: feedforwardBiquad,
    // @ts-ignore
    length: x => x.header.number_of_channels
  })

const feedforwardDataParser = new Parser().endianess('little').array('rates', {
  type: feedforwardRateParser,
  // @ts-ignore
  length: x => x.header.number_of_rates
})

const feedforwardParametersParser = new Parser()
  .endianess('little')
  .nest('header', { type: commonHeaderParser })

  // TODO: resolve this probable bug in the DBMC2 firmware
  // there seems to be a bug in the DBMC2 firmware that means that the parameter type is not populated correctly so we just ignore this for now
  //  .choice('data', {
  // @ts-ignore
  //    tag: x => x.header.type,
  //    choices: {
  //      4: feedforwardDataParser
  //    }
  //  })

  .nest('data', { type: feedforwardDataParser })
  .nest(null, { type: crcParser })

export const feedforwardParametersParse = (data: Buffer): FeedforwardParameters =>
  // @ts-ignore
  feedforwardParametersParser.parse(data)

export const feedforwardParametersParseBytes = (data: number[]): FeedforwardParameters =>
  feedforwardParametersParse(Buffer.from(data))

export const feedforwardParametersParseHex = (data: string): FeedforwardParameters =>
  feedforwardParametersParse(Buffer.from(data, 'hex'))

const feedforwardBiquadEmitter = data =>
  Struct('biquads')
    .field('cfg1', U32LE(data.cfg1))
    .field('stages', biquadEmitter(data))

const feedforwardRateEmitter = data =>
  Struct('data').field(
    'channels',
    data.channels.reduce(
      (a, v, i) => a.field('channel' + i, feedforwardBiquadEmitter(v)),
      Struct('channels')
    )
  )

const feedforwardDataEmitter = data =>
  Struct('data').field(
    'rates',
    data.rates.reduce(
      (a, v, i) => a.field('rate' + i, feedforwardRateEmitter(v)),
      Struct('rates')
    )
  )

const feedforwardParametersEmitterNoCrc = data =>
  Struct('feedforward')
    .field('header', commonHeaderEmitter(data.header))
    .field('data', feedforwardDataEmitter(data.data))

const feedforwardParametersEmitterStruct = (data: FeedforwardParameters): Struct => {
  const p = feedforwardParametersEmitterNoCrc(data)
  const c = crc(p.toBuffer())

  return Struct('parameters')
    .field('type', U8(0x04))
    .field('parameters', p)
    .field('crc', U16BE(c))
}

export const feedforwardParametersEmitter = (data: FeedforwardParameters): string => 
  feedforwardParametersEmitterStruct(data)
    .toBuffer()
    .toString('hex')

export const feedforwardParametersEmitterBytes = (data: FeedforwardParameters): number[] => 
  feedforwardParametersEmitterStruct(data).toBytes()

const talkthroughRateParser = new Parser()
  .endianess('little')
  .array('channels', {
    type: biquadParser(7),
    // @ts-ignore
    length: x => x.header.number_of_channels
  })

const talkthroughDataParser = new Parser().endianess('little').array('rates', {
  type: talkthroughRateParser,
  // @ts-ignore
  length: x => x.header.number_of_rates
})

const talkthroughParametersParser = new Parser()
  .endianess('little')
  .nest('header', { type: commonHeaderParser })

  // TODO: resolve this probable bug in the DBMC2 firmware
  // there seems to be a bug in the DBMC2 firmware that means that the parameter type is not populated correctly so we just ignore this for now
  //  .choice('data', {
  // @ts-ignore
  //    tag: x => x.header.type,
  //    choices: {
  //      5: talkthroughDataParser
  //    }
  //  })

  .nest('data', { type: talkthroughDataParser })
  .nest(null, { type: crcParser })

export const talkthroughParametersParse = (data: Buffer): TalkthroughParameters =>
  // @ts-ignore
  talkthroughParametersParser.parse(data)

export const talkthroughParametersParseBytes = (data: number[]): TalkthroughParameters => 
  talkthroughParametersParse(Buffer.from(data))

export const talkthroughParametersParseHex = (data: string): TalkthroughParameters => 
  talkthroughParametersParse(Buffer.from(data, 'hex'))

const talkthroughRateEmitter = data =>
  Struct('data').field(
    'channels',
    data.channels.reduce(
      (a, v, i) => a.field('channel' + i, biquadEmitter(v)),
      Struct('channels')
    )
  )

const talkthroughDataEmitter = data =>
  Struct('data').field(
    'rates',
    data.rates.reduce(
      (a, v, i) => a.field('rate' + i, talkthroughRateEmitter(v)),
      Struct('rates')
    )
  )

const talkthroughParametersEmitterNoCrc = data =>
  Struct('talkthrough')
    .field('header', commonHeaderEmitter(data.header))
    .field('data', talkthroughDataEmitter(data.data))

const talkthroughParametersEmitterStruct = (data: TalkthroughParameters): Struct => {
  const p = talkthroughParametersEmitterNoCrc(data)
  const c = crc(p.toBuffer())

  return Struct('parameters')
    .field('type', U8(0x05))
    .field('parameters', p)
    .field('crc', U16BE(c))
}

export const talkthroughParametersEmitter = (data: TalkthroughParameters): string => 
  talkthroughParametersEmitterStruct(data)
    .toBuffer()
    .toString('hex')

export const talkthroughParametersEmitterBytes = (data: TalkthroughParameters): number[] => 
  talkthroughParametersEmitterStruct(data).toBytes()

const calibrationChannelParser = new Parser()
  .endianess('little')
  .uint32('dc_offset_with_fb')
  .uint32('dc_offset')
  .uint32('c1_man')
  .uint32('c1_exp')
  .uint32('c2_man')
  .uint32('c2_exp')
  .uint32('ff_cfg3')
  .uint16('lev_again')

const calibrationDataParser = new Parser()
  .endianess('little')
  .uint8('resistor_calibration')
  .array('channels', {
    type: calibrationChannelParser,
    // @ts-ignore
    length: x => x.header.number_of_channels
  })

const calibrationHeaderParser = new Parser()
  .endianess('little')
  .uint8('type')
  .uint16('version')
  .uint8('number_of_channels')

const calibrationParametersParser = new Parser()
  .endianess('little')
  .nest('header', { type: calibrationHeaderParser })

  // TODO: resolve this probable bug in the DBMC2 firmware
  // there seems to be a bug in the DBMC2 firmware that means that the parameter type is not populated correctly so we just ignore this for now
  //  .choice('data', {
  // @ts-ignore
  //    tag: x => x.header.type,
  //    choices: {
  //      9: calibrationDataParser
  //    }
  //  })

  .nest('data', { type: calibrationDataParser })
  .nest(null, { type: crcParser })

export const calibrationParametersParse = (data: Buffer): CalibrationParameters =>
  // @ts-ignore
  calibrationParametersParser.parse(data)

export const calibrationParametersParseBytes = (data: number[]): CalibrationParameters => 
  calibrationParametersParse(Buffer.from(data))

export const calibrationParametersParseHex = (data: string): CalibrationParameters => 
  calibrationParametersParse(Buffer.from(data, 'hex'))

const calibrationHeaderEmitter = data =>
  Struct('header')
    .field('version', U16LE(data.version))
    .field('number_of_channels', U8(data.number_of_channels))

const calibrationChannelEmitter = data =>
  Struct('channel')
    .field('dc_offset_with_fb', U32LE(data.dc_offset_with_fb))
    .field('dc_offset', U32LE(data.dc_offset))
    .field('c1_man', U32LE(data.c1_man))
    .field('c1_exp', U32LE(data.c1_exp))
    .field('c2_man', U32LE(data.c2_man))
    .field('c2_exp', U32LE(data.c2_exp))
    .field('ff_cfg3', U32LE(data.ff_cfg3))
    .field('lev_again', U16LE(data.lev_again))

const calibrationDataEmitter = data =>
  Struct('data')
    .field('resistor_calibration', U8(data.resistor_calibration))
    .field(
      'channels',
      data.channels.reduce(
        (a, v, i) => a.field('channel' + i, calibrationChannelEmitter(v)),
        Struct('channels')
      )
    )

const calibrationParametersEmitterNoCrc = data =>
  Struct('calibration')
    .field('header', calibrationHeaderEmitter(data.header))
    .field('data', calibrationDataEmitter(data.data))

const calibrationParametersEmitterStruct = (data: CalibrationParameters): Struct => {
  const p = calibrationParametersEmitterNoCrc(data)
  const c = crc(p.toBuffer())

  return Struct('parameters')
    .field('type', U8(0x09))
    .field('parameters', p)
    .field('crc', U16BE(c))
}

export const calibrationParametersEmitter = (data: CalibrationParameters): string => 
  calibrationParametersEmitterStruct(data)
    .toBuffer()
    .toString('hex')

export const calibrationParametersEmitterBytes = (data: CalibrationParameters): number[] => 
  calibrationParametersEmitterStruct(data).toBytes()
