import WebSocketAsPromised from 'websocket-as-promised'
import * as EVBTypes from '../../../../types/dbmc2evb2'
import { toHex } from './binary';

const FBM_MEM_SE_MSK = 0b001001110
const FBM_MEM_SE_VAL = 0b000000000
const FBM_ECM_SE_MSK = 0b001011110
const FBM_ECM_SE_VAL = 0b000000110
const FBM_MEM_DF_MSK = 0b001011110
const FBM_MEM_DF_VAL = 0b001011000

const VCE_MEM_SE_MSK = 0b000100001
const VCE_MEM_SE_VAL = 0b000000000
const VCE_ECM_SE_MSK = 0b000100001
const VCE_ECM_SE_VAL = 0b000000001

const EXP_FILTER_MSK = 0b001000000
const EXP_ENABLE_VAL = 0b000000000
const EXP_DISABLE_VAL = 0b001000000

const MICBIAS_CA_MSK = 0b10000000
const MICBIAS_CA_VAL = 0b00000000
const MICBIAS_CB_MSK = 0b10000000
const MICBIAS_CB_VAL = 0b10000000

const EEPROM_SECTOR_FIRMWARE_F = 0
const EEPROM_SECTOR_FIRMWARE_L = 37

const EEPROM_SECTOR_SETUP = 1008
const EEPROM_SECTOR_PLAYBACK = 1009
const EEPROM_SECTOR_VOICEPICKUP = 1010
const EEPROM_SECTOR_FEEDBACK = 1011
const EEPROM_SECTOR_FEEDFORWARD = 1012
const EEPROM_SECTOR_TALKTHROUGH = 1013
// const EEPROM_SECTOR_SU                = 1014
// const EEPROM_SECTOR_DYNAMICCOMPRESSOR = 1015
// const EEPROM_SECTOR_AP                = 1016
const EEPROM_SECTOR_CALIBRATION = 1017

const EEPROM_SECTOR_LENGTH = 4096

async function request<T>(ws: WebSocketAsPromised, req: T) {
  console.info('%cws req: ' + JSON.stringify(req), 'color: #777;')
  const res = await ws.sendRequest(req)
  console.info('%cws res:  ' + JSON.stringify(res), 'color: #777;')
  return res
}

function delay(ms: number) {
  return new Promise(res => setTimeout(res, ms))
}

function micToString(c) {
  return '(IO9 - IO1): ' + c.toString(2).padStart(9, '0')
}

function micCompare(c: number, mask: number, value: number) {
  return (c & mask) === value
}

async function getMicrophoneConfiguration(
  ws: WebSocketAsPromised,
  path: string
) {
  console.log('DBMC2 EVB getMicrophoneConfiguration for board', { path })
  const r = await request(ws, {
    SettingsRead: { Device: path }
  })

  const c = r.Data
  console.log(
    'DBMC2 EVB getMicrophoneConfiguration completed: ' + micToString(c)
  )
  return c
}

async function setMicrophoneConfiguration(
  ws: WebSocketAsPromised,
  path: string,
  c: string
) {
  console.log('DBMC2 EVB setMicrophoneConfiguration for board', { path, c })
  await request(ws, {
    SettingsWrite: { Device: path, Data: c, Mask: 0x01ff }
  })
  await delay(20)
  console.log('DBMC2 EVB setMicrophoneConfiguration completed')
  return c
}

async function writeEeprom(
  ws: WebSocketAsPromised,
  path: string,
  first,
  last,
  data
) {
  console.log('DBMC2 writeEeprom', { path, first, last, data })
  await request(ws, {
    EepromWrite: {
      Device: path,
      FirstSector: first,
      LastSector: last,
      Data: data
    }
  })
  // The DBMC2 takes no more than a second to boot
  await new Promise(resolve => setTimeout(resolve, 1000))
}

async function readEeprom(ws: WebSocketAsPromised, path: string, first, last) {
  console.log('DMBC2 readEeProm', { path, first, last })
  const c = await request(ws, {
    EepromRead: { Device: path, FirstSector: first, LastSector: last }
  })
  return c.Data
}

async function create(
  ws: WebSocketAsPromised,
  path: string,
  target: string,
  major: number,
  minor: number
) {
  console.log('DBMC2 EVB create for board', { path, target, major, minor })
  await request(ws, {
    RawConfigure: { Device: path, Target: target, Major: major, Minor: minor }
  })
  console.log('DBMC2 EVB create completed')
}

// TODO: see if this can be deleted. I don't think it is being used
async function initialize(ws: WebSocketAsPromised, device) {
  const path = device.Path
  console.log('DBMC2 EVB initialize starting for board', { path })

  // TODO: Figure this out
  // ui.add_dbmc2evb_id(path)
  // ui.show_dbmc2evb(true)

  console.log('DBMC2 EVB initialize completed')
}

async function setControl(
  ws: WebSocketAsPromised,
  path: string,
  enable: boolean
) {
  console.log('DBMC2 EVB setControl starting for board', { path, enable })
  await request(ws, {
    GpioWrite: { Device: path, Data: enable ? 0x00 : 0x04, Mask: 0x04 }
  })
  console.log('DBMC2 EVB setControl completed')
}

async function setControlOn(ws: WebSocketAsPromised, path: string) {
  setControl(ws, path, true)
}

async function setControlOff(ws: WebSocketAsPromised, path: string) {
  setControl(ws, path, false)
}

async function setReset(
  ws: WebSocketAsPromised,
  path: string,
  enable: boolean
) {
  console.log('DBMC2 EVB setReset starting for board', { path, enable })
  await request(ws, {
    GpioWrite: { Device: path, Data: enable ? 0x00 : 0x10, Mask: 0x10 }
  })
  console.log('DBMC2 EVB setReset completed')
}

async function setResetOn(ws: WebSocketAsPromised, path: string) {
  setReset(ws, path, true)
}

async function setResetOff(ws: WebSocketAsPromised, path: string) {
  setReset(ws, path, false)
}

async function setLed(ws: WebSocketAsPromised, path: string, enable: boolean) {
  console.log('DBMC2 EVB setLed starting for board', { path, enable })
  await request(ws, {
    GpioWrite: { Device: path, Data: enable ? 0x00 : 0x80, Mask: 0x80 }
  })
  console.log('DBMC2 EVB setLed completed')
}

async function setLedOn(ws: WebSocketAsPromised, path: string) {
  setLed(ws, path, true)
}

async function setLedOff(ws: WebSocketAsPromised, path: string) {
  setLed(ws, path, false)
}

async function setFilter(
  ws: WebSocketAsPromised,
  path: string,
  enable: boolean
) {
  console.log('DBMC2 EVB setFilter starting for board', { path, enable })
  let c = await getMicrophoneConfiguration(ws, path)

  let m
  let b

  if (enable) {
    m = EXP_FILTER_MSK
    b = EXP_ENABLE_VAL
  } else {
    m = EXP_FILTER_MSK
    b = EXP_DISABLE_VAL
  }

  c = (c & ~m) | b

  await setMicrophoneConfiguration(ws, path, c)

  if (enable) {
    console.log(
      'DBMC2 EVB setFilter: enable, requires that FB microphone be set to single ended'
    )
    const isNotMems = ((c & ~FBM_MEM_SE_MSK) | FBM_MEM_SE_VAL) === 0
    await setAncMicrophoneConfiguration(ws, path, !isNotMems, true)
  }

  console.log('DBMC2 EVB setFilter completed')
}

async function setFilterOn(ws: WebSocketAsPromised, path: string) {
  setFilter(ws, path, true)
}

async function setFilterOff(ws: WebSocketAsPromised, path: string) {
  setFilter(ws, path, false)
}

async function setMicbiasConfiguration(
  ws: WebSocketAsPromised,
  path: string,
  defaultConfiguration: boolean
) {
  console.log('DBMC2 EVB setMicbiasConfiguration starting for board', {
    path,
    configuration: defaultConfiguration ? 'A' : 'B'
  })
  let c = await getMicrophoneConfiguration(ws, path)

  let m
  let b

  if (defaultConfiguration) {
    m = MICBIAS_CA_MSK
    b = MICBIAS_CA_VAL
  } else {
    m = MICBIAS_CB_MSK
    b = MICBIAS_CB_VAL
  }

  c = (c & ~m) | b

  await setMicrophoneConfiguration(ws, path, c)
  console.log('DBMC2 EVB setMicbiasConfiguration completed')
}

async function setAncMicrophoneConfiguration(
  ws: WebSocketAsPromised,
  path: string,
  mems: boolean,
  singleEnded: boolean
) {
  console.log('DBMC2 EVB setAncMicrophoneConfiguration starting for board', {
    path,
    configuration: {
      type: mems ? ' MEMS' : ' ECM',
      connection: singleEnded ? ' SE' : ' DIFF'
    }
  })
  let c = await getMicrophoneConfiguration(ws, path)

  let m
  let b

  if (mems) {
    m = FBM_MEM_SE_MSK
    b = FBM_MEM_SE_VAL
  } else {
    if (singleEnded) {
      m = FBM_ECM_SE_MSK
      b = FBM_ECM_SE_VAL
    } else {
      m = FBM_MEM_DF_MSK
      b = FBM_MEM_DF_VAL
    }
  }

  c = (c & ~m) | b

  await setMicrophoneConfiguration(ws, path, c)
  console.log('DBMC2 EVB setAncMicrophoneConfiguration completed')
}

async function setVoiceMicrophoneConfiguration(
  ws: WebSocketAsPromised,
  path: string,
  memsSingleEnded: boolean
) {
  console.log('DBMC2 EVB setVoiceMicrophoneConfiguration starting for board', {
    path,
    configuration: memsSingleEnded ? 'mems single ended' : 'ecm differential'
  })
  let c = await getMicrophoneConfiguration(ws, path)

  let m
  let b

  if (memsSingleEnded) {
    m = VCE_MEM_SE_MSK
    b = VCE_MEM_SE_VAL
  } else {
    m = VCE_ECM_SE_MSK
    b = VCE_ECM_SE_VAL
  }

  c = (c & ~m) | b

  await setMicrophoneConfiguration(ws, path, c)
  console.log('DBMC2 EVB setVoiceMicrophoneConfiguration completed')
}

function decodeCommand(service: number, opcode: number) {
  //the vast majority of these service ids and opcodes will never be used by SSE
  //but it's useful to include them to easily spot mistaken command calls
  const commands = {
    0x00: [
      'System',
      {
        0x00: 'Get Version Command',
        0x10: 'Set UUID',
        0x01: 'Get UUID',
        0x02: 'Set Operation Mode',
        0x03: 'Get Operation Mode',
        0x04: 'Ping',
        0x05: 'Trigger Host Interrupt',
        0x06: 'Set Input Clock',
        0x07: 'Get Input Clock',
        0x09: 'Read Memory',
        0x0A: 'Write Memory',
        0x0B: 'Get Maximum Message Size',
        0x0D: 'Get Boot Information',
        0x0E: 'Clear Boot Information',
        0x17: 'Upload Parameter Set',
        0x18: 'Download Parameter Set',
        0x19: 'Delete Parameter Set',
        0x21: 'Clear Parameter Set',
      }
    ],
    0x01: [
      'Interrupts',
      {
        0x00: 'Set Host Interrupt Configuration',
        0x01: 'Get Host Interrupt Configuration',
        0x02: 'Get Host Interrupt Status',
        0x03: 'Set Host Interrupt Status',
      }
    ],
    0x02: [
      'GPIOs',
      {
        0x00: 'Set GPIO Configuration',
        0x01: 'Get GPIO Configuration',
        0x02: 'Set GPIOs',
        0x03: 'Get GPIOs',
        0x04: 'Clear GPIOs',
        0x05: 'Toggle GPIOs',
        0x06: 'Get GPIO Interrupt Status',
        0x07: 'Set GPIO Firmware Function',
        0x08: 'Get GPIO Firmware Function',
      }
    ],
    0x03: [
      'AudioRouting',
      {
        0x10: 'Add Route',
        0x11: 'Delete Route',
        0x12: 'Get Routes',
        0x17: 'Clear Routes',
        0x0A: 'Set Path Parameters',
        0x0B: 'Get Path Parameters',
        0x13: 'Set Supervisory Path',
        0x14: 'Get Supervisory Path',
        0x15: 'Set Voice Pickup Path',
        0x16: 'Get Voice Pickup Path',
        0x18: 'Set FF Path',
        0x19: 'Get FF Path',
        0x1A: 'Set Calibration Path',
        0x1B: 'Get Calibration Path',
      }
    ],
    0x04: [
      'DigitalAudioInterface – DAI',
      {
        0x00: 'Set DAI Type',
        0x01: 'Get DAI Type',
        0x02: 'Set DAI Format (DEPRECATED)',
        0x06: 'Set DAI Format',
        0x03: 'Get DAI Format (DEPRECATED)',
        0x07: 'Get DAI Format',
        0x04: 'Set DAI Control',
        0x05: 'Get DAI Control',
      }
    ],
    0x05: [
      'AcousticNoiseCancellation – ANC',
      {
        0x03: 'Set ANC Mode',
        0x04: 'Get ANC Mode',
        0x05: 'Set Awareness Level',
        0x06: 'Get Awareness Level',
        0x07: 'Set Wind Noise Suppression Mode',       
        0x08: 'Get Wind Noise Suppression Mode', 
      }
    ],
    0x06: [
      'AnalogFrontend',
      {
        0x00: 'Set Target Gain',
        0x01: 'Get Target Gain',
        0x09: 'Set Current Gain',
        0x02: 'Get Current Gain',
        0x07: 'Set Microphone BIAS',
        0x08: 'Get Microphone BIAS',
        0x0A: 'Resistor Calibration',
        0x0C: 'DC Offset Calibration (DEPRECATED)',
        0x0D: 'Set ADC Mux',
        0x0E: 'Get ADC Mux',
        0x0F: 'DC Offset Calibration',
      }
    ],
    0x08: [
      'AudioClockRecovery – ACR',
      {
        0x00: 'Set ACR Mode',
        0x01: 'Get ACR Mode', 
      }
    ],
    0x09: [
      'PulseWidthModulation – PWM',
      {
        0x00: 'Set PWM Configuration',
        0x01: 'Get PWM Configuration',
        0x02: 'Set PWM Mode',
        0x03: 'Get PWM Mode',
      }
    ],
    0x0A:[
      'Dynamic Compressor',
      {
        0x01: 'Set Dynamic Compressor Control',
        0x02: 'Get Dynamic Compressor Control',
      }
    ],
    0x0B: [
      'Adaptive Processing',
      {
        0x03: 'Start Pre-Processor',
        0x04: 'Stop Pre-Processor',
        0x05: 'Read Pre-Processor Buffer',
        0x06: 'Reset Pre-Processor Buffer',
        0x07: 'Start Application',
        0x08: 'Stop Application',
        0x09: 'Get Active Applications',
        0x0A: 'Get Wearing State',
        0x0B: 'Set ANC Profile Parameters (not supported)',
        0x0C: 'Get ANC Profile Parameters (not supported)',
        0x0D: 'Set ANC Profile',
        0x0E: 'Get ANC Profile',
      }
    ],
    0xFE: [
      'Test and Debug',
      {
        0x00: 'Start Tone Generation',
        0x01: 'Stop Tone Generation',
        0x02: 'Start Noise Generation',
        0x03: 'Stop Noise Generation',
        0x04: 'Set Memory',
        0x05: 'Set PLL Frequency',
        0x06: 'Get PLL Frequency',
        0x07: 'Set DC2DC Mode',
        0x08: 'Get DC2DC Mode',
        0x0D: 'Set DC2DC Clock',
        0x0E: 'Get DC2DC Clock',
        0x0F: 'Set FB GM Stage',
        0x10: 'Get FB GM Stage',
        0x12: 'Set ZOH DC Offset',
        0x13: 'Get ZOH DC Offset',
        0x16: 'Disable Parameters Checksum Error Panic',
        0x17: 'Adaptive Pre-Processor Debug Configuration',
        0x18: 'Adaptive Pre-Processor Step',
        0x19: 'Write Pre-Processor Buffer',
        0xFE: 'Get Core Dump',
        0x1A: 'Bypass Filters',
        0x1B: 'Write Parameter Set',
        0x1C: 'Set PBSRC Mode',
        0x1D: 'Get PBSRC Mode',
        0x1E: 'Set Offset and PWM Mode',
        0x1F: 'Get Trace',
        0x20: 'Reset DLC Trace',
        0x21: 'Get Frame Counters',
        0x22: 'Set Calibration States',
        0x25: 'Get Calibration States',
        0x26: 'Set Adaptive Processor Drop Frame Mode',
        0x23: 'Set Modes Constraints Check',
        0x24: 'Fault',
      }
    ]
  }
  
  if (!commands[service]) {
    return '!!! unknown service !!!'
  }

  if (!commands[service][1][opcode]) {
    return `${commands[service][0]} : !!! unknown opcode !!!`
  }

  return `${commands[service][0]} : ${commands[service][1][opcode]}`
}

export function decodeErrorCode(errCode){
  const errCodes={
    0x01: {name: 'EGENERIC', description: 'Generic Error'},
    0x02: {name: 'EFAULT',   description: 'Bad Address'},
    0x03: {name: 'EBUSY',    description: 'Resource or device busy'},
    0x04: {name: 'EINVAL',   description: 'Invalid argument'},
    0x05: {name: 'ENOSYS',   description: 'Invalid service call'},
    0x06: {name: 'ENOENT',   description: 'No such entry'},
    0x07: {name: 'ENODATA',  description: 'No data available'},
    0x08: {name: 'EBADMSG',  description: 'Badly formatted/unexpected message'},
    0x09: {name: 'ENOMEM',   description: 'Not enough memory'},
    0x0A: {name: 'EPERM',    description: 'No permission'},
    0x0B: {name: 'EIO',      description: 'I/O error'},
    0x0C: {name: 'ENOTSUP',  description: 'Not supported or implemented'},
    0x0D: {name: 'EAGAIN',   description: 'Try again later'},
    0x0E: {name: 'ENOPARAM', description: 'No parameter'},
    0x0F: {name: 'EFAPP',    description: 'Framed-Adaptive Pre-Processor Error'},
    0x10: {name: 'EPANIC',   description: 'System Panic'},
  }

  if (!errCodes[errCode]){
    return `🛑 UNKNOWN : Unknown Error Code "${errCode}"`
  }

  return `🛑 ${errCodes[errCode].name} : ${errCodes[errCode].description}`
}

async function sendCommand<T>(ws: WebSocketAsPromised, path: string, cmd: T) {
  //@ts-ignore
  cmd=[0xC2, ...cmd] // prepend FW > 3.11 0xC2 id byte
  
  const logEnabled = true

  logEnabled && console.log(
    `%cDBMC2 EVB sendCommand %c${toHex(cmd[3],2)}/${toHex(cmd[4], 2)}%c [${decodeCommand(
      cmd[3],
      cmd[4]
    )}] %crequest: [${cmd}]%c starting for board ${path}`,
    'color: orangered;',
    'background-color: orangered; color:#222;',
    'color: limegreen;',
    'color: cyan;',
    ''
  )

  const response = await request(ws, {
    Dbmc2: { Device: path, Request: cmd }
  })

  logEnabled && console.log(`%cDBMC2 EVB sendCommand %c${toHex(cmd[3], 2)}/${toHex(cmd[4], 2)}%c completed %cresponse: [${response?.Response}]`,
  'color: orangered;',
  'background-color: orangered; color:#222;',
  'background-color: inherit; color: orangered;',
  'color: cyan;',
  response)
  return response
}

function getResetFromData(data) {
  return (data & 0x10) === 0
}

function getLedFromData(data) {
  return (data & 0x80) === 0
}

function getControlFromData(data) {
  return (data & 0x04) === 0
}

function getMicbiasFromData(data) {
  let state
  if ((data & MICBIAS_CA_MSK) > 0) {
    state = 'channel-ganged' as EVBTypes.Micbias
  } else {
    state = 'function-ganged' as EVBTypes.Micbias
  }
  return state
}

function getFilterFromData(data) {
  return micCompare(data, EXP_FILTER_MSK, EXP_ENABLE_VAL)
}

function getAncMicrophoneFromData(data) {
  let state
  if (micCompare(data, FBM_MEM_SE_MSK, FBM_MEM_SE_VAL)) {
    state = 'mems-se' as EVBTypes.AncMicrophone
  } else if (micCompare(data, FBM_ECM_SE_MSK, FBM_ECM_SE_VAL)) {
    state = 'ecm-se' as EVBTypes.AncMicrophone
  } else if (micCompare(data, FBM_MEM_DF_MSK, FBM_MEM_DF_VAL)) {
    state = 'mems-diff' as EVBTypes.AncMicrophone
  } else {
    console.error(
      'dbmc2.getAncMicrophoneFromData invalid microphone for data',
      data
    )
  }
  return state
}

function getVoiceMicrophoneFromData(data) {
  let state
  if (micCompare(data, VCE_MEM_SE_MSK, VCE_MEM_SE_VAL)) {
    state = 'mems-se' as EVBTypes.VoiceMicrophone
  } else if (micCompare(data, VCE_ECM_SE_MSK, VCE_ECM_SE_VAL)) {
    state = 'ecm-diff' as EVBTypes.VoiceMicrophone
  } else {
    console.error(
      'dbmc2.getVoiceMicrophoneFromData invalid microphone for data',
      data
    )
  }
  return state
}

async function getReset(ws: WebSocketAsPromised, path: string) {
  console.log('DBMC2 EVB getReset starting for board', { path })
  const r = await request(ws, {
    GpioRead: { Device: path }
  })
  const state = getResetFromData(r.Data)
  console.log('DBMC2 EVB getReset ' + state + ' completed')
  return state
}

async function getLed(ws: WebSocketAsPromised, path: string) {
  console.log('DBMC2 EVB getLed starting for board', { path })
  const r = await request(ws, {
    GpioRead: { Device: path }
  })
  const state = getLedFromData(r.Data)
  console.log('DBMC2 EVB getLed ' + state + ' completed')
  return state
}

async function getControl(ws: WebSocketAsPromised, path: string) {
  console.log('DBMC2 EVB getControl starting for board', { path })
  const r = await request(ws, {
    GpioRead: { Device: path }
  })
  const state = getControlFromData(r.Data)
  console.log('DBMC2 EVB getControl ' + state + ' completed')
  return state
}

async function getFilter(ws: WebSocketAsPromised, path: string) {
  console.log('DBMC2 EVB getFilter starting for board', { path })
  const c = await getMicrophoneConfiguration(ws, path)
  const state = getFilterFromData(c)
  console.log('DBMC2 EVB getFilter ' + state + ' completed')
  return state
}

async function getMicbias(ws, path) {
  console.log('DBMC2 EVB getMicbias starting for board', { path })
  const c = await getMicrophoneConfiguration(ws, path)
  const state = getMicbiasFromData(c)
  console.log('DBMC2 EVB getMicbias ' + state + ' completed')
  return state
}

async function getAncMicrophone(ws: WebSocketAsPromised, path: string) {
  console.log('DBMC2 EVB getAncMicrophone starting for board', { path })
  const c = await getMicrophoneConfiguration(ws, path)
  const state = getAncMicrophoneFromData(c)
  console.log('DBMC2 EVB getAncMicrophone ' + state + ' completed')
  return state
}

async function getVoiceMicrophone(ws: WebSocketAsPromised, path: string) {
  console.log('DBMC2 EVB getVoiceMicrophone starting for board', { path })
  const c = await getMicrophoneConfiguration(ws, path)
  const state = getVoiceMicrophoneFromData(c) as 'mems-se' | 'ecm-diff'
  console.log('DBMC2 EVB getVoiceMicrophone ' + state + ' completed')
  return state
}

async function writeFirmware(ws: WebSocketAsPromised, path: string, data) {
  console.log('DBMC2 EVB writeFirmware starting for board', { path })
  await writeEeprom(
    ws,
    path,
    EEPROM_SECTOR_FIRMWARE_F,
    EEPROM_SECTOR_FIRMWARE_L,
    data
  )
  console.log('DBMC2 EVB writeFirmware completed')
}

async function readFirmware(ws: WebSocketAsPromised, path: string) {
  console.log('DBMC2 EVB readFirmware starting for board', { path })
  const c = await readEeprom(
    ws,
    path,
    EEPROM_SECTOR_FIRMWARE_F,
    EEPROM_SECTOR_FIRMWARE_L
  )
  console.log('DBMC2 EVB readFirmware completed')
  return c
}

function padSector(data) {
  return [...data, ...Array(EEPROM_SECTOR_LENGTH - data.length).fill(0xff)]
}

function packParameters(hex, commandData=[]) {
  const len = 4 + hex.length
  const data = [
    (len & 0x00ff) >>> 0,
    (len & 0xff00) >>> 8,
    0x00,
    0x17,
    ...hex,
    ...commandData,
    0xff,
    0xff
  ]
  
  return padSector(data)
}

async function writeParameters(
  ws,
  path,
  setup,
  playback,
  voicepickup,
  feedback,
  feedforward,
  talkthough,
  calibration
) {
  console.log('DBMC2 EVB writeParameters starting for board', { path })

  const data = [
    ...packParameters(setup),
    ...packParameters(playback, [0x06, 0x00, 0x03, 0x0A, 0x00, 0x00]),
    ...packParameters(voicepickup, [0x06, 0x00, 0x03, 0x0A, 0x01, 0x00]),
    ...packParameters(feedback, [0x06, 0x00, 0x03, 0x0A, 0x03, 0x00]),
    ...packParameters(feedforward, [0x06, 0x00, 0x03, 0x0A, 0x04, 0x00]),
    ...packParameters(talkthough, [0x06, 0x00, 0x03, 0x0A, 0x05, 0x00]),
    ...padSector([]),
    ...padSector([]),
    ...padSector([]),
    ...packParameters(calibration)
  ]
  await writeEeprom(
    ws,
    path,
    EEPROM_SECTOR_SETUP,
    EEPROM_SECTOR_CALIBRATION,
    data
  )
  console.log('DBMC2 EVB writeParameters completed')
}

async function writeSetup(ws, path, hex) {
  console.log('DBMC2 EVB writeSetup starting for board', { path })
  const len = 4 + hex.length
  const data = [
    (len & 0x00ff) >>> 0,
    (len & 0xff00) >>> 8,
    0x00,
    0x17,
    ...hex,
    0xff,
    0xff
  ]
  await writeEeprom(ws, path, EEPROM_SECTOR_SETUP, EEPROM_SECTOR_SETUP, data)
  console.log('DBMC2 EVB writeSetup completed')
}

async function readSetup(ws, path) {
  console.log('DBMC2 EVB readSetup starting for board', { path })
  const c = await readEeprom(ws, path, EEPROM_SECTOR_SETUP, EEPROM_SECTOR_SETUP)
  console.log('DBMC2 EVB readSetup completed')
  return c
}

async function eraseSetup(ws, path) {
  console.log('DBMC2 EVB eraseSetup starting for board', { path })
  const c = await writeEeprom(
    ws,
    path,
    EEPROM_SECTOR_SETUP,
    EEPROM_SECTOR_SETUP,
    [0xff]
  )
  console.log('DBMC2 EVB eraseSetup completed')
  return c
}

async function writePlayback(ws, path, hex) {
  console.log('DBMC2 EVB writePlayback starting for board', { path })
  const len = 4 + hex.length
  const data = [
    (len & 0x00ff) >>> 0,
    (len & 0xff00) >>> 8,
    0x00,
    0x17,
    ...hex,
    0x06, 0x00, 0x03, 0x0A, 0x00, 0x00,
    0xff,
    0xff
  ]
  await writeEeprom(
    ws,
    path,
    EEPROM_SECTOR_PLAYBACK,
    EEPROM_SECTOR_PLAYBACK,
    data
  )
  console.log('DBMC2 EVB writePlayback completed')
}

async function readPlayback(ws, path) {
  console.log('DBMC2 EVB readPlayback starting for board', { path })
  const c = await readEeprom(
    ws,
    path,
    EEPROM_SECTOR_PLAYBACK,
    EEPROM_SECTOR_PLAYBACK
  )
  console.log('DBMC2 EVB readPlayback completed')
  return c
}

async function erasePlayback(ws, path) {
  console.log('DBMC2 EVB erasePlayback starting for board', { path })
  const c = await writeEeprom(
    ws,
    path,
    EEPROM_SECTOR_PLAYBACK,
    EEPROM_SECTOR_PLAYBACK,
    [0xff]
  )
  console.log('DBMC2 EVB erasePlayback completed')
  return c
}

async function writeVoicePickup(ws, path, hex) {
  console.log('DBMC2 EVB writeVoicePickup starting for board', { path })
  const len = 4 + hex.length
  const data = [
    (len & 0x00ff) >>> 0,
    (len & 0xff00) >>> 8,
    0x00,
    0x17,
    ...hex,
    0x06, 0x00, 0x03, 0x0A, 0x01, 0x00,
    0xff,
    0xff
  ]
  await writeEeprom(
    ws,
    path,
    EEPROM_SECTOR_VOICEPICKUP,
    EEPROM_SECTOR_VOICEPICKUP,
    data
  )
  console.log('DBMC2 EVB writeVoicePickup completed')
}

async function readVoicePickup(ws, path) {
  console.log('DBMC2 EVB readVoicePickup starting for board', { path })
  const c = await readEeprom(
    ws,
    path,
    EEPROM_SECTOR_VOICEPICKUP,
    EEPROM_SECTOR_VOICEPICKUP
  )
  console.log('DBMC2 EVB readVoicePickup completed')
  return c
}

async function eraseVoicePickup(ws, path) {
  console.log('DBMC2 EVB eraseVoicePickup starting for board', { path })
  const c = await writeEeprom(
    ws,
    path,
    EEPROM_SECTOR_VOICEPICKUP,
    EEPROM_SECTOR_VOICEPICKUP,
    [0xff]
  )
  console.log('DBMC2 EVB eraseVoicePickup completed')
  return c
}

async function writeFeedback(ws, path, hex) {
  console.log('DBMC2 EVB writeFeedback starting for board', { path })
  const len = 4 + hex.length
  const data = [
    (len & 0x00ff) >>> 0,
    (len & 0xff00) >>> 8,
    0x00,
    0x17,
    ...hex,
    0x06, 0x00, 0x03, 0x0A, 0x03, 0x00,
    0xff,
    0xff
  ]
  await writeEeprom(
    ws,
    path,
    EEPROM_SECTOR_FEEDBACK,
    EEPROM_SECTOR_FEEDBACK,
    data
  )
  console.log('DBMC2 EVB writeFeedback completed')
}

async function readFeedback(ws, path) {
  console.log('DBMC2 EVB readFeedback starting for board', { path })
  const c = await readEeprom(
    ws,
    path,
    EEPROM_SECTOR_FEEDBACK,
    EEPROM_SECTOR_FEEDBACK
  )
  console.log('DBMC2 EVB readFeedback completed')
  return c
}

async function eraseFeedback(ws, path) {
  console.log('DBMC2 EVB eraseFeedback starting for board', { path })
  const c = await writeEeprom(
    ws,
    path,
    EEPROM_SECTOR_FEEDBACK,
    EEPROM_SECTOR_FEEDBACK,
    [0xff]
  )
  console.log('DBMC2 EVB eraseFeedback completed')
  return c
}

async function writeFeedforward(ws, path, hex) {
  console.log('DBMC2 EVB writeFeedforward starting for board', { path })
  const len = 4 + hex.length
  const data = [
    (len & 0x00ff) >>> 0,
    (len & 0xff00) >>> 8,
    0x00,
    0x17,
    ...hex,
    0x06, 0x00, 0x03, 0x0A, 0x04, 0x00,
    0xff,
    0xff
  ]
  await writeEeprom(
    ws,
    path,
    EEPROM_SECTOR_FEEDFORWARD,
    EEPROM_SECTOR_FEEDFORWARD,
    data
  )
  console.log('DBMC2 EVB writeFeedforward completed')
}

async function readFeedforward(ws, path) {
  console.log('DBMC2 EVB readFeedforward starting for board', { path })
  const c = await readEeprom(
    ws,
    path,
    EEPROM_SECTOR_FEEDFORWARD,
    EEPROM_SECTOR_FEEDFORWARD
  )
  console.log('DBMC2 EVB readFeedforward completed')
  return c
}

async function eraseFeedforward(ws, path) {
  console.log('DBMC2 EVB eraseFeedforward starting for board', { path })
  const c = await writeEeprom(
    ws,
    path,
    EEPROM_SECTOR_FEEDFORWARD,
    EEPROM_SECTOR_FEEDFORWARD,
    [0xff]
  )
  console.log('DBMC2 EVB eraseFeedforward completed')
  return c
}

async function writeTalkthrough(ws, path, hex) {
  console.log('DBMC2 EVB writeTalkthrough starting for board', { path })
  const len = 4 + hex.length
  const data = [
    (len & 0x00ff) >>> 0,
    (len & 0xff00) >>> 8,
    0x00,
    0x17,
    ...hex,
    0x06, 0x00, 0x03, 0x0A, 0x05, 0x00,
    0xff,
    0xff
  ]
  await writeEeprom(
    ws,
    path,
    EEPROM_SECTOR_TALKTHROUGH,
    EEPROM_SECTOR_TALKTHROUGH,
    data
  )
  console.log('DBMC2 EVB writeTalkthrough completed')
}

async function readTalkthrough(ws, path) {
  console.log('DBMC2 EVB readTalkthrough starting for board', { path })
  const c = await readEeprom(
    ws,
    path,
    EEPROM_SECTOR_TALKTHROUGH,
    EEPROM_SECTOR_TALKTHROUGH
  )
  console.log('DBMC2 EVB readTalkthrough completed')
  return c
}

async function eraseTalkthrough(ws, path) {
  console.log('DBMC2 EVB eraseTalkthrough starting for board', { path })
  const c = await writeEeprom(
    ws,
    path,
    EEPROM_SECTOR_TALKTHROUGH,
    EEPROM_SECTOR_TALKTHROUGH,
    [0xff]
  )
  console.log('DBMC2 EVB eraseTalkthrough completed')
  return c
}

async function writeCalibration(ws, path, hex) {
  console.log('DBMC2 EVB writeCalibration starting for board', { path })
  const len = 4 + hex.length
  const data = [
    (len & 0x00ff) >>> 0,
    (len & 0xff00) >>> 8,
    0x00,
    0x17,
    ...hex,
    0xff,
    0xff
  ]
  await writeEeprom(
    ws,
    path,
    EEPROM_SECTOR_CALIBRATION,
    EEPROM_SECTOR_CALIBRATION,
    data
  )
  console.log('DBMC2 EVB writeCalibration completed')
}

async function readCalibration(ws, path) {
  console.log('DBMC2 EVB readCalibration starting for board', { path })
  const c = await readEeprom(
    ws,
    path,
    EEPROM_SECTOR_CALIBRATION,
    EEPROM_SECTOR_CALIBRATION
  )
  console.log('DBMC2 EVB readCalibration completed')
  return c
}

async function eraseCalibration(ws, path) {
  console.log('DBMC2 EVB eraseCalibration starting for board', { path })
  const c = await writeEeprom(
    ws,
    path,
    EEPROM_SECTOR_CALIBRATION,
    EEPROM_SECTOR_CALIBRATION,
    [0xff]
  )
  console.log('DBMC2 EVB eraseCalibration completed')
  return c
}

export default {
  create,
  initialize,
  setControl,
  setControlOn,
  setControlOff,
  setReset,
  setResetOn,
  setResetOff,
  setLed,
  setLedOn,
  setLedOff,
  setFilter,
  setFilterOn,
  setFilterOff,
  setMicbiasConfiguration,
  setAncMicrophoneConfiguration,
  setVoiceMicrophoneConfiguration,
  sendCommand,
  getReset,
  getLed,
  getControl,
  getFilter,
  getMicbias,
  getAncMicrophone,
  getVoiceMicrophone,
  writeFirmware,
  readFirmware,
  writeParameters,
  writeSetup,
  readSetup,
  eraseSetup,
  writeVoicePickup,
  readVoicePickup,
  eraseVoicePickup,
  writePlayback,
  readPlayback,
  erasePlayback,
  writeFeedback,
  readFeedback,
  eraseFeedback,
  writeFeedforward,
  readFeedforward,
  eraseFeedforward,
  writeTalkthrough,
  readTalkthrough,
  eraseTalkthrough,
  writeCalibration,
  readCalibration,
  eraseCalibration
}

export const modeToString = (modeIndex: number) => {
  switch (modeIndex) {
    case 0:
      return 'Setup'
    case 1:
      return 'Setup Oscillator'
    case 2:
      return 'Active'
    case 3:
      return 'Low Power'
    case 4:
      return 'Hibernation'
    case 5:
      return 'Production Test'
    case 6:
      return 'Laboratory'
    case 7:
      return 'Panic'
    default:
      return 'Unknown'
  }
}
