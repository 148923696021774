export function bitmask(width: number) {
  return Math.pow(2, width) - 1
}

export function getRegisterField(register: number, bitmin: number, bitmax: number) {
  const bitmaskShifted = bitmask(bitmax - bitmin + 1) << bitmin
  const valueShifted = register & bitmaskShifted
  const value = valueShifted >>> bitmin
  return value
}

export function updateRegister(oldValue: number, bitmin: number, bitmax: number, value: number) {
  console.log(toBin(oldValue))
  const bitmaskShifted = bitmask(bitmax - bitmin + 1) << bitmin
  const valueShifted = value << bitmin
  const newValue =  (oldValue & ~bitmaskShifted) | (valueShifted & bitmaskShifted)
  console.log(toBin(newValue))
  return newValue
}


export function convertString(str: string) {
  const r = [2 * str.length + 2, 0x03]
  for (let i = 0; i < str.length; ++i) {
    r.push(str.charCodeAt(i))
    r.push(0)
  }
  for (let i = 2 * str.length; i < 60; ++i) {
    r.push(0)
  }
  return r
}

export function pad(n: number, width: number, z = '0') {
  const newN = String(n)
  return newN.length >= width
    ? newN
    : new Array(width - newN.length + 1).join(z) + newN
}

export function convertType(major: number, minor: number) {
  return pad(major, 3) + pad(minor, 3)
}

export function createUuid() {
  let dt = new Date().getTime()
  const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    const r = (dt + Math.random() * 16) % 16 | 0
    dt = Math.floor(dt / 16)
    return (String(c) === 'x' ? r : (r & 0x3) | 0x8).toString(16)
  })
  return uuid
}

export function uuidToBase64(uuid: string) {
  return btoa(
    String.fromCharCode.apply(
      null,
      uuid
        .replace(/[-]+/g, '')
        .replace(/([\da-fA-F]{2}) ?/g, '0x$1 ')
        .replace(/ +$/, '')
        .split(' ')
    )
  )
}

export function toHex(n: number, digits?: number) {
  if (digits === undefined) {
    return '0x' + n.toString(16)
  } else {
    return '0x' + ('0'.repeat(digits) + n.toString(16)).substr(-digits)
  }
}

export function toBin(n: number, digits: number = 32) {
  return '0b' + ('0'.repeat(digits) + (n >>> 0).toString(2)).substr(-digits)
}

export function dBRange(min: number, max: number, inc: number) {
  const options = []
  for (let v = min; v <= max; v += inc) {
    options.push(v + ' dB')
  }
  return options
}

export function getHexDigits(bitmin: number, bitmax: number) {
  const width = bitmax - bitmin + 1
  return Math.ceil(width / 4)
}
