import { useEffect, useRef } from 'react'
import { Nullable } from '../types/misc'
import { deepEqual } from '../utils/common'

export default function useDeepEffect<T>(fn: Function, deps: T[]) {
  const prevDeps = useRef<Nullable<typeof deps>>(deps)
  const cleanUp = useRef<Nullable<Function>>(null)

  useEffect(() => {
    if (!deepEqual(prevDeps.current, deps)) {
      prevDeps.current = deps
      cleanUp.current = fn()

      return () => {
        if (typeof cleanUp?.current === 'function') cleanUp.current()
      }
    }
  }, [fn, deps])
}
