const voicepickupFields = {
  rate: {
    channel: {
      DDF_CFG1: {
        prop: 'cfg1',
        mask:0xFFFFFFFF,
        fields: [
          {
            name: 'SYNC_MODE',
            bitmin: 28,
            bitmax: 28,
            description: 'DDF synchronization mode',
            options: [
              'do not synchronize DDF to DDF frame', 
              'synchronize DDF to DDF frame'
            ]
          },
          {
            name: 'DDF_ENABLE',
            bitmin: 27,
            bitmax: 27,
            description: 'DDF synchronization mode',
            options: ['disabled', 'enabled']
          },
          {
            name: 'INPUT_FRMT',
            bitmin: 26,
            bitmax: 26,
            description: 'DDF input format',
            options: [
              'input is 17 level binary offset', 
              'input is 2 level PDM (not supported in DBMC2)'
            ]
          },
          {
            name: 'CICI_BP',
            bitmin: 25,
            bitmax: 25,
            description: 'Bypass CICI sub-module',
            options: ['no bypass', 'bypass']
          },
          {
            name: 'CICD_BP',
            bitmin: 24,
            bitmax: 24,
            description: 'Bypass CICD sub-module',
            options: ['no bypass', 'bypass']
          },
          {
            name: 'CIC_ORDER',
            bitmin: 20,
            bitmax: 22,
            description: 'CIC filter order',
            options: []
          },
          {
            name: 'CDF',
            bitmin: 8,
            bitmax: 16,
            description: 'CIC decimation factor',
            options: []
          },
          {
            name: 'ROUND_MODE',
            bitmin: 6,
            bitmax: 6,
            description: 'Rounding mode of DDF output',
            options: [
              'perform rounding', 
              'perform truncate'
            ]
          },
          {
            name: 'OUT_16_24',
            bitmin: 5,
            bitmax: 5,
            description: 'Define the number of output bits',
            options: ['24 bit', '16 bit']
          }
        ]
      },
      DDF_CFG2: {
        prop: 'cfg2',
        mask:0xFFFFFFFF,
        fields: [
          {
            name: 'PRE_IIR_SHIFT',
            bitmin: 24,
            bitmax: 28,
            description: 'The number of bits to left shift in pre IIR submodule',
            options: []
          },
          {
            name: 'PRE_IIR_G1',
            bitmin: 12,
            bitmax: 20,
            description: 'Gain factor of pre IIR sub-module',
            options: []
          },
          {
            name: 'POST_IIR_SHIFT',
            bitmin: 9,
            bitmax: 11,
            description: 'The number of bits to left or right shift in post IIR sub-module',
            options: []
          },
          {
            name: 'POST_IIR_G1',
            bitmin: 0,
            bitmax: 8,
            description: 'Gain factor of post IIR sub-module',
            options: []
          }
        ]
      },
      DDF_CFG3: {
        prop: 'cfg3',
        mask:0xFFFFFFFF,
        fields: [
          {
            name: 'SIIR1',
            bitmin: 20,
            bitmax: 23,
            description: 'Number of Bi-quad stages before M1 decimation',
            options: []
          },
          {
            name: 'SIIR2',
            bitmin: 20,
            bitmax: 23,
            description: 'Number of Bi-quad stages after M1 decimation',
            options: []
          },
          {
            name: 'IDF1',
            bitmin: 8,
            bitmax: 12,
            description: 'IIR decimation factor 1',
            options: []
          },
          {
            name: 'IDF2',
            bitmin: 0,
            bitmax: 4,
            description: 'IIR decimation factor 2',
            options: []
          }
        ]
      },
      stage: {
        BQ_B0: {
          prop: 'b0',
          mask:0xFFFFFFFF,
          fields: [
            {
              name: 'BQ_B0',
              bitmin: 0,
              bitmax: 31,
              description: 'b0 Biquad coefficient',
              options: []
            }
          ]
        },
        BQ_B1: {
          prop: 'b1',
          mask:0xFFFFFFFF,
          fields: [
            {
              name: 'BQ_B1',
              bitmin: 0,
              bitmax: 31,
              description: 'b1 Biquad coefficient',
              options: []
            }
          ]
        },
        BQ_B2: {
          prop: 'b2',
          mask:0xFFFFFFFF,
          fields: [
            {
              name: 'BQ_B2',
              bitmin: 0,
              bitmax: 31,
              description: 'b2 Biquad coefficient',
              options: []
            }
          ]
        },
        BQ_A1: {
          prop: 'a1',
          mask:0xFFFFFFFF,
          fields: [
            {
              name: 'BQ_A1',
              bitmin: 0,
              bitmax: 31,
              description: 'a1 Biquad coefficient',
              options: []
            }
          ]
        },
        BQ_A2: {
          prop: 'a2',
          mask:0xFFFFFFFF,
          fields: [
            {
              name: 'BQ_A2',
              bitmin: 0,
              bitmax: 31,
              description: 'a2 Biquad coefficient',
              options: []
            }
          ]
        }
      }
    }
  }
}

export default voicepickupFields
