import styled from '@emotion/styled/macro'

const Status = styled.p<{ error?: boolean }>`
  padding: 0;
  margin-top: 24px;
  line-height: 20px;
  margin-bottom: 24px;
  font-size: 14px;
  font-weight: bold;
  color: ${({ error }) => (error ? '#ec1b23' : '#666666')};
`

export default Status
