import React from 'react'
import OriginalSelect from 'react-select'

export default function Select({
  styles,
  ...props
}: React.ComponentProps<typeof OriginalSelect>) {
  return (
    <OriginalSelect
      {...props}
      menuPortalTarget={document.body}
      styles={{
        control: provided => ({
          ...provided,
          borderWidth: 0,
          padding: 4
        }),
        ...styles
      }}
    />
  )
}
