import { format as dateFormat, formatDistanceToNow } from 'date-fns'

export const timestampToLocalDate = (timestamp: string) =>
  formatDate(convertTimestampToDate(timestamp))

export const intervalToNow = (timestamp: string) =>
  formatDistanceToNow(convertTimestampToDate(timestamp))

export const convertTimestampToDate = (timestamp: string) => {
  if (timestamp) {
    try {
      const matches = timestamp.match(
        /(\d{4})-([01]\d)-([0-3]\d)T([0-2]\d):([0-5]\d):([0-5]\d)/
      )
      if (matches.length !== 7) throw new Error('')

      const year = parseInt(matches[1], 10)
      const month = parseInt(matches[2], 10) - 1
      const day = parseInt(matches[3], 10)
      const hours = parseInt(matches[4], 10)
      const minutes = parseInt(matches[5], 10)
      const seconds = parseInt(matches[6], 10)

      return new Date(Date.UTC(year, month, day, hours, minutes, seconds))
    } catch (error) {
      console.error('Error formatting timestamp', timestamp)
    }
  }
  return new Date()
}

export const interval = (timestampFrom: string, timestampTo: string) => {
  return (
    +convertTimestampToDate(timestampTo) -
    +convertTimestampToDate(timestampFrom)
  )
}

export const formatDate = date => dateFormat(date, 'd MMM yyyy HH:mm:ss OOOO')

export const UTCNow = () => Math.floor(new Date().getTime() / 1000.0)

const nextDate = date =>
  new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1)

const satSunOrFri = date =>
  date.getDay() === 0 || date.getDay() === 5 || date.getDay() === 6

/*
  Get the first day within year given as parameter that belongs to first week
  of the year. First week of the year is the week that has at least Mon, Tue,
  Wed, and Thu on January's side, e.g. If 31 Dec is Wednesday, week number 1
  starts 29 Dec, this function will return 1.1 of the year.
 */
function firstWeekDateOfYear(year) {
  let date = 1
  let firstWeekDate = new Date(year, 0, date, 1, 1, 1)
  // SUN 0
  // MON 1
  // TUE 2
  // WED 3
  // THU 4
  // FRI 5
  // SAT 6
  // If the day is SUN, FRI, or SAT, increment until it isn't
  while (satSunOrFri(firstWeekDate)) firstWeekDate = new Date(year, 0, ++date)
  return firstWeekDate
}

/*
  Calculate which weeknumber is on which month on a give year,
  and returns maps from month to week and from week to month.
 */
export function getMappings(year) {
  // Array with 12 months, each containing 4 or 5 weeks?
  const weekToMonth = {}
  const monthToWeeks = {}
  let dayOfWeek = firstWeekDateOfYear(year)
  // Add the first week unless it is monday
  let currentWeek = 1
  if (dayOfWeek.getDay() !== 1) {
    if (monthToWeeks[dayOfWeek.getMonth()])
      monthToWeeks[dayOfWeek.getMonth()].push(currentWeek)
    else monthToWeeks[dayOfWeek.getMonth()] = [currentWeek]
    weekToMonth[currentWeek] = dayOfWeek.getMonth()
    currentWeek++
  }
  while (true) {
    if (dayOfWeek.getDay() === 1) {
      if (dayOfWeek.getFullYear() !== year) break
      if (monthToWeeks[dayOfWeek.getMonth()])
        monthToWeeks[dayOfWeek.getMonth()].push(currentWeek)
      else monthToWeeks[dayOfWeek.getMonth()] = [currentWeek]
      weekToMonth[currentWeek] = dayOfWeek.getMonth()
      currentWeek++
    }
    dayOfWeek = nextDate(dayOfWeek)
  }
  return {
    weekToMonth,
    monthToWeeks
  }
}
