import { AxiosResponse } from 'axios'
import { useEffect, useState } from 'react'
import uuid from 'uuid'
import { interpretError } from '../utils/common'
import { emptyArray } from '../utils/memoizer'
import useCounter from './use-counter'
import useToggle from './use-toggle'

export default function useAsync<T, U>({
  fn,
  args = emptyArray
}: {
  fn: (...args: U[]) => Promise<AxiosResponse<T>>
  args?: U[]
}) {
  const [data, setData] = useState({} as T)
  const [loading, startLoading, finishLoading] = useToggle(true)
  const [error, setError] = useState(null)
  const [trigger, refresh] = useCounter()
  const [initialized, initialize] = useToggle(false)
  const [id, setId] = useState(uuid())

  const refreshData = async () => {
    refresh()

    return await new Promise((resolve, reject) => {
      if (!loading) resolve()
      if (error) reject()
    })
  }

  useEffect(() => {
    let valid = true

    const doAsyncOperation = async () => {
      setId(uuid())
      startLoading()
      try {
        const { data: responseData }: { data: T } = await fn(...args)

        if (valid) {
          setData(responseData)
          initialize()
          setError('')
        }
      } catch (err) {
        initialize()
        setError(interpretError(err))
      }
      finishLoading()
    }

    doAsyncOperation()

    return () => {
      valid = false
    }
  }, [args, trigger, startLoading, finishLoading, fn, initialize])

  return { data, loading, error, setData, refreshData, initialized, id }
}
