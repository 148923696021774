import { useObserver } from 'mobx-react-lite'
import React, { useContext } from 'react'
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  RouteComponentProps,
  RouteProps,
  Switch
} from 'react-router-dom'
import Layout from './containers/layout'
import ProductionStore from './context/production-store'
import { RootStoreContext } from './context/root-store'
import ForgotPassword from './pages/auth/forgot-password.page'
import Login from './pages/auth/login.page'
import ResetPassword from './pages/auth/reset-password.page'
import ProductTestResults from './pages/control/product-results.page'
import Product from './pages/control/product/product.page'
import Products from './pages/control/products/products.page'
import Station from './pages/control/station/station'
import Stations from './pages/control/stations.page'
import Design from './pages/engineer/design/design.page'
import Home from './pages/home.page'
import Logout from './pages/logout'
import NotFound from './pages/not-found.page'
import SetupStations from './pages/production/setup-stations/setup-stations.page'
import StationTest from './pages/production/station-test/test.page'
import FqdnResult from './pages/production/test-results/fqdn-result'
import About from './pages/support/about.page'
import Users from './pages/support/users/users.page'

type UserType =
  | 'authenticated'
  | 'unauthenticated'
  | 'generic'
  | 'administrator'

const GuardedRoute = ({
  userType = 'generic',
  ...props
}: RouteProps & { userType?: UserType }) => {
  const {
    authentication: {
      isLoggedIn,
      user: { administrator }
    }
  } = useContext(RootStoreContext)

  const Page = <Route {...props} />

  const componentToRender = () => {
    switch (userType) {
      case 'generic':
        return Page
      case 'authenticated':
        return isLoggedIn ? Page : <NotFound />
      case 'unauthenticated':
        return !isLoggedIn ? Page : <NotFound />
      case 'administrator':
        return administrator ? Page : <NotFound />
      default:
        return <NotFound />
    }
  }

  const shouldRouteRenderFooter = () => userType !== 'unauthenticated'

  return useObserver(() => (
    <Layout hasFooter={shouldRouteRenderFooter()}>{componentToRender()}</Layout>
  ))
}

function Routes() {
  const {
    authentication: { isLoggedIn }
  } = useContext(RootStoreContext)

  return (
    <Router>
      <Switch>
        <GuardedRoute exact path="/support/about" component={About} />
        <GuardedRoute
          userType={'authenticated'}
          path="/support/users"
          component={Users}
        />
        <GuardedRoute
          userType={'authenticated'}
          exact
          path="/missioncontrol/stations"
          component={Stations}
        />
        <GuardedRoute
          exact
          path="/missioncontrol/station/:stationName"
          render={function StationRoute({
            match: { params }
          }: RouteComponentProps<{ stationName: string }>) {
            return <Station {...params} section={false} />
          }}
        />
        <GuardedRoute
          userType={'authenticated'}
          exact
          path="/missioncontrol/products"
          component={Products}
        />
        <GuardedRoute
          exact
          userType={'authenticated'}
          path="/missioncontrol/product/:id"
          component={Product}
        />
        <GuardedRoute
          path="/missioncontrol/product/:productId/result/:resultUuid/:testId?"
          userType={'authenticated'}
          component={ProductTestResults}
        />
        <GuardedRoute
          exact
          path="/production/setup"
          render={function SetupStationsRoute(props) {
            return (
              <ProductionStore>
                <SetupStations {...props} />
              </ProductionStore>
            )
          }}
        />
        <GuardedRoute
          exact
          path="/production/test"
          render={function StationTestRoute() {
            return (
              <ProductionStore>
                <StationTest />
              </ProductionStore>
            )
          }}
        />
        <GuardedRoute
          exact
          path="/production/:fqdn/test"
          render={function StationTestRoute({
            match: { params }
          }: RouteComponentProps<{ fqdn: string }>) {
            return (
              <ProductionStore>
                <StationTest {...params} />
              </ProductionStore>
            )
          }}
        />
        <GuardedRoute
          exact
          path="/production/:fqdn/test/result"
          render={function FqdnResultRoute({
            match: { params }
          }: RouteComponentProps<{ fqdn: string }>) {
            return (
              <ProductionStore>
                <FqdnResult {...params} />
              </ProductionStore>
            )
          }}
        />
        {isLoggedIn && <Redirect from={'/login'} to={'/'} />}
        <GuardedRoute
          userType={'unauthenticated'}
          exact
          path="/login"
          component={Login}
        />
        <GuardedRoute
          userType={'generic'}
          exact
          path="/forgot-password"
          component={ForgotPassword}
        />
        <GuardedRoute
          userType={'unauthenticated'}
          exact
          path="/authentication/reset"
          component={ResetPassword}
        />
        <GuardedRoute
          userType={'authenticated'}
          path="/engineer/design"
          component={Design}
        />
        <GuardedRoute exact path="/logout" component={Logout} />
        <GuardedRoute exact path="/support/about" component={About} />
        <GuardedRoute exact path="/" component={Home} />
        <GuardedRoute path="**" component={NotFound} />
      </Switch>
    </Router>
  )
}

export default Routes
