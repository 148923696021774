import { Chart, Results, Stage } from '../../../types/test-result'
import {
  baseMeta,
  FullPlotlyMeta,
  parseColor,
  Color
} from '../../../utils/plotly'

export function createResultChart(
  chart: Chart,
  data: Results['Data'],
  bounds: Stage['Bounds']['Response']
) {
  const plotChart: Partial<FullPlotlyMeta> = {
    data: [],
    configuration: baseMeta.configuration,
    layout: {
      ...baseMeta.layout,
      title: chart.Label,
      xaxis: {
        ...baseMeta.layout.xaxis,
        range: [
          Math.log10(chart.XAxis.Minimum),
          Math.log10(chart.XAxis.Maximum)
        ],
        title: chart.XAxis.Label
      },
      yaxis: {
        ...baseMeta.layout.yaxis,
        range: [chart.YAxis.Minimum, chart.YAxis.Maximum],
        title: chart.YAxis.Label
      }
    }
  }

  chart.Bounds.forEach(cb => {
    const b = bounds.find(b => cb.Name === b.Name)
    const bSegments = [].concat(b.Lower || [], b.Upper || [])
    bSegments.forEach(bs => {
      const xyp = bs.reduce(
        (c, p) => {
          c.x.push(p[0])
          c.y.push(p[1])
          return c
        },
        { x: [], y: [] }
      )

      plotChart.data.push({
        line: {
          color: parseColor(cb.Color as Color),
          width: 2
        },
        mode: 'lines',
        showlegend: false,
        name: 'Bounds',
        x: xyp.x,
        y: xyp.y
      })
    })
  })

  chart.Series.forEach(s => {
    if (data[s.XAxis] && data[s.YAxis]) {
      plotChart.data.push({
        line: {
          width: 2,
          color: s.Color
        },
        mode: 'lines',
        showlegend: true,
        name: s.Label,
        x: data[s.XAxis],
        y: data[s.YAxis]
      })
    }
  })

  return plotChart
}
