import styled from '@emotion/styled/macro'
import React from 'react'

export default function TopRightIcon({
  children,
  ...props
}: React.ComponentProps<typeof Container>) {
  return <Container {...props}>{children}</Container>
}

const Container = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;
`
