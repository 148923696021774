import { dBRange } from '../../utils/binary'

const setupFields = {
  common: {
    INAMP23_CFG: {
      prop: 'afe_inamp23_cfg',
      mask:0x001F001F,
      fields: [
        {
          name: 'INAMP_3_EN',
          bitmin: 28,
          bitmax: 28,
          description: 'VP Right enable',
          options: ['disabled', 'enabled']
        },
        {
          name: 'INAMP_3_DISCHARGE_INPUT_N',
          bitmin: 27,
          bitmax: 27,
          description: 'VP Right discharge inputs',
          options: ['inputs are discharged', 'inputs are not discharged']
        },
        {
          name: 'INAMP_3_INC_CURR',
          bitmin: 26,
          bitmax: 26,
          description: 'VP Right increment 2nd stage current',
          options: ['do not increment', 'increment 2nd stage current']
        },
        {
          name: 'INAMP_3_ICTRL',
          bitmin: 24,
          bitmax: 25,
          description: 'VP Right current reference',
          options: [
            'current reference is 50%', 
            'current reference is 60%', 
            'current reference is 75%', 
            'current reference is 100%'
          ]
        },
        {
          name: 'Voice Pickup Right Input Gain',//'INAMP_3_GAIN',
          bitmin: 16,
          bitmax: 20,
          description: 'VP Right gain',
          options: dBRange(-6, 25, 1)
        },
        {
          name: 'INAMP_2_EN',
          bitmin: 12,
          bitmax: 12,
          description: 'VP Left enable',
          options: ['disabled', 'enabled']
        },
        {
          name: 'INAMP_2_DISCHARGE_INPUT_N',
          bitmin: 11,
          bitmax: 11,
          description: 'VP Left discharge inputs',
          options: ['inputs are discharged', 'inputs are not discharged']
        },
        {
          name: 'INAMP_2_INC_CURR',
          bitmin: 10,
          bitmax: 10,
          description: 'VP Left increment 2nd stage current',
          options: ['do not increment', 'increment 2nd stage current']
        },
        {
          name: 'INAMP_2_ICTRL',
          bitmin: 8,
          bitmax: 9,
          description: 'VP Left current reference',
          options: [
            'current reference is 50%', 
            'current reference is 60%', 
            'current reference is 75%', 
            'current reference is 100%', 
          ]
        },
        {
          name: 'Voice Pickup Left Input Gain',//'INAMP_2_GAIN',
          bitmin: 0,
          bitmax: 4,
          description: 'VP Left gain',
          options: dBRange(-6, 25, 1)
        }
      ]
    },
    FB_CFG0: {
      prop: 'afe_fb_cfg0',
      mask:0x00000070,
      fields: [
        {
          name: 'FB_GM_CM_TRIM',
          bitmin: 20,
          bitmax: 23,
          description: '',
          options: []
        },
        {
          name: 'FB_VCOM_TRIM',
          bitmin: 16,
          bitmax: 19,
          description: '',
          options: []
        },
        {
          name: 'FB_POLARITY',
          bitmin: 15,
          bitmax: 15,
          description: '',
          options: ['negative', 'positive']
        },
        {
          name: 'FB_GM_CURR_CTRL',
          bitmin: 10,
          bitmax: 11,
          description: '',
          options: []
        },
        {
          name: 'FB_EXT_PGA',
          bitmin: 8,
          bitmax: 9,
          description: '',
          options: []
        },
        {
          name: 'FB_FBMICS2ADCS',
          bitmin: 7,
          bitmax: 7,
          description: '',
          options: ['disabled', 'enabled']
        },
        {
          name: 'Feedback Input PGA Gain',//'FB_MIC_PGA',
          bitmin: 4,
          bitmax: 6,
          description: '',
          options: [
            {label:'0 dB', value:0/3},
            {label:'3 dB', value:3/3},
            {label:'6 dB', value:6/3},
            {label:'9 dB', value:9/3},
            {label:'15 dB', value:15/3}
          ]
        },
        {
          name: 'FB_EXT2NOTCH2_SEL',
          bitmin: 3,
          bitmax: 3,
          description: '',
          options: ['disabled', 'enabled']
        },
        {
          name: 'FB_EXT2PEAK_SEL',
          bitmin: 2,
          bitmax: 2,
          description: '',
          options: ['disabled', 'enabled']
        },
        {
          name: 'FB_GM_2STG_CURR',
          bitmin: 1,
          bitmax: 1,
          description: '',
          options: ['disabled', 'enabled']
        },
        {
          name: 'FM_GM_DISCONNECT',
          bitmin: 0,
          bitmax: 0,
          description: '',
          options: ['disabled', 'enabled']
        }
      ]
    },
    DAC_CFG: {
      prop: 'afe_dac_cfg',
      mask:0x07000000,
      fields: [
        {
          name: 'DAC_ICTRL',
          bitmin: 24,
          bitmax: 26,
          description: 'Trim DAC current',
          options: [
            '62%', 
            '75%', 
            '87%', 
            '100%',  
            '112%',  
            '125%',  
            '137%',  
            '150%'
          ]
        },
        {
          name: 'DAC_OFFSETCURR_TRIM',
          bitmin: 20,
          bitmax: 22,
          description: 'Offset at G1 | G2/3/4',
          options: [
            '0 | 0', 
            '0.2 mV | 0',
            '2.5 mV | 0',
            '-2.5 mV | 0',
            '0 | 4 mV', 
            '0.2 mV | 4 mV',
            '2.5 mV | 51 mV',
            '-2.5 mV | -43 mV'
          ]
        },
        {
          name: 'DAC_CROSSCTRL',
          bitmin: 18,
          bitmax: 19,
          description: 'Controls the cross point of the DAC switches',
          options: [
            'default', 
            '+20 pS on rise', 
            '+20 pS on fall', 
            'not used'
          ]
        },
        {
          name: 'DAC_VOFF',
          bitmin: 16,
          bitmax: 17,
          description: 'Controls DAC switch Voff overdrive',
          options: [
            'VCCA - 1.25 V', 
            'VCCA - 1.125 V',
            'VCCA - 1.325 V',
            'VCCA - 1.2 V'
          ]
        },
        {
          name: 'OUTAMP_ICTRL',
          bitmin: 12,
          bitmax: 13,
          description: '',
          options: []
        },
        {
          name: 'OUTAMP_CLIPDTCT_HIGH_TRIM',
          bitmin: 10,
          bitmax: 11,
          description: '',
          options: []
        },
        {
          name: 'OUTAMP_CLIPDTCT_LOW_TRIM',
          bitmin: 8,
          bitmax: 9,
          description: '',
          options: []
        },
        {
          name: 'OUTAMP_EN_CLSG_HIGH_EXT',
          bitmin: 6,
          bitmax: 6,
          description: '',
          options: ['disabled', 'enabled']
        },
        {
          name: 'OUTAMP_EN_CLSG_HIGH_INT',
          bitmin: 5,
          bitmax: 5,
          description: '',
          options: ['disabled', 'enabled']
        },
        {
          name: 'OUTAMP_EN_CLSG_LOW_INT',
          bitmin: 4,
          bitmax: 4,
          description: '',
          options: ['disabled', 'enabled']
        },
        {
          name: 'OUTAMP_HALF_GAIN',
          bitmin: 3,
          bitmax: 3,
          description: '',
          options: ['disabled', 'enabled']
        },
        {
          name: 'OUTAMP_HI_CURR_CLSAB',
          bitmin: 2,
          bitmax: 2,
          description: '',
          options: ['disabled', 'enabled']
        },
        {
          name: 'OUTAMP_LP_MODE',
          bitmin: 0,
          bitmax: 0,
          description: '',
          options: ['disabled', 'enabled']
        }
      ]
    },
    /*
    LC_ST_CFG1: {
      prop: 'lev_lc_st_cfg1',
      mask:0x00007F00,
      fields: [
        {
          name: 'STT_FACTOR',
          bitmin: 23,
          bitmax: 31,
          description: 'Smooth transition tick',
          options: []
        },
        {
          name: 'ST_MAXL',
          bitmin: 8,
          bitmax: 14,
          description: 'DC2DC digital control max value',
          options: []
        },
        {
          name: 'ST_MINL',
          bitmin: 1,
          bitmax: 7,
          description: 'DC2DC digital control min value',
          options: []
        },
        {
          name: 'LC_ST_EN',
          bitmin: 0,
          bitmax: 0,
          description: 'Enable class G smooth transition',
          options: ['disabled', 'enabled']
        }
      ]
    }
    */
  },
  channel: {
    /*
    VP_HQ_DDF_DC_OFFSET: {
      mask:0x00FFFFFF,
      fields: [
        {
          name: 'DC_OFFSET',
          bitmin: 0,
          bitmax: 23,
          description: 'DC offset value added to the signal at the post IIR output',
          options: []
        }
      ]
    },
    VP1_MAX_GAIN: {
      mask:0xFFFFFFFF,
      fields: [
        {
          name: 'VP1_MAX_GAIN',
          bitmin: 0,
          bitmax: 31,
          description: 'Undocumented',
          options: []
        }
      ]
    },
    VP2_MAX_GAIN: {
      mask:0xFFFFFFFF,
      fields: [
        {
          name: 'VP2_MAX_GAIN',
          bitmin: 0,
          bitmax: 31,
          description: 'Undocumented',
          options: []
        }
      ]
    },
    */
   AFE_INAMP01_CFG: {
      mask:0x001F,
      fields: [
        {
          name: 'INAMP_1_EN',
          bitmin: 28,
          bitmax: 28,
          description: 'FF right',
          options: ['disabled', 'enabled']
        },
        {
          name: 'INAMP_1_DISCHARGE_INPUT_N',
          bitmin: 27,
          bitmax: 27,
          description: 'FF right dicharge inputs',
          options: ['not discharged', 'discharged']
        },
        {
          name: 'INAMP_1_INC_CURR',
          bitmin: 26,
          bitmax: 26,
          description: 'FF right increment 2nd stage current',
          options: ['not incremented', 'increment']
        },
        {
          name: 'INAMP_1_ICTRL',
          bitmin: 24,
          bitmax: 25,
          description: 'FF right current reference',
          options: [
            '50%',  
            '60%',  
            '75%',  
            '100%'
          ]
        },
        {
          name: 'INAMP_1_GAIN',
          bitmin: 16,
          bitmax: 20,
          description: 'FF right gain',
          options: dBRange(-6, 25, 1)
        },
        {
          name: 'INAMP_0_EN',
          bitmin: 12,
          bitmax: 12,
          description: 'FF left',
          options: ['disabled', 'enabled']
        },
        {
          name: 'INAMP_0_DISCHARGE_INPUT_N',
          bitmin: 11,
          bitmax: 11,
          description: 'FF left dicharge inputs',
          options: ['not discharged', 'discharged']
        },
        {
          name: 'INAMP_0_INC_CURR',
          bitmin: 10,
          bitmax: 10,
          description: 'FF left increment 2nd stage current',
          options: ['not incremented', 'increment']
        },
        {
          name: 'INAMP_0_ICTRL',
          bitmin: 8,
          bitmax: 9,
          description: 'FF left current reference',
          options: [
            '50%',  
            '60%',  
            '75%',  
            '100%'
          ]
        },
        {
          name: 'Feedforward Input Gain',//'INAMP_0_GAIN',
          bitmin: 0,
          bitmax: 4,
          description: 'FF left gain',
          options: dBRange(-6, 25, 1)
        }
      ]
    },
    AFE_TX_CTRL: {
      mask:0x0100,
      fields: [
        {
          name: 'FB_GM_EN_R',
          bitmin: 28,
          bitmax: 28,
          description: '',
          options: ['disabled', 'enabled']
        },
        {
          name: 'FB_PEAKAMP_EN_R',
          bitmin: 27,
          bitmax: 27,
          description: '',
          options: ['disabled', 'enabled']
        },
        {
          name: 'FB_FILTERS_EN_R',
          bitmin: 26,
          bitmax: 26,
          description: '',
          options: ['disabled', 'enabled']
        },
        {
          name: 'FB_EXTFILT_EN_R',
          bitmin: 25,
          bitmax: 25,
          description: '',
          options: ['disabled', 'enabled']
        },
        {
          name: 'FB_DIFFMIC_EN_R',
          bitmin: 24,
          bitmax: 24,
          description: '',
          options: ['disabled', 'enabled']
        },
        {
          name: 'FB_MICAMP_EN_R',
          bitmin: 23,
          bitmax: 23,
          description: '',
          options: ['disabled', 'enabled']
        },
        {
          name: 'FB_OPAMP_BIAS_EN_R',
          bitmin: 22,
          bitmax: 22,
          description: '',
          options: ['disabled', 'enabled']
        },
        {
          name: 'FB_V_SWBIAS_EN_R',
          bitmin: 21,
          bitmax: 21,
          description: '',
          options: ['disabled', 'enabled']
        },
        {
          name: 'FB_I_SWBIAS_EN_R',
          bitmin: 20,
          bitmax: 20,
          description: '',
          options: ['disabled', 'enabled']
        },
        {
          name: 'FB_VCOM_GEN_EN_R',
          bitmin: 18,
          bitmax: 18,
          description: '',
          options: ['disabled', 'enabled']
        },
        {
          name: 'OUTAMP_EN_R',
          bitmin: 17,
          bitmax: 17,
          description: '',
          options: ['disabled', 'enabled']
        },
        {
          name: 'DAC_EN_R',
          bitmin: 16,
          bitmax: 16,
          description: '',
          options: ['disabled', 'enabled']
        },
        {
          name: 'FB_GM_EN_L',
          bitmin: 12,
          bitmax: 12,
          description: '',
          options: ['disabled', 'enabled']
        },
        {
          name: 'FB_PEAKAMP_EN_L',
          bitmin: 11,
          bitmax: 11,
          description: '',
          options: ['disabled', 'enabled']
        },
        {
          name: 'FB_FILTERS_EN_L',
          bitmin: 10,
          bitmax: 10,
          description: '',
          options: ['disabled', 'enabled']
        },
        {
          name: 'FB_EXTFILT_EN_L',
          bitmin: 9,
          bitmax: 9,
          description: '',
          options: ['disabled', 'enabled']
        },
        {
          name: 'Feedback Microphone Input Mode',//'FB_DIFFMIC_EN_L',
          bitmin: 8,
          bitmax: 8,
          description: '',
          options: ['Single-Ended', 'Differential']
        },
        {
          name: 'FB_MICAMP_EN_L',
          bitmin: 7,
          bitmax: 7,
          description: '',
          options: ['disabled', 'enabled']
        },
        {
          name: 'FB_OPAMP_BIAS_EN_L',
          bitmin: 6,
          bitmax: 6,
          description: '',
          options: ['disabled', 'enabled']
        },
        {
          name: 'FB_V_SWBIAS_EN_L',
          bitmin: 5,
          bitmax: 5,
          description: '',
          options: ['disabled', 'enabled']
        },
        {
          name: 'FB_I_SWBIAS_EN_L',
          bitmin: 4,
          bitmax: 4,
          description: '',
          options: ['disabled', 'enabled']
        },
        {
          name: 'FB_VCOM_GEN_EN_L',
          bitmin: 2,
          bitmax: 2,
          description: '',
          options: ['disabled', 'enabled']
        },
        {
          name: 'OUTAMP_EN_L',
          bitmin: 1,
          bitmax: 1,
          description: '',
          options: ['disabled', 'enabled']
        },
        {
          name: 'DAC_EN_L',
          bitmin: 0,
          bitmax: 0,
          description: '',
          options: ['disabled', 'enabled']
        }
      ]
    },
    /*
    PB_MAX_GAIN: {
      mask:0xFFFFFFFF,
      fields: [
        {
          name: 'PB_MAX_GAIN',
          bitmin: 0,
          bitmax: 31,
          description: 'Undocumented',
          options: []
        }
      ]
    },
   PB_CMPG_DGU_DC_C1_EXP: {
      mask:0xFFFFFFFF,
      fields: [
        {
          name: 'DC_C1_EXPONENT',
          bitmin: 0,
          bitmax: 4,
          description: 'The exponent of the data combine module 1st channel fixed gain in Q4.0',
          options: []
        }
      ]
    },
    PB_CMPG_DGU_DC_C1_MAN: {
      mask:0xFFFFFFFF,
      fields: [
        {
          name: 'DC_C1_MANTISSA',
          bitmin: 0,
          bitmax: 9,
          description: 'The mantissa of the data combine module 1st channel fixed gain in Q0.10',
          options: []
        }
      ]
    },
    PB_CMPG_DGU_DC_C2_EXP: {
      mask:0xFFFFFFFF,
      fields: [
        {
          name: 'DC_C2_EXPONENT',
          bitmin: 0,
          bitmax: 4,
          description: 'The exponent of the data combine module 2nd channel fixed gain in Q4.0',
          options: []
        }
      ]
    },
    PB_CMPG_DGU_DC_C2_MAN: {
      mask:0xFFFFFFFF,
      fields: [
        {
          name: 'DC_C2_MANTISSA',
          bitmin: 0,
          bitmax: 9,
          description: 'The mantissa of the data combine module 2nd channel fixed gain in Q0.10',
          options: []
        }
      ]
    },
    PB_PGAIN1_DGU_DC_C1_EXP: {
      mask:0xFFFFFFFF,
      fields: [
        {
          name: 'DC_C1_EXPONENT',
          bitmin: 0,
          bitmax: 4,
          description: 'The exponent of the data combine module 1st channel fixed gain in Q4.0',
          options: []
        }
      ]
    },
    PB_PGAIN1_DGU_DC_C1_MAN: {
      mask:0xFFFFFFFF,
      fields: [
        {
          name: 'DC_C1_MANTISSA',
          bitmin: 0,
          bitmax: 9,
          description: 'The mantissa of the data combine module 1st channel fixed gain in Q0.10',
          options: []
        }
      ]
    },
    PB_PGAIN2_DGU_DC_C1_EXP: {
      mask:0xFFFFFFFF,
      fields: [
        {
          name: 'DC_C2_EXPONENT',
          bitmin: 0,
          bitmax: 4,
          description: 'The exponent of the data combine module 2nd channel fixed gain in Q4.0',
          options: []
        }
      ]
    },
    PB_PGAIN2_DGU_DC_C1_MAN: {
      mask:0xFFFFFFFF,
      fields: [
        {
          name: 'DC_C2_MANTISSA',
          bitmin: 0,
          bitmax: 9,
          description: 'The mantissa of the data combine module 2nd channel fixed gain in Q0.10',
          options: []
        }
      ]
    }
    */
  },  
  mode: {
    PB_PBG_DGU_DC_C1_MAN: {
      mask:0xFFFFFFFF,
      fields: [
        {
          name: 'DC_C1_EXPONENT',
          bitmin: 0,
          bitmax: 4,
          description: 'The exponent of the data combine module 1st channel fixed gain in Q4.0',
          options: []
        }
      ]
    },
    PB_PBG_DGU_DC_C1_EXP: {
      mask:0xFFFFFFFF,
      fields: [
        {
          name: 'DC_C2_MANTISSA',
          bitmin: 0,
          bitmax: 9,
          description: 'The mantissa of the data combine module 2nd channel fixed gain in Q0.10',
          options: []
        }
      ]
    },
    /*
    PB_ZOH_GAIN: {
      mask:0xFFFFFFFF,
      fields: [
        {
          name: 'ZOH_GAIN',
          bitmin: 0,
          bitmax: 11,
          description: 'ZOH unsigned gain value',
          options: []
        }
      ]
    }
    */
  }
}

export default setupFields
