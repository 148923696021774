import styled from '@emotion/styled/macro'
import { observer } from 'mobx-react-lite'
import React, { useContext, useMemo } from 'react'
import { ReactComponent as RefreshIcon } from '../../../assets/icons/refresh.svg'
import Button from '../../../components/button'
import Card from '../../../components/card'
import { OneFourthFit } from '../../../components/grids'
import PropertiesInfo, { Properties } from '../../../components/properties-info'
import Subtitle from '../../../components/subtitle'
import Title from '../../../components/title'
import { mobileSize } from '../../../constants'
import Content from '../../../containers/content'
import { RootStoreContext } from '../../../context/root-store'
import useAsync from '../../../hooks/use-async'
import usePagedAsync from '../../../hooks/use-paged-async'
import {
  fetchFullStationDetails,
  fetchStationMessages
} from '../../../services/entities/station'
import { formatBytes } from '../../../utils/common'
import { timestampToLocalDate } from '../../../utils/date-time'
import { capitalize } from '../../../utils/text'
import ChannelForm from './channel-form'
import FirmwareForm from './firmware-form'
import LicenseeForm from './licensee-form'
import ResetReportOpts from './reset-report-opts'
import UpdateTestForm from './update-test'
import StationUpdates from './updates'
export interface SectionProp {
  stationName: string
  setSectionLoaded: Function
  updatesTableSize?: number
}

interface Props {
  stationName: string
}

const pageParams = {
  pageSize: 10
}

function Station({
  stationName,
  ...contentProps
}: Props & Omit<React.ComponentProps<typeof Content>, 'children'>) {
  const {
    authentication: { user }
  } = useContext(RootStoreContext)
  const stationParams = useMemo(() => [stationName], [stationName])
  const { data, error, initialized } = useAsync({
    fn: fetchFullStationDetails,
    args: stationParams
  })
  const messages = usePagedAsync({
    fn: fetchStationMessages,
    args: stationParams,
    opts: pageParams,
    entity: 'messages'
  })
  const nonSectionProps = useMemo(
    () => ({
      section: false,
      title: 'Missioncontrol',
      subtitle: ['Station', `/missioncontrol/stations`],
      itemName: data?.Test?.Name ?? '',
      itemNameLoading: !initialized,
      ...contentProps
    }),
    [data, contentProps, initialized]
  )

  return (
    <Content
      error={error}
      loading={!messages.initialized && !initialized}
      section={!!contentProps.section}
      {...nonSectionProps}>
      <Container>
        {data.Timestamp && (
          <Timestamp>
            Last updated on: {timestampToLocalDate(data.Timestamp)}
          </Timestamp>
        )}
        <Title>Configuration</Title>
        <OneThird>
          {data.Test && (
            <Card>
              <Subtitle>Test</Subtitle>
              <PropertiesInfo data={data['Test']} />
            </Card>
          )}
          {data.LicenceStatus && (
            <Card>
              <Subtitle>Licence</Subtitle>
              <PropertiesInfo
                data={data.LicenceStatus}
                keys={['Licensee', 'Message', 'Status']}
                content={capitalize}
              />
              <PropertiesInfo
                data={data.LicenceStatus}
                keys={['From', 'To']}
                content={timestampToLocalDate}
              />
            </Card>
          )}
          {(data.Packages || data.SupportConnection) && (
            <Card>
              {data.Packages && (
                <>
                  <Subtitle>Firmware</Subtitle>
                  {data.Packages.map(({ Name, Version }) => (
                    <Properties key={`${Name}-${Version}`}>
                      <div>{Version}</div>
                      <div>{Name}</div>
                    </Properties>
                  ))}
                </>
              )}
              {data.SupportConnection && (
                <>
                  <Subtitle>Support Channels</Subtitle>
                  <PropertiesInfo
                    data={{
                      status: capitalize(
                        data.SupportConnection.Active ? 'Active' : 'Inactive'
                      ),
                      ...(data.SupportConnection.Port !== 0 &&
                      data.SupportConnection.Active
                        ? {
                            command: `ssh -i ~/.ssh/soundstation root@localhost -p ${data.SupportConnection.Port}`
                          }
                        : {})
                    }}
                  />
                </>
              )}
            </Card>
          )}
        </OneThird>
        <IconedTitle>
          <Title>Updates</Title>
          <RefreshButton
            onClick={messages.refreshData}
            loading={messages.loading}
            icon={true}>
            <RefreshIcon width={20} />
          </RefreshButton>
        </IconedTitle>
        <StationUpdates {...messages} />
        <Title>Configuration</Title>
        <OneFourthFit>
          <UpdateTestForm
            stationName={stationName}
            refreshStationMessages={messages.refreshData}
          />
          {user.administrator && data.LicenceStatus && data.Host?.MachineId && (
            <LicenseeForm
              stationName={stationName}
              host={data.Host.MachineId}
              {...data.LicenceStatus}
            />
          )}
          {user.administrator && <FirmwareForm stationName={stationName} />}
          <div>
            {user.administrator && data.SupportConnection && (
              <ChannelForm
                stationName={stationName}
                {...data.SupportConnection}
              />
            )}
            <ResetReportOpts
              stationName={stationName}
              refreshStationMessages={messages.refreshData}
            />
          </div>
        </OneFourthFit>
        <Title>System</Title>
        <OneThird>
          {(data.Analyzer || data.Cpu) && (
            <Card>
              {data.Analyzer && (
                <>
                  <Subtitle>Analyzer</Subtitle>
                  <PropertiesInfo data={data.Analyzer} />
                </>
              )}
              {data.Cpu && (
                <>
                  <Subtitle>CPU</Subtitle>
                  <PropertiesInfo
                    data={data.Cpu}
                    content={n => `${(Math.round(+n * 10) / 10).toFixed(1)}%`}
                  />
                </>
              )}
            </Card>
          )}
          {(data.Host || data.Internet) && (
            <Card>
              {data.Host && (
                <>
                  <Subtitle>Host</Subtitle>
                  <PropertiesInfo
                    data={{
                      ...data.Host,
                      ...{
                        'Up Time': `${data.Host.Uptime.Hours}:${data.Host.Uptime.Minutes}:${data.Host.Uptime.Seconds}`
                      }
                    }}
                  />
                </>
              )}
              {data.Internet && (
                <>
                  <Subtitle>Internet</Subtitle>
                  <PropertiesInfo data={data.Internet} />
                </>
              )}
            </Card>
          )}
          {data.Filesystem && (
            <Card>
              <Subtitle>File System</Subtitle>
              <PropertiesInfo data={data.Filesystem} />
            </Card>
          )}
          {data.Load && (
            <Card>
              <Subtitle>Load</Subtitle>
              <PropertiesInfo data={data.Load} />
            </Card>
          )}
          {data.Memory && (
            <Card>
              <Subtitle>Memory</Subtitle>
              <PropertiesInfo data={data.Memory} content={formatBytes} />
            </Card>
          )}
          {data.Network && (
            <Card>
              <Subtitle>Network</Subtitle>
              <PropertiesInfo data={data.Network} />
            </Card>
          )}
        </OneThird>
      </Container>
    </Content>
  )
}

export default observer(Station)

const RefreshButton = styled(Button)`
  justify-self: end;
`

const IconedTitle = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
`

const OneThird = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 20px;

  @media screen and (max-width: ${mobileSize}px) {
    grid-template-columns: 1fr;
  }
`

const Timestamp = styled.p`
  font-size: 13px;
  margin-bottom: 32px;
`

const Container = styled.div`
  position: relative;
  font-size: 14px;
  font-weight: 300;
  color: #666;
`
