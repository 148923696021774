import React, { useCallback, useState } from 'react'
import Card from '../../../../../components/card'
import CardTitle from '../../../../../components/card-title'
import LoadingSpinner from '../../../../../components/loading-spinner'
import { Gridder } from '../../../../../components/grids'
import HorizontalTab from '../../../../../components/horizontal-tab'
import { RadioSelect } from '../../../../../components/radio-button'
import { CalibrationParameters } from '../../../../../types/dbmc2evb2'
import { resizeArray } from '../../../../../utils/array'
import SubSubtitle from '../../../../../components/subsubtitle'
import calibrationFields from '../../constants/fields/calibration-fields'
import { updateRegister } from '../../utils/binary'
import { createInputComponents } from '../input-fields'
import { round } from 'mathjs'

const channelToString = (c: number) => (c === 1 ? 'Mono' : 'Stereo')
const editorComponentHeight = '220px'

const channelOptions = [1, 2].map(channel => ({
  label: channelToString(channel),
  value: channel
}))

interface Props {
  parameters: CalibrationParameters
  onChange: (s: CalibrationParameters) => void
}

export default React.memo(({ parameters, onChange }: Props)=> {
  console.log('*** CalibrationDesigner')

  const isLoading = parameters === undefined

  const getCommonName = useCallback(() => {
    if (parameters.header.number_of_channels < 2) return ''
    return 'Common'
  }, [parameters.header.number_of_channels])

  const getChannelName = useCallback(
    (index: number, useLetter?:boolean) => {
      if (parameters.header.number_of_channels < 2)
        return ''
      if (useLetter)
        return index === 0 ? 'L' : 'R'
      else
        return index === 0 ? 'Left' : 'Right'
    },
    [parameters.header.number_of_channels]
  )

  const updateChannels = useCallback(
    (numOfChannels: number) => {
      parameters.header.number_of_channels = numOfChannels
      parameters.data.channels = resizeArray(
        parameters.data.channels,
        numOfChannels
      )
      onChange(parameters)
    },
    [parameters, onChange]
  )
  
  //Feedback gain to dbmc param
  const dbmcFbLcGain=(gain:number)=>{
    let result=0
    if (gain === 0) {
      return 0
    }
    
    result = Math.round((gain*Math.pow(2,3)) + Math.pow(2,10))
    return result
  }

  //Feedback dbmc param to gain
  const dbmcFbLcGainReverse=(data:number)=>{
    let gain = 0

    if (data === 0){
      return 0
    }
    
    gain = (data - Math.pow(2,10)) / (Math.pow(2,3));
    return gain
  }

  //Feedforward/Talkthrough gain to dbmc exponent/mantissa
  const dguGain = (gain:number) => {
    let exponent, mantissa = 0

    //cannot be greater than 50
    //or less than -100

    let K = Math.pow(10, gain/20)
    let E = Math.ceil(Math.log2(K))
    let M = Math.floor(1024*K/Math.pow(2, E) + 0.5)
    if (M>=1024){
      M = M/2;
      E = E+1;
    }
    mantissa = M;
    if (E>=0){
      exponent  = E;
    } else {
      exponent  = E + 32;
    }

    return {exponent,mantissa}
  }

  //Feedforward/Talkthrough dbmc exponent/mantissa to gain
  const dguGainReverse = (exponent:number, mantissa:number) => {
    let gain=0

    if (exponent >= 16){
      // interpret as negative
      exponent = -16 + (exponent-16)
    }

    let K = mantissa/1024 * Math.pow(2, exponent)
    gain = round(20*Math.log10(K), 2)
    return gain
  }

  const [feedbackLeft, setFeedbackLeft] = useState(
    dbmcFbLcGainReverse(parameters.data.channels[0].lev_again).toString()
  )

  const [feedbackRight, setFeedbackRight] = useState(
    parameters.data.channels.length > 1 
      ? dbmcFbLcGainReverse(parameters.data.channels[1].lev_again).toString() 
      : '0'
  )

  const [feedforwardLeft, setFeedforwardLeft] = useState(
    dguGainReverse(parameters.data.channels[0].c1_exp, parameters.data.channels[0].c1_man).toString()
  )

  const [feedforwardRight, setFeedforwardRight] = useState(
    parameters.data.channels.length > 1 
      ? dguGainReverse(parameters.data.channels[1].c1_exp, parameters.data.channels[1].c1_man).toString()
      : '0'
  )
  
  const [talkthroughLeft, setTalkthroughLeft] = useState(
    dguGainReverse(parameters.data.channels[0].c2_exp, parameters.data.channels[0].c2_man).toString()
  )

  const [talkthroughRight, setTalkthroughRight] = useState(
    parameters.data.channels.length > 1 
      ? dguGainReverse(parameters.data.channels[1].c2_exp, parameters.data.channels[1].c2_man).toString()
      : '0'
  )

  const updateFeedback = (gainStr:string, channelIndex:number)=>{
    
    channelIndex === 0 && setFeedbackLeft(gainStr)
    channelIndex === 1 && setFeedbackRight(gainStr)

    let gain=parseFloat(gainStr)
    if (!Number.isNaN(gain)) {
      console.log('*** updateFeedback ***')
      if (gain < -64) {
        gain = -64
        channelIndex === 0 && setFeedbackLeft(gain.toString())
        channelIndex === 1 && setFeedbackRight(gain.toString())
      }
      if (gain > 0) {
        gain = 0
        channelIndex === 0 && setFeedbackLeft(gain.toString())
        channelIndex === 1 && setFeedbackRight(gain.toString())
      }
      const dbmcGain=dbmcFbLcGain(gain)
      parameters.data.channels[channelIndex].lev_again=dbmcGain
      onChange(parameters)
    } else {
      channelIndex === 0 && setFeedbackLeft('0')
      channelIndex === 1 && setFeedbackRight('0')
    }
  }

  const updateFeedforward = (gainStr:string, channelIndex:number)=>{
    
    channelIndex === 0 && setFeedforwardLeft(gainStr)
    channelIndex === 1 && setFeedforwardRight(gainStr)
    
    let gain = parseFloat(gainStr)
      if (!Number.isNaN(gain)) {
        console.log('*** updateFeedforward ***')
        if (gain < -100) {
          gain = -100
          channelIndex === 0 && setFeedforwardLeft(gain.toString())
          channelIndex === 1 && setFeedforwardRight(gain.toString())
        }
        if (gain > 50) {
          gain = 50
          channelIndex === 0 && setFeedforwardLeft(gain.toString())
          channelIndex === 1 && setFeedforwardRight(gain.toString())
        }
        const dbmcParams=dguGain(gain)
        parameters.data.channels[channelIndex].c1_exp=dbmcParams.exponent
        parameters.data.channels[channelIndex].c1_man=dbmcParams.mantissa
        onChange(parameters)
      } else {
        channelIndex === 0 && setFeedforwardLeft('0')
        channelIndex === 1 && setFeedforwardRight('0')
      }
  }

  const updateTalkthrough = (gainStr:string, channelIndex:number)=>{

    channelIndex === 0 && setTalkthroughLeft(gainStr)
    channelIndex === 1 && setTalkthroughRight(gainStr)

    let gain = parseFloat(gainStr)
    if (!Number.isNaN(gain)) {
      console.log('*** updateTalkthrough ***')
      if (gain < -100) {
        gain = -100
        channelIndex === 0 && setTalkthroughLeft(gain.toString())
        channelIndex === 1 && setTalkthroughRight(gain.toString())
      }
      if (gain > 50) {
        gain = 50
        channelIndex === 0 && setTalkthroughLeft(gain.toString())
        channelIndex === 1 && setTalkthroughRight(gain.toString())
      }
      const dbmcParams=dguGain(gain)
      parameters.data.channels[channelIndex].c2_exp=dbmcParams.exponent
      parameters.data.channels[channelIndex].c2_man=dbmcParams.mantissa
      onChange(parameters)
    } else {
      channelIndex === 0 && setTalkthroughLeft('0')
      channelIndex === 1 && setTalkthroughRight('0')
    }
  }

  return (
    <>
      {isLoading ? (
        <div style={{ textAlign: 'center' }}>
          <LoadingSpinner variant={'dark'} />
        </div>
      ) : (
        <>
          <Gridder align={'start'} template={['200px', '1fr']} gap={'24px'}>
            <Card>
              <CardTitle>Channels</CardTitle>
              <RadioSelect
                options={channelOptions}
                onChange={o => updateChannels(o.value as number)}
                value={parameters.header.number_of_channels}
              />
            </Card>
          </Gridder>

          <Gridder
            align={'start'}
            template={['1fr', '1fr', '1fr']}
            gap={'24px'}>
            <div>
              <SubSubtitle>{getCommonName()}</SubSubtitle>
              <HorizontalTab
                nested
                persistenceKey={`calibration-common`}
                height={editorComponentHeight}
                options={[
                  {
                    title: 'Resitor Calibration',
                    subtitle: '',//toHex(parameters.data.resistor_calibration, 2),
                    component: createInputComponents(
                      parameters.data.resistor_calibration,
                      calibrationFields.RESISTOR_CALIBRATION.fields,
                      calibrationFields.RESISTOR_CALIBRATION.mask,
                      (field, { value, bitmin, bitmax }) => {
                        parameters.data.resistor_calibration = updateRegister(
                          parameters.data.resistor_calibration,
                          bitmin,
                          bitmax,
                          value
                        )
                        onChange(parameters)
                      }
                    )
                  }
                ]}
              />
            </div>
            {parameters.data.channels
              .slice(0, 2)
              .map((channel, channelIndex) => (
                <div key={`calibration-channel-${channelIndex}`}>
                  <SubSubtitle>{getChannelName(channelIndex)}</SubSubtitle>
                  <div className="calibration-channel-container">
                    <div className="calibration-channel-row">
                      <div className="calibration-channel-label-col">{`FB ${getChannelName(channelIndex, true)} (dB)`}</div>
                      <div className="calibration-channel-input-col">
                        <input 
                          key={`fb-${channelIndex}`}
                          id={`fb-${channelIndex}`}
                          className="calibration-channel-input"
                          type="number"
                          min="-64"
                          max="0"
                          step="0.125"
                          value={channelIndex === 0 ? feedbackLeft : feedbackRight}
                          onChange={e=>{
                            if (channelIndex === 0) {
                              setFeedbackLeft(e.target.value)
                            } else {
                              setFeedbackRight(e.target.value)
                            }
                          }}
                          onBlur={e=>updateFeedback(e.target.value, channelIndex)}
                        />
                      </div>
                    </div>
                    <div className="calibration-channel-row">
                      <div className="calibration-channel-label-col">{`FF ${getChannelName(channelIndex, true)} (dB)`}</div>
                      <div className="calibration-channel-input-col">
                        <input
                          key={`ff-${channelIndex}`}
                          className="calibration-channel-input"
                          type="number"
                          min="-100"
                          max="50"
                          step="0.125"
                          value={channelIndex === 0 ? feedforwardLeft : feedforwardRight}
                          onChange={e=>{
                            if (channelIndex === 0) {
                              setFeedforwardLeft(e.target.value)
                            } else {
                              setFeedforwardRight(e.target.value)
                            }
                          }}
                          onBlur={e=>updateFeedforward(e.target.value, channelIndex)}
                        />
                      </div>
                    </div>
                    <div className="calibration-channel-row">
                      <div className="calibration-channel-label-col">{`TT ${getChannelName(channelIndex, true)} (dB)`}</div>
                      <div className="calibration-channel-input-col">
                        <input
                          key={`tt-${channelIndex}`}
                          className="calibration-channel-input"
                          type="number"
                          min="-100"
                          max="50"
                          step="0.125"
                          value={channelIndex === 0 ? talkthroughLeft : talkthroughRight}
                          onChange={e=>{
                            if (channelIndex === 0) {
                              setTalkthroughLeft(e.target.value)
                            } else {
                              setTalkthroughRight(e.target.value)
                            }
                          }}
                          onBlur={e=>updateTalkthrough(e.target.value, channelIndex)}
                        />
                      </div>
                    </div>

                  </div>

                </div>
              ))}

          </Gridder>
          
        </>
      )}
    </>
  )
},()=>true)
