import styled from '@emotion/styled/macro'
import React from 'react'
import controlPanel from '../assets/images/control-panel.jpg'
import BodyStyle from '../components/body-style'
import LoadingSpinner from '../components/loading-spinner'
import { mobileSize } from '../constants'
import useAsync from '../hooks/use-async'
import { fetchNotices } from '../services/entities/notice'
import { timestampToLocalDate } from '../utils/date-time'

export default Home

function Home() {
  const {
    data: { status },
    loading,
    error
  } = useAsync({ fn: fetchNotices })

  return (
    <Container>
      <BodyStyle style={homeBackgroundImage} />
      <Content>
        <StatusPane>
          {error ||
            (!loading && status ? (
              <>
                <Status>{status.message}</Status>
                <DateTime>{timestampToLocalDate(status.timestamp)}</DateTime>
              </>
            ) : (
              <LoadingSpinner />
            ))}
        </StatusPane>
        <Name>Soundstation</Name>
      </Content>
    </Container>
  )
}

const homeBackgroundImage = `
  background-image: url(${controlPanel});
  background-attachment: fixed;
  background-position: center center;
  background-size: cover;
`

const Name = styled.div`
  font-size: 34px;
  color: #fff;
`

const DateTime = styled.div`
  color: #fff;
`

const Status = styled.div`
  color: #189453;
`

const StatusPane = styled.div`
  font-size: 13px;
  padding: 10px 32px;
  border-radius: 0px;
  width: 100%;
  min-width: 300px;
  display: grid;
  background-color: rgba(0, 0, 0, 0.65);
  align-items: center;
  line-height: 3;
  min-height: 100px;

  svg {
    align-self: center;
    justify-self: center;
  }
`

const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-column-gap: 120px;
  bottom: 60px;
  align-content: end;
  align-items: center;
  padding-bottom: 10vh;
  width: 96%;
  margin: 0 auto;
  height: 100%;

  @media screen and (max-width: ${mobileSize}px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    grid-row-gap: 20px;
    margin-left: 40px;
    margin-right: 40px;
  }
`

const Container = styled.div`
  height: 100vh;

  /* &:after {
    content: ' ';
    position: fixed;
    background-color: rgba(0, 0, 0, 0.25);
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 0;
  } */
`
