import styled from '@emotion/styled/macro'
import React, { useCallback, useMemo } from 'react'
import Card from '../../../components/card'
import PropertiesInfo from '../../../components/properties-info'
import { TableRow } from '../../../components/table'
import { mobileSize } from '../../../constants'
import useAsync from '../../../hooks/use-async'
import useRunEvery from '../../../hooks/use-run-every'
import useThrottle from '../../../hooks/use-throttle'
import { fetchProdStationSystem } from '../../../services/entities/production-test'
import { StationInfo } from '../../../types/station'
import {
  formatBytes,
  isEmpty,
  joinFractions,
  omit,
  toPercent
} from '../../../utils/common'
import { intervalToNow } from '../../../utils/date-time'
import { capitalize } from '../../../utils/text'
import LoadingSpinner from '../../../components/loading-spinner'

interface StationRowProps {
  fqdn: string
  gridSizes: number[]
  toggle: (fqdn: string) => void
  isSelected: boolean
}

const interpretInternet = (internet: StationInfo['Internet']) => {
  if (internet.Restricted === 'reachable') return 'International+'
  if (internet.International === 'reachable') return 'International'
  if (internet.Local === 'reachable') return 'Local'
  return ''
}

export default function StationRow({
  fqdn,
  gridSizes,
  toggle,
  isSelected
}: React.ComponentProps<'div'> & StationRowProps) {
  const fqdnParams = useMemo(() => [fqdn], [fqdn])
  const { data, refreshData, initialized } = useAsync({
    fn: fetchProdStationSystem,
    args: fqdnParams
  })
  const onRowClick = useCallback(() => {
    if (initialized) toggle(fqdn)
  }, [toggle, fqdn, initialized])
  const throttledRowClick = useThrottle(onRowClick, 400)

  useRunEvery(refreshData, 2000)

  return (
    <>
      <TableRow
        onClick={throttledRowClick}
        gridSizes={gridSizes}
        isSelected={isSelected}>
        <div>{fqdn}</div>
        <div>
          {initialized ? (
            data.Host?.Hostname ? (
              'Online'
            ) : (
              'Offline'
            )
          ) : (
            <LoadingSpinner height={15} variant={'dark'} />
          )}
        </div>
        {data && (
          <>
            <div>
              {data.Test?.Name}{' '}
              {!!data?.Test?.Version && `(${data.Test?.Version})`}
            </div>
            <div>{data.Soundstation?.Version}</div>
            <div>
              {data.LicenceStatus?.Licensee &&
                capitalize(data.LicenceStatus?.Licensee)}{' '}
            </div>
            <div>
              {data.LicenceStatus?.Status && (
                <>
                  <span>{capitalize(data.LicenceStatus.Status)}</span>{' '}
                  <span>
                    (
                    {data.LicenceStatus?.To &&
                      intervalToNow(data.LicenceStatus?.To)}{' '}
                    remaining)
                  </span>
                </>
              )}
            </div>
            <div>{data.Network?.HardwareAddress}</div>
            <div>{data.Network?.IpAddress}</div>
            <div>{data.Internet && interpretInternet(data.Internet)}</div>
          </>
        )}
      </TableRow>
      {!isEmpty(data) && (
        <Popup shown={isSelected}>
          <Row gridSizes={[1, 1, 1.5, 1]}>
            <div>
              {data.Analyzer && (
                <StationRowCard>
                  <Title>Analyzer</Title>
                  <StationRowPropertiesInfo data={data.Analyzer} />
                </StationRowCard>
              )}
              {data.Cpu && (
                <StationRowCard>
                  <Title>CPU</Title>
                  <StationRowPropertiesInfo
                    data={data.Cpu}
                    content={(value: string) =>
                      `${
                        isNaN(parseFloat(value))
                          ? value
                          : toPercent(Number(value))
                      }`
                    }
                  />
                </StationRowCard>
              )}
            </div>
            <div>
              {data.Filesystem && (
                <StationRowCard>
                  <Title>File System</Title>
                  <StationRowPropertiesInfo data={data.Filesystem} />
                </StationRowCard>
              )}
              {data.Load && (
                <StationRowCard>
                  <Title>Load</Title>
                  <StationRowPropertiesInfo data={data.Load} />
                </StationRowCard>
              )}
            </div>
            <div>
              {data.Host && (
                <StationRowCard>
                  <Title>Host</Title>
                  <StationRowPropertiesInfo
                    gridSizes={[1, 2]}
                    data={{
                      ...omit(data.Host, 'Hostname'),
                      upTime: `${data.Host.Uptime.Hours}:${data.Host.Uptime.Minutes}:${data.Host.Uptime.Seconds}`,
                      operatingSystem: `${data.Host.OperatingSystem.Name} ${data.Host.OperatingSystem.Release}`
                    }}
                  />
                </StationRowCard>
              )}
              {data.LicenceStatus && (
                <StationRowCard>
                  <Title>License</Title>
                  <StationRowPropertiesInfo data={data.LicenceStatus} />
                </StationRowCard>
              )}
            </div>
            <div>
              {data.Internet && (
                <StationRowCard>
                  <Title>Internet</Title>
                  <StationRowPropertiesInfo data={data.Internet} />
                </StationRowCard>
              )}
              {data.Memory && (
                <StationRowCard>
                  <Title>Memory</Title>
                  <StationRowPropertiesInfo
                    data={data.Memory}
                    content={formatBytes}
                  />
                </StationRowCard>
              )}
            </div>
          </Row>
          <Row gridSizes={[1.5, 2, 2.5]}>
            <div>
              {data.Network && (
                <StationRowCard>
                  <Title>Network</Title>
                  <StationRowPropertiesInfo data={data.Network} />
                </StationRowCard>
              )}
            </div>
            <div>
              {data.Test && (
                <StationRowCard>
                  <Title>Test</Title>
                  <StationRowPropertiesInfo data={omit(data.Test, 'Id')} />
                </StationRowCard>
              )}
              {data.Packages && (
                <StationRowCard>
                  <Title>Packages</Title>
                  {data.Packages.map(({ Name, Version, Source }) => (
                    <StationRowPropertiesInfo
                      key={`${Name}-${Version}-${Source}`}
                      shouldCapitalize={false}
                      gridSizes={[4, 3]}
                      data={{
                        [Name]: `${Version} (${Source.substring(0, 15)})`
                      }}
                    />
                  ))}
                </StationRowCard>
              )}
            </div>
            <div>
              {data.Processes && (
                <StationRowCard>
                  <Title>Processes</Title>
                  {data.Processes.map(({ Name, Cpu, Resident, Size }) => (
                    <StationRowPropertiesInfo
                      key={`${Name}-${Cpu}-${Resident}-${Size}`}
                      gridSizes={[3, 4]}
                      shouldCapitalize={false}
                      data={{
                        [Name]: `CPU: ${toPercent(Number(Cpu))} RSS: ${formatBytes(
                          Number(Resident)
                        )} VSZ: ${formatBytes(Number(Size))}`
                      }}
                    />
                  ))}
                </StationRowCard>
              )}
            </div>
          </Row>
        </Popup>
      )}
    </>
  )
}

const StationRowCard = styled(Card)`
  padding: 16px;
  padding-bottom: 4px;
  margin-top: 8px;
  margin-bottom: 8px;
`

const StationRowPropertiesInfo = styled(PropertiesInfo)`
  font-size: 13px;
  margin: 1px 0;
`

const Title = styled.p`
  margin-bottom: 2px;
`

const Popup = styled.div<{ shown: boolean }>`
  font-size: 13px;
  margin: ${({ shown }) => (shown ? '12px 0px 24px 0px' : 0)};
  transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
  max-height: ${({ shown }) => (shown ? 1500 : 0)}px;
  overflow: hidden;
  transition: 400ms all;
  color: #666;
`

const Row = styled.div<{ gridSizes?: number[] }>`
  display: grid;
  grid-template-columns: ${({ gridSizes }) =>
    gridSizes ? joinFractions(gridSizes) : `repeat(5, 1fr)`};
  grid-gap: 8px;

  @media screen and (max-width: ${mobileSize}px) {
    grid-template-columns: 1fr;
  }

  ${Title} {
    font-weight: bold;
  }
`
