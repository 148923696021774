import React, { useCallback } from 'react'
import Flex from '../../../../../components/auto-flow-flex'
import Card from '../../../../../components/card'
import CardTitle from '../../../../../components/card-title'
import Checkbox from '../../../../../components/checkbox'
import LoadingSpinner from '../../../../../components/loading-spinner'
import { Gridder } from '../../../../../components/grids'
import HorizontalTab from '../../../../../components/horizontal-tab'
import { RadioSelect } from '../../../../../components/radio-button'
import { DeviceSetupParameters } from '../../../../../types/dbmc2evb2'
import { resizeArray } from '../../../../../utils/array'
import Subtitle from '../../../../../components/subtitle'
import SubSubtitle from '../../../../../components/subsubtitle'
import setupFields from '../../constants/fields/setup-fields'
import { toHex, updateRegister } from '../../utils/binary'
import { modeToString } from '../../utils/dbmc2'
import { createInputComponents } from '../input-fields'

const editorComponentHeight = '220px'

interface SetupData {
  parameters: DeviceSetupParameters
  onChange: (p: DeviceSetupParameters) => void
}

const channelToString = (c: number) => (c === 1 ? 'Mono' : 'Stereo')

const allowedModes = [2, 3, 5]

const channelOptions = [1, 2].map(channel => ({
  label: channelToString(channel),
  value: channel
}))

export default function SetupParametersDesigner({
  parameters,
  onChange
}: SetupData) {
  const isLoading = parameters === undefined

  const updateChannels = useCallback(
    (numOfChannels: number) => {
      parameters.header.number_of_channels = numOfChannels
      parameters.data.channels = resizeArray(
        parameters.data.channels,
        numOfChannels
      )
      onChange(parameters)
    },
    [parameters, onChange]
  )

  const updateModes = useCallback(
    (mode, newValue) => {
      if (newValue === false) {
        if (parameters.header.number_of_modes > 1) {
          const i = parameters.header.modes.indexOf(mode)
          if (i >= 0) {
            parameters.header.modes.splice(i, 1)
            parameters.header.number_of_modes = parameters.header.modes.length
            parameters.data.channels.forEach(channel =>
              channel.modes.splice(i, 1)
            )
          }
        }
      } else {
        if (parameters.header.modes.indexOf(mode) === -1) {
          parameters.header.modes.push(mode)
          parameters.header.number_of_modes = parameters.header.modes.length
          parameters.data.channels.forEach(
            channel =>
              (channel.modes = resizeArray(
                channel.modes,
                parameters.header.number_of_modes
              ))
          )
        }
      }
      onChange(parameters)
    },
    [parameters, onChange]
  )

  const updateCommon = useCallback(
    (register, field, value, bitmin, bitmax) => {
      parameters.data.common[register] = updateRegister(
        parameters.data.common[register],
        bitmin,
        bitmax,
        value
      )
      onChange(parameters)
    },
    [parameters, onChange]
  )

  const updateChannelCommon = useCallback(
    (channelIndex, register, field, value, bitmin, bitmax) => {
      parameters.data.channels[channelIndex][register] = updateRegister(
        parameters.data.channels[channelIndex][register],
        bitmin,
        bitmax,
        value
      )
      onChange(parameters)
    },
    [parameters, onChange]
  )

  const updateChannelMode = useCallback(
    (channelIndex, modeIndex, register, field, value, bitmin, bitmax) => {
      parameters.data.channels[channelIndex].modes[modeIndex][
        register
      ] = updateRegister(
        parameters.data.channels[channelIndex].modes[modeIndex][register],
        bitmin,
        bitmax,
        value
      )
      onChange(parameters)
    },
    [parameters, onChange]
  )

  const getChannelName = useCallback(
    (channelIndex: number) => {
      if (parameters.header.number_of_channels < 2) return ''
      return channelIndex === 0 ? 'Left Channel' : 'Right Channel'
    },
    [parameters]
  )

  const getModeName = useCallback(
    (modeIndex: number) => modeToString(parameters.header.modes[modeIndex]),
    [parameters]
  )

  return (
    <>
      {isLoading ? (
        <div style={{ textAlign: 'center' }}>
          <LoadingSpinner variant={'dark'} />
        </div>
      ) : (
        <>
          <Gridder align={'start'} template={['200px', '1fr']} gap={'24px'}>
            <Card>
              <CardTitle>Channels</CardTitle>
              <RadioSelect
                options={channelOptions}
                onChange={o => updateChannels(o.value as number)}
                value={parameters.header.number_of_channels}
              />
            </Card>
            <Card>
              <CardTitle>Modes</CardTitle>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                {allowedModes.map(mode => (
                  <Flex key={mode} gap={'12px 8px'} style={{ marginRight: 20 }}>
                    <Checkbox
                      onChange={newValue => updateModes(mode, newValue)}
                      initialValue={parameters.header.modes.includes(mode)}
                    />
                    <p>{modeToString(mode)}</p>
                  </Flex>
                ))}
              </div>
            </Card>
          </Gridder>
          <Gridder template={['1fr']} gap={'24px'}>
            <div>
              <Subtitle>Common</Subtitle>
              <HorizontalTab
                nested
                searchable={false}
                height={editorComponentHeight}
                options={Object.keys(setupFields.common)
                  .map(registerName => ({
                    registerName,
                    register:
                      setupFields.common[registerName].prop ??
                      registerName.toLowerCase()
                  }))
                  .map(({ registerName, register }) => ({
                    title: registerName,
                    subtitle: toHex(parameters.data.common[register], 8),
                    component: createInputComponents(
                      parameters.data.common[register],
                      setupFields.common[registerName].fields,
                      setupFields.common[registerName].mask,
                      (field, { value, bitmin, bitmax }) =>
                        updateCommon(register, field, value, bitmin, bitmax)
                    )
                  }))}
              />
            </div>
          </Gridder>
          {parameters.data.channels.slice(0, 2).map((channel, channelIndex) => (
            <div key={`channel-${channelIndex}`}>
              <Subtitle>{getChannelName(channelIndex)}</Subtitle>
              <Gridder template={['1fr', '1fr']} gap={'32px'}>
                <div>
                  <SubSubtitle>Common</SubSubtitle>
                  <HorizontalTab
                    nested
                    persistenceKey={`channel-${channelIndex}`}
                    searchable={false}
                    navigationProps={{
                      width: '240px',
                      fontSize: 14
                    }}
                    height={editorComponentHeight}
                    options={Object.keys(setupFields.channel)
                      .map(registerName => ({
                        registerName,
                        register:
                          setupFields.channel[registerName].prop ??
                          registerName.toLowerCase()
                      }))
                      .map(({ registerName, register }) => ({
                        title: registerName,
                        subtitle: toHex(
                          parameters.data.channels[channelIndex][register],
                          8
                        ),
                        component: createInputComponents(
                          parameters.data.channels[channelIndex][register],
                          setupFields.channel[registerName].fields,
                          setupFields.channel[registerName].mask,
                          (field, { value, bitmin, bitmax }) =>
                            updateChannelCommon(
                              channelIndex,
                              register,
                              field,
                              value,
                              bitmin,
                              bitmax
                            )
                        )
                      }))}
                  />
                </div>
                {parameters.data.channels[channelIndex].modes
                  .slice(0, parameters.header.number_of_modes)
                  .map((mode, modeIndex) => (
                    <div key={`channel-mode-${channelIndex}-${modeIndex}`}>
                      <SubSubtitle>{getModeName(modeIndex)}</SubSubtitle>
                      <HorizontalTab
                        nested
                        persistenceKey={`channel-mode-${channelIndex}-${modeIndex}`}
                        searchable={false}
                        navigationProps={{
                          width: '240px',
                          fontSize: 14
                        }}
                        height={editorComponentHeight}
                        options={Object.keys(setupFields.mode)
                          .map(registerName => ({
                            registerName,
                            register:
                              setupFields.mode[registerName].prop ??
                              registerName.toLowerCase()
                          }))
                          .map(({ registerName, register }) => ({
                            title: registerName,
                            subtitle: toHex(parameters.data.channels[channelIndex].modes[modeIndex][register],8),
                            component: createInputComponents(
                              parameters.data.channels[channelIndex].modes[modeIndex][register],
                              setupFields.mode[registerName].fields,
                              setupFields.mode[registerName].mask,
                              (field, { value, bitmin, bitmax }) =>
                                updateChannelMode(
                                  channelIndex,
                                  modeIndex,
                                  register,
                                  field,
                                  value,
                                  bitmin,
                                  bitmax
                                )
                            )
                          }))}
                      />
                    </div>
                  ))}
              </Gridder>
            </div>
          ))}
        </>
      )}
    </>
  )
}
