import styled from '@emotion/styled/macro'
import React, { useCallback, useMemo } from 'react'
import { ReactComponent as EditIcon } from '../../../assets/icons/edit.svg'
import placeholderImage from '../../../assets/images/headphone-placeholder.jpg'
import Button from '../../../components/button'
import SectionTitle from '../../../components/section-title'
import TopRightOptions from '../../../components/top-right-options'
import useToggle from '../../../hooks/use-toggle'
import { deleteProductImage } from '../../../services/entities/product'
import { AsyncRequest } from '../../../types/async'
import { Base64Media } from '../../../types/misc'
import { ProductInfo } from '../../../types/product'
import { timestampToLocalDate } from '../../../utils/date-time'
import EditProductDetailsForm from './edit-product-details-form'
import Modal from '../../../components/modal'
import ImageHoverCard from '../../../components/image-hover-card'

const descriptionMaxLength = 1000

export default function ProductDetails({
  data,
  refreshData,
  image,
  productId
}: AsyncRequest<ProductInfo> & {
  image: AsyncRequest<Base64Media>
  productId: string
}) {
  const [isDeleting, startDeleting, finishDeleting] = useToggle(false)
  const [previewShown, showPreview, hidePreview] = useToggle(false)
  const [editDetailsShown, showEditDetails, hideEditDetails] = useToggle(false)
  const onPreviewClick = useCallback(() => {
    if (image?.data?.base64) showPreview()
  }, [image, showPreview])
  const hasImage = useMemo(() => !image.error || !!image.data.base64, [image])
  const imageData = useMemo(() => {
    return image.error || image.loading ? placeholderImage : image?.data?.base64
  }, [image])
  const onEditSave = useCallback(() => {
    hideEditDetails()
    refreshData()
    image.refreshData()
  }, [hideEditDetails, refreshData, image])
  const onDeleteConfirm = async () => {
    startDeleting()
    await deleteProductImage(productId)
    image.refreshData()
    finishDeleting()
  }

  return (
    <>
      <Modal shown={editDetailsShown} hide={hideEditDetails}>
        <SectionTitle title={'Edit Product Details'} />
        <EditProductDetailsForm
          onSave={onEditSave}
          image={image}
          productId={productId}
          refreshData={refreshData}
          imageData={imageData}
          onDeleteConfirm={onDeleteConfirm}
          isDeleting={isDeleting}
          {...data}
        />
      </Modal>
      <TitlePane>
        <SectionTitle noMargin={true} title={'Product Details'} />
        <TopRightOptions>
          <Button onClick={showEditDetails} icon={true}>
            <StyledEditIcon fill={'#333'} height={16} width={16} />
          </Button>
        </TopRightOptions>
      </TitlePane>
      <Container>
        <ImageHoverCard
          backgroundColor={'transparent'}
          loading={image.loading}
          onClick={onPreviewClick}
          clickable={!!image?.data?.base64}
          height={'200px'}
          width={'200px'}>
          <img src={imageData} alt={'Headphone'} />
        </ImageHoverCard>
        <Details>
          <Description>
            {data?.description?.length > descriptionMaxLength
              ? `${data?.description?.substr(0, descriptionMaxLength)}...`
              : data?.description}
          </Description>
          <Manufacturer>{data.manufacturer}</Manufacturer>
          {data.timestamp && (
            <Timestamp>{timestampToLocalDate(data.timestamp)}</Timestamp>
          )}
        </Details>
      </Container>
      {hasImage && (
        <Modal shown={previewShown} hide={hidePreview} photo={true}>
          <img src={imageData} alt={'Device Preview'} />
        </Modal>
      )}
    </>
  )
}

const StyledEditIcon = styled(EditIcon)`
  width: 16px;
  height: 16px;
  fill: #333;
  stroke: #333;
`

const TitlePane = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
`

const Timestamp = styled.div`
  font-size: 13px;
`

const Manufacturer = styled.div`
  font-size: 14px;
  margin-top: 24px;
`

const Description = styled.p`
  font-size: 14px;
  height: 132px;
`

const Details = styled.div`
  line-height: 1.8;
  display: grid;
  grid-auto-flow: row;
`

const Container = styled.div`
  display: grid;
  grid-template-columns: 212px 1fr;
`
