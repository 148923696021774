import React, { useContext, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { RootStoreContext } from '../context/root-store'
import { Redirect } from 'react-router'

function Logout() {
  const {
    authentication: { logout }
  } = useContext(RootStoreContext)

  useEffect(() => {
    logout()
  }, [logout])

  return <Redirect to={'/login'} />
}

export default observer(Logout)
