export default `
    input, textarea {
        font-weight: 300;
        display: block;
        width: 100%;
        padding: 14px;
        border-radius: 4px;
        font-size: 14px;
        background-color: transparent;
        border: 1px solid rgba(0, 0, 0, .1);
        color: #333;
        font-family: 'Px Grotesk', sans-serif;

        .touched {
            &:invalid {
                border: 1px solid #ec1b23;
            }
        }

        &:focus {
            border: 1px solid rgba(0, 0, 0, .2);
        }

        &:disabled {
            opacity: 0.7;
        }
    }
`
