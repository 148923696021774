import styled from '@emotion/styled/macro'
import React, { useCallback, useMemo } from 'react'
import { Flipped } from 'react-flip-toolkit'
import { Link } from 'react-router-dom'
import { ReactComponent as InvisibleIcon } from '../../../assets/icons/invisibility.svg'
import placeholderImage from '../../../assets/images/headphone-placeholder.jpg'
import Shimmer from '../../../components/shimmer'
import TopRightIcon from '../../../components/top-right-icon'
import useAsync from '../../../hooks/use-async'
import { fetchProductImage } from '../../../services/entities/product'
import { ProductInfo } from '../../../types/product'

interface Props extends ProductInfo {
  stagger: boolean
  addToFilteredIds: (id: ProductInfo['id']) => void
  type: string
}

export default function ProductCard({
  addToFilteredIds,
  id,
  name
}: React.ComponentProps<typeof StyledCard> & Props) {
  const flipId = `item-${id}`
  const idParams = useMemo(() => [id], [id])
  const {
    data: { base64: image },
    loading
  } = useAsync({ fn: fetchProductImage, args: idParams })

  const clearFilter = useCallback(() => addToFilteredIds(id), [
    addToFilteredIds,
    id
  ])

  return (
    <Flipped flipId={flipId} key={flipId}>
      <div>
        <StyledCard>
          <TopRightIcon>
            <InvisibleIcon
              fill="#585250"
              height={20}
              width={20}
              onClick={clearFilter}
            />
          </TopRightIcon>
          <Link to={`/missioncontrol/product/${id}`} key={`${name}-${id}`}>
            <ProductPreview>
              {loading && <Shimmer />}
              <img
                alt="Product Preview"
                src={!!image ? image : placeholderImage}
              />
            </ProductPreview>
            <ProductDetails>
              <ProductName>{name}</ProductName>
            </ProductDetails>
          </Link>
        </StyledCard>
      </div>
    </Flipped>
  )
}

const ProductName = styled.div`
  font-weight: 300;
  line-height: 1.5;
`

const ProductDetails = styled.div`
  padding: 24px 17px;
`

const ProductPreview = styled.div`
  height: 200px;
  width: 100%;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  overflow: hidden;
  position: relative;

  img {
    transition: 400ms;
    object-fit: cover;
    height: 200px;
    width: 100%;
  }
`

const StyledCard = styled.div`
  background-color: #fff;
  cursor: pointer;
  transition: 400ms all;
  border-radius: 8px;
  position: relative;

  :hover {
    img {
      transform: scale(1.05);
    }
  }
`
