import WebSocketAsPromised from 'websocket-as-promised'

export async function getSocketConnection() {
  const socket = new WebSocketAsPromised('ws://localhost:4321', {
    packMessage: data => {
      return JSON.stringify(data)
    },

    unpackMessage: message => {
      let data = JSON.parse(message as string)
      if (typeof data === 'object' && data !== null) {
        const keys = Object.keys(data)
        if (keys.length > 0) {
          data = data[keys[0]]
          data['Type'] = keys[0]
        }
      }
      return data
    },

    attachRequestId: (data, requestId) => {
      if (typeof data === 'object' && data !== null) {
        const keys = Object.keys(data)
        if (keys.length > 0) {
          const key = keys[0]
          data[key].Id = requestId
        }
      }
      console.log('WEB SOCKET data', data)
      return data
    },

    extractRequestId: data =>
      data &&
      String(data.Type) !== 'EepromProgress' &&
      String(data.Type) !== 'EepromUpdate' &&
      String(data.Type) !== 'GpioUpdate' &&
      String(data.Type) !== 'SettingsUpdate' &&
      data.Id
  })

  await new Promise((resolve, reject) => {
    console.log('WEB SOCKET opening connection')
    socket.open().then(openedSocket => {
      console.log('WEB SOCKET open')
      resolve(openedSocket)
    })
    setTimeout(() => {
      reject('WEB SOCKET did not open after timeout')
    }, 2000)
  })

  socket.onClose.addListener(() => {
    console.log('WEB SOCKET closed')
    socket.removeAllListeners()
  })

  return socket
}
