import styled from '@emotion/styled/macro'
import React from 'react'
import Button from '../../../components/button'
import Card from '../../../components/card'
import LoadingSpinner from '../../../components/loading-spinner'
import Status from '../../../components/status'
import Subtitle from '../../../components/subtitle'
import useStatusError from '../../../hooks/use-status-error'
import useToggle from '../../../hooks/use-toggle'
import {
  postStationReport,
  postStationReset
} from '../../../services/entities/station'
import { interpretError } from '../../../utils/common'

interface Props {
  stationName: string
  refreshStationMessages: () => void
}

export default function ResetReportOpts({
  stationName,
  refreshStationMessages
}: Props) {
  const [loading, startLoading, finishLoading] = useToggle(false)
  const { status, error, setStatus, setError } = useStatusError()

  const onReset = async () => {
    startLoading()
    try {
      await postStationReset(stationName)
      setStatus('Successfully queued reset')
    } catch (err) {
      setError(interpretError(err))
    }
    finishLoading()
    refreshStationMessages()
  }

  const onReport = async () => {
    startLoading()
    try {
      await postStationReport(stationName)
      setStatus('Successfully queued report')
    } catch (err) {
      setError(interpretError(err))
    }
    finishLoading()
    refreshStationMessages()
  }

  return (
    <ResetReportCard>
      <VerticallyMiddle>
        <StyledSubtitle>Options </StyledSubtitle>
        {loading && (
          <StyledLoadingSpinner width={18} height={18} variant={'dark'} />
        )}
      </VerticallyMiddle>
      {(error || status) && <Status>{status ?? error}</Status>}
      <VerticallyMiddle>
        <Button onClick={onReset} disabled={loading}>
          Reset
        </Button>
        <Button onClick={onReport} disabled={loading}>
          Report
        </Button>
      </VerticallyMiddle>
    </ResetReportCard>
  )
}

const StyledLoadingSpinner = styled(LoadingSpinner)`
  align-self: start;
`

const StyledSubtitle = styled(Subtitle)`
  margin-top: 0px;
`

const ResetReportCard = styled(Card)`
  width: fit-content;
`

const VerticallyMiddle = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 10px;
  grid-auto-columns: max-content;
`
