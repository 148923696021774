import useAsync from '../hooks/use-async'
import usePagedAsync from '../hooks/use-paged-async'

// not used
export const hasFieldsSet = (
  obj: Record<string, Record<string, string> | string>,
  fields: string[]
) => {
  if (!obj || typeof obj !== 'object') return false

  for (let i = 0; i < fields.length; i++) {
    if (!obj.hasOwnProperty(fields[i]) || !obj[fields[i]]) return false
  }
  return true
}

export const requestSuccessful = (status: number) => status === 200

// not tested
export const delay = (ms: number) => new Promise(res => setTimeout(res, ms))

// not used
export const renameProperty = <ResultingType>(
  obj: Record<string, unknown>,
  oldKey: string,
  newKey: string
): ResultingType => {
  if (oldKey !== newKey) {
    const descriptor = Object.getOwnPropertyDescriptor(obj, oldKey)

    if (descriptor) Object.defineProperty(obj, newKey, descriptor)
    delete obj[oldKey]
  }
  return obj as ResultingType
}

export const deepEqual = (obj1, obj2) => {
  function isObject(obj) {
    if (typeof obj === 'object' && obj != null) {
      return true
    } else {
      return false
    }
  }

  if (obj1 === obj2) {
    return true
  } else if (isObject(obj1) && isObject(obj2)) {
    if (Object.keys(obj1).length !== Object.keys(obj2).length) {
      return false
    }
    for (const prop in obj1) {
      if (!deepEqual(obj1[prop], obj2[prop])) {
        return false
      }
    }
    return true
  }
}

export const interpretError = (err: unknown) => {
  let error = 'An unknown error occured.'

  if (typeof err === 'undefined' || typeof err === 'boolean') return error

  if (Array.isArray(err)) {
    error = err.join(', ')
  } else if (typeof err === 'object' && err instanceof Error) {
    error = err.message
  } else if (typeof err === 'string') error = err

  return error
}



export function formatBytes(bytes: number, decimals = 2) {
  if (bytes === 0) return '0 Bytes'
  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  const i = Math.floor(Math.log(bytes) / Math.log(k))
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

// not used
export function readableBoolean(bool: boolean) {
  if (bool) return 'Yes'
  else return 'No'
}

// not used
export function getFilename(filePath: string) {
  return filePath.split('\\')[filePath.split('\\').length - 1]
}

export function noop() {
  return
}

// not used
export function pick<T, K extends keyof T>(obj: T, ...paths: K[]): Pick<T, K> {
  return paths.reduce(
    (prev, curr) => ({
      ...prev,
      [curr]: obj[curr]
    }),
    {} as Pick<T, K>
  )
}

export function omit<T, K extends string>(obj: T, ...paths: (keyof T)[]) {
  return Object.keys(obj)
    .filter(key => !paths.includes(key as keyof T))
    .reduce<Omit<T, K>>(
      (accumulator, prop) => ({
        ...accumulator,
        [prop]: obj[prop]
      }),
      {} as Omit<T, K>
    )
}

export function joinFractions(sizes: number[]) {
  return sizes.map(size => `${size ?? 1}fr `)
}



export function toPercent(value: number, decimalPlaces = 1) {
  return value && Number(value).toFixed(decimalPlaces) + ' %'
}

// not used
export function chooseRandom<T>(arr: T[]): T {
  return arr[Math.floor(Math.random() * arr.length)]
}

// ** cant test ** because the way jest is set up does not allow import of modules
// I've tried to change this, but react-app-rewired is taking over the config of Jest
// by removing the react-app-rewired, and calling Jest directly works, but
// when trying to import usePagedAsync, it ends up trying to import SVG files
// which then trips up Jest.
export function areInitialized(
  ...asyncEntity: Array<
    ReturnType<typeof useAsync> | ReturnType<typeof usePagedAsync>
  >
) {
  for (let i = 0; i < asyncEntity.length; i++)
    if (!asyncEntity[i].initialized) return false
  return true
}


export function isEmpty<T>(obj: T extends infer U ? U : unknown) {
  return Object.entries(obj).length === 0 && obj.constructor === Object
}

// wont test
export function throttle(
  func: Function,
  wait: number,
  options?: { leading?: boolean; trailing?: boolean }
) {
  let context, args, result
  let timeout = null
  let previous = 0
  if (!options) options = {}
  const later = function() {
    previous = options.leading === false ? 0 : Date.now()
    timeout = null
    result = func.apply(context, args)
    if (!timeout) context = args = null
  }
  return function(...args) {
    const now = Date.now()
    if (!previous && options.leading === false) previous = now
    const remaining = wait - (now - previous)
    context = this
    if (remaining <= 0 || remaining > wait) {
      if (timeout) {
        clearTimeout(timeout)
        timeout = null
      }
      previous = now
      result = func.apply(context, args)
      if (!timeout) context = args = null
    } else if (!timeout && options.trailing !== false) {
      timeout = setTimeout(later, remaining)
    }
    return result
  }
}

// not used
export function shallowObjectEqual<T>(a: T, b: T) {
  const aKeys = Object.keys(a)
  const bKeys = Object.keys(b)

  if (aKeys.length !== bKeys.length) return false
  for (let i = 0; i < aKeys.length; i++)
    if (a[aKeys[i]] !== b[aKeys[i]]) return false

  return true
}

// not used
export function shallowArrayEqual<T>(a: T[], b: T[]) {
  if (a.length !== b.length) return false

  for (let i = 0; i < a.length; i++) if (a[i] !== b[i]) return false
  return true
}

// not used
export function shallowEqual<T, U>(
  a: T extends Array<U> ? U[] : T,
  b: typeof a
) {
  if (Array.isArray(a) && Array.isArray(b)) return shallowArrayEqual(a, b)
  else if (typeof a === 'object' && typeof b === 'object')
    return shallowObjectEqual(a, b)
  return false
}

// wont test
export function debounce<T>(
  func: (...args: T[]) => void,
  wait: number,
  immediate = false
) {
  let timeout
  return function(...args: T[]) {
    const later = function() {
      timeout = null
      if (!immediate) func(...args)
    }
    const callNow = immediate && !timeout
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
    if (callNow) func(...args)
  }
}

// not used
export function nativeEqual<T>(a: T, b: T) {
  return a === b
}

// not used
export function combine<T>(a: T[], b: T[]) {
  return [...new Set([...a, ...b])]
}

export function sleep(ms){
  return new Promise((resolve, reject) => setTimeout(resolve, ms))
}
