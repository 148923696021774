import styled from '@emotion/styled/macro'
import React from 'react'
import { ReactComponent as CloseIcon } from '../assets/icons/close.svg'
import Showable from './showable'
import useKeypress from '../hooks/use-keypress'
import { keyEscape } from '../utils/key-codes'
import { noop } from '../utils/common'

interface ModalProps {
  hide: () => void
  shown: boolean
}

export default function Modal({
  shown,
  hide,
  children,
  modalContentProps,
  photo = false,
  ...props
}: ModalProps &
  React.ComponentProps<typeof ModalWrapper> & {
    children: React.ReactNode
    modalContentProps?: React.ComponentProps<typeof ModalContent>
    photo?: boolean
  }) {
  return (
    <ModalWrapper shown={shown} hide={hide} {...props}>
      <ModalContent photo={photo} close={hide} {...modalContentProps}>
        {children}
      </ModalContent>
    </ModalWrapper>
  )
}

interface ModalContentProps {
  title?: string
  close?: () => void
  photo?: boolean
}

export function ModalContent({
  children,
  title = '',
  close,
  photo = false,
  ...props
}: React.ComponentProps<'div'> & ModalContentProps) {
  return (
    <ModalContentContainer {...props} photo={photo}>
      <CloseIconContainer onClick={close} photo={photo}>
        <StyledCloseIcon fill={photo ? '#ffffff' : '#000000'} />
      </CloseIconContainer>
      {title && <ModalHeader>{title}</ModalHeader>}
      <Content>{children}</Content>
    </ModalContentContainer>
  )
}

interface ModalWrapperProps {
  onClose?: () => void
  title?: string
}

export function ModalWrapper({
  children,
  shown,
  hide,
  title = '',
  onClose = noop,
  ...props
}: React.ComponentProps<typeof ModalWrapperContainer> & ModalWrapperProps) {
  useKeypress(keyEscape, hide)

  const closeWithHandler = () => {
    hide()
    onClose()
  }

  return (
    <ModalWrapperContainer shown={shown} hide={hide} {...props}>
      <Overlay onClick={hide} />
      <ModalContent close={closeWithHandler} title={title}>
        {children}
      </ModalContent>
    </ModalWrapperContainer>
  )
}

const Overlay = styled.div`
  background-color: rgba(0, 0, 0, 0.45);
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
`

const ModalWrapperContainer = styled(Showable)`
  opacity: ${({ shown }) => (shown ? 1 : 0)};
  transition: 400ms ease;
  position: fixed;

  img {
    border-radius: 8px;
    display: block;
  }
`

const StyledCloseIcon = styled(CloseIcon)`
  cursor: pointer;
  height: 12px;
`

const CloseIconContainer = styled.div<{ photo?: boolean }>`
  position: absolute;
  top: ${({ photo }) => (photo ? -16 : 12)}px;
  right: ${({ photo }) => (photo ? -16 : 12)}px;
`

const Content = styled.div`
  font-weight: 300;
  font-size: 15px;
`

export const ModalHeader = styled.div`
  font-size: 22px;
  color: #515151;
  margin-bottom: 32px;
`

const ModalContentContainer = styled.div<{ photo?: boolean }>`
  background: #f2f2f2;
  border-radius: 8px;
  padding: ${({ photo }) => (photo ? 0 : '26px 28px')};
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
`
