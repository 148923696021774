import { types } from 'mobx-state-tree'
import { localPersistKey } from '../constants'
import Authentication from './authentication'

const Root = types
  .model('Root', {
    authentication: Authentication
  })
  .postProcessSnapshot(snapshot =>
    localStorage.setItem(localPersistKey, JSON.stringify(snapshot))
  )

export default Root
