import { useEffect, useState, useCallback } from 'react'
import {
  getBoardControl,
  getBoardMicrophones,
  getCalibrationParameters,
  getDeviceControl,
  getFeedbackParameters,
  getFeedforwardParameters,
  getFirmwareVersion,
  getPlaybackParameters,
  getDeviceSetupParameters,
  getTalkthroughParameters,
  getVoicePickupParameters,
  setAnc,
  setAncMicrophones,
  setAwareness,
  setCalibrationParameters,
  setDaughter,
  setFeedbackParameters,
  setFeedforwardParameters,
  setFilter,
  setLed,
  setMicbias,
  setPlaybackParameters,
  setReset,
  setDeviceSetupParameters,
  setTalkthroughParameters,
  setVoiceMicrophones,
  setVoicePickupParameters,
} from './board'
import {
  deviceSetupParametersParseBytes,
  playbackParametersParseBytes,
  voicePickupParametersParseBytes,
  feedbackParametersParseBytes,
  feedforwardParametersParseBytes,
  talkthroughParametersParseBytes,
  calibrationParametersParseBytes,
} from './serialization'
import initialData from '../constants/initial-data'
import { Dbmc2Evb2 } from '../../../../types/dbmc2evb2'

// deliberately keep this as a global because we don't want changes
// to this value to trigger useEffect 
let isLoadingFromBoard:boolean = true

export function useBoard(socket, path, firmware) {
  const [data, setData] = useState<Dbmc2Evb2>({ ...initialData, path })

  const [isReady, setIsReady] = useState(false)

  const loadFromBoard = useCallback(() => {
    isLoadingFromBoard = true

    const doLoadData = async () => {
      const boardControl = await getBoardControl(socket, path)
      const microphones = await getBoardMicrophones(socket, path)
      const setup = await getDeviceSetupParameters(socket, path)
      const playback = await getPlaybackParameters(socket, path)
      const voicepickup = await getVoicePickupParameters(socket, path)
      const feedback = await getFeedbackParameters(socket, path)
      const feedforward = await getFeedforwardParameters(socket, path)
      const talkthrough = await getTalkthroughParameters(socket, path)
      const calibration = await getCalibrationParameters(socket, path)
      const firmware = await getFirmwareVersion(socket, path)
      const deviceControl = await getDeviceControl(socket, path)

      // TODO: the parsing of these byte arrays can fail if the format for the parameters
      // stored on the DBMC2 is out of sync with the parser. If any of the parsers fail,
      // a message should be displayed to the user

      setData(currentData => ({
        ...currentData,
        boardControl,
        deviceControl,
        microphones,
        firmware,
        parameters: {
          ...currentData.parameters,
          setup: deviceSetupParametersParseBytes(setup),
          playback: playbackParametersParseBytes(playback),
          feedback: feedbackParametersParseBytes(feedback),
          feedforward: feedforwardParametersParseBytes(feedforward),
          talkthrough: talkthroughParametersParseBytes(talkthrough),
          voicepickup: voicePickupParametersParseBytes(voicepickup),
          calibration: calibrationParametersParseBytes(calibration)
        }
      }))

      setTimeout(() => {
        isLoadingFromBoard = false
        setIsReady(true)
      }, 1000)
    }

    doLoadData()
  }, [socket, path, setData])

  useEffect(() => {
    loadFromBoard()
  }, [loadFromBoard])

  useEffect(() => {
    setData(data => ({
      ...data,
      isFlashWriting: firmware.inprogress,
      flashMessage: firmware.inprogress ? `${firmware.action} ${firmware.complete}%` : ''
    }))
  }, [firmware, setData])

  useEffect(() => {
    if (isLoadingFromBoard) {
      return
    }
    if (data.microphones.anc !== undefined) {
      console.log('**** board control anc microphones data has changed *****')
      ;(async () => setAncMicrophones(socket, path, data.microphones.anc))()
    }
  }, [data.microphones.anc, path, socket])

  useEffect(() => {
    if (isLoadingFromBoard) {
      return
    }
    if (data.microphones.voice !== undefined) {
      console.log('**** board control voice microphones data has changed *****')
      ;(async () => setVoiceMicrophones(socket, path, data.microphones.voice))()
    }
  }, [data.microphones.voice, path, socket])

  useEffect(() => {
    if (isLoadingFromBoard) {
      return
    }

    if (data.microphones.micbias !== undefined) {
      console.log('**** board control micbias data has changed *****')
      ;(async () => setMicbias(socket, path, data.microphones.micbias))()
    }
  }, [data.microphones.micbias, path, socket])

  useEffect(() => {
    if (isLoadingFromBoard) return

    const reset = data.boardControl.reset
    if (reset !== undefined) {
      console.log('**** board control reset data has changed *****')
      ;(async () => setReset(socket, path, reset))()
      if (!reset) {
        loadFromBoard()
      }
    }
  }, [data.boardControl.reset, loadFromBoard, path, socket])

  useEffect(() => {
    if (isLoadingFromBoard) {
      return
    }
    if (data.deviceControl.anc !== undefined) {
      console.log('**** device control anc data has changed *****')
      ;(async () => {
        // FW bug work around to avoid error return from DBMC2
        await setAnc(socket, path, 0)
        await setAnc(socket, path, data.deviceControl.anc)
      })()
    }
  }, [data.deviceControl.anc, path, socket])

  useEffect(() => {
    if (isLoadingFromBoard) {
      return
    }
    if (data.deviceControl.awareness !== undefined) {
      console.log('**** device control awareness data has changed *****')
      ;(async () => setAwareness(socket, path, data.deviceControl.awareness))()
    }
  }, [data.deviceControl.awareness, path, socket])

  useEffect(() => {
    if (isLoadingFromBoard) {
      return
    }
    if (data.boardControl.led !== undefined) {
      console.log('**** board control led data has changed *****')
      ;(async () => setLed(socket, path, data.boardControl.led))()
    }
  }, [data.boardControl.led, path, socket])

  useEffect(() => {
    if (isLoadingFromBoard) {
      return
    }
    if (data.boardControl.filter !== undefined) {
      console.log('**** board control filter data has changed *****')
      ;(async () => setFilter(socket, path, data.boardControl.filter))()
    }
  }, [data.boardControl.filter, path, socket])

  useEffect(() => {
    if (isLoadingFromBoard) {
      return
    }
    if (data.boardControl.daughter !== undefined) {
      console.log('**** board control daughter data has changed *****')
      ;(async () => setDaughter(socket, path, data.boardControl.daughter))()
    }
  }, [data.boardControl.daughter, path, socket])

  useEffect(() => {
    if (isLoadingFromBoard) {
      return
    }
    if (data.parameters.setup !== undefined) {
      console.log('**** parameter device setup data has changed *****')
      ;(async () => setDeviceSetupParameters(socket, path, data.parameters.setup))()
    }
  }, [data.parameters.setup, path, socket])

  useEffect(() => {
    if (isLoadingFromBoard) {
      return
    }
    if (data.parameters.playback !== undefined) {
      console.log('**** parameter playback data has changed *****')
      ;(async () => setPlaybackParameters(socket, path, data.parameters.playback))()
    }
  }, [data.parameters.playback, path, socket])

  useEffect(() => {
    if (isLoadingFromBoard) return
    if (data.parameters.voicepickup !== undefined) {
      console.log('**** parameter voicepickup data has changed *****')
      ;(async () => setVoicePickupParameters(socket, path, data.parameters.voicepickup))()
    }
  }, [data.parameters.voicepickup, path, socket])

  useEffect(() => {
    if (isLoadingFromBoard) {
      return
    }
    
    if (data.parameters.feedback !== undefined) {
      console.log('**** parameter feedback data has changed *****',data.parameters.feedback)
      ;(async () => setFeedbackParameters(socket, path, data.parameters.feedback))()
    }
  }, [data.parameters.feedback, path, socket])

  useEffect(() => {
    if (isLoadingFromBoard) return
    if (data.parameters.feedforward !== undefined) {
      console.log('**** parameter feedforward data has changed *****')
      ;(async () => setFeedforwardParameters(socket, path, data.parameters.feedforward))()
    }
  }, [data.parameters.feedforward, path, socket])

  useEffect(() => {
    if (isLoadingFromBoard) return
    if (data.parameters.talkthrough !== undefined) {
      console.log('**** parameter talkthrough data has changed *****')
      ;(async () => setTalkthroughParameters(socket, path, data.parameters.talkthrough))()
    }
  }, [data.parameters.talkthrough, path, socket])

  useEffect(() => {
    if (isLoadingFromBoard) {
      return
    }
    if (data.parameters.calibration !== undefined) {
      console.log('**** parameter calibration data has changed *****')
      ;(async () => setCalibrationParameters(socket, path, data.parameters.calibration))()
    }
  }, [data.parameters.calibration, path, socket])

  return [data, setData, isReady] as const
}
