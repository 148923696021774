import styled from '@emotion/styled/macro'
import React, { useCallback, useState } from 'react'
import ImageHoverCard from '../../../components/image-hover-card'
import Modal from '../../../components/modal'
import SectionTitle from '../../../components/section-title'
import useToggle from '../../../hooks/use-toggle'
import evbOne from '../../../assets/images/evb1.jpg'
import evbTwo from '../../../assets/images/evb2.jpg'
import evbThree from '../../../assets/images/evb3.jpg'
import evbFour from '../../../assets/images/evb4.jpg'

const images = [evbOne, evbTwo, evbThree, evbFour]

export default function ConnectEVB() {
  const [currentImage, setCurrentImage] = useState<string>(null)
  const [shown, show, hide] = useToggle(false)

  const previewImage = useCallback(
    (imageLink: string) => {
      setCurrentImage(imageLink)
      show()
    },
    [setCurrentImage, show]
  )

  return (
    <>
      <SectionTitle title={'Connect'} />
      <Container>
        {images.map(imageLink => (
          <ImageHoverCard
            key={imageLink}
            onClick={() => previewImage(imageLink)}>
            <img src={imageLink} alt={'Connect EVB Tutorial'} />
          </ImageHoverCard>
        ))}
      </Container>
      <Modal hide={hide} shown={shown} photo={true}>
        <img
          style={{ maxHeight: 600, borderRadius: 8 }}
          src={currentImage}
          alt={'Clicked Connect EVB Tutorial'}
        />
      </Modal>
    </>
  )
}

const Container = styled.div`
  margin-top: 24px;
  margin-bottom: 24px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-gap: 24px;
`
