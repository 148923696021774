import styled from '@emotion/styled/macro'
import React, { useState } from 'react'
import defaultUser from '../assets/images/default-user.png'
import testResultProcessed from '../assets/images/test-result-processed.png'
import { systemInitiator } from '../constants'
import { timestampToLocalDate } from '../utils/date-time'
import { capitalize } from '../utils/text'

interface Props {
  initiator: string
  message: string
  timestamp: string
  details: Record<string, Record<string, string> | string>
}

export default function LogsInfo({
  details = {},
  initiator,
  message,
  timestamp
}: Props) {
  const [moreDetails, setMoreDetailsShown] = useState(false)
  const toggleMoreDetails = () => setMoreDetailsShown(shown => !shown)

  return (
    <Container>
      {initiator === systemInitiator ? (
        <SystemAvatar src={testResultProcessed} />
      ) : (
        <Avatar src={defaultUser} />
      )}
      <Details>
        <Email>{initiator}</Email>
        <Message>{message}</Message>
        <Timestamp>{timestampToLocalDate(timestamp)}</Timestamp>{' '}
        {!!Object.keys(details).length && (
          <>
            <DetailsSwitch onClick={toggleMoreDetails}>
              {moreDetails ? 'Hide Details' : 'Show Details'}
            </DetailsSwitch>
            <MoreDetails shown={moreDetails}>
              <MoreDetailsContent>
                {Object.keys(details).map((key, index) => (
                  <p key={`${details[key]}-${index}`}>
                    {capitalize(key)}: {details[key]}
                  </p>
                ))}
              </MoreDetailsContent>
            </MoreDetails>
          </>
        )}
      </Details>
    </Container>
  )
}

const MoreDetailsContent = styled.div`
  height: 100%;
  overflow: auto;
  padding: 12px;
`

const MoreDetails = styled.div<{ shown: boolean }>`
  font-size: 12px;
  color: #333333;
  line-height: 18px;
  max-height: ${({ shown }) => (shown ? 400 : 0)}px;
  overflow: hidden;
  transition: 400ms all;
  background-color: #eaeaea;
  border-radius: 12px;
  margin-top: ${({ shown }) => (shown ? '12px' : '0px')};
`

const DetailsSwitch = styled.div`
  font-size: 14px;
  transition: 400ms ease;
  cursor: pointer;

  &:hover {
    color: rgb(237, 28, 36);
  }
`

const Timestamp = styled.p`
  font-size: 12px;
  color: #666;
`

const Message = styled.p`
  color: #666666;
`

const Email = styled.p`
  color: #333333;
`

const Details = styled.div`
  font-weight: 300;
  line-height: 24px;
  font-size: 15px;
`

const Avatar = styled.img`
  height: 50px;
  width: 50px;
  background-color: #d4d4d4;
  border-radius: 50px;
`

const SystemAvatar = styled(Avatar)`
  background-color: rgb(237, 28, 36);
`

const Container = styled.div`
  display: grid;
  grid-template-columns: 50px 1fr;
  grid-column-gap: 25px;
  padding: 12px 12px 12px 0px;
  border-radius: 12px;
  transition: 400ms all;
  max-width: 300px;
`
