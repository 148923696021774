import styled from '@emotion/styled/macro'

export const FormContainer = styled.div`
  margin-top: 24px;
`

export const SubmitContainer = styled.div`
  display: grid;
  justify-items: end;
  margin-top: 6px;
`

export const InputGroup = styled.div`
  span {
    font-size: 12px;
    color: #d93900;
    font-weight: bold;
    margin-top: 4px;
    line-height: 1.5;
  }
`

export const InputError = styled.div`
  font-size: 12px;
  color: #ec1b23;
  font-weight: bold;
  margin-top: 4px;
  line-height: 1.5;
`

export const FormInline = styled.div`
  display: grid;
  grid-template-columns: 102px 1fr;
  align-items: center;

  p:first-of-type {
    align-self: start;
    line-height: 45px;
  }
`
