import styled from '@emotion/styled/macro'
import React from 'react'
import scope from '../assets/images/scope.jpg'
import BodyStyle from '../components/body-style'

export default function AuthLayout({
  children
}: Pick<React.ComponentProps<typeof Container>, 'children'>) {
  return (
    <Container>
      <BodyStyle style={authBackgroundImage} />
      <Overlay />
      <Content>
        <AuthForm>{children}</AuthForm>
      </Content>
    </Container>
  )
}

const authBackgroundImage = `
  background-image: url(${scope});
  background-position: center center;
  background-attachment: fixed;
  background-size: cover;
`

const AuthForm = styled.div`
  width: 380px;
  padding: 40px;
`

const Content = styled.div`
  display: grid;
  height: inherit;
  align-content: center;
  justify-content: center;
`

const Overlay = styled.div`
  position: fixed;
  background-color: rgba(35, 32, 31, 0.3);
  z-index: -1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`

const Container = styled.div`
  height: 100vh;
  width: 100%;
`
