import styled from '@emotion/styled/macro'
import React, { useContext } from 'react'
import { ReactComponent as PlusIcon } from '../../../assets/icons/plus-icon.svg'
import Button from '../../../components/button'
import IconSwitch from '../../../components/icon-switch'
import { ModalWrapper } from '../../../components/modal'
import Table, { TableData } from '../../../components/table'
import TopRightOptions from '../../../components/top-right-options'
import Content from '../../../containers/content'
import { RootStoreContext } from '../../../context/root-store'
import usePagedAsync from '../../../hooks/use-paged-async'
import useToggle from '../../../hooks/use-toggle'
import {
  fetchUsers,
  toggleActive,
  toggleAdmin,
  toggleOmniscience
} from '../../../services/entities/user'
import { User } from '../../../types/user'
import { capitalize } from '../../../utils/text'
import AddUserForm from './add-user-form'

const createdGuardedMeta = (isAdmin: User['administrator']) =>
  ({
    entity: 'users',
    fields: {
      email: { label: 'Email', size: 3 },
      company: { label: 'Company', size: 2 },
      familyName: { label: 'Surname', size: 1 },
      givenName: { label: 'Given Name', size: 1.5 },
      phoneNumber: { label: 'Phone', size: 1.5 },
      authenticatorStatus: {
        label: 'Authenticator',
        size: 1.5,
        content: capitalize
      },
      active: {
        label: 'Active',
        size: 1,
        content: function ActiveToggle(active, user) {
          return (
            <IconSwitch
              height={18}
              initialValue={active}
              onSwitch={() => toggleActive(user.email, active)}
              isLabel={!isAdmin}
            />
          )
        }
      },
      omniscient: {
        label: 'Omniscience',
        size: 1,
        content: function OmniscienceToggle(omniscient, user) {
          return (
            <IconSwitch
              height={18}
              initialValue={omniscient}
              onSwitch={() => toggleOmniscience(user.email, omniscient)}
              isLabel={!isAdmin}
            />
          )
        }
      },
      administrator: {
        label: 'Administrator',
        size: 1,
        content: function AdminToggle(administrator, user) {
          return (
            <IconSwitch
              height={16}
              initialValue={administrator}
              onSwitch={() => toggleAdmin(user.email, administrator)}
              isLabel={!isAdmin}
            />
          )
        }
      }
    },
    arrangement: [
      'email',
      'company',
      'familyName',
      'givenName',
      'phoneNumber',
      'authenticatorStatus',
      'active',
      'omniscient',
      'administrator'
    ]
  } as TableData<User>['meta'])

const rowStyle = `
  padding-top: 20px;
  padding-bottom: 20px;
`

const pageParams = {
  pageSize: 100
}

export default function Users() {
  const {
    authentication: {
      user: { administrator }
    }
  } = useContext(RootStoreContext)
  const {
    loading,
    initialized,
    error,
    data,
    count,
    setPage,
    refreshData
  } = usePagedAsync({ fn: fetchUsers, opts: pageParams, entity: 'users' })
  const [addUserShown, showAddUser, hideAddUser] = useToggle(false)
  const closeModal = () => {
    refreshData()
    hideAddUser()
  }

  return (
    <Content
      title={'Support'}
      subtitle={'Users'}
      error={error}
      loading={!initialized}>
      <TopRightOptions>
        {administrator && (
          <Button icon={true} variant={'light'} onClick={showAddUser}>
            <PlusIcon fill="#000" height={20} width={20} />
          </Button>
        )}
      </TopRightOptions>
      <ModalWrapper
        onClose={closeModal}
        title={'Add a User'}
        shown={addUserShown}
        hide={closeModal}>
        <AddUserContent>
          <AddUserForm closeModal={closeModal} />
        </AddUserContent>
      </ModalWrapper>
      <Table
        rowStyle={rowStyle}
        meta={createdGuardedMeta(administrator)}
        data={data}
        keyExtractor={user => user.email}
        pageSize={pageParams.pageSize}
        error={error}
        count={count}
        onPageChange={({ selected }) => setPage(selected)}
        loading={loading}
      />
    </Content>
  )
}

const AddUserContent = styled.div`
  width: 340px;
`
