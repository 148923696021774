import styled from '@emotion/styled/macro'

export const Tab = styled.div<{ selected?: boolean }>`
  font-weight: bold;
  color: #666666;
  text-transform: uppercase;
  font-size: 14px;
  padding: 16px 20px;
  border-radius: 8px;
  display: grid;
  align-items: center;
  transition: 400ms all;
  cursor: pointer;

  :hover {
    background-color: #ddd;
  }

  ${({ selected }) =>
    selected &&
    `
    background-color: #ddd;
  `}

  svg {
    height: 20px;
    width: 20px;
    fill: #ff0200;

    * {
      fill: #ff0200;
    }
  }
`
