import { useCallback, useEffect, useRef, useState } from 'react'

export type UseFieldsType<T> = [
  T,
  (field: keyof T, value: T[typeof field]) => void,
  () => void
]

export default function useFields<T>(
  initialValue: T,
  onChange?: (value: T) => void
) {
  const [fieldValues, _setFieldValue] = useState(initialValue)
  const refOnChange = useRef(onChange)

  const setFieldValue = useCallback((field: keyof T, value: unknown) => {
    _setFieldValue(currentFieldValues => ({
      ...currentFieldValues,
      [field]: value
    }))
  }, [])

  useEffect(() => {
    if (refOnChange.current) {
      refOnChange.current(fieldValues)
    }
  }, [fieldValues])

  const reset = useCallback(() => _setFieldValue(initialValue), [initialValue])

  return [fieldValues, setFieldValue, reset] as UseFieldsType<T>
}
