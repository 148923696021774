const playbackFields = {
  rate: {
    channel: {
      comp1: {
        BQC_CFG1: {
          prop: 'bqc_cfg1',
          mask:0x000007C0,
          fields: [
            {
              name: 'IR',
              bitmin: 24,
              bitmax: 30,
              description: 'Interpolation Ratio',
              options: []
            },
            {
              name: 'DR',
              bitmin: 16,
              bitmax: 22,
              description: 'Decimation Ratio',
              options: []
            },
            {
              name: 'SBQ',
              bitmin: 6,
              bitmax: 10,
              description: 'Number of Biquad stages',
              options: []
            },
            {
              name: 'OUT_DLY',
              bitmin: 1,
              bitmax: 5,
              description:
                'The number of operational clock cycles by which valid BQC output is delayed',
              options: []
            },
            {
              name: 'BQC_EN',
              bitmin: 0,
              bitmax: 0,
              description: 'Enable Biquad filter',
              options: ['disabled', 'enabled']
            }
          ]
        },
        stage: {
          BQ_B0: {
            prop: 'b0',
            mask:0xFFFFFFFF,
            fields: [
              {
                name: 'BQ_B0',
                bitmin: 0,
                bitmax: 31,
                description: 'b0 Biquad coefficient',
                options: []
              }
            ]
          },
          BQ_B1: {
            prop: 'b1',
            mask:0xFFFFFFFF,
            fields: [
              {
                name: 'BQ_B1',
                bitmin: 0,
                bitmax: 31,
                description: 'b1 Biquad coefficient',
                options: []
              }
            ]
          },
          BQ_B2: {
            prop: 'b2',
            mask:0xFFFFFFFF,
            fields: [
              {
                name: 'BQ_B2',
                bitmin: 0,
                bitmax: 31,
                description: 'b2 Biquad coefficient',
                options: []
              }
            ]
          },
          BQ_A1: {
            prop: 'a1',
            mask:0xFFFFFFFF,
            fields: [
              {
                name: 'BQ_A1',
                bitmin: 0,
                bitmax: 31,
                description: 'a1 Biquad coefficient',
                options: []
              }
            ]
          },
          BQ_AN: {
            prop: 'a2',
            mask:0xFFFFFFFF,
            fields: [
              {
                name: 'BQ_A2',
                bitmin: 0,
                bitmax: 31,
                description: 'a2 Biquad coefficient',
                options: []
              }
            ]
          }
        }
      },
      equ: {
        BQC_CFG1: {
          prop: 'bqc_cfg1',
          mask:0xFFFFFFFF,
          fields: [
            {
              name: 'IR',
              bitmin: 24,
              bitmax: 30,
              description: 'Interpolation Ratio',
              options: []
            },
            {
              name: 'DR',
              bitmin: 16,
              bitmax: 22,
              description: 'Decimation Ratio',
              options: []
            },
            {
              name: 'SBQ',
              bitmin: 6,
              bitmax: 10,
              description: 'Number of Biquad stages',
              options: []
            },
            {
              name: 'OUT_DLY',
              bitmin: 1,
              bitmax: 5,
              description:
                'The number of operational clock cycles by which valid BQC output is delayed',
              options: []
            },
            {
              name: 'BQC_EN',
              bitmin: 0,
              bitmax: 0,
              description: 'Enable Biquad filter',
              options: ['disabled', 'enabled']
            }
          ]
        },
        stage: {
          BQ_B0: {
            prop: 'b0',
            mask:0xFFFFFFFF,
            fields: [
              {
                name: 'BQ_B0',
                bitmin: 0,
                bitmax: 31,
                description: 'b0 Biquad coefficient',
                options: []
              }
            ]
          },
          BQ_B1: {
            prop: 'b1',
            mask:0xFFFFFFFF,
            fields: [
              {
                name: 'BQ_B1',
                bitmin: 0,
                bitmax: 31,
                description: 'b1 Biquad coefficient',
                options: []
              }
            ]
          },
          BQ_B2: {
            prop: 'b2',
            mask:0xFFFFFFFF,
            fields: [
              {
                name: 'BQ_B2',
                bitmin: 0,
                bitmax: 31,
                description: 'b2 Biquad coefficient',
                options: []
              }
            ]
          },
          BQ_A1: {
            prop: 'a1',
            mask:0xFFFFFFFF,
            fields: [
              {
                name: 'BQ_A1',
                bitmin: 0,
                bitmax: 31,
                description: 'a1 Biquad coefficient',
                options: []
              }
            ]
          },
          BQ_A2: {
            prop: 'a2',
            mask:0xFFFFFFFF,
            fields: [
              {
                name: 'BQ_A2',
                bitmin: 0,
                bitmax: 31,
                description: 'a2 Biquad coefficient',
                options: []
              }
            ]
          }
        }
      }
    }
  }
}

export default playbackFields
