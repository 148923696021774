import installWindowsOne from '../../../../assets/images/install-windows-1.png'
import installWindowsTwo from '../../../../assets/images/install-windows-2.png'
import installWindowsThree from '../../../../assets/images/install-windows-3.png'
import installWindowsFour from '../../../../assets/images/install-windows-4.png'
import installWindowsFive from '../../../../assets/images/install-windows-5.png'
import installWindowsSix from '../../../../assets/images/install-windows-6.png'

import installMacOne from '../../../../assets/images/install-mac-1.png'
import installMacTwo from '../../../../assets/images/install-mac-2.png'
import installMacThree from '../../../../assets/images/install-mac-3.png'
import installMacFour from '../../../../assets/images/install-mac-4.png'
import installMacFive from '../../../../assets/images/install-mac-5.png'
import installMacSix from '../../../../assets/images/install-mac-6.png'

import installLinuxOne from '../../../../assets/images/install-linux-1.png'
import installLinuxTwo from '../../../../assets/images/install-linux-2.png'
import installLinuxThree from '../../../../assets/images/install-linux-3.png'

export default {
  windowsInstallationImages: [
    installWindowsOne,
    installWindowsTwo,
    installWindowsThree,
    installWindowsFour,
    installWindowsFive,
    installWindowsSix
  ],
  macInstallationImages: [
    installMacOne,
    installMacTwo,
    installMacThree,
    installMacFour,
    installMacFive,
    installMacSix
  ],
  linuxInstallationImages: [installLinuxOne, installLinuxTwo, installLinuxThree]
}
