import React from 'react'
import Error from '../components/error'
import LoadingSpinner from '../components/loading-spinner'
import PaddedContainer from '../components/padded-container'
import PageTitle, { Props as PageTitleProps } from '../components/page-title'
import { interpretError } from '../utils/common'
import { ConditionalWrapper } from '../utils/react-utils'
import { capitalize } from '../utils/text'

interface Props {
  children: React.ReactNode
  loading?: boolean
  error?: unknown
  section?: boolean
  title?: string
  padded?: boolean
  wrapperProps?: React.ComponentProps<typeof ConditionalWrapper>
}

export default function Content({
  children,
  title = '',
  subtitle = '',
  loading = false,
  error = null,
  itemName = '',
  itemNameLoading = false,
  section = false,
  wrapperProps
}: Props & Pick<PageTitleProps, 'itemName' | 'itemNameLoading' | 'subtitle'>) {
  return (
    <>
      {!section ? (
        <PageTitle
          title={title}
          subtitle={subtitle}
          itemName={itemName}
          itemNameLoading={itemNameLoading}
        />
      ) : (
        <div />
      )}
      <ConditionalWrapper
        condition={!section}
        wrapper={PaddedContainer}
        wrapperProps={wrapperProps}>
        {error ? (
          <Error message={capitalize(interpretError(error))} fullPage={true} />
        ) : (
          <>
            {children}
            {loading && (
              <LoadingSpinner
                fullPage={!section}
                height={40}
                width={40}
                variant={'dark'}
              />
            )}
          </>
        )}
      </ConditionalWrapper>
    </>
  )
}
