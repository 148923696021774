import React, { useCallback, useMemo } from 'react'
import { Gridder } from '../../../../../components/grids'
import HorizontalTab from '../../../../../components/horizontal-tab'
import feedbackFields from '../../constants/fields/feedback-fields'
import { FeedbackParameters } from '../../../../../types/dbmc2evb2'
import { createInputComponents } from '../input-fields'
import { resizeArray } from '../../../../../utils/array'
import LoadingSpinner from '../../../../../components/loading-spinner'
import Card from '../../../../../components/card'
import CardTitle from '../../../../../components/card-title'
import { RadioSelect } from '../../../../../components/radio-button'
import Flex from '../../../../../components/auto-flow-flex'
import Checkbox from '../../../../../components/checkbox'
import { BiquadStage } from '../../../../../types/dbmc2evb2'
import FilterViewer from '../filter-viewer'
import FilterEditor from '../filter-editor'
import { toHex, updateRegister } from '../../utils/binary'
import initialData from '../../constants/initial-data'
import SubSubtitle from '../../../../../components/subsubtitle'

const rates = [8000, 16000, 44100, 48000, 96000]
const channelToString = (c: number) => (c === 1 ? 'Mono' : 'Stereo')
const editorComponentHeight = '220px'

const channelOptions = [1, 2].map(channel => ({
  label: channelToString(channel),
  value: channel
}))

// this is the same as playback
const getRateName = (r: number) => {
  const dps = 1
  const n = (r / 1000).toFixed(dps)
  const c = n.slice(-dps) === '0'.repeat(dps) ? n.slice(0, -(dps + 1)) : n
  return `${c} kHz`
}

interface FeedbackFilterViewerProps {
  parameters: typeof initialData.parameters.feedback
}

function FeedbackFilterViewer(props: FeedbackFilterViewerProps) {
  const { parameters } = props

  const getChannelName = useCallback(
    (index: number) => {
      if (parameters.header.number_of_channels < 2) return ''
      return index === 0 ? 'Left' : 'Right'
    },
    [parameters]
  )

  const stages = useMemo(
    () =>
      parameters.data.rates.reduce((rates, rate) => {
        rates.push(
          rate.channels.reduce((channels, channel) => {
            channels.push(channel.stages)
            return channels
          }, [])
        )
        return rates
      }, []),
    [parameters]
  )

  return (
    <FilterViewer
      rates={parameters.header.rates.map(r => ({
        label: getRateName(r),
        value: r
      }))}
      channels={[1, 2]
        .slice(0, parameters.header.number_of_channels)
        .map((channel, index) => ({
          label: getChannelName(index),
          value: channel
        }))}
      domains={null}
      stages={stages}
      persistenceKey="feedback"
    />
  )
}

interface Props {
  parameters: FeedbackParameters
  onChange: (s: FeedbackParameters) => void
}

export default function FeedbackDesigner(props: Props) {
  console.log('*** FeedbackDesigner')

  const { parameters, onChange } = props
  const isLoading = parameters === undefined

  // this is almost the same as used in playback
  const updateChannels = useCallback(
    (numOfChannels: number) => {
      parameters.header.number_of_channels = numOfChannels
      parameters.data.common.channels = resizeArray(
        parameters.data.common.channels,
        parameters.header.number_of_channels
      )
      parameters.data.rates.forEach(
        rate =>
          (rate.channels = resizeArray(
            rate.channels,
            parameters.header.number_of_channels
          ))
      )
      onChange(parameters)
    },
    [parameters, onChange]
  )

  // this is the same as used in playback
  const updateRates = useCallback(
    (rate, newValue) => {
      const i = parameters.header.rates.indexOf(rate)
      if (newValue === false) {
        if (parameters.header.number_of_rates > 1) {
          if (i >= 0) {
            parameters.header.rates.splice(i, 1)
            parameters.header.number_of_rates = parameters.header.rates.length
            parameters.data.rates.splice(i, 1)
          }
        }
      } else {
        if (i === -1) {
          parameters.header.rates.push(rate)
          parameters.header.number_of_rates = parameters.header.rates.length
          parameters.data.rates = resizeArray(
            parameters.data.rates,
            parameters.header.number_of_rates
          )
        }
      }
      onChange(parameters)
    },
    [parameters, onChange]
  )

  // this is the same as used in playback
  const sortedRates = parameters?.header?.rates?.sort(
    (a: number, b: number) => a - b
  )

  // this is the same as playback
  const getChannelName = useCallback(
    (index: number) => {
      if (parameters.header.number_of_channels < 2) return ''
      return index === 0 ? 'Left' : 'Right'
    },
    [parameters]
  )

  const getCommonName = useCallback(() => {
    if (parameters.header.number_of_channels < 2) return ''
    return 'Common'
  }, [parameters])

  // this is the same as playback
  const getBiquadCoefficientName = (stage: number, coefficient: string) =>
    `BQ${stage}_${coefficient.toUpperCase()}`

  // this is mostly like playback
  const handleBiquadStageChange = useCallback(
    (
      biquadStage: BiquadStage,
      rate: number,
      channel: number,
      stage: number
    ) => {
      const rateIndex = parameters.header.rates.indexOf(rate)
      parameters.data.rates[rateIndex].channels[channel].stages[
        stage - 1
      ] = biquadStage

      console.log('onChange', parameters)
      onChange(parameters)
    },
    [onChange, parameters]
  )

  // this is mostly like playback
  const createFilterEditor = useCallback(
    (rate: number, channel: number, stage: number) => {
      const rateIndex = parameters?.header.rates.indexOf(rate)
      const rates = parameters?.data.rates[rateIndex]
      const biquadStage: BiquadStage =
        rates?.channels[channel].stages[stage - 1]

      return function render() {
        return (
          <div>
            <FilterEditor
              onChange={biquadStage =>
                handleBiquadStageChange(biquadStage, rate, channel, stage)
              }
              rate={rate}
              biquadStage={biquadStage}></FilterEditor>
          </div>
        )
      }
    },
    [handleBiquadStageChange, parameters]
  )

  // this is mostly like playback
  const createStagesComponent = useCallback(
    (rate: number, channel: number) => {
      const getStages = () => [1, 2, 3, 4]

      return function render() {
        return (
          <HorizontalTab
            nested
            persistenceKey={`feedback-filter`}
            padOptions={true}
            navigationProps={{
              width: '120px'
            }}
            height={editorComponentHeight}
            options={getStages().map(stage => {
              return {
                title: `Stage ${stage}`,
                component: createFilterEditor(rate, channel, stage)
              }
            })}
          />
        )
      }
    },
    [createFilterEditor]
  )

  // this is mostly like playback
  const createParamsComponent = useCallback(
    (rate: number, channel: number) => {
      const getStages = () => [1, 2, 3, 4]

      const rateIndex = parameters?.header.rates.indexOf(rate)
      const rates = parameters?.data.rates[rateIndex]

      return function render() {
        return (
          <HorizontalTab
            navigationProps={{
              width: '120px'
            }}
            nested
            persistenceKey={`feedback-params`}
            height={editorComponentHeight}
            options={[
              {
                title: 'BQC_CFG1',
                component: createInputComponents(
                  rates.channels[channel].bqc_cfg1,
                  feedbackFields.rate.channel.BQC_CFG1.fields,
                  feedbackFields.rate.channel.BQC_CFG1.mask,
                  (field, { value, bitmin, bitmax }) => {
                    rates.channels[channel].bqc_cfg1 = updateRegister(
                      rates.channels[channel].bqc_cfg1,
                      bitmin,
                      bitmax,
                      value
                    )
                    onChange(parameters)
                  }
                )
              },
              ...getStages().map((stage, stageIndex) =>
                Object.keys(feedbackFields.rate.channel.stage)
                  .map(
                    coefficient =>
                      feedbackFields.rate.channel.stage[coefficient]
                  )
                  .map(coefficient => ({
                    title: getBiquadCoefficientName(
                      stageIndex,
                      coefficient.prop
                    ),
                    component: createInputComponents(
                      rates.channels[channel].stages[stageIndex][coefficient.prop],
                      coefficient.fields,
                      coefficient.mask,
                      (field, { value, bitmin, bitmax }) => {
                        rates.channels[channel].stages[stageIndex][coefficient.prop] = updateRegister(
                          rates.channels[channel].stages[stageIndex][coefficient.prop],
                          bitmin,
                          bitmax,
                          value
                        )
                        onChange(parameters)
                      }
                    )
                  }))
              )
            ].flat()}
          />
        )
      }
    },
    [onChange, parameters]
  )

  const createChannelComponent = useCallback(
    (channel: number, rate: number) => {
      return function render() {
        return (
          <HorizontalTab
            nested
            persistenceKey={`feedback-stage`}
            padOptions={false}
            navigationProps={{
              width: '120px'
            }}
            height={editorComponentHeight}
            options={[
              {
                title: 'Stages',
                component: createStagesComponent(rate, channel)
              },
              {
                title: 'Params',
                component: createParamsComponent(rate, channel)
              }
            ]}
          />
        )
      }
    },
    [createParamsComponent, createStagesComponent]
  )

  // mostly the same as playback
  const createRateComponent = useCallback(
    (rate: number) => {
      if (parameters?.header.number_of_channels < 2) {
        return createChannelComponent(0, rate)
      } else {
        return function render() {
          return (
            <HorizontalTab
              nested
              persistenceKey={`feedback-channel`}
              padOptions={false}
              navigationProps={{
                width: '120px'
              }}
              height={editorComponentHeight}
              options={[0, 1].map(channel => ({
                title: getChannelName(channel),
                component: createChannelComponent(channel, rate)
              }))}
            />
          )
        }
      }
    },
    [createChannelComponent, getChannelName, parameters]
  )

  return (
    <div style={{ borderWidth: 2, borderColor: 'red' }}>
      {isLoading ? (
        <div style={{ textAlign: 'center' }}>
          <LoadingSpinner variant={'dark'} />
        </div>
      ) : (
        <div>
          <Gridder align={'start'} template={['200px', '1fr']} gap={'24px'}>
            <Card>
              <CardTitle>Channels</CardTitle>
              <RadioSelect
                options={channelOptions}
                onChange={o => updateChannels(o.value as number)}
                value={parameters.header.number_of_channels}
              />
            </Card>
            <Card>
              <CardTitle>Rates</CardTitle>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                {rates.map(rate => (
                  <Flex key={rate} gap={'12px 8px'} style={{ marginRight: 20 }}>
                    <Checkbox
                      onChange={newValue => updateRates(rate, newValue)}
                      initialValue={parameters.header.rates.includes(rate)}
                    />
                    <p>{getRateName(rate)}</p>
                  </Flex>
                ))}
              </div>
            </Card>
          </Gridder>

          <Card padding={'8px 0px'}>
            <CardTitle style={{padding:'8px 20px 0px 20px'}}>Feedback ANC Audio Compensation Filter (COMP2)</CardTitle>
            <HorizontalTab
              nested
              padOptions={false}
              navigationProps={{
                width: '120px'
              }}
              persistenceKey={'feedback-rate'}
              height={editorComponentHeight}
              options={sortedRates.map(rate => ({
                title: getRateName(rate),
                component: createRateComponent(rate)
              }))}
            />
          </Card>

          <div>
            <FeedbackFilterViewer parameters={parameters} />
          </div>

          <Gridder
            align={'start'}
            template={['1fr', '1fr', '1fr']}
            gap={'24px'}>
            <div>
              <SubSubtitle>{getCommonName()}</SubSubtitle>
              <HorizontalTab
                navigationProps={{
                  width: '120px'
                }}
                nested
                persistenceKey={`feedback-params-common`}
                height={editorComponentHeight}
                options={[
                  {
                    title: 'FB_CFG0',
                    subtitle: toHex(parameters.data.common.fb_cfg0, 8),
                    component: createInputComponents(
                      parameters.data.common.fb_cfg0,
                      feedbackFields.common.FB_CFG0.fields,
                      feedbackFields.common.FB_CFG0.mask,
                      (field, { value, bitmin, bitmax }) => {
                        parameters.data.common.fb_cfg0 = updateRegister(
                          parameters.data.common.fb_cfg0,
                          bitmin,
                          bitmax,
                          value
                        )
                        onChange(parameters)
                      }
                    )
                  },
                  {
                    title: 'FB_CFG3',
                    subtitle: toHex(parameters.data.common.fb_cfg3, 8),
                    component: createInputComponents(
                      parameters.data.common.fb_cfg3,
                      feedbackFields.common.FB_CFG3.fields,
                      feedbackFields.common.FB_CFG3.mask,
                      (field, { value, bitmin, bitmax }) => {
                        parameters.data.common.fb_cfg3 = updateRegister(
                          parameters.data.common.fb_cfg3,
                          bitmin,
                          bitmax,
                          value
                        )
                        onChange(parameters)
                      }
                    )
                  }
                ].flat()}
              />
            </div>
            {parameters.data.common.channels
              .slice(0, 2)
              .map((channel, channelIndex) => (
                <div key={`feedback-params-channel-${channelIndex}`}>
                  <SubSubtitle>{getChannelName(channelIndex)}</SubSubtitle>
                  <HorizontalTab
                    navigationProps={{
                      width: '120px'
                    }}
                    nested
                    height={editorComponentHeight}
                    persistenceKey={`feedback-params-channel-${getChannelName(
                      channelIndex
                    ).toLowerCase()}`}
                    options={[
                      {
                        title: 'TX_CTRL',
                        subtitle: toHex(
                          parameters.data.common.channels[channelIndex].tx_ctrl,
                          8
                        ),
                        component: createInputComponents(
                          parameters.data.common.channels[channelIndex].tx_ctrl,
                          feedbackFields.common.channel.TX_CTRL.fields,
                          feedbackFields.common.channel.TX_CTRL.mask,
                          (field, { value, bitmin, bitmax }) => {
                            parameters.data.common.channels[channelIndex].tx_ctrl = updateRegister(
                              parameters.data.common.channels[channelIndex].tx_ctrl,
                              bitmin,
                              bitmax,
                              value
                            )
                            onChange(parameters)
                          }
                        )
                      },
                      {
                        title: 'FB_CFG12',
                        subtitle: toHex(parameters.data.common.channels[channelIndex].fb_cfg12,8),
                        component: createInputComponents(
                          parameters.data.common.channels[channelIndex].fb_cfg12,
                          feedbackFields.common.channel.FB_CFG12.fields,
                          feedbackFields.common.channel.FB_CFG12.mask,
                          (field, { value, bitmin, bitmax }) => {
                            parameters.data.common.channels[channelIndex].fb_cfg12 = updateRegister(
                              parameters.data.common.channels[channelIndex].fb_cfg12,
                              bitmin,
                              bitmax,
                              value
                            )
                            onChange(parameters)
                          }
                        )
                      }
                    ].flat()}
                  />
                </div>
              ))}
          </Gridder>
        </div>
      )}
    </div>
  )
}
