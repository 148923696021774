import WebSocketAsPromised from 'websocket-as-promised'
import dbmc2 from './dbmc2'
import * as EVBTypes from '../../../../types/dbmc2evb2'
import {
  deviceSetupParametersEmitterBytes,
  playbackParametersEmitterBytes,
  voicePickupParametersEmitterBytes,
  feedbackParametersEmitterBytes,
  feedforwardParametersEmitterBytes,
  talkthroughParametersEmitterBytes,
  calibrationParametersEmitterBytes
} from './serialization'

// System path IDs for "Upload/Download/Delete/Clear Parameter Set"
const PATHID_SYSTEM_DEVICESETUP = 0x00
const PATHID_SYSTEM_PLAYBACK = 0x01
const PATHID_SYSTEM_VOICEPICKUP = 0x02
const PATHID_SYSTEM_FEEDBACK = 0x03
const PATHID_SYSTEM_FEEDFORWARD = 0x04
const PATHID_SYSTEM_TALKTHROUGH = 0x05
//const PATHID_SYSTEM_SUPERVISORY = 0x06
//const PATHID_SYSTEM_DYNAMICCOMPRESSOR = 0x07
//const PATHID_SYSTEM_AUDIOPROCESSOR = 0x08
const PATHID_SYSTEM_CALIBRATION = 0x09

// Audio Routing path IDs for "Set/Get Path Parameters"
// differ from System IDs because of course they do
const PATHID_AUDIOROUTING_PLAYBACK =0x00
const PATHID_AUDIOROUTING_VOICEPICKUP = 0x01
//const PATHID_AUDIOROUTING_SUPERVISORY = 0x02
const PATHID_AUDIOROUTING_FEEDBACK = 0x03
const PATHID_AUDIOROUTING_FEEDFORWARD = 0x04
const PATHID_AUDIOROUTING_TALKTHROUGH = 0x05
//const PATHID_AUDIOROUTING_AUDIOPROCESSOR = 0x06
//const PATHID_AUDIOROUTING_DYNAMICCOMPRESSOR = 0x07

const OPCODE_UPLOAD_PARAMETER_SET = 0x17
const OPCODE_DOWNLOAD_PRAMETER_SET = 0x18
//const OPCODE_DELETE_PARAMETER_SET = 0x19
const OPCODE_CLEAR_PARAMETER_SET = 0x21
const OPCODE_SET_PATH_PARAMETERS = 0x0A

const SERVICEID_SYSTEM = 0x00
//const SERVICEID_INTERRUPTS = 0x1
//const SERVICEID_GPIOS = 0x02
const SERVICEID_AUDIOROUTING = 0x03
//const SERVICEID_DAI = 0x04 //digital audio interface
const SERVICEID_ANC = 0x05 //acoustic noise cancellation
//const SERVICEID_ANALOGFRONTEND = 0x06
//const SERVICEID_ACR = 0x08 //audio clock recovery
//const SERVICEID_PWM = 0X09 //pulse width modulation
//const SERVICEID_DYNAMICCOMPRESSOR = 0x0A
//const SERVICEID_ADAPTIVEPROCESSING = 0x0B
//const SERVICEID_TESTDEBUG = 0xFE

const COMMAND_GET_VERSION =                [0x04, 0x00, SERVICEID_SYSTEM, 0x00]
const COMMAND_GET_ANC =                    [0x04, 0x00, SERVICEID_ANC, 0x04]
const COMMAND_GET_AWARENESS =              [0x04, 0x00, SERVICEID_ANC, 0x06] 
const COMMAND_GET_DEVICESETUP_PARAMETERS = [0x06, 0x00, SERVICEID_SYSTEM, OPCODE_DOWNLOAD_PRAMETER_SET, PATHID_SYSTEM_DEVICESETUP, 0x00]
const COMMAND_GET_PLAYBACK_PARAMETERS =    [0x06, 0x00, SERVICEID_SYSTEM, OPCODE_DOWNLOAD_PRAMETER_SET, PATHID_SYSTEM_PLAYBACK,    0x00]
const COMMAND_GET_VOICEPICKUP_PARAMETERS = [0x06, 0x00, SERVICEID_SYSTEM, OPCODE_DOWNLOAD_PRAMETER_SET, PATHID_SYSTEM_VOICEPICKUP, 0x00]
const COMMAND_GET_FEEDBACK_PARAMETERS =    [0x06, 0x00, SERVICEID_SYSTEM, OPCODE_DOWNLOAD_PRAMETER_SET, PATHID_SYSTEM_FEEDBACK,    0x00]
const COMMAND_GET_FEEDFORWARD_PARAMETERS = [0x06, 0x00, SERVICEID_SYSTEM, OPCODE_DOWNLOAD_PRAMETER_SET, PATHID_SYSTEM_FEEDFORWARD, 0x00]
const COMMAND_GET_TALKTHROUGH_PARAMETERS = [0x06, 0x00, SERVICEID_SYSTEM, OPCODE_DOWNLOAD_PRAMETER_SET, PATHID_SYSTEM_TALKTHROUGH, 0x00]
const COMMAND_GET_CALIBRATION_PARAMETERS = [0x06, 0x00, SERVICEID_SYSTEM, OPCODE_DOWNLOAD_PRAMETER_SET, PATHID_SYSTEM_CALIBRATION, 0x00]

const COMMAND_SET_ANC = [0x05, 0x00, SERVICEID_ANC, 0x03]
const COMMAND_SET_AWARENESS = [0x05, 0x00, SERVICEID_ANC, 0x05]

export async function configureBoard(
  ws: WebSocketAsPromised,
  path: string,
  target: { name: string; major: number; minor: number }
) {
  await dbmc2.create(ws, path, target.name, target.major, target.minor)
}

export async function getBoardControl(ws: WebSocketAsPromised, path: string) {
  const reset = await dbmc2.getReset(ws, path)
  const led = await dbmc2.getLed(ws, path)
  const filter = await dbmc2.getFilter(ws, path)
  const daughter = await dbmc2.getControl(ws, path)

  return { reset, led, filter, daughter }
}

export async function getBoardMicrophones(
  ws: WebSocketAsPromised,
  path: string
) {
  const anc = await dbmc2.getAncMicrophone(ws, path)
  const voice = await dbmc2.getVoiceMicrophone(ws, path)
  const micbias = await dbmc2.getMicbias(ws, path)

  return { anc, voice, micbias }
}

export async function setLed(
  ws: WebSocketAsPromised,
  path: string,
  enabled: boolean
) {
  await dbmc2.setLed(ws, path, enabled)
}

export async function setFilter(
  ws: WebSocketAsPromised,
  path: string,
  enabled: boolean
) {
  await dbmc2.setFilter(ws, path, enabled)
}

export async function setDaughter(
  ws: WebSocketAsPromised,
  path: string,
  enabled: boolean
) {
  await dbmc2.setControl(ws, path, enabled)
}

export async function setReset(
  ws: WebSocketAsPromised,
  path: string,
  enabled: boolean
) {
  await dbmc2.setReset(ws, path, enabled)
  if (!enabled) {
    await new Promise(r => setTimeout(r, 500))
  }
}

export async function setMicbias(
  ws: WebSocketAsPromised,
  path: string,
  micbias: EVBTypes.Micbias
) {
  await dbmc2.setMicbiasConfiguration(ws, path, micbias === 'function-ganged')
}

export async function setAncMicrophones(
  ws: WebSocketAsPromised,
  path: string,
  anc: EVBTypes.AncMicrophone
) {
  switch (anc) {
    case 'mems-se':
      return await dbmc2.setAncMicrophoneConfiguration(ws, path, true, true)
    case 'ecm-se':
      return await dbmc2.setAncMicrophoneConfiguration(ws, path, false, true)
    case 'mems-diff':
      //todo - does recent changes to mems effect the following 3rd param?
      return await dbmc2.setAncMicrophoneConfiguration(ws, path, false, false)
  }
}

export async function setVoiceMicrophones(
  ws: WebSocketAsPromised,
  path: string,
  voice: EVBTypes.VoiceMicrophone
) {
  await dbmc2.setVoiceMicrophoneConfiguration(ws, path, voice === 'mems-se')
}

async function sendCommand(
  ws: WebSocketAsPromised,
  path: string,
  cmd: number[]
) {

  const packet = (await dbmc2.sendCommand(ws, path, cmd)).Response
  if (!packet) {
    return null
  }
  const length = packet[0] + (packet[1] << 8)
  const response = packet.splice(4, length - 4)
  return response
}

export async function getDeviceSetupParameters(
  ws: WebSocketAsPromised,
  path: string
) {
  return await sendCommand(ws, path, COMMAND_GET_DEVICESETUP_PARAMETERS)
}

export async function getPlaybackParameters(
  ws: WebSocketAsPromised,
  path: string
) {
  return await sendCommand(ws, path, COMMAND_GET_PLAYBACK_PARAMETERS)
}

export async function getVoicePickupParameters(
  ws: WebSocketAsPromised,
  path: string
) {
  return await sendCommand(ws, path, COMMAND_GET_VOICEPICKUP_PARAMETERS)
}

export async function getFeedbackParameters(
  ws: WebSocketAsPromised,
  path: string
) {
  return await sendCommand(ws, path, COMMAND_GET_FEEDBACK_PARAMETERS)
}

export async function getFeedforwardParameters(
  ws: WebSocketAsPromised,
  path: string
) {
  return await sendCommand(ws, path, COMMAND_GET_FEEDFORWARD_PARAMETERS)
}

export async function getTalkthroughParameters(
  ws: WebSocketAsPromised,
  path: string
) {
  return await sendCommand(ws, path, COMMAND_GET_TALKTHROUGH_PARAMETERS)
}

export async function getCalibrationParameters(
  ws: WebSocketAsPromised,
  path: string
) {
  return await sendCommand(ws, path, COMMAND_GET_CALIBRATION_PARAMETERS)
}

export async function getFirmwareVersion(
  ws: WebSocketAsPromised,
  path: string
) {
  const bytes = await sendCommand(ws, path, COMMAND_GET_VERSION)

  if (!bytes || bytes.length < 30) {
    return { version: 'none', commit: 'none' }
  }

  let version = ''
  let commit = ''

  for (let i = 0; i < 30; ++i) {
    const c = bytes[1 + i]
    if (c === 0) {
      break
    } else {
      version += String.fromCharCode(c)
    }
  }

  for (let i = 0; i < 41; ++i) {
    const c = bytes[1 + 30 + i]
    if (c === 0) {
      break
    } else {
      commit += String.fromCharCode(c)
    }
  }

  console.log(version)
  return { version, commit }
}

export async function getDeviceControl(ws: WebSocketAsPromised, path: string) {
  return {
    anc: await getAnc(ws, path),
    awareness: await getAwareness(ws, path)
  }
}

export async function getAnc(ws: WebSocketAsPromised, path: string) {
  const a = await sendCommand(ws, path, COMMAND_GET_ANC)
  if (a && a.length>1) {
    return a[1]
  }
  return 0
}

export async function setAnc(
  ws: WebSocketAsPromised,
  path: string,
  anc: number
) {
  await sendCommand(ws, path, COMMAND_SET_ANC.concat(anc))
}

export async function getAwareness(ws: WebSocketAsPromised, path: string) {
  const a = await sendCommand(ws, path, COMMAND_GET_AWARENESS)
  if (a && a.length>1) {
    return a[1]
  }
  return 0
}

export async function setAwareness(
  ws: WebSocketAsPromised,
  path: string,
  awareness: number
) {
  await sendCommand(ws, path, COMMAND_SET_AWARENESS.concat(awareness))
}

async function uploadParameterSet(
  ws: WebSocketAsPromised,
  path: string,
  paramsTypeId: number,
  params: number[],
  setPathParameters: boolean = false,
  audioRoutingId: number = 0
) {
  //set anc mode to zero
  const anc = await getAnc(ws, path)
  if (anc===0) {
    console.log('✅ Get ANC already mode 0')
  } else {
    await setAnc(ws, path, 0)
  }

  await sendCommand(ws, path, [
    0x05, 
    0x00, 
    SERVICEID_SYSTEM, 
    OPCODE_CLEAR_PARAMETER_SET,
    paramsTypeId
  ])

  const len = 4 + params.length
  const uploadParameterSetResult = await sendCommand(ws, path, [
    (len & 0x00ff) >>> 0,
    (len & 0xff00) >>> 8,
    SERVICEID_SYSTEM,
    OPCODE_UPLOAD_PARAMETER_SET,
    ...params
  ])

  if (setPathParameters) {
    await sendCommand(ws, path, [
      0x06,
      0x00,
      SERVICEID_AUDIOROUTING,
      OPCODE_SET_PATH_PARAMETERS,
      audioRoutingId,
      0x00//set id
    ])
  }

  //restore anc mode
  if (anc===0) {
    console.log('✅ Set ANC already mode 0')
  } else {
    await setAnc(ws, path, anc)
  }

  return uploadParameterSetResult
}

export async function setDeviceSetupParameters(
  ws: WebSocketAsPromised,
  path: string,
  params: EVBTypes.DeviceSetupParameters
) {
  return await uploadParameterSet(
    ws,
    path,
    PATHID_SYSTEM_DEVICESETUP, 
    deviceSetupParametersEmitterBytes(params),
    false
  )
}

export async function setPlaybackParameters(
  ws: WebSocketAsPromised,
  path: string,
  params: EVBTypes.PlaybackParameters
) {
  return await uploadParameterSet(
    ws,
    path,
    PATHID_SYSTEM_PLAYBACK,
    playbackParametersEmitterBytes(params),
    true,
    PATHID_AUDIOROUTING_PLAYBACK
  )
}

export async function setVoicePickupParameters(
  ws: WebSocketAsPromised,
  path: string,
  params: EVBTypes.VoicePickupParameters
) {
  return await uploadParameterSet(
    ws,
    path,
    PATHID_SYSTEM_VOICEPICKUP,
    voicePickupParametersEmitterBytes(params),
    true,
    PATHID_AUDIOROUTING_VOICEPICKUP
  )
}

export async function setFeedbackParameters(
  ws: WebSocketAsPromised,
  path: string,
  params: EVBTypes.FeedbackParameters
) {
  return await uploadParameterSet(
    ws,
    path,
    PATHID_SYSTEM_FEEDBACK,
    feedbackParametersEmitterBytes(params),
    true,
    PATHID_AUDIOROUTING_FEEDBACK
  )
}

export async function setFeedforwardParameters(
  ws: WebSocketAsPromised,
  path: string,
  params: EVBTypes.FeedforwardParameters
) {
  return await uploadParameterSet(
    ws,
    path,
    PATHID_SYSTEM_FEEDFORWARD,
    feedforwardParametersEmitterBytes(params),
    true,
    PATHID_AUDIOROUTING_FEEDFORWARD
  )
}

export async function setTalkthroughParameters(
  ws: WebSocketAsPromised,
  path: string,
  params: EVBTypes.TalkthroughParameters
) {
  return await uploadParameterSet(
    ws,
    path,
    PATHID_SYSTEM_TALKTHROUGH,
    talkthroughParametersEmitterBytes(params),
    true,
    PATHID_AUDIOROUTING_TALKTHROUGH
  )
}

export async function setCalibrationParameters(
  ws: WebSocketAsPromised,
  path: string,
  params: EVBTypes.CalibrationParameters
) {
  return await uploadParameterSet(
    ws,
    path,
    PATHID_SYSTEM_CALIBRATION,
    calibrationParametersEmitterBytes(params),
    false
  )
}

export async function writeParameters(
  ws: WebSocketAsPromised,
  path: string,
  parameters: {
    setup: EVBTypes.DeviceSetupParameters
    playback: EVBTypes.PlaybackParameters
    voicepickup: EVBTypes.VoicePickupParameters
    feedback: EVBTypes.FeedbackParameters
    feedforward: EVBTypes.FeedforwardParameters
    talkthrough: EVBTypes.TalkthroughParameters
    calibration: EVBTypes.CalibrationParameters
  }
) {
  await dbmc2.writeParameters(
    ws,
    path,
    deviceSetupParametersEmitterBytes(parameters.setup),
    playbackParametersEmitterBytes(parameters.playback),
    voicePickupParametersEmitterBytes(parameters.voicepickup),
    feedbackParametersEmitterBytes(parameters.feedback),
    feedforwardParametersEmitterBytes(parameters.feedforward),
    talkthroughParametersEmitterBytes(parameters.talkthrough),
    calibrationParametersEmitterBytes(parameters.calibration)
  )
}

export async function writeFirmware(
  ws: WebSocketAsPromised,
  path: string,
  firmware: ArrayBuffer
) {
  const data = Array.from(new Uint8Array(firmware))
  await dbmc2.writeFirmware(ws, path, data)
}
