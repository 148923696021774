import React from 'react'
import ResultsWrapper from '../../../components/results-wrapper'
import UserInfo from '../../../components/user-info'
import { User } from '../../../types/user'
import { SectionProp } from './product.page'
import styled from '@emotion/styled/macro'

export default function ProductUsers({
  error,
  count,
  data,
  loading,
  setPage,
  onDelete
}: SectionProp<User> & {
  onDelete: React.ComponentProps<typeof UserInfo>['onDelete']
}) {
  return (
    <Container>
      <ResultsWrapper
        error={error}
        count={count}
        onPageChange={({ selected }) => setPage(selected)}
        loading={loading}>
        {data.map(({ email, company, givenName, familyName }) => (
          <UserInfo
            name={`${givenName} ${familyName}`}
            email={email}
            company={company}
            key={email}
            onDelete={onDelete}
          />
        ))}
      </ResultsWrapper>
    </Container>
  )
}

const Container = styled.div`
  min-width: 280px;
`
