import styled from '@emotion/styled/macro'

const Card = styled.div<{ fit?: boolean; padding?: string }>`
  background-color: #f9f9f9;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
  padding: ${({ fit, padding }) => padding ?? (fit ? '6px' : '16px 20px')};
  border-radius: 6px;
  font-size: 15px;
  margin-bottom: 16px;
  width: 100%;
  position: relative;

  > p:first-of-type {
    margin-top: 0px;
  }
`

export default Card
