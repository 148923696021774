import { types } from 'mobx-state-tree'

const AuthUser = types.model('AuthUser', {
  active: types.boolean,
  administrator: types.boolean,
  authenticatorStatus: types.string,
  company: types.string,
  email: types.string,
  familyName: types.string,
  givenName: types.string,
  key: types.string,
  omniscient: types.boolean,
  phoneNumber: types.string
})

export default AuthUser
