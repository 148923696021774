const talkthroughFields = {
  rate: {
    channel: {
      // It's not possible to discover what register 'cfg' actually refers to
      // so we are not defining any fields.
      CFG: {
        prop: 'cfg',
        mask:0xFFFFFFFF,
        fields: [
          {
            name: 'cfg',
            bitmin: 0,
            bitmax: 31,
            description: '',
            options: []
          }
        ]
      },
      stage: {
        BQ_B0: {
          prop: 'b0',
          mask:0xFFFFFFFF,
          fields: [
            {
              name: 'BQ_B0',
              bitmin: 0,
              bitmax: 31,
              description: 'b0 Biquad coefficient',
              options: []
            }
          ]
        },
        BQ_B1: {
          prop: 'b1',
          mask:0xFFFFFFFF,
          fields: [
            {
              name: 'BQ_B1',
              bitmin: 0,
              bitmax: 31,
              description: 'b1 Biquad coefficient',
              options: []
            }
          ]
        },
        BQ_B2: {
          prop: 'b2',
          mask:0xFFFFFFFF,
          fields: [
            {
              name: 'BQ_B2',
              bitmin: 0,
              bitmax: 31,
              description: 'b2 Biquad coefficient',
              options: []
            }
          ]
        },
        BQ_A1: {
          prop: 'a1',
          mask:0xFFFFFFFF,
          fields: [
            {
              name: 'BQ_A1',
              bitmin: 0,
              bitmax: 31,
              description: 'a1 Biquad coefficient',
              options: []
            }
          ]
        },
        BQ_A2: {
          prop: 'a2',
          mask:0xFFFFFFFF,
          fields: [
            {
              name: 'BQ_A2',
              bitmin: 0,
              bitmax: 31,
              description: 'a2 Biquad coefficient',
              options: []
            }
          ]
        }
      }
    }
  }
}

export default talkthroughFields
