import { Config, Layout, ModeBarDefaultButtons, PlotData } from 'plotly.js'
import { Chart } from '../types/test-result'

export type SupportedColors =
  | 'Blue'
  | 'Black'
  | 'Red'
  | 'Green'
  | 'Yellow'
  | 'Cyan'
  | 'Magenta'
  | 'White'

export type TripleColorType = { R: number; G: number; B: number }

export type Color = SupportedColors | TripleColorType

export interface FullPlotlyMeta extends Chart {
  data: Partial<PlotData>[]
  layout: Partial<Layout>
  configuration: Partial<Config>
}

export const baseMeta: FullPlotlyMeta = {
  configuration: {
    scrollZoom: true,
    responsive: true,
    modeBarButtonsToRemove: ([
      'sendDataToCloud',
      'hoverCompareCartesian',
      // @ts-ignore
      'editInChartStudio',
      'hoverClosestCartesian',
      'hoverCompareCartesian',
      'pan2d',
      'toggleSpikelines'
    ] as unknown) as ModeBarDefaultButtons[],
    displaylogo: false
  },
  layout: {
    autosize: false,
    font: {
      family: 'Px Grotesk',
      size: 14
    },
    titlefont: {
      size: 14
    },
    legend: {
      orientation: 'v',
      bgcolor: '#ffffff7f',
      x: 0,
      y: 1,
      font: {
        size: 10
      }
    },
    margin: {
      t: 50,
      l: 80,
      b: 80,
      r: 10
    },
    // @ts-ignore
    modebar: {
      orientation: 'h',
      bgcolor: 'transparent',
      color: '#a4a4a4',
      activecolor: 'rgb(68,68,68)'
    },
    paper_bgcolor: 'transparent',
    xaxis: {
      autotick: false,
      autorange: false,
      dtick: 'D1',
      gridcolor: '#bdbdbd',
      mirror: 'ticks',
      showgrid: true,
      showline: true,
      ticks: 'inside',
      tickfont: {
        family: 'Px Grotesk',
        size: 14
      },
      titlefont: {
        size: 14
      },
      type: 'log',
      zeroline: false
    },
    yaxis: {
      autotick: false,
      autorange: false,
      gridcolor: '#bdbdbd',
      mirror: 'ticks',
      showgrid: true,
      showline: true,
      ticks: 'inside',
      tickfont: {
        family: 'Px Grotesk',
        size: 14
      },
      tickmode: 'auto',
      titlefont: {
        size: 14
      },
      zeroline: false
    }
  }
}

export function parseColor(color: Color) {
  return typeof color === 'string'
    ? parseColorString(color)
    : parseColorTriple(color)
}

function parseColorTriple(color: TripleColorType) {
  return `rgb(${color.R},${color.G},${color.B})`
}

function parseColorString(color: SupportedColors) {
  switch (color) {
    case 'Blue':
      return 'rgb(0,0,255)'
    case 'Black':
      return 'rgb(0,0,0)'
    case 'Red':
      return 'rgb(255,0,0)'
    case 'Green':
      return 'rgb(0,255,0)'
    case 'Yellow':
      return 'rgb(255,255,0)'
    case 'Cyan':
      return 'rgb(0,255,255)'
    case 'Magenta':
      return 'rgb(255,0,255)'
    case 'White':
      return 'rgb(255,255,255)'
    default:
      console.warn('Color is unhandled in parseColorString')
  }
}
