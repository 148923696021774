import styled from '@emotion/styled/macro'
import React, { useCallback } from 'react'
import { ReactComponent as CheckIcon } from '../assets/icons/check-mark.svg'
import useToggle from '../hooks/use-toggle'

interface Props {
  onChange?: (newValue: boolean) => void
  initialValue?: boolean
}

export default function Checkbox({ onChange, initialValue = false }: Props) {
  const [isChecked, , , toggleChecked] = useToggle(initialValue)

  const toggle = useCallback(() => {
    toggleChecked()
    onChange && onChange(!isChecked)
  }, [toggleChecked, onChange, isChecked])

  return (
    <Container checked={isChecked} onClick={toggle}>
      {isChecked && <StyledCheckIcon />}
    </Container>
  )
}

const StyledCheckIcon = styled(CheckIcon)`
  height: 1rem;
  width: 1rem;
  fill: #fff;
`

const Container = styled.div<{ checked?: boolean }>`
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 8px;
  border: 2px solid #878787;
  display: grid;
  align-items: center;
  justify-items: center;
  transition: 160ms ease-in-out;
  cursor: pointer;

  ${({ checked }) =>
    checked
      ? `
      background-color: #878787;
    `
      : `
      background-color: transparent;
    `}
`
