export function capitalize(text: string) {
  if (!text.length) return ''

  const letters = text.split('')
  const firstLetter = letters[0]

  return firstLetter
    .toUpperCase()
    .concat(letters.slice(1, letters.length).join(''))
}
