import styled from '@emotion/styled/macro'
import React from 'react'

interface Props {
  title: string
}

export default function SectionTitle({
  title,
  noMargin = false,
  ...props
}: Props & React.ComponentProps<typeof Title>) {
  return (
    <Title noMargin={noMargin} {...props}>
      {title}
    </Title>
  )
}

const Title = styled.p<{ noMargin?: boolean }>`
  font-size: 18px;
  font-weight: 300;
  margin-bottom: ${({ noMargin }) => (noMargin ? '0px' : '28px')};
`
