import { keyframes } from '@emotion/core'
import styled from '@emotion/styled/macro'
import React from 'react'

const shimmer = keyframes`
  from {
    transform: translateX(-33.3%);
  }
  to {
    transform: translateX(33.3%);
  }
`

interface Props {
  bgColor?: string
  fgColor?: string
  noBorderRadius?: boolean
}

export default function ShimmerComponent({
  ...props
}: React.ComponentProps<typeof ShimmerContainer> & Props) {
  return <ShimmerContainer {...props}></ShimmerContainer>
}

const ShimmerContainer = styled.div<Props>`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-x: hidden;
  border-radius: .5em;
  z-index: 1;

  &::after {
    z-index: 1;
    content: '';
    position: absolute;
    top: 0;
    right: -100%;
    bottom: 0;
    left: -100%;
    ${({ bgColor, fgColor }) => `
      background-image: linear-gradient(
        to right,
        ${bgColor ?? 'rgba(230, 230, 230, 1)'} 33.3%,
        ${fgColor ?? 'rgba(244, 244, 244, 1)'},
        ${bgColor ?? 'rgba(230, 230, 230, 1)'} 66.6%
      );
    `}
    animation: ${shimmer} 1s infinite linear;
  }
`
