import styled from '@emotion/styled/macro'
import Slider from 'rc-slider'
import 'rc-slider/assets/index.css'
import React, { useEffect, useState } from 'react'
import Card from '../../../../../components/card'
import CardTitle from '../../../../../components/card-title'
import { Gridder } from '../../../../../components/grids'
import { RadioSelect } from '../../../../../components/radio-button'
import { AncValue } from '../../../../../types/dbmc2evb2'
import { SelectOption } from '../../../../../types/input-option'
import { getDeviceControl, setAnc, setAwareness } from '../../utils/board'

const ancOptions: SelectOption<AncValue>[] = [
  { label: 'Off', value: 0 },
  { label: 'Feedback', value: 1 },
  { label: 'Feed-Forward', value: 2 },
  { label: 'Hybrid', value: 3 }
]

interface EVBControl {
  data: any
  setData: (data: any) => void
  socket: any
  path: string
}

export default function EVBControl(props: EVBControl) {
  const { data, setData, socket, path } = props
  
  const [ancState, setAncState] = useState(data.deviceControl.anc)
  const [awarenessState, setAwarenessState] = useState(data.deviceControl.awareness)

  //anc change handler
  useEffect(()=>{
    console.log(`ancState:${ancState}`)
    if (ancState !== data.deviceControl.anc) {
      const setter = async ()=>{
        console.log('%c++++++ ancState change start ++++++', 'color:black; background-color:red;')
        setData(currentData=>({
          ...currentData,
          deviceControl: {...currentData.deviceControl, ancState}
        }))
        //@ts-ignore
        window.checkDisabled=true
        await setAnc(socket, path, 0)
        await setAnc(socket, path, ancState)
        //@ts-ignore
        window.checkDisabled=false
        console.log('%c++++++ ancState change end ++++++', 'color:black; background-color:red;')
      }
      setter()      
    }
  }, [ancState, data.deviceControl.anc, socket, path, setData])

  //awareness change handler
  useEffect(()=>{
    console.log(`awarenessState:${awarenessState}`)
    if (awarenessState !== data.deviceControl.awareness) {
      const setter = async ()=>{
        console.log('%c++++++ awarenessState change start ++++++', 'color:black; background-color:red;')
        setData(currentData => ({
          ...currentData,
          deviceControl: { ...currentData.deviceControl, awarenessState }
        }))
        //@ts-ignore
        window.checkDisabled=true
        await setAwareness(socket, path, awarenessState)
        //@ts-ignore
        window.checkDisabled=false
        console.log('%c++++++ awarenessState change end ++++++', 'color:black; background-color:red;')
      }
      setter()
    }
  }, [awarenessState, data.deviceControl.awareness, socket, path, setData])  
  
  //periodic board anc & awareness checker
  useEffect(() => {
    let timer 
    
    const checker = () => {
      timer = setInterval(()=>{
        //@ts-ignore
        console.log(`timer:${timer} (${window.checkDisabled})`)
        //@ts-ignore
        if (!window.checkDisabled) {
          getDeviceControl(socket, path).then(deviceControl=>{
            console.log(deviceControl)
            setAncState(deviceControl.anc)
            setAwarenessState(deviceControl.awareness)
          })
        }  
      }, 1000)
    }
    checker()
    
    return ()=>clearInterval(timer)

  }, [socket, path])
  
  return (
    <Gridder template={['500px', '160px']} gap={'24px'}>
      <Card>
        <CardTitle>Active Noise Cancellation</CardTitle>
        <RadioSelect
          options={ancOptions}
          onChange={val=>setAncState(val.value)}
          value={ancState}
        />
      </Card>
      <Card style={{ alignSelf: 'start' }}>
        <CardTitle>Awareness</CardTitle>
        <div style={{fontSize:'13px',marginTop:'-3px'}}>{awarenessState}</div>
        <MySlider
          max={199}
          onChange={val=>setAwarenessState(val)}
          value={awarenessState}
        />
      </Card>
    </Gridder>
  )
}

const MySlider = styled(Slider)`
  > .rc-slider-handle {
    border: solid 2px #878787;
    background-color: #f9f9f9;
    :active {
      box-shadow: 0 0 5px #878787;
    }
  }
  > .rc-slider-track {
    background-color: #878787;
  }
`
