import styled from '@emotion/styled/macro'
import React from 'react'
import { mobileSize } from '../constants'

export default function PaddedContainer({
  children
}: React.ComponentPropsWithRef<typeof Container>) {
  return <Container>{children}</Container>
}

const Container = styled.div`
  padding: 110px 26px 40px;
  height: inherit;

  @media screen and (max-width: ${mobileSize}px) {
    padding: 127px 22px;
  }
`
