import styled from '@emotion/styled/macro'

const Title = styled.p`
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 20px;
  margin-top: 25px;
`

export const WhiteTitle = styled(Title)`
  color: white;
`

export default Title
