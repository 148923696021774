import styled from '@emotion/styled/macro'
import { useMachine } from '@xstate/react'
import React, { useCallback, useEffect, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { Machine } from 'xstate'
import { ReactComponent as SearchIcon } from '../../../assets/icons/search.svg'
import Button from '../../../components/button'
import LoadingSpinner from '../../../components/loading-spinner'
import OriginalShimmer from '../../../components/shimmer'
import useAsync from '../../../hooks/use-async'
import useRunEvery from '../../../hooks/use-run-every'
import {
  fetchProdStationStatus,
  fetchProdStationSystem,
  startProductionTest
} from '../../../services/entities/production-test'
import { noop, isEmpty } from '../../../utils/common'

interface Props {
  fqdn: string
}

interface MachineType {
  states: {
    loading: {}
    offline: {}
    online: {
      states: {
        loading: {}
        ready: {}
        testing: {}
        finished: {
          states: {
            pass: {}
            fail: {}
            error: {}
          }
        }
      }
    }
  }
}

type ActionConstants =
  | 'LOADED_OFFLINE'
  | 'LOADED_RUNNING'
  | 'PASSED_BEFORE'
  | 'FAILED_BEFORE'
  | 'ERROR_BEFORE'
  | 'LOADED_READY'
  | 'CONNECTED'
  | 'DISCONNECTED'
  | 'TEST_LOADED'
  | 'START_TESTING'
  | 'PASS'
  | 'FAIL'
  | 'ERROR'

type ActionType<T> = {
  type: ActionConstants
  payload: T extends unknown ? void : T
}

const testMachine = Machine<void, MachineType, ActionType<unknown>>({
  id: 'station-test-result-preview',
  initial: 'loading',
  states: {
    loading: {
      on: {
        LOADED_OFFLINE: 'offline',
        PASSED_BEFORE: 'online.finished.pass',
        FAILED_BEFORE: 'online.finished.fail',
        ERROR_BEFORE: 'online.finished.error',
        LOADED_RUNNING: 'online.testing',
        LOADED_READY: 'online.ready'
      }
    },
    offline: {
      id: 'offline',
      on: {
        CONNECTED: 'online',
        PASSED_BEFORE: 'online.finished.pass',
        FAILED_BEFORE: 'online.finished.fail',
        ERROR_BEFORE: 'online.finished.error'
      }
    },
    online: {
      initial: 'loading',
      on: {
        DISCONNECTED: 'offline'
      },
      states: {
        loading: {
          on: {
            TEST_LOADED: 'ready'
          }
        },
        ready: {
          on: {
            START_TESTING: 'testing'
          }
        },
        testing: {
          on: {
            PASS: 'finished.pass',
            FAIL: 'finished.fail',
            ERROR: 'finished.error'
          }
        },
        finished: {
          on: {
            START_TESTING: 'testing'
          },
          states: {
            pass: {},
            fail: {},
            error: {}
          }
        }
      }
    }
  }
})

function Shimmer(props: React.ComponentProps<typeof OriginalShimmer>) {
  return (
    <OriginalShimmer
      style={{ borderRadius: '99px' }}
      fgColor={'#efefef'}
      bgColor={'#e0e0e0'}
      {...props}
    />
  )
}

export default function ResultPreview({
  fqdn,
  ...props
}: React.ComponentProps<typeof Container> & Props) {
  const [current, send] = useMachine<void, ActionType<unknown>>(testMachine)
  const fqdnParams = useMemo(() => [fqdn], [fqdn])
  const status = useAsync({ fn: fetchProdStationStatus, args: fqdnParams })
  const system = useAsync({ fn: fetchProdStationSystem, args: fqdnParams })
  const initialized = status.initialized

  const interpretStatus = useCallback(() => {
    if (status?.data?.Status === 'Pass') send('PASSED_BEFORE')
    else if (status?.data?.Status === 'Fail') send('FAILED_BEFORE')
    else if (status?.data?.Status === 'Error') send('ERROR_BEFORE')
    else if (status?.data?.Status === 'Running') send('LOADED_RUNNING')
    else if (!isEmpty(system.data) || status?.data?.Status) send('LOADED_READY')
    else send('LOADED_OFFLINE')
  }, [send, status, system])

  const stationLoaded =
    !(status.loading || system.loading) || (status.error && system.error)

  useEffect(() => {
    if (current.matches('loading')) {
      if (stationLoaded) {
        interpretStatus()
      }
    }
  }, [current, stationLoaded, interpretStatus])

  useEffect(() => {
    if (current.matches('offline')) {
      interpretStatus()
    }
  }, [current, send, interpretStatus])

  const startTest = async () => {
    send('START_TESTING')

    try {
      await startProductionTest(fqdn)
      await status.refreshData()
    } catch (err) {
      if (system.error && isEmpty(system.data)) send('DISCONNECTED')
    }
  }

  const shouldRefresh = (refreshData: Function) => () => {
    if (initialized) {
      refreshData()
    }
  }

  useEffect(() => {
    const result = status.data?.Status

    if (result === 'Pass') send('PASS')
    else if (result === 'Fail') send('FAIL')
    else if (result === 'Error') send('ERROR')
    else if (result === 'Running') noop()
    else send('DISCONNECTED')
  }, [status.data, send])

  useRunEvery(shouldRefresh(system.refreshData), 2000)
  useRunEvery(shouldRefresh(status.refreshData), 2000)

  return (
    <Container {...props}>
      <>
        {
          <Name>
            {!(initialized && system.data?.Test?.Name) && <Shimmer />}{' '}
            {system?.data?.Test?.Name || ''}
          </Name>
        }
        <StatusContainer>
          <Status>
            {!status.initialized && <Shimmer />}
            <StatusMessage>
              {current?.matches('offline') &&
              isEmpty(system?.data) &&
              !status?.data?.Stage
                ? 'Station is Offline'
                : current?.matches('online.ready')
                ? 'Ready'
                : current?.matches('online.finished')
                ? 'Finished'
                : current?.matches('online.testing')
                ? !!status?.data?.Action
                  ? status?.data?.Action
                  : 'Running'
                : status?.data?.Status}
            </StatusMessage>{' '}
            {current.matches('online.testing') &&
              !status?.data?.Action &&
              status?.data?.Stage !== 'Talkthrough' && (
                <Stage>{status?.data?.Stage}</Stage>
              )}
          </Status>
          {!current?.matches('online.testing') && (
            <ViewResultContainer>
              <>
                {!initialized && <Shimmer />}
                {current.matches('online') &&
                  !current.matches('online.testing') && (
                    <StyledLink to={`/production/${fqdn}/test/result`}>
                      <SearchIcon height={16} fill={'#179654'} />{' '}
                      <span>View Test Result</span>
                    </StyledLink>
                  )}
              </>
            </ViewResultContainer>
          )}
        </StatusContainer>
        {
          <ResultPreviewButton
            onClick={startTest}
            disabled={
              !current?.matches('online.ready') &&
              !current?.matches('online.finished')
            }
            status={
              status?.data?.Status?.toLowerCase() as ResultPreviewButtonProps['status']
            }>
            {!initialized && <Shimmer style={{ borderRadius: '.5em' }} />}
            {current.matches('offline') && 'Station is Offline'}
            {current.matches('online.loading') && 'Test Not Ready'}
            {current.matches('online.testing') && <LoadingSpinner width={24} />}
            {current.matches('online.finished') && status.data.Status}
            {current.matches('online.ready') && 'Start Test'}
          </ResultPreviewButton>
        }
        <Fqdn>
          {!initialized && <Shimmer style={{ borderRadius: '.5em' }} />}
          {initialized && fqdn}
        </Fqdn>
      </>
    </Container>
  )
}

const StyledLink = styled(Link)`
  display: grid;
  align-items: center;
  grid-gap: 4px;
  grid-template-columns: 16px 1fr;
  justify-self: end;
`

const StatusMessage = styled.span`
  position: relative;
  min-width: 60px;
  min-height: 30px;
`

const Stage = styled.span`
  font-style: italic;
  min-width: 60px;
  min-height: 20px;
`

const ViewResultContainer = styled.div`
  position: relative;
  min-height: 18px;
  width: 100%;
  color: #179654;
  border-radius: 0;
  font-weight: 300;
  cursor: pointer;
  font-size: 14px;
  align-items: center;
  justify-self: end;
  display: grid;
`

const StatusContainer = styled.div`
  margin: 12px 0;
  align-items: center;
  min-width: 30px;
  display: grid;
  position: relative;
  grid-gap: 24px;
  min-height: 24px;
  grid-auto-flow: column;
`

const Fqdn = styled.div`
  font-size: 12px;
  line-height: 1.5;
  margin-top: 15px;
  position: relative;
  min-height: 18px;
  border-radius: 1em;
`

interface ResultPreviewButtonProps {
  status?: 'pass' | 'error' | 'fail'
}

const ResultPreviewButton = styled(Button)`
  position: relative;
  font-size: 18px;
  height: 79px;
  border: none;
  border-radius: 12px;
  background: ${({ status }: ResultPreviewButtonProps) =>
    !status
      ? '#acacac'
      : status === 'pass'
      ? '#179654'
      : status === 'error'
      ? '#ef5021'
      : status === 'fail'
      ? '#ee1d25'
      : 'acacac'};
  width: 100%;
  color: #fff;
  cursor: ${({ status }) => (!status ? 'default' : 'pointer')};
  font-weight: 300;

  :hover {
    transform: translate(0);
    background-color: #949494;
  }
`

const Status = styled.div`
  font-size: 14px;
  position: relative;
  min-height: 18px;
  line-height: 1.5;
`

const Name = styled.div`
  position: relative;
  font-size: 18px;
  line-height: 1.5;
  margin: 8px 0px;
  min-height: 24px;
`

const Container = styled.div`
  width: 100%;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  overflow: hidden;
  font-weight: 300;
  min-height: 200px;
  max-width: 360px;
  margin-left: auto;
  margin-right: auto;
`
