import styled from '@emotion/styled/macro'
import React, { useRef, useState } from 'react'
import { ReactComponent as ArrowRight } from '../../../assets/icons/arrow-right.svg'
import { ReactComponent as PlusIcon } from '../../../assets/icons/plus-icon.svg'
import Button from '../../../components/button'
import Card from '../../../components/card'
import LoadingSpinner from '../../../components/loading-spinner'
import Status from '../../../components/status'
import Subtitle from '../../../components/subtitle'
import TopRightIcon from '../../../components/top-right-icon'
import { defaultErrorMessage, mobileSize } from '../../../constants'
import useStatusError from '../../../hooks/use-status-error'
import { postStationTest } from '../../../services/entities/station'
import { Nullable } from '../../../types/misc'
import { interpretError, requestSuccessful } from '../../../utils/common'
import { capitalize } from '../../../utils/text'
import useToggle from '../../../hooks/use-toggle'
import HiddenInput from '../../../components/hidden-input'

interface Props {
  stationName: string
  refreshStationMessages: () => void
}

export default function EditStation({
  stationName,
  refreshStationMessages
}: Props) {
  const filePicker = useRef(null)
  const formRef = useRef<Nullable<HTMLFormElement>>(null)
  const [currentFile, setCurrentFile] = useState<Nullable<File>>(null)
  const [currentFileContents, setCurrentFileContents] = useState(null)
  const [loading, startLoading, finishLoading] = useToggle(false)
  const { status, error, setError, setStatus } = useStatusError()
  const canSubmit = currentFile && currentFileContents && !loading
  const resetContents = () => {
    formRef?.current.reset()
    setCurrentFile(null)
    setCurrentFileContents(null)
  }
  const onFilePick = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist()
    if (e?.currentTarget?.files[0]) {
      setCurrentFile(e.currentTarget.files[0])
      const reader = new FileReader()
      startLoading()
      reader.onload = () => {
        finishLoading()
        setCurrentFileContents(reader.result)
      }
      reader.readAsText(e.currentTarget.files[0])
    }
  }
  const openFilePicker = () => filePicker?.current && filePicker.current.click()

  const onTestSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    e.persist()

    startLoading()
    try {
      const { status, data, request } = await postStationTest(
        stationName,
        currentFileContents
      )
      if (status && data && requestSuccessful(status)) {
        resetContents()
        setStatus('Successfully uploaded your test.')
        refreshStationMessages()
      } else {
        const serverError =
          request?.response && JSON.parse(request.response).message
        setError(capitalize(serverError ?? defaultErrorMessage))
      }
    } catch (err) {
      setError(interpretError(err))
    }
    finishLoading()
  }

  return (
    <Container>
      <Card>
        <Subtitle>Update Test</Subtitle>
        <form ref={formRef}>
          <OneHalf>
            <Button icon={true} onClick={openFilePicker}>
              <PlusIcon height={24} />
            </Button>
            <FileName>{currentFile?.name ?? 'No file chosen'}</FileName>
          </OneHalf>
          <Status error={!!error}>
            {capitalize(error ?? status ?? 'Pick a JSON file.')}
          </Status>
          <TopRightIcon>
            <Button
              type="button"
              icon={true}
              onClick={onTestSubmit}
              disabled={!canSubmit}>
              {loading ? (
                <LoadingSpinner variant={'dark'} height={24} width={24} />
              ) : (
                <ArrowRight width={24} />
              )}
            </Button>
          </TopRightIcon>
          <HiddenInput
            required
            accept="application/json"
            type="file"
            ref={filePicker}
            onChange={onFilePick}
          />
        </form>
      </Card>
    </Container>
  )
}

const FileName = styled.p`
  font-size: 14px;
  line-height: 20px;
  word-break: break-word;
`

const OneHalf = styled.div`
  display: grid;
  grid-template-columns: 68px 1fr;
  align-items: center;
`

const Container = styled.div`
  width: 100%;
  display: grid;
  position: relative;

  @media screen and (max-width: ${mobileSize}px) {
    grid-template-columns: 1fr;
  }
`
