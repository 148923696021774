import styled from '@emotion/styled/macro'

const AlignRight = styled.div`
  margin-top: 16px;
  margin-bottom: 16px;
  display: grid;
  justify-items: end;
  justify-content: end;
`

export default AlignRight
