import styled from '@emotion/styled/macro'
import React, { useLayoutEffect, useRef, useState } from 'react'
import Plot from 'react-plotly.js'
import Card from '../../../components/card'
import { mobileSize } from '../../../constants'
import { TestResult } from '../../../types/test-result'
import { formatDate } from '../../../utils/date-time'
import { capitalize } from '../../../utils/text'
import { createResultChart } from './result-chart.utils'
import AutoSizer from 'react-virtualized-auto-sizer'
import { FixedSizeList as List } from 'react-window'

interface Options {
  unnamed?: boolean
}

interface Props {
  data: TestResult
  download: string
  opts?: Options
}

export default function TestResults({
  data,
  download,
  opts = {}
}: React.ComponentProps<'div'> & Props) {
  const plotContainerRef = useRef<HTMLDivElement>(null)
  const [width, setWidth] = useState(0)

  useLayoutEffect(() => {
    if (plotContainerRef.current) {
      setWidth(plotContainerRef.current.clientWidth)
    }
  }, [])

  return (
    <Container>
      <RightOptions>
        <ResultTitle hasLabel={true}>
          {opts.unnamed ? 'Test Results' : data?.Test?.Name}
        </ResultTitle>
      </RightOptions>
      <ResultSubtitle>
        <div>{data?.Test?.Version}</div>{' '}
        <a href={download} download={data?.Test?.Id}>
          download
        </a>
      </ResultSubtitle>
      <StatContainer>
        <Column>
          <PropContainer gridSizes={['92px', '1fr']}>
            <Prop>
              <PropName>Author</PropName>
              <PropValue>{data.Test?.Author}</PropValue>
            </Prop>
            <Prop>
              <PropName>Type</PropName>
              <PropValue>{data.Results?.Type}</PropValue>
            </Prop>
            <Prop>
              <PropName>Station</PropName>
              <PropValue>{data.Results?.Station}</PropValue>
            </Prop>
            <Prop>
              <PropName>HUT</PropName>
              <PropValue>{data.Results?.Uuid}</PropValue>
            </Prop>
            <Prop>
              <PropName>Messages</PropName>
              <PropValue>
                {data.Results?.Messages.map((m, i) => (
                  <div key={`${m}-${i}`}>{m}</div>
                ))}
              </PropValue>
            </Prop>
          </PropContainer>
        </Column>
        <Column>
          <PropContainer gridSizes={['84px', '1fr']}>
            <Prop>
              <PropName>Temperature</PropName>
              <PropValue>
                {data.Results?.Environment?.Temperature?.toFixed(1)} &#176;C
              </PropValue>
            </Prop>
            <Prop>
              <PropName>Humidity</PropName>
              <PropValue>
                {data.Results?.Environment?.Humidity.toFixed(1)} %
              </PropValue>
            </Prop>
            <Prop>
              <PropName>Pressure</PropName>
              <PropValue>
                {Number(data.Results?.Environment?.Pressure / 1000).toFixed(2)}{' '}
                kPa
              </PropValue>
            </Prop>
          </PropContainer>
        </Column>
        <Column>
          <PropContainer gridSizes={['84px', '1fr']}>
            <Prop>
              <PropName>Started</PropName>
              <PropValue>
                {data.Results?.Start &&
                  formatDate(Date.parse(data.Results?.Start))}
              </PropValue>
            </Prop>
            <Prop>
              <PropName>Finished</PropName>
              <PropValue>
                {data.Results?.Finish &&
                  formatDate(Date.parse(data.Results?.Finish))}
              </PropValue>
            </Prop>
            <Prop>
              <PropName>Duration</PropName>
              <PropValue>
                {Math.ceil(
                  (Date.parse(data.Results?.Finish) -
                    Date.parse(data.Results?.Start)) /
                    1000
                ) + ' s'}
              </PropValue>
            </Prop>
          </PropContainer>
        </Column>
        <TestStatus status={data.Results?.Status?.toLowerCase()}>
          {data.Results?.Status}
        </TestStatus>
      </StatContainer>
      <PlotContainer ref={plotContainerRef}>
        {plotContainerRef?.current &&
          data.Test?.Stages?.filter(stage => stage?.Charts?.length).map(
            stage => (
              <StageContainer key={stage.Name}>
                <ResultTitle>{stage.Name}</ResultTitle>
                {stage.Charts.map(c =>
                  createResultChart(
                    c,
                    data.Results.Data,
                    stage.Bounds?.Response
                  )
                ).map(({ data: chartData, layout, configuration }) => (
                  <Plot
                    key={JSON.stringify({
                      chartData,
                      configuration,
                      layout
                    })}
                    data={chartData}
                    layout={{ ...layout, ...(width ? { width } : {}) }}
                    config={configuration}
                  />
                ))}
                <Column>
                  {stage.Tables.map(table => (
                    <FitContent key={table.Label}>
                      <PropTitle>{table.Label}</PropTitle>
                      {table.Entries.map(entry => (
                        <Prop
                          key={`${entry.Label}-${entry.Prefix}-${entry.Suffix}`}>
                          <PropName>{entry.Label}</PropName>
                          {data.Results?.Data?.hasOwnProperty(entry.Name) && (
                            <PropValue>
                              {[
                                entry.Prefix,
                                data?.Results?.Data[entry?.Name].toString(),
                                entry.Suffix
                              ]
                                .join(' ')
                                .trim()}
                            </PropValue>
                          )}
                        </Prop>
                      ))}
                    </FitContent>
                  ))}
                </Column>
              </StageContainer>
            )
          )}
      </PlotContainer>
      <Section>
        <ResultTitle>Configuration</ResultTitle>
        <div>
          <PropContainer gridSizes={['86px', '1fr']}>
            <PropTitle>Analyzer</PropTitle>
            <Prop>
              <PropName>UUID</PropName>
              <PropValue>
                {data.Results?.Configuration?.Analyzer?.Uuid}
              </PropValue>
            </Prop>
            <Prop>
              <PropName>Version</PropName>
              <PropValue>
                {data.Results?.Configuration?.Analyzer?.Version}
              </PropValue>
            </Prop>
          </PropContainer>
        </div>
        <Section>
          <PropContainer gridSizes={['232px', 'minmax(120px, 1fr)']}>
            <PropTitle>Device</PropTitle>
            <Prop>
              <PropName>Type</PropName>
              <PropValue>{data.Test?.Requires?.Device?.Name}</PropValue>
            </Prop>
            <Prop>
              <PropName>Version</PropName>
              <PropValue>
                {data.Results?.Configuration?.DeviceHandler?.Version}
              </PropValue>
            </Prop>
            {Object.keys(data.Test?.Requires?.Device?.Parameters)
              .filter(key => key !== 'Patches')
              .map((key, i) => (
                <Prop key={'deviceparameters' + i}>
                  <PropName>{key.replace(/([A-Z])/g, ' $1').trim()}</PropName>
                  <PropValue>
                    {data.Test?.Requires?.Device?.Parameters[key]?.toString()}
                  </PropValue>
                </Prop>
              ))}
          </PropContainer>
        </Section>
        <Section>
          <PropContainer gridSizes={['108px', '1fr']}>
            <PropTitle>Soundstation</PropTitle>
            <Prop>
              <PropName>UUID</PropName>
              <PropValue>
                {data.Results?.Configuration?.Soundstation?.Uuid}
              </PropValue>
            </Prop>
            <Prop>
              <PropName>Version</PropName>
              <PropValue>
                {data.Results?.Configuration?.Soundstation?.Version}
              </PropValue>
            </Prop>
          </PropContainer>
        </Section>
        <Section>
          <PropContainer gridSizes={['108px', '1fr']}>
            <PropTitle>Microphones</PropTitle>
            <Prop>
              <PropName>UUID</PropName>
              <PropValue>
                {data.Results?.Configuration?.Microphones?.Uuid}
              </PropValue>
            </Prop>
          </PropContainer>
        </Section>
      </Section>
      <Section>
        <ResultTitle>Log</ResultTitle>
        <div>
          <LogContainer>
            <AutoSizer>
              {({ height, width }) => (
                <List
                  height={height}
                  itemCount={data.Log.length}
                  itemSize={36}
                  width={width}>
                  {({ index, style }) => (
                    <div
                      className={index % 2 === 0 ? 'log' : 'log odd'}
                      key={`log-entry-${index}`}
                      style={style}>
                      {capitalize(data.Log[index] ?? '')}
                    </div>
                  )}
                </List>
              )}
            </AutoSizer>
          </LogContainer>
        </div>
      </Section>
    </Container>
  )
}

const PlotContainer = styled.div`
  width: 100%;
`

const Section = styled.div`
  margin: 32px 0;
`

const FitContent = styled.div`
  width: fit-content;
  min-width: 200px;
`

const gridMediaStyling = `
  @media screen and (max-width: ${mobileSize}px) {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 24px;
  }
`

const OneHalf = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 52px;

  ${gridMediaStyling}
`

const RightOptions = styled(OneHalf)`
  *:last-of-type {
    justify-self: end;
  }
`

const LogContainer = styled.div`
  font-weight: 300;
  line-height: 1.6;
  height: 600px;

  .log {
    line-height: 36px;

    &.odd {
      background-color: rgba(0, 0, 0, 0.05);
    }
  }
`

const StageContainer = styled.div`
  margin: 32px 0px;
  width: 100%;

  &:first-of-type {
    margin-top: 52px;
  }
`

const Column = styled.div`
  display: grid;
  grid-gap: 12px;
  line-height: 1.5;
  height: fit-content;
`

interface TestStatusProps {
  status: string
}

const TestStatus = styled(Column)`
  background: ${({ status }: TestStatusProps) =>
    status === 'pass' ? 'green' : status === 'fail' ? '#ec1b23' : 'orange'};
  text-align: center;
  width: 64px;
  color: white;
  font-weight: bold;
  font-size: 20px;
  text-transform: uppercase;
  height: 120px;
  width: 100%;
  align-content: center;
  border-radius: 8px;
`

const PropValue = styled.div``

const PropName = styled.div`
  color: #a4a4a4;
  width: fit-content;
`

const Prop = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 4px 0px;
  grid-gap: 12px;

  ${gridMediaStyling}
`

const PropContainer = styled.div<{ gridSizes?: string[] }>`
  display: grid;
  ${Prop} {
    grid-template-columns: ${({ gridSizes }) =>
      gridSizes ? gridSizes.join(' ') : '1fr 1fr'};
  }
`

const StatContainer = styled.div`
  display: grid;
  grid-template-columns: 1.8fr 1fr 1.4fr 0.65fr;
  font-weight: 300;
  margin: 24px 0px;
  grid-gap: 24px;

  ${gridMediaStyling}
`

const PropTitle = styled.div`
  margin-bottom: 12px;
`

const ResultSubtitle = styled.div`
  color: #a4a4a4;
  display: grid;
  grid-auto-flow: column;
  width: fit-content;
  grid-gap: 23px;
  margin-bottom: 24px;
  grid-gap: 12px;

  div:first-of-type {
    font-style: italic;
  }

  ${gridMediaStyling}
`

const ResultTitle = styled.div<{ hasLabel?: boolean }>`
  font-size: 18px;
  font-weight: 300;
  margin-bottom: ${({ hasLabel }) => (hasLabel ? '4px' : '24px')};
  color: #666;
`

const Container = styled.div`
  line-height: 1.5;
  font-size: 14px;
  color: #212529;
  font-weight: 300;

  ${Card} {
    width: 100%;
  }
`
