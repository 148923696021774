import { ToggleOption } from '../types/input-option'

export function createDefaultOption<T>(value: T) {
  return {
    value,
    label: String(value)
  } as { value: T; label: string }
}

export const toggleOptions: ToggleOption[] = [
  { label: 'Enabled', value: 'enabled' },
  { label: 'Disabled', value: 'disabled' }
]
