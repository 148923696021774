import { Form, FormikProps, withFormik } from 'formik'
import React from 'react'
import { Link } from 'react-router-dom'
import * as Yup from 'yup'
import AuthLayout from '../../containers/auth-layout'
import { createRequiredTextField } from '../../utils/form'
import { capitalize } from '../../utils/text'
import {
  LoginField,
  Title,
  Subtitle,
  LoginButton,
  LinkUnderForm
} from '../../components/auth'
import { postForgotPassword } from '../../services/entities/authentication'
import { interpretError } from '../../utils/common'

const initialValues = {
  email: '',
  password: ''
}

const forgotPassSchema = Yup.object().shape({
  email: createRequiredTextField('email').email('Enter a valid email.')
})

export default withFormik<{}, typeof initialValues>({
  mapPropsToValues: () => ({
    ...initialValues
  }),
  handleSubmit: async ({ email }, { setStatus, resetForm }) => {
    try {
      await postForgotPassword(email)
      resetForm()
      setStatus('Please check your inbox.')
    } catch (err) {
      setStatus(interpretError(err))
    }
  },
  validationSchema: forgotPassSchema
})(ForgotPassword)

function ForgotPassword({
  isValid,
  isSubmitting,
  status,
  dirty
}: FormikProps<typeof initialValues>) {
  return (
    <AuthLayout>
      <Form>
        <Title>Reset Password</Title>
        <Subtitle>
          {capitalize(status ? status : 'Enter your email below')}
        </Subtitle>
        <LoginField
          type="email"
          autoComplete="email"
          required
          placeholder="Email"
          disabled={isSubmitting}
          id="email"
          name="email"
          autoFocus
        />
        <LoginButton
          type="submit"
          disabled={!isValid || !dirty}
          loading={isSubmitting}
          variant={'light'}>
          Reset Password
        </LoginButton>
        <LinkUnderForm>
          <Link to={'/login'}>Return to Login</Link>
        </LinkUnderForm>
      </Form>
    </AuthLayout>
  )
}
