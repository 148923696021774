import { types } from 'mobx-state-tree'

const Error = types
  .model('Error', {
    error: types.string
  })
  .actions(self => ({
    fireError: (error: string) => {
      self.error = error
    },
    clearError: () => {
      self.error = ''
    }
  }))

export default Error
