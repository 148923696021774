import styled from '@emotion/styled/macro'

const FilterInputGroup = styled.div`
  font-size: 13px;
  input {
    padding: 10px;
    font-size: 13px;
  }
  p {
    margin-bottom: 4px;
  }
`

export default FilterInputGroup
