export const mobileSize = 868
export const maxWidth = 1360
export const tokenPoolInterval = 120000
export const siteKey = 'soundstation'
export const tokenKey = `${siteKey}:token`
export const localPersistKey = `${siteKey}:root`
export const tableSize = 5
export const defaultPagePayload = {
  pageNumber: 0,
  pageSize: tableSize
}
export const systemInitiator = 'System'
export const defaultErrorMessage = 'An unknown error occured.'
export const noop = () => {}
export const dateFormat = 'YYYY[-]MM[-]DD[T]HH[:]mm[:]ss[.]SSS[Z]'
export const filterDelimeter = '|'
export const pageSizeOptions = [5, 10, 20, 25]
export const alphabet = 'ABCDEFGHIJKLMNOPQRSTWXYZ'
export const colors = [
  '#FF6633',
  '#3366E6',
  '#999966',
  '#B34D4D',
  '#80B300',
  '#809900',
  '#66991A',
  '#FF1A66',
  '#E6331A',
  '#66994D',
  '#B366CC',
  '#4D8000',
  '#B33300',
  '#66664D',
  '#991AFF',
  '#E666FF',
  '#E666B3',
  '#B3B31A',
  '#4D8066',
  '#809980',
  '#999933',
  '#FF3380',
  '#CCCC00',
  '#9900B3',
  '#E64D66',
  '#4DB380',
  '#FF4D4D'
]
