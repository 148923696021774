import styled from '@emotion/styled/macro'
import React from 'react'
import Table, { TableData } from '../../components/table'
import Content from '../../containers/content'
import usePagedAsync from '../../hooks/use-paged-async'
import { fetchStations } from '../../services/entities/station'
import { Station as StationType } from '../../types/station'
import { intervalToNow, timestampToLocalDate } from '../../utils/date-time'
import { capitalize } from '../../utils/text'
import Station from './station/station'
import 'react-datetime/css/react-datetime.css'

const meta: TableData<StationType>['meta'] = {
  entity: 'stations',
  fields: {
    name: {
      label: 'Station',
      size: 3
    },
    locationName: { label: 'Location', size: 2.5 },
    testName: { label: 'Product', size: 2 },
    testVersion: { label: 'Version', size: 1.2 },
    testAuthor: { label: 'Author', size: 4 },
    licenceStatus: {
      label: 'Licence Status',
      size: 2,
      content: (status, datum) =>
        status === 'valid'
          ? `Valid (${intervalToNow(datum.licenceTo)} remaining)`
          : capitalize(status)
    },
    licensee: {
      label: 'Licensee',
      size: 2
    },
    licenceFrom: {
      label: 'Licence From',
      size: 2.5,
      content: (timestamp, datum) =>
        datum.licenceStatus === 'not installed'
          ? ''
          : timestampToLocalDate(timestamp)
    },
    licenceTo: {
      label: 'Licence To',
      size: 2.5,
      content: (timestamp, datum) =>
        datum.licenceStatus === 'not installed'
          ? ''
          : timestampToLocalDate(timestamp)
    },
    timestamp: { label: 'Last Report', size: 3, content: timestampToLocalDate }
  },
  arrangement: [
    'name',
    'locationName',
    'testName',
    'testVersion',
    'testAuthor',
    'licenceStatus',
    'licensee',
    'licenceFrom',
    'licenceTo',
    'timestamp'
  ],
  filters: {
    name: {
      label: 'Station',
      type: 'text'
    },
    locationName: {
      label: 'Location',
      type: 'text'
    },
    testName: {
      label: 'Product',
      type: 'text'
    },
    testVersion: {
      label: 'Version',
      type: 'text'
    },
    testAuthor: {
      label: 'Author',
      type: 'text'
    },
    licenceStatus: {
      label: 'License Status',
      type: 'enum',
      options: [
        { label: 'Valid', value: 'valid' },
        { label: 'Expired', value: 'expired' },
        { label: 'Invalid', value: 'invalid' },
        { label: 'Not Installed', value: 'not installed' }
      ]
    },
    licensee: {
      label: 'Licensee',
      type: 'text'
    },
    licenceFrom: {
      type: 'timestamp',
      label: 'License From'
    },
    licenceTo: {
      type: 'timestamp',
      label: 'License To'
    },
    timestamp: {
      type: 'timestamp',
      label: 'Last Seen'
    }
  },
  rowHoverContent: function StationInfo(station) {
    return <StyledStation stationName={station.name} section={true} />
  }
}

const pageSize = 5

const stationsParams = {
  pageSize,
  filterKey: 'stations-page'
}

export default function Stations() {
  const {
    data,
    loading,
    error,
    setPage,
    count,
    query,
    initialized
  } = usePagedAsync({
    // @ts-ignore
    fn: fetchStations,
    opts: stationsParams,
    entity: 'stations'
  })

  return (
    <Container>
      <Content
        title={'Missioncontrol'}
        subtitle={'Stations'}
        loading={!initialized}
        error={error}>
        <Table
          meta={meta}
          query={query}
          data={data}
          keyExtractor={station => station.uuid}
          pageSize={pageSize}
          error={error}
          count={count}
          onPageChange={({ selected }) => setPage(selected)}
          loading={loading}
        />
      </Content>
    </Container>
  )
}

const Container = styled.div`
  position: relative;
`

const StyledStation = styled(Station)`
  transform: scaleY(0.6);
`
