import { css } from '@emotion/core'
import styled from '@emotion/styled/macro'
import { mobileSize } from '../constants'

export const Gridder = styled.div<{
  template: string[]
  gap?: string
  otherStyles?: string
  align?: 'start' | 'center' | 'end'
  marginBottom?: string
  height?: string
}>`
  display: grid;

  ${({
    height = 'fit-content',
    template,
    gap = '4px',
    align = 'center',
    marginBottom = '12px'
  }) => `
    grid-template-columns: ${template.join(' ')};
    grid-gap: ${gap};
    align-items: ${align};
    margin-bottom: ${marginBottom};
    height: ${height};
  `}
`

const mobileColumnTemplate = css`
  @media screen and (max-width: ${mobileSize}px) {
    grid-template-columns: 1fr;
    grid-auto-flow: row;
  }
`

export const OneFourthFit = styled.div`
  display: grid;
  grid-column-gap: 20px;
  grid-template-columns: repeat(4, 1fr);

  ${mobileColumnTemplate}
`

export const OneHalf = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 20px;

  ${mobileColumnTemplate}
`

export const JustifyRight = styled.div`
  justify-self: end;
  justify-items: end;
  display: grid;
`
