import { keyframes } from '@emotion/core'
import styled from '@emotion/styled/macro'

const hide = keyframes`
  100% {
    height: 0;
    transform: translateY(10000%);
  }
`

const Showable = styled.div<{ shown: boolean; hide: () => void }>`
  opacity: ${({ shown }) => (shown ? 1 : 0)};
  overflow: hidden;
  animation-name: ${({ shown }) => (shown ? 'none' : hide)};
  animation-delay: 400ms;
  animation-fill-mode: forwards;
  transition: 400ms all;
  z-index: 4;
`

export default Showable
