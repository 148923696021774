import { Result, Err, Ok } from 'ts-results'

const maximum = (integerBits: number, fractionBits: number): number => (Math.pow(2, (integerBits+fractionBits)) - 1) / Math.pow(2, fractionBits);
const minimum = (integerBits: number, fractionBits: number): number => -Math.pow(2, (integerBits+fractionBits)) / Math.pow(2, fractionBits);

const toFixed = (integerBits: number, fractionBits: number, real: number, negate: boolean = false): Result<number, string> => {

  if (negate) {
    real *= -1
  }

  const totalBits = integerBits + fractionBits + 1;

  if ((real < minimum(integerBits, fractionBits)) || (real > maximum(integerBits, fractionBits))) {
    return Err('real conversion to fixed precision failed, value exceeded range');
  }

  const fixed = Math.round(real * Math.pow(2, fractionBits));

  return Ok(fixed + (fixed < 0 ? Math.pow(2, totalBits) : 0));
};


const toFloat = (integerBits: number, fractionBits: number, fixed: number, negate: boolean = false): Result<number, string> => {

  const totalBits = integerBits + fractionBits + 1;

  let real = fixed;

  if (real >= (Math.pow(2, totalBits - 1))) {
    real = real - Math.pow(2, totalBits);
  }

  real = real / Math.pow(2, fractionBits);

  if (negate) {
    real *= -1
  }

  return Ok(real);
};

export default { maximum, minimum, toFixed, toFloat }

