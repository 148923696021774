import makeInspectable from 'mobx-devtools-mst'
import { applySnapshot, getSnapshot } from 'mobx-state-tree'
import React, { createContext } from 'react'
import initialData from '../initial-data'
import Root from '../models/root'
import { localPersistKey } from '../constants'
import { StateShape } from '../initial-data'

const initialStore = initialData.root

const persistedRoot: StateShape['root'] =
  localStorage.getItem(localPersistKey) &&
  JSON.parse(localStorage.getItem(localPersistKey))

const store = Root.create(persistedRoot ?? initialStore)

makeInspectable(store)

export const RootStoreContext = createContext(store)

export default function RootStore({ children }: { children: React.ReactNode }) {
  return (
    <RootStoreContext.Provider value={store}>
      {children}
    </RootStoreContext.Provider>
  )
}

if (module.hot) {
  if (module.hot.data && module.hot.data.store) {
    applySnapshot(store, module.hot.data.store)
  }
  module.hot.dispose(data => {
    data.store = getSnapshot(store)
  })
}
