export default function detectOS() {
  const platform = window.navigator.platform,
    macosPlatforms = ['Macintosh', 'MacIntel'],
    windowsPlatforms = ['Win32', 'Win64', 'Windows'],
    linuxPlatforms = [
      'Linux',
      'Linux i686',
      'Linux i686 on x86_64',
      'Linux i686 X11',
      'Linux x86_64',
      'Linux x86_64 X11'
    ]
  let os: 'MacOS' | 'Windows' | 'Linux' = null

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = 'MacOS'
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = 'Windows'
  } else if (linuxPlatforms.indexOf(platform) !== -1) {
    os = 'Linux'
  }

  return os
}
