import { types } from 'mobx-state-tree'
import { siteKey } from '../constants'
import { fqdn as fqdnPattern } from '../regex'

const storageKey = `${siteKey}:production-stations`

const ProductionStation = types
  .model('ProductionStation', {
    list: types.array(types.string)
  })
  .postProcessSnapshot(snapshot =>
    localStorage.setItem(storageKey, JSON.stringify(snapshot))
  )
  .actions(self => ({
    addToList: (fqdn: string) => {
      if (fqdnPattern.test(fqdn))
        if (!self.list.includes(fqdn)) self.list.push(fqdn)
        else throw new Error('FQDN already exists.')
      else throw new Error('Invalid FQDN format.')
    },
    removeFromList: (fqdn: string) => {
      self.list.replace(self.list.filter(currentFqdn => fqdn !== currentFqdn))
    }
  }))

export default ProductionStation
