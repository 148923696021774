import styled from '@emotion/styled/macro'
import React, { useCallback, useState } from 'react'
import { ReactComponent as MacOSLogo } from '../../../assets/icons/mac-os.svg'
import { ReactComponent as WindowsLogo } from '../../../assets/icons/windows.svg'
import { ReactComponent as LinuxLogo } from '../../../assets/icons/linux-os.svg'
import Button from '../../../components/button'
import ImageHoverCard from '../../../components/image-hover-card'
import Modal from '../../../components/modal'
import SectionTitle from '../../../components/section-title'
import useToggle from '../../../hooks/use-toggle'
import detectOS from '../../../utils/detect-os'
import images from './utils/installation-image-exporters'

interface PlatformDownloadButtonProps {
  type: 'MacOS' | 'Windows' | 'Linux'
  emphasis: boolean
  link: string
  onClick?: () => void
}

function PlatformDownloadButton({
  type,
  emphasis,
  link,
  onClick = () => false
}: PlatformDownloadButtonProps) {
  return (
    <a href={link ?? '#'}>
      <DownloadButton
        disabled={!link}
        onClick={onClick}
        style={{ opacity: emphasis ? 1 : 0.3 }}>
        {type === 'MacOS' && (
          <>
            <Logo>
              <MacOSLogo
                style={{
                  marginTop: -3
                }}
              />
            </Logo>
            <DownloadName1>
              <p>MacOS</p>
            </DownloadName1>
            <DownloadName2>
              <p>Sierra</p>
            </DownloadName2>
          </>
        )}
        {type === 'Linux' && (
          <>
            <Logo>
              <LinuxLogo
                style={{
                  marginTop: -3,
                  transform: 'scale(1.2)'
                }}
              />
            </Logo>
            <DownloadName1>
              <p>Linux</p>
            </DownloadName1>
            <DownloadName2>
              <p>Ubuntu 18.04.4 LTS amd64</p>
            </DownloadName2>
          </>
        )}
        {type === 'Windows' && (
          <>
            <Logo>
              <WindowsLogo
                style={{
                  transform: 'scale(0.9)'
                }}
              />
            </Logo>
            <DownloadName1>
              <p>Windows</p>
            </DownloadName1>
            <DownloadName2>
              <p>Windows 10</p>
            </DownloadName2>
          </>
        )}
      </DownloadButton>
    </a>
  )
}

interface DownloadPluginPageProps {
  os: 'MacOS' | 'Windows' | 'Linux'
  downloadLinksLoading: boolean
  windowsOsDirectLink: string
  macOsDirectLink: string
  linuxOsDirectLink: string
}

export default function DownloadPluginPage({
  os,
  downloadLinksLoading,
  windowsOsDirectLink,
  macOsDirectLink,
  linuxOsDirectLink
}: DownloadPluginPageProps) {
  const [instructionOs, setInstructionOs] = useState<
    'MacOS' | 'Windows' | 'Linux'
  >(detectOS())
  const [currentShownImage, setCurrentShownImage] = useState<string>(null)
  const [modalShown, showModal, hideModal] = useToggle(false)

  const showModalImage = useCallback(
    (imageUrl: string) => {
      setCurrentShownImage(imageUrl)
      showModal()
    },
    [showModal, setCurrentShownImage]
  )

  return (
    <>
      <MainInstructionText>
        Soundstation Engineer requires the latest plugin to be installed on your
        computer.
      </MainInstructionText>

      <StyledSectionTitle
        title={'Download'}
        style={{ marginTop: '2em', marginBottom: '10px' }}
      />

      <Platforms>
        {os === 'Windows' && windowsOsDirectLink && (
          <PlatformDownloadButton
            type="Windows"
            emphasis={true}
            onClick={() => setInstructionOs('Windows')}
            link={windowsOsDirectLink}
          />
        )}
        {os === 'MacOS' && macOsDirectLink && (
          <PlatformDownloadButton
            type="MacOS"
            emphasis={true}
            onClick={() => setInstructionOs('MacOS')}
            link={macOsDirectLink}
          />
        )}
        {os === 'Linux' && linuxOsDirectLink && (
          <PlatformDownloadButton
            type="Linux"
            emphasis={true}
            onClick={() => setInstructionOs('Linux')}
            link={linuxOsDirectLink}
          />
        )}
        {os !== 'Linux' && linuxOsDirectLink && (
          <PlatformDownloadButton
            type="Linux"
            emphasis={!os}
            onClick={() => setInstructionOs('Linux')}
            link={linuxOsDirectLink}
          />
        )}
        {os !== 'MacOS' && macOsDirectLink && (
          <PlatformDownloadButton
            type="MacOS"
            emphasis={!os}
            onClick={() => setInstructionOs('MacOS')}
            link={macOsDirectLink}
          />
        )}
        {os !== 'Windows' && windowsOsDirectLink && (
          <PlatformDownloadButton
            type="Windows"
            emphasis={!os}
            onClick={() => setInstructionOs('Windows')}
            link={windowsOsDirectLink}
          />
        )}
      </Platforms>

      {!!os && (
        <>
          <StyledSectionTitle title={'Install'} />
          <ImageContainer>
            {(instructionOs === 'MacOS' ||
              instructionOs === 'Windows' ||
              instructionOs === 'Linux') &&
              (instructionOs === 'MacOS'
                ? images.macInstallationImages
                : instructionOs === 'Linux'
                ? images.linuxInstallationImages
                : images.windowsInstallationImages
              ).map((imageLink, index) => (
                <ImageHoverCard
                  key={`${imageLink}-${index}`}
                  onClick={() => showModalImage(imageLink)}>
                  <img src={imageLink} alt={'Instruction'} />
                </ImageHoverCard>
              ))}
          </ImageContainer>
          <Modal shown={modalShown} hide={hideModal} photo={true}>
            <img
              src={currentShownImage}
              style={{ maxWidth: 868 }}
              alt={'Installation Preview'}
            />
          </Modal>
        </>
      )}
    </>
  )
}

const StyledSectionTitle = styled(SectionTitle)`
  margin-top: 32px;
  margin-bottom: 32px;
`

const ImageContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
`

const Logo = styled.div`
  justify-self: center;

  svg {
    width: 24px;
    height: 24px;
  }
`

const DownloadButton = styled(Button)`
  width: 200px;
  height: 120px;
  margin-top: 16px;
  margin-bottom: 16px;
  color: #000000;
  font-size: 15px;
  line-height: 1;
  padding: 8px;
  background-color: #ffffff;
  border-radius: 9px;
  box-shadow: 0px 3px 7px 1px rgba(0, 0, 0, 0.03);
  display: grid;
  align-items: center;
  cursor: pointer;
  transition: 500ms all;
  opacity: 0.8;
  text-transform: none;

  &:hover {
    opacity: 1;
  }
`

const MainInstructionText = styled.h2`
  line-height: 1.6;
  font-size: 16px;
  margin-bottom: 1em;
`

const Platforms = styled.div`
  display: grid;
  grid-template-columns: repeat(3, min-content);
  grid-gap: 20px;
  align-items: end;
`

const DownloadName1 = styled.div`
  color: #231f1e;
  font-size: 10pt;
  text-align: center;
  font-weight: 800;
  padding: 0;
  padding-top: 10px;
`

const DownloadName2 = styled.div`
  color: #231f1e;
  font-size: 0.8em;
  text-align: center;
  padding: 0;
`
