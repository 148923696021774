import { AxiosResponse } from 'axios'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { TableData } from '../components/table'
import {
  ListableParams,
  ResultList,
  SelectableFromList
} from '../types/result-list'
import { createFacetsFromParams } from '../utils/api-interpreters'
import { interpretError, omit } from '../utils/common'
import { emptyArray } from '../utils/memoizer'
import useFilter from './use-filter'
import useCounter from './use-counter'
import useToggle from './use-toggle'
import { pageSizeFilterMeta } from '../components/filter'
import { tableSize } from '../constants'

export interface PagedAsync<T> {
  page: number
  count: number
  data: T[]
  loading: boolean
  error: string
  setPage: (page: number) => void
  setData: (newData: T[]) => void
  refreshData: () => void
}

interface Opts<T> extends Partial<SelectableFromList>, Partial<ListableParams> {
  filter?: TableData<T>['meta']['filters']
  filterKey?: string
  initialFilters?: {
    [key in keyof TableData<T>['meta']['filters']]: unknown
  }
}

export default function usePagedAsync<T, U>({
  fn,
  args = emptyArray,
  opts,
  entity
}: {
  fn: (...args: U[]) => Promise<AxiosResponse<ResultList<T>>>
  args?: U[]
  opts?: Opts<T>
  entity: string
}) {
  const [page, _setPage] = useState(0)
  const [count, setCount] = useState(0)
  const [data, setData] = useState<T[]>([] as T[])
  const [loading, startLoading, finishLoading] = useToggle(true)
  const [error, setError] = useState(null)
  const [trigger, refreshData] = useCounter()
  const [initialized, initialize] = useToggle(false)
  const appendData = (newdata: T[]) =>
    setData(oldData => [...oldData, ...newdata])
  const prependData = (newdata: T[]) =>
    setData(oldData => [...newdata, ...oldData])
  const filterMeta = useMemo(
    () => ({
      ...opts?.filter,
      pageSize: pageSizeFilterMeta
    }),
    [opts]
  )
  const filterParams = useMemo(
    () => ({
      filters: filterMeta,
      filterKey: opts?.filterKey ?? '',
      entity,
      initialFilters: opts?.initialFilters ?? undefined
    }),
    [opts, entity, filterMeta]
  )
  const query = useFilter(filterParams)
  const { queryFields } = query
  const [pageChanged, hasChangedPage] = useToggle(false)
  const setPage = useCallback(
    (page: number) => {
      _setPage(page)
      hasChangedPage()
    },
    [_setPage, hasChangedPage]
  )

  useEffect(() => {
    let valid = true

    async function doAsyncOperation() {
      startLoading()
      setError(null)
      try {
        const payload = createFacetsFromParams({
          ...opts,
          filter: omit(queryFields, 'pageSize') as typeof queryFields,
          entity,
          pageNumber: page,
          pageChanged,
          pageSize: queryFields?.pageSize ?? opts?.pageSize ?? undefined
        })

        const argsWithPayload = [...args, payload] as U[]
        const asyncInvoker = () => fn(...argsWithPayload)

        const {
          data: { data, count, page: currentPage }
        } = await asyncInvoker()

        if (valid) {
          setData(data)
          setCount(count)
          if (currentPage.number !== page) setPage(currentPage.number)
          initialize()
          finishLoading()
        }
      } catch (err) {
        setError(interpretError(err))
        initialize()
        finishLoading()
      }
    }

    doAsyncOperation()

    return () => {
      valid = false
    }
  }, [
    trigger,
    args,
    finishLoading,
    startLoading,
    opts,
    fn,
    initialize,
    queryFields,
    entity,
    page,
    setPage,
    pageChanged
  ])

  return {
    page,
    count,
    data,
    loading,
    error,
    setPage,
    setData,
    refreshData,
    appendData,
    prependData,
    initialized,
    query,
    pageSize: Number(query?.queryFields?.pageSize ?? tableSize)
  }
}
