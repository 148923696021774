import queryString from 'query-string'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import { TableData } from '../components/table'
import { siteKey, filterDelimeter } from '../constants'
import { convertFilterToQuery } from '../utils/api-interpreters'
import { capitalize } from '../utils/text'
import useDeepEffect from './use-deep-effect'
import useToggle from './use-toggle'
import { isEmpty } from '../utils/common'
import { pageSizeFilterMeta } from '../components/filter'

const emptyFields = {}

function extractKey(searchKey: string, entity: string) {
  const capitalizedEntity = capitalize(entity)
  const key = searchKey.substr(
    searchKey.indexOf(capitalizedEntity) + entity.length,
    searchKey.length
  )
  const lowerCaseKey = key
    .charAt(0)
    .toLowerCase()
    .concat(key.substr(1, key.length))
  return lowerCaseKey
}

export function createInitialValues<T>(
  filters: TableData<T>['meta']['filters']
) {
  return Object.keys(filters).reduce(
    (query, currentProp) => ({
      ...query,
      [currentProp]: filters[currentProp].initialValue
        ? filters[currentProp].initialValue
        : filters[currentProp].type === 'enum' &&
          filters[currentProp].type === 'multi'
        ? []
        : null
    }),
    {} as {
      [key in keyof TableData<T>['meta']['filters']]: string
    }
  )
}

export type QueryFields<T> = {
  [key in keyof T & string]: string
}

export default function useFilter<T>({
  filters,
  filterKey,
  entity,
  initialFilters
}: {
  filters: TableData<T>['meta']['filters'] & {
    pageSize: typeof pageSizeFilterMeta
  }
  filterKey: string
  entity: string
  initialFilters?: {
    // @ts-ignore
    [key in keyof TableData<T>['meta']['filters']]: unknown
  }
}) {
  const initialQuery = useMemo(
    () => ({ ...createInitialValues(filters), ...initialFilters }),
    [filters, initialFilters]
  )
  const [queryFields, innerSetQueryFields] = useState(initialQuery)
  const location = useLocation()
  const history = useHistory()
  const [firstLoad, , setHasLoaded] = useToggle(true)
  const reset = useCallback(() => {
    innerSetQueryFields(initialQuery)
    const searchQuery = queryString.parse(location.search) || {}
    const nonRelatedSearchQueries = Object.keys(searchQuery)
      .filter(key => !key.startsWith(`filter${capitalize(entity)}`))
      .reduce(
        (query, currentKey) => ({
          ...query,
          [currentKey]: searchQuery[currentKey]
        }),
        {}
      )
    history.push({ search: queryString.stringify(nonRelatedSearchQueries) })
  }, [innerSetQueryFields, initialQuery, entity, history, location.search])

  const combineSetQueryFields = useCallback(
    newFields =>
      innerSetQueryFields({
        ...queryFields,
        ...newFields
      }),
    [queryFields, innerSetQueryFields]
  )

  const setQueryField = useCallback(
    (name: keyof TableData<T>['meta']['filters'] | string, value: unknown) => {
      innerSetQueryFields(prev => ({
        ...prev,
        [name]: value
      }))
    },
    []
  )

  const setQueryFields = useCallback(
    newFields => innerSetQueryFields(newFields),
    []
  )

  const nonEmptyFields = useMemo(
    () =>
      Object.keys(queryFields || {}).reduce(
        (fields, currentField) => ({
          ...fields,
          ...((queryFields[currentField] !== '' &&
            queryFields[currentField] !== null &&
            typeof queryFields[currentField] !== 'undefined') ||
          (Array.isArray(queryFields[currentField]) &&
            !!queryFields[currentField]?.length)
            ? {
                [currentField]: queryFields[currentField]
              }
            : {})
        }),
        {} as typeof queryFields
      ),
    [queryFields]
  )

  useEffect(() => {
    if (firstLoad) {
      if (!!location.search) {
        const generalSearchQuery = queryString.parse(location.search)
        const entitySearchQuery = Object.keys(generalSearchQuery).reduce(
          (query, currentKey) => {
            const value = generalSearchQuery[currentKey].toString()
            const key = extractKey(currentKey, entity)
            const isValueArray = value.split(filterDelimeter).length > 1
            return {
              ...query,
              ...(currentKey.startsWith(`filter${capitalize(entity)}`)
                ? {
                    [key]: isValueArray ? value.split(filterDelimeter) : value
                  }
                : {})
            }
          },
          {}
        )
        combineSetQueryFields(entitySearchQuery)
        setHasLoaded()
      } else if (
        !location.search &&
        !!localStorage.getItem(`${siteKey}:${filterKey}`)
      ) {
        const initialQueries = JSON.parse(
          localStorage.getItem(`${siteKey}:${filterKey}`)
        )
        setQueryFields(initialQueries[entity])
      }
      setHasLoaded()
    }
  }, [
    combineSetQueryFields,
    location.search,
    setQueryFields,
    queryFields,
    firstLoad,
    setHasLoaded,
    filterKey,
    entity
  ])

  useDeepEffect(() => {
    history.push({
      search: queryString.stringify(
        convertFilterToQuery(nonEmptyFields, entity)
      )
    })
  }, [history, nonEmptyFields])

  useDeepEffect(() => {
    if (!!filterKey)
      localStorage.setItem(
        `${siteKey}:${filterKey}`,
        JSON.stringify({ [entity]: nonEmptyFields })
      )
  }, [nonEmptyFields, filterKey])

  return {
    queryFields: isEmpty(nonEmptyFields)
      ? (emptyFields as typeof nonEmptyFields)
      : nonEmptyFields,
    setQueryField,
    setQueryFields,
    combineSetQueryFields,
    reset
  }
}
