import api from '../../services/common/api'
import { Authentication } from '../../types/authentication'

export function postForgotPassword(username: string) {
  return api.post('/authentication/forgotpassword', {
    username
  })
}

export function postAuthenticate(username: string, password: string) {
  return api.post<Authentication>('/authentication/login', {
    username,
    password
  })
}

export function postRefreshToken(username: string, refreshToken: string) {
  return api.post<Authentication>('/authentication/refresh', {
    username,
    refreshToken
  })
}

export function postChangePassword(
  username: string,
  password: string,
  confirmationCode: string
) {
  return api.post('/authentication/forgotpasswordconfirmation', {
    username,
    password,
    confirmationCode
  })
}
