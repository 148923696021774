import React from 'react'
import Error from '../components/error'
import { useHistory } from 'react-router'

export default function NotFoundPage() {
  const history = useHistory()
  return (
    <Error
      error={false}
      message={'The page you are looking for does not exist'}
      fullPage={true}
      buttonProps={{
        label: 'Go back to homepage',
        onClick: () => history.push('/')
      }}
    />
  )
}
