import Window from '../../window'

const Beacon = {
  navigate: (path: string) => {
    Window.Beacon('navigate', path)
    Window.Beacon('open')
  },
  close: () => Window.Beacon('close')
}

export default Beacon
