import { useCallback, useState } from 'react'

export default function useToggle(value: boolean) {
  const [state, setState] = useState(value)
  const show = useCallback(() => setState(true), [])
  const hide = useCallback(() => setState(false), [])
  const toggle = useCallback(() => setState(shown => !shown), [])

  return [state, show, hide, toggle] as const
}
