import styled from '@emotion/styled/macro'
import React, { useCallback } from 'react'
import { ReactComponent as CheckIcon } from '../assets/icons/check.svg'
import { ReactComponent as CloseIcon } from '../assets/icons/close.svg'
import useToggle from '../hooks/use-toggle'
import { noop } from '../utils/common'
import LoadingSpinner from './loading-spinner'
import useThrottle from '../hooks/use-throttle'

interface Props {
  height?: SwitchComponentProps['height']
  width?: SwitchComponentProps['width']
  onSwitch?: (isOn) => void
  initialValue?: boolean | string
  isLabel?: boolean
}

export default function IconSwitch(props: React.ComponentProps<'div'> & Props) {
  const {
    height = 24,
    width,
    initialValue,
    isLabel = false,
    onSwitch,
    ...otherProps
  } = props
  const [loading, startLoading, finishLoading] = useToggle(false)
  const [isOn, , , toggle] = useToggle(
    typeof initialValue === 'string' ? initialValue === 'true' : initialValue
  )
  const switchWidth = width ?? height * 2.5
  const handleSwitch = useCallback(async () => {
    toggle()
    startLoading()
    if (onSwitch) await onSwitch(isOn)
    finishLoading()
  }, [toggle, startLoading, onSwitch, finishLoading, isOn])
  const throttledSwitch = useThrottle(handleSwitch, 50)

  return (
    <Container
      {...otherProps}
      height={height}
      width={switchWidth}
      {...(isOn ? { on: 'on' } : {})}
      onClick={isLabel || loading ? noop : throttledSwitch}
      isLabel={isLabel}>
      {loading ? (
        <LoadingSpinner height={14} variant={'dark'} />
      ) : isOn ? (
        <StyledCheckIcon />
      ) : (
        <StyledCloseIcon />
      )}
    </Container>
  )
}

interface SwitchComponentProps {
  height: number
  width: number
  on?: string
  isLabel: boolean
}

const StyledCheckIcon = styled(CheckIcon)`
  g {
    height: 18px;
    fill: #189453;
  }
`

const StyledCloseIcon = styled(CloseIcon)`
  height: 12px;
  path {
    fill: #ec1b23;
  }
`

const Container = styled.div<SwitchComponentProps>`
  border-radius: 99px;
  width: 24px;
  height: 24px;
  color: #666;
  text-transform: uppercase;
  text-align: center;
  font-weight: bold;
  font-size: 12px;
  display: grid;
  align-items: center;
  justify-items: center;
  transition: 400ms all;
  cursor: pointer;

  ${({ isLabel }) =>
    isLabel
      ? `cursor: default; 
         background-color: transparent
        `
      : `:hover {
          transform: translate(-2px, -2px);
        }
      `}
`
