import styled from '@emotion/styled/macro'
import { ErrorMessage, Field, FormikProps, withFormik } from 'formik'
import React from 'react'
import * as Yup from 'yup'
import { ReactComponent as ArrowRight } from '../../../assets/icons/arrow-right.svg'
import Button from '../../../components/button'
import Card from '../../../components/card'
import CenteredMargin from '../../../components/centered-margin'
import { InputError } from '../../../components/form'
import Status from '../../../components/status'
import Subtitle from '../../../components/subtitle'
import TopRightIcon from '../../../components/top-right-icon'
import { postStationFirmware } from '../../../services/entities/station'
import { interpretError } from '../../../utils/common'
import { createRequiredTextField } from '../../../utils/form'

interface Props {
  stationName: string
}

const firmwareFormSchema = Yup.object().shape({
  name: createRequiredTextField('name'),
  version: createRequiredTextField('version')
})

const initialValues = {
  name: '',
  version: ''
}

export default withFormik<Props, typeof initialValues>({
  mapPropsToValues: () => initialValues,
  handleSubmit: async (
    { name, version },
    { resetForm, setStatus, props: { stationName } }
  ) => {
    try {
      await postStationFirmware(stationName, {
        name,
        version
      })
      resetForm()
      setStatus('Successfully queued firmware update.')
    } catch (err) {
      setStatus(interpretError(err))
    }
  },
  validationSchema: firmwareFormSchema
})(FirmwareForm)

function FirmwareForm({
  handleSubmit,
  status,
  isSubmitting,
  isValid,
  dirty
}: FormikProps<typeof initialValues> & Props) {
  return (
    <Container>
      <Card>
        <Subtitle>Firmware Update</Subtitle>
        {status && <Status>{status}</Status>}
        <Form onSubmit={handleSubmit}>
          <CenteredMargin>
            <Field type="text" placeholder="Name" name="name" required />
            <InputError>
              <ErrorMessage name="name" />
            </InputError>
          </CenteredMargin>
          <CenteredMargin>
            <Field type="text" placeholder="Version" name="version" required />
            <InputError>
              <ErrorMessage name="version" />
            </InputError>
          </CenteredMargin>
          <TopRightIcon>
            <Button
              type="submit"
              loading={isSubmitting}
              icon={true}
              disabled={!dirty || !isValid}>
              <ArrowRight width={24} />
            </Button>
          </TopRightIcon>
        </Form>
      </Card>
    </Container>
  )
}

const Form = styled.form`
  width: 250px;
`

const Container = styled.div`
  position: relative;
`
