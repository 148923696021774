import React from 'react'

interface ConditionalWrapperProps {
  condition: boolean
  wrapper: React.ComponentType
}

export function ConditionalWrapper({
  condition,
  wrapper,
  children,
  ...props
}: ConditionalWrapperProps & React.ComponentProps<typeof wrapper>) {
  const Container = wrapper
  return condition ? <Container {...props}>{children}</Container> : children
}
