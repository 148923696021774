import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import Clickable from '../../../components/clickable'
import Table, { TableData } from '../../../components/table'
import { Result } from '../../../types/result'
import { interval, timestampToLocalDate } from '../../../utils/date-time'
import { SectionProp } from './product.page'
import { omit } from '../../../utils/common'
import { getProductMessages } from '../../../services/entities/product'

export const createResultsMeta = ({
  selectedTestId,
  productId
}: { selectedTestId?: string; productId?: string } = {}): TableData<
  Result
>['meta'] => ({
  entity: 'result',
  fields: {
    to: {
      label: 'Timestamp',
      size: 2.5,
      content: to => timestampToLocalDate(to)
    },
    uuid: {
      label: 'UUID',
      size: 3.5,
      content: function LinkToProductResults(uuid, datum) {
        return !selectedTestId || selectedTestId !== datum.id ? (
          <Link
            to={`/missioncontrol/product/${datum.productId}/result/${datum.uuid}/${datum.id}`}>
            <span>{uuid}</span>
          </Link>
        ) : (
          <span>{uuid}</span>
        )
      }
    },
    testVersion: { label: 'Version', size: 1 },
    stationName: {
      label: 'Station',
      size: 3,
      content: function LinkToStation(stationName) {
        return (
          <Clickable to={`/missioncontrol/station/${stationName}`}>
            {stationName}
          </Clickable>
        )
      }
    },
    testType: { label: 'Type', size: 1 },
    duration: {
      label: 'Duration',
      size: 1,
      content: duration => duration / 1000 + ' s'
    },
    result: { label: 'Result', size: 1 }
  },
  arrangement: [
    'to',
    'uuid',
    'testVersion',
    'stationName',
    'testType',
    'duration',
    'result'
  ],
  filters: {
    from: {
      type: 'date',
      label: 'Timestamp From'
    },
    to: {
      type: 'date',
      label: 'Timestamp To'
    },
    uuid: {
      type: 'text',
      label: 'UUID'
    },
    testVersion: {
      type: 'text',
      label: 'Version'
    },
    stationName: {
      type: 'text',
      label: 'Station'
    },
    testType: {
      type: 'enum',
      label: 'Type',
      options: [
        { value: 'controlled', label: 'Controlled' },
        { value: 'uncontrolled', label: 'Uncontrolled' }
      ]
    },
    result: {
      type: 'enum',
      options: [
        { value: 'Error', label: 'Error' },
        { value: 'Fail', label: 'Fail' },
        { value: 'Pass', label: 'Pass' }
      ],
      multi: true
    },
    ...(productId
      ? {
          messages: {
            type: 'enum',
            label: 'Messages',
            size: 2,
            multi: true,
            options: (inputFilter: string) =>
              getProductMessages(productId).then(({ data: axiosData }) =>
                axiosData.data
                  .filter(value => value.includes(inputFilter))
                  .map(message => ({
                    label: message,
                    value: message
                  }))
              )
          }
        }
      : {})
  }
})

const populateInterval = (data: Result[]) =>
  data.map(({ from, to, ...data }) => ({
    ...data,
    duration: interval(from, to),
    from,
    to
  }))

const exclusionList = ['uuid']

function forceExclusionListToKeys<T>() {
  return exclusionList as (keyof T)[]
}

export default function ProductResult({
  data,
  count,
  loading,
  error,
  setPage,
  rowProps,
  initialPage,
  tableTitle: title,
  query,
  includeUuid = true,
  selector,
  selectedTestId,
  entity,
  productId,
  ...props
}: SectionProp<Result> & {
  rowProps?: TableData<Result>['meta']['rowProps']
  initialPage?: number
  includeUuid?: boolean
  selector?: (datum: Result) => boolean
  selectedTestId?: string
  entity: string
  productId?: string
}) {
  const populatedData = useMemo(() => populateInterval(data), [data])
  const resultsMeta = useMemo(
    () =>
      createResultsMeta({
        selectedTestId,
        ...(productId ? { productId } : {})
      }),
    [selectedTestId, productId]
  )
  const meta = useMemo(
    () =>
      includeUuid
        ? resultsMeta
        : {
            ...resultsMeta,
            filters: omit(resultsMeta.filters, 'uuid')
          },
    [includeUuid, resultsMeta]
  )

  return (
    <Table
      {...(title ? { title } : {})}
      meta={{ ...meta, ...rowProps, ...(selector ? { selector } : {}) }}
      data={populatedData}
      keyExtractor={result => result.id}
      {...(query ? { query } : {})}
      {...(rowProps ? { rowProps } : {})}
      {...(!includeUuid
        ? {
            excludedFilterFields: forceExclusionListToKeys<
              typeof resultsMeta.fields
            >()
          }
        : {})}
      error={error}
      emptyMessage={'No results found for provided search criteria'}
      onPageChange={({ selected }) => setPage(selected)}
      count={count}
      loading={loading}
      {...(initialPage !== undefined ? { initialPage } : {})}
      name={entity}
      {...props}
    />
  )
}
