import React, { useMemo } from 'react'
import Content from '../../../containers/content'
import useAsync from '../../../hooks/use-async'
import { fetchTestResult } from '../../../services/entities/production-test'
import { isEmpty } from '../../../utils/common'
import TestResults from './test-results.page'

interface Props {
  fqdn: string
}

export default function FqdnResult({ fqdn }: Props) {
  const fqdnParams = useMemo(() => [fqdn], [fqdn])
  const { data, error, initialized } = useAsync({
    fn: fetchTestResult,
    args: fqdnParams
  })

  return (
    <Content
      title={'Production'}
      subtitle={['Test', `/production/${fqdn}/test`]}
      itemName={'Results'}
      loading={!initialized}
      error={error}>
      {!isEmpty(data) && (
        <TestResults
          data={data}
          download={URL.createObjectURL(
            new Blob([JSON.stringify(data)], { type: 'application/json' })
          )}
        />
      )}
    </Content>
  )
}
