import styled from '@emotion/styled/macro'
import React from 'react'
import AlignRight from './align-right'

export default function TopRightOptions({
  children,
  ...props
}: React.ComponentProps<typeof AlignRight>) {
  return (
    <AlignRight {...props}>
      <Container>{children}</Container>
    </AlignRight>
  )
}

const Container = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 8px;
`
