import styled from '@emotion/styled/macro'
import React from 'react'
import { interpretError } from '../utils/common'
import { capitalize } from '../utils/text'
import Button from './button'
import { maxWidth } from '../constants'

interface Prop {
  message?: string
  fullPage?: boolean
  error?: boolean
  buttonProps?: React.ComponentProps<typeof ErrorButton> & {
    label: string
  }
}

export default function Error({
  message = 'An error occured',
  fullPage = false,
  error = true,
  buttonProps
}: Prop) {
  const displayMessage = interpretError(message)
  return (
    <Container fullPage={fullPage}>
      {error && <Title>An error occured:</Title>}
      {message && <Subtitle>{capitalize(displayMessage)}</Subtitle>}
      {buttonProps && (
        <ErrorButton {...buttonProps}>{buttonProps.label}</ErrorButton>
      )}
    </Container>
  )
}

const ErrorButton = styled(Button)`
  margin-top: 32px;
  justify-self: center;
  min-width: 120px;
  width: fit-content;
  padding: 12px 24px;
  height: fit-content;
`

const Title = styled.div`
  font-size: 20px;
  line-height: 1.5;
  font-weight: 300;
`

const Subtitle = styled.div`
  font-size: 30px;
  line-height: 2;
  font-weight: 300;
`

const Container = styled.div<{ fullPage: boolean }>`
  display: grid;
  width: 100%;
  height: inherit;
  align-content: center;
  justify-content: center;
  color: #666;
  padding: 0 26px;
  max-width: ${maxWidth}px;
  margin: 0 auto;

  ${({ fullPage }) =>
    fullPage &&
    `
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
  `}
`
