import { ResultList, ListableParams } from '../../types/result-list'
import { User } from '../../types/user'
import api from '../common/api'
import { defaultPagePayload } from '../../constants'

export function fetchUsers(opts?: ListableParams) {
  return api.get<ResultList<User>>('/users', {
    params: {
      ...defaultPagePayload,
      ...opts
    }
  })
}

interface NewUser {
  email: string
  phoneNumber: string
  company: string
  enabled: boolean
  givenName: string
  familyName: string
}

export function addUser(user: NewUser) {
  return api.post('/users', user)
}

export function toggleAdmin(username: string, isAdmin: boolean) {
  const url = encodeURI(`/users/${username}/administrator`)
  return isAdmin ? api.delete(url) : api.post(url)
}

export function toggleOmniscience(username: string, isOmniscient: boolean) {
  const url = encodeURI(`/users/${username}/omniscience`)
  return isOmniscient ? api.delete(url) : api.post(url)
}

export function toggleActive(username: string, isActive: boolean) {
  const url = encodeURI(`/users/${username}/active`)
  return isActive ? api.delete(url) : api.post(url)
}
