import styled from '@emotion/styled/macro'
import React, { useEffect, useState, useCallback } from 'react'
import { Link } from 'react-router-dom'
import { ReactComponent as ChevronRight } from '../assets/icons/breadcrumb-arrow.svg'
import LoadingSpinner from './loading-spinner'
import { maxWidth } from '../constants'

export interface Props {
  title: string | string[]
  subtitle?: string | string[]
  itemName?: string | string[]
  itemNameLoading?: boolean
}

export default function PageTitle({
  title,
  subtitle,
  itemName,
  itemNameLoading = false
}: Props) {
  const [shadow, setShadow] = useState(`0px 0px 10px 0px #00000000`)

  const updateScroll = useCallback(() => {
    const opacity = Math.min(30, window.scrollY)
    let hex = opacity.toString(16)
    if (hex.length < 2) {
      hex = '0' + hex
    }
    setShadow(`0px 0px 10px 0px #000000${hex}`)
  }, [setShadow])

  useEffect(() => {
    updateScroll()
    window.addEventListener('scroll', updateScroll)
  }, [updateScroll])

  const showSseVersion = e => {
    //can obviously only use the hash from the previous commit so:
    //1) push changes to live branch
    //2) copy commit short hash and update it here
    //3) push to live again
    //4) build 
    if (e.shiftKey && title === "Engineer") {
      alert('SSE build: 1b06062')
    }
  }

  return (
    <Container shadow={shadow}>
      <InnerContainer>
        <Title onClick={e=>showSseVersion(e)}>
          {Array.isArray(title) ? (
            <Link to={title[1]}>{title[0] ?? ''}</Link>
          ) : (
            title
          )}
          {Array.isArray(subtitle) ? (
            <Link to={subtitle[1]}>
              <SubTitle>{subtitle[0] ?? ''}</SubTitle>
            </Link>
          ) : (
            <SubTitle>{subtitle}</SubTitle>
          )}
          {itemName && (
            <>
              <StyledChevronRight />
              {Array.isArray(itemName) ? (
                <Link to={itemName[1]}>
                  <ItemName>
                    {itemNameLoading ? (
                      <LoadingSpinner variant={'dark'} />
                    ) : (
                      itemName[0]
                    )}
                  </ItemName>
                </Link>
              ) : (
                <ItemName>
                  {itemNameLoading ? (
                    <LoadingSpinner variant={'dark'} />
                  ) : (
                    itemName
                  )}
                </ItemName>
              )}
            </>
          )}
        </Title>
      </InnerContainer>
    </Container>
  )
}

const StyledChevronRight = styled(ChevronRight)`
  width: 16px;
  height: 18px;
  fill: #a4a4a4;
  margin-left: 8px;
  margin-right: 8px;
`

const ItemName = styled.span`
  font-size: 18px;
  font-weight: 300;
`

const SubTitle = styled.span`
  font-weight: 300;
  margin-left: 10px;
`

const Title = styled.div`
  font-weight: bold;
  font-size: 18px;
  line-height: 36px;
  color: #23201f;
  height: 32px;
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-column-gap: 2px;
  align-items: center;
  transform: translateX(73px);
  margin-top: 30px;
  width: fit-content;
`

const Container = styled.div<{ shadow?: string }>`
  background-color: #f2f2f2;
  height: 88px;
  position: fixed;
  z-index: 3;
  transition: 0;
  left: 0;
  width: 100%;
  box-shadow: ${props => props.shadow};
  display: flex;
  justify-content: center;
`

const InnerContainer = styled.div`
  max-width: ${maxWidth}px;
  flex: 1;
`
