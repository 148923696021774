import { css, Global } from '@emotion/core'
import { observer } from 'mobx-react-lite'
import React, { useContext, useEffect } from 'react'
import globalStyle from '../assets/styles/global'
import RootStore, { RootStoreContext } from '../context/root-store'
import Routes from '../routes'

export default observer(App)

function App() {
  const {
    authentication: { startPool, isLoggedIn }
  } = useContext(RootStoreContext)

  useEffect(() => {
    if (isLoggedIn) startPool()
  }, [isLoggedIn, startPool])

  return (
    <>
      <Global
        styles={css`
          ${globalStyle}
        `}
      />
      <RootStore>
        <Routes />
      </RootStore>
    </>
  )
}
