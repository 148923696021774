import { useState } from 'react'
import { Nullable } from '../types/misc'

export default function useStatusError({
  status: initialStatus = null,
  error: initialError = null
} = {}) {
  const [error, internalSetError] = useState<Nullable<string>>(initialError)
  const [status, internalSetStatus] = useState<Nullable<string>>(initialStatus)

  const setError = (err: string) => {
    internalSetStatus(null)
    internalSetError(err)
  }

  const setStatus = (status: string) => {
    internalSetError(null)
    internalSetStatus(status)
  }

  const reset = () => {
    internalSetError(null)
    internalSetStatus(null)
  }

  return { error, status, setError, setStatus, reset }
}
