import styled from '@emotion/styled/macro'
import React, { useMemo, useState } from 'react'
import useLocalStorage from '../hooks/use-local-storage'
import { Gridder } from './grids'

export interface HorizontalTabOption {
  title: string
  subtitle?: string
  component: React.FunctionComponent
}

interface Props {
  options: HorizontalTabOption[]
  selectedOption?: number
  height?: string
  searchable?: boolean
  navigationProps?: {
    width?: string
    fontSize?: number
  }
  persistenceKey?: string
  nested?: boolean
  padOptions?: boolean
}

export default function HorizontalTab<T>({
  options,
  selectedOption,
  height = '382px',
  searchable = false,
  navigationProps = {
    width: '200px',
    fontSize: 15
  },
  nested = false,
  persistenceKey,
  padOptions = true
}: Props) {

  const [currentOption, setCurrentOption] = useLocalStorage(
    persistenceKey,
    selectedOption ?? 0
  )
  
  const [searchQuery, setSearchQuery] = useState('')
  const OptionComponent = useMemo(
    () => options[currentOption]?.component,
    [ currentOption, options ]
  )

  const filteredOptions = useMemo(
    () =>
      options.filter(
        option =>
          option?.subtitle?.toLowerCase().includes(searchQuery.toLowerCase()) ||
          option.title.toLowerCase().includes(searchQuery.toLowerCase())
      ),
    [options, searchQuery]
  )


  return (
    <Container
      template={[navigationProps.width, '1fr']}
      gap={nested ? '0px' : '20px'}
      height={height}
      nested={nested}>
      <NavigationContainer style={{ height }}>
        <div>
          {searchable && (
            <SearchInput
              width={Number(navigationProps.width.replace('px', ''))}
              type="text"
              placeholder="Search"
              onChange={event => setSearchQuery(event?.currentTarget?.value)}
            />
          )}
        </div>
        <Navigation searchable={searchable}>
          {filteredOptions.map((option: HorizontalTabOption, index: number) => (
            <Option
              selected={index === currentOption}
              key={option.title}
              onClick={() => setCurrentOption(index)}>
              <OptionTitle style={{ fontSize: navigationProps.fontSize }}>
                {option.title}
              </OptionTitle>
              {option.subtitle && (
                <OptionSubtitle
                  style={{ fontSize: navigationProps.fontSize - 1 }}>
                  {option.subtitle}
                </OptionSubtitle>
              )}
            </Option>
          ))}
        </Navigation>
      </NavigationContainer>
      <OptionContent>
        <OptionContentContainer padOptions={padOptions}>
          {OptionComponent && <OptionComponent />}
        </OptionContentContainer>
      </OptionContent>
    </Container>
  )
}

const NavigationContainer = styled.div`
  padding-top: 8px;
`

const SearchInput = styled.input<{ width: number }>`
  padding: 8px 12px;
  margin: 12px auto 12px auto;
  background-color: #fff;
  border-radius: 99px;
  width: ${({ width }) => width - 32}px;
`

const Container = styled(Gridder)<{
  height: string
  child?: boolean
  nested?: boolean
  backgroundColor?: string
}>`
  background-color: ${({ backgroundColor }) => backgroundColor?backgroundColor:'#f9f9f9;'};
  border-radius: ${({ nested }) => (nested ? '0px' : '6px')};
  margin: ${({ nested }) => (nested ? '0px' : '20px')};
  /* padding: 0px 4px 0px 0px; */
  max-height: ${({ height }) => height};
  box-shadow: ${({ nested }) =>
    nested ? '0' : '0 2px 4px 0 rgba(0, 0, 0, 0.08)'};
`

const OptionContentContainer = styled.div<{ padOptions?: boolean }>`
  ${({ padOptions }) => padOptions && `padding: 20px`};
`

// const OptionContentContainer = styled.div<{ nested?: boolean }>`
//    ${({ nested }) => !nested && `padding: 12px 20px`};
// `

const OptionContent = styled.div<{ nested?: boolean }>`
  align-self: start;
  overflow-y: ${({ nested }) => (nested ? 'hidden' : 'scroll')};
  height: 100%;
  /* margin-right: 12px; */

  scrollbar-width: thin;
  scrollbar-color: #888;

  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-track {
    margin-top: 32px;
    margin-bottom: 32px;
  }
  &::-webkit-scrollbar-button {
    background: transparent;
  }
  &::-webkit-scrollbar-track-piece {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    height: 20px;
    border-radius: 20px;
    background: rgba(0, 0, 0, 0.1);
  }
`

const Navigation = styled.div<{ searchable?: boolean }>`
  height: ${({ searchable }) =>
    searchable ? `calc(100% - 68px)` : `calc(100% - 20px)`};
  overflow-y: scroll;
  overflow-x: hidden;

  scrollbar-width: thin;
  scrollbar-color: #888;
  // margin-top: 6px;
  // margin-bottom: 6px;

  &::-webkit-scrollbar {
    width: 2px;
  }
  &::-webkit-scrollbar-button {
    background: #999;
  }
  &::-webkit-scrollbar-track-piece {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.1);
  }
`

const OptionSubtitle = styled.p`
  font-size: 15px;
  line-height: 1.4;
  color: #999;
`

const OptionTitle = styled.p`
  font-size: 16px;
  font-weight: bold;
  line-height: 1.6;
`

const Option = styled.div<{ selected?: boolean }>`
  padding-top: 6px;
  padding-bottom: 6x;
  padding-left: 8px;
  padding-right: 8px;
  cursor: pointer;
  transition: 400ms all;
  position: relative;
  word-break: break-all;
  border: 1px solid ${({ selected }) => (selected ? '#eaeaea' : '#f3f3f3')};

  ${({ selected }) =>
    selected &&
    ` 
      background-color: #EAEAEA;
    `}
`
