import styled from '@emotion/styled/macro'
import { ErrorMessage, Field, Form, FormikProps, withFormik } from 'formik'
import React, { useCallback, useRef } from 'react'
import * as Yup from 'yup'
import { ReactComponent as UploadIcon } from '../../../assets/icons/arrow-up.svg'
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg'
import Button from '../../../components/button'
import CenteredMargin from '../../../components/centered-margin'
import {
  FormContainer,
  InputError,
  InputGroup,
  SubmitContainer
} from '../../../components/form'
import Status from '../../../components/status'
import {
  postProductDetails,
  postProductImage
} from '../../../services/entities/product'
import { AsyncRequest } from '../../../types/async'
import { Base64Media } from '../../../types/misc'
import { ProductInfo } from '../../../types/product'
import { interpretError } from '../../../utils/common'
import ImageHoverCard from '../../../components/image-hover-card'

interface Props extends ProductInfo {
  productId: string
  onSave: () => void
  image: AsyncRequest<Base64Media>
  refreshData: AsyncRequest<ProductInfo>['refreshData']
  imageData: string
  onDeleteConfirm: () => void
  isDeleting: boolean
}

const productDetailsSchema = Yup.object().shape({
  description: Yup.string(),
  manufacturer: Yup.string(),
  productImage: Yup.string()
})

const initialValues = {
  description: '',
  manufacturer: '',
  productImage: ''
}

export default withFormik<Props, typeof initialValues>({
  mapPropsToValues: ({ manufacturer = '', description = '' }) => ({
    manufacturer,
    description,
    productImage: undefined
  }),
  handleSubmit: async (values, { resetForm, props, setStatus }) => {
    try {
      await Promise.all([
        postProductDetails(props.productId, values),
        values.productImage
          ? postProductImage(props.productId, values.productImage)
          : new Promise(res => res())
      ])
      resetForm()
      props.onSave()
    } catch (err) {
      setStatus(interpretError(err))
    }
  },
  validationSchema: productDetailsSchema,
  validateOnBlur: true,
  enableReinitialize: true
})(EditProductDetailsForm)

function EditProductDetailsForm({
  isSubmitting,
  status,
  errors,
  isValid,
  dirty,
  initialValues,
  getFieldProps,
  setFieldValue,
  image,
  imageData,
  values,
  onDeleteConfirm
}: Props & FormikProps<typeof initialValues>) {
  const fileInput = useRef<HTMLInputElement | null>(null)
  const onUploadClick = (e: React.MouseEvent) => {
    e.stopPropagation()
    fileInput?.current?.click()
  }

  const onSelectImage = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e?.currentTarget?.files[0]) {
      const reader = new FileReader()
      reader.readAsDataURL(e.currentTarget.files[0])
      reader.onload = async () => {
        setFieldValue('productImage', reader.result)
      }
    }
  }

  const onConfirmDelete = useCallback(() => {
    if (
      window.confirm(
        `Are you sure you'd like to delete this product image? This action cannot be undone.`
      )
    ) {
      setFieldValue('productImage', undefined)
      onDeleteConfirm()
      if (fileInput?.current) fileInput.current.value = ''
    }
  }, [setFieldValue, onDeleteConfirm])

  return (
    <>
      <Form>
        <FileUpload
          type="file"
          ref={fileInput}
          onChange={onSelectImage}
          accept="image/x-png"
        />
        {status && <StyledStatus>{status}</StyledStatus>}
        <Container>
          <ImageHoverCard
            clickable={false}
            width={'226px'}
            height={'226px'}
            backgroundColor={'transparent'}>
            <img alt={'Headphone'} src={values?.productImage ?? imageData} />
            <FloatingTopRightOptions>
              <Option onClick={onUploadClick}>
                <UploadIcon fill={'#fff'} height={14} width={14} />
              </Option>
              {!image.error && image?.data?.base64 && !values.productImage && (
                <Option onClick={onConfirmDelete}>
                  <CloseIcon fill={'#fff'} height={10} width={10} />
                </Option>
              )}
            </FloatingTopRightOptions>
          </ImageHoverCard>
          {/* <Preview clickable={false} style={{ height: 226, width: 226 }}> */}

          {/* </Preview> */}
          <EditForm>
            <CenteredMargin>
              <Label>Manufacturer</Label>
              <InputGroup>
                <Field
                  placeholder="Manufacturer"
                  name="manufacturer"
                  error={errors.manufacturer}
                />
                <InputError>
                  <ErrorMessage name="manufacturer" />
                </InputError>
              </InputGroup>
            </CenteredMargin>
            <CenteredMargin>
              <Label>Description</Label>
              <InputGroup>
                <TextArea
                  rows={5}
                  name="description"
                  {...getFieldProps('description')}
                />
                <InputError>
                  <ErrorMessage name="description" />
                </InputError>
              </InputGroup>
            </CenteredMargin>
            <SubmitCenteredMargin>
              <SubmitContainer>
                <Button
                  type="submit"
                  disabled={!(dirty && isValid)}
                  loading={isSubmitting}>
                  Save Changes
                </Button>
              </SubmitContainer>
            </SubmitCenteredMargin>
          </EditForm>
        </Container>
      </Form>
    </>
  )
}

const StyledStatus = styled(Status)`
  margin-bottom: 24px;
  margin-top: 0;
  line-height: 1;
`

const Label = styled.label`
  margin-bottom: 4px;
  font-size: 14px;
`

const SubmitCenteredMargin = styled(CenteredMargin)`
  margin-top: 0px !important;
`

const Option = styled.div<{ disabled?: boolean }>`
  display: grid;
  align-items: center;
  justify-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 100%;
  height: 28px;
  width: 28px;
  margin-right: 6px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};

  svg,
  svg path {
    fill: #fff;
  }
`

const TextArea = styled.textarea`
  line-height: 1.5;
  padding-top: 8px;
  color: inherit;
`

const EditForm = styled(FormContainer)`
  width: 400px;
  margin-top: 0px;
`

const Container = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 24px;
`

const FloatingTopRightOptions = styled.div`
  position: absolute;
  right: 2px;
  top: 10px;
  display: grid;
  grid-auto-flow: column;
  z-index: 2;
`

const FileUpload = styled.input`
  visibility: hidden;
  height: 0;
  overflow: hidden;
  width: 0;
  position: absolute;
`
