import styled from '@emotion/styled/macro'
import { ErrorMessage, Field, Form, FormikProps, withFormik } from 'formik'
import React from 'react'
import * as Yup from 'yup'
import AlignRight from '../../../components/align-right'
import Button from '../../../components/button'
import CenteredMargin from '../../../components/centered-margin'
import { InputError } from '../../../components/form'
import Status from '../../../components/status'
import { fqdn } from '../../../regex'
import { interpretError } from '../../../utils/common'
import { createRequiredTextField } from '../../../utils/form'

interface Props {
  closeModal?: () => void
  add: ({ fqdn }: { fqdn: string }) => void
}

const initialValues = {
  fqdn: ''
}

const addStationSchema = Yup.object().shape({
  fqdn: createRequiredTextField('fqdn', 'Enter station name').matches(
    fqdn,
    'Enter a valid station name'
  )
})

export default withFormik<Props, typeof initialValues>({
  mapPropsToValues: () => ({
    ...initialValues
  }),
  handleSubmit: (
    { fqdn },
    { setStatus, resetForm, props: { closeModal, add } }
  ) => {
    try {
      add({ fqdn })
      resetForm()
      closeModal()
    } catch (err) {
      setStatus(interpretError(err))
    }
  },
  validationSchema: addStationSchema
})(AddStationForm)

function AddStationForm({
  isSubmitting,
  isValid,
  dirty,
  status
}: FormikProps<typeof initialValues> & Props) {
  return (
    <StyledForm>
      {status && <Status>{status}</Status>}
      <AddStationCenteredMargin>
        <AddStationInline>
          <label htmlFor="fqdn">Station Name</label>
          <div>
            <Field type="input" placeholder="Station Name" name="fqdn" />
            <InputError>
              <ErrorMessage name="fqdn" />
            </InputError>
          </div>
        </AddStationInline>
      </AddStationCenteredMargin>
      <SubmitButtonAlignRight>
        <Button
          type="submit"
          disabled={!isValid || !dirty}
          loading={isSubmitting}>
          Add Station
        </Button>
      </SubmitButtonAlignRight>
    </StyledForm>
  )
}

const SubmitButtonAlignRight = styled(AlignRight)`
  margin-bottom: 0px;
`

const StyledForm = styled(Form)`
  margin-top: 24px;
`

const AddStationCenteredMargin = styled(CenteredMargin)`
  line-height: 40px;
`

const AddStationInline = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 92px 1fr;
  grid-gap: 12px;
  width: 320px;

  label {
    align-self: start;
  }
`
